import {
	fork,
	takeEvery,
	takeLatest,
	put,
	all,
	call,
	select,
	delay,
} from 'redux-saga/effects';
import { agentPlayersActions } from './actions';
import { agentPlayersReducers } from './slice';
import { logger } from '../../helpers/logger';
import {
	getUser,
	restorePartnerID,
	restoreWebsite,
	storeWebsiteID,
} from '../../helpers/utility';
import { agentPlayersAPI } from '../../helpers/api/agentPlayers';
import { TABLE_TYPES } from '../../constants/tableTypes';
import { RootState } from '../store';
import {
	IAgentAdjustmentModalArgument,
	IAgentData,
	IAgentPlayerSettings,
	IAgentPlayersBaseData,
	IAgentPlayersDataResponse,
	IAgentPlayersDepositAndWithdrawData,
} from './types';
import { AxiosApiResponse } from '../../helpers/api/types';
import {
	adaptAgentPlayerData,
	adaptAgentPlayersList,
	adaptSettingsData,
	getListParams,
	prepareAgentPlayerAdjustmentData,
	prepareAgentPlayerData,
} from './utils';
import { ISagaActionType } from '../types';
import notifications from '../../helpers/notifications';
import { messages } from './messages';
import { getHeadersTotalCount } from '../../helpers/utils';
import tableActions from '../tables/actions';

const tableType = TABLE_TYPES.agentPlayerList;

function getStoreData(state: RootState) {
	const { AgentPlayers, Tables, App } = state;

	return {
		filter            : AgentPlayers.filter,
		pagination        : Tables.get(tableType).pagination,
		sorting           : Tables.get(tableType).sorting,
		websiteID         : App.get('websiteID'),
		partnerID         : App.get('partnerID'),
		baseData          : AgentPlayers.baseData,
		updatedData       : AgentPlayers.updatedData,
		changePasswordData: AgentPlayers.changePasswordData,
		adjustmentData    : AgentPlayers.depositAndWithdrawData,
		playerID          : AgentPlayers.playerID,
	};
}

function* getAgentPlayersList() {
	yield takeEvery(agentPlayersActions.GET_AGENT_PLAYER_LIST, function* () {
		yield put(agentPlayersReducers.setUI({ listLoading: true }));
		const { filter, pagination, sorting } = yield select(getStoreData);

		const agentData = getUser();
		const params = getListParams(filter, pagination, sorting);
		try {
			const result: AxiosApiResponse<IAgentPlayersBaseData[]> = yield call(
				agentPlayersAPI.getAgentPlayerList,
				params
			);
			const adapted: IAgentPlayersBaseData[] = adaptAgentPlayersList(
				result.data.data
			);
			const totalCount = getHeadersTotalCount(result.headers);
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
			yield put(agentPlayersReducers.setAgentPlayers(adapted));
			yield call(storeWebsiteID, agentData.websiteID);
			yield put(agentPlayersActions.updateMyData());
		} catch (e) {
			logger.log('e', e);
		}
		yield delay(1500);
		yield put(agentPlayersReducers.setUI({ listLoading: false }));
	});
}

function* createAgentPlayer() {
	yield takeEvery(agentPlayersActions.CREATE_AGENT_PLAYER, function* () {
		yield put(agentPlayersReducers.setUI({ modalLoading: true }));
		const { baseData } = yield select(getStoreData);
		const websiteID = restoreWebsite();
		const partner_id = restorePartnerID();
		try {
			const params = { website_id: websiteID };
			const prepared: Partial<IAgentPlayersDataResponse> =
        prepareAgentPlayerData(baseData);

			yield call(
				agentPlayersAPI.createAgentPlayer,
				{ data: prepared, partner_id },
				params
			);
			notifications.showSuccess(messages.success);
			yield put(agentPlayersActions.agentPlayerListReload());
			yield put(agentPlayersReducers.resetBaseData());
			yield put(agentPlayersReducers.setUI({ visible: false }));
		} catch (error) {
			notifications.showError(`${error.response.data.text}`);
		}
		yield put(agentPlayersReducers.setUI({ modalLoading: false }));
	});
}

function* updateAgentPlayerPassword() {
	yield takeEvery(
		agentPlayersActions.UPDATE_AGENT_PLAYER_PASSWORD,
		function* () {
			yield put(agentPlayersReducers.setUI({ modalLoading: true }));
			const { changePasswordData } = yield select(getStoreData);
			const websiteID = restoreWebsite();
			const partner_id = restorePartnerID();
			try {
				const data = { new_password: changePasswordData.newPassword };
				const params = { website_id: websiteID };
				yield call(
					agentPlayersAPI.changePlayerPassword,
					changePasswordData.playerID,
					{ data, partner_id },
					params
				);
				yield put(agentPlayersReducers.resetPlayerChangePasswordData());
				notifications.showSuccess(messages.passwordChange);
			} catch (e) {
				logger.log('e', e);
			}
			yield put(agentPlayersReducers.setUI({ changePassword: false }));
		}
	);
}

function* updateAgentPlayer() {
	yield takeEvery(agentPlayersActions.UPDATE_AGENT_PLAYER, function* () {
		yield put(agentPlayersReducers.setUI({ modalLoading: true }));
		const { updatedData, playerID } = yield select(getStoreData);
		const data = { status_id: updatedData.status };
		try {
			yield call(agentPlayersAPI.updateAgentPlayer, playerID, data);
			yield put(agentPlayersActions.agentPlayerListReload());
			notifications.showSuccess(messages.update);
		} catch (e) {
			logger.log('e', e);
			notifications.showError(messages.error);
		}
		yield put(agentPlayersReducers.setUI({ modalLoading: false }));
	});
}

// function* deleteAgentPlayer() {
// 	yield takeEvery(agentPlayersActions.DELETE_AGENT_PLAYER, function* (action: ISagaActionType<number>) {
// 		const { data: playerID } = action;
// 		try {
// 			const params = { website_id: restoreWebsite() };
// 			yield call(agentPlayersAPI.deleteAgentPlayer, playerID, params);
// 			yield put(agentPlayersActions.agentPlayerListReload());
// 		} catch (e) {
// 			logger.log('e', e);
// 		}
// 	});
// }

function* getAgentPlayerInfo() {
	yield takeEvery(agentPlayersActions.GET_AGENT_PLAYER_INFO, function* () {
		yield put(agentPlayersReducers.setUI({ listLoading: true }));
		try {
			const resultCountries: AxiosApiResponse<{ id: number }[]> = yield call(
				agentPlayersAPI.getCountriesAgentPlayer
			);
			const resultCurrency: AxiosApiResponse<{ id: number }[]> = yield call(
				agentPlayersAPI.getCurrencyAgentPlayer
			);
			const resultLanguages: AxiosApiResponse<{ id: number }[]> = yield call(
				agentPlayersAPI.getLanguagesAgentPlayer
			);
			yield put(
				agentPlayersReducers.setAgentPlayerInfo({
					countries: resultCountries.data.data,
					currency : resultCurrency.data.data,
					languages: resultLanguages.data.data,
				})
			);
		} catch (e) {
			logger.log('e', e);
		}
		yield put(agentPlayersReducers.setUI({ listLoading: false }));
	});
}

function* getAgentPlayerByID() {
	yield takeEvery(
		agentPlayersActions.GET_AGENT_PLAYER_BY_ID,
		function* (action: ISagaActionType<number>) {
			const { data } = action;
			yield put(agentPlayersReducers.setUI({ listLoading: true }));
			try {
				const result: AxiosApiResponse<IAgentPlayersBaseData> = yield call(
					agentPlayersAPI.getAgentPlayerByID,
					{ id: data }
				);
				const adapted: IAgentPlayersBaseData = adaptAgentPlayerData(
					result.data.data[0]
				);
				yield put(agentPlayersReducers.setAgentPlayerData(adapted));
				yield put(agentPlayersActions.updateMyData());
			} catch (e) {
				logger.log('e', e);
				notifications.showError(messages.error);
			}
			yield delay(1500);
			yield put(agentPlayersReducers.setUI({ listLoading: false }));
		}
	);
}
function* updateMe() {
	yield takeEvery(agentPlayersActions.UPDATE_MY_DATA, function* () {
		let data = getUser();
		yield put(agentPlayersReducers.setUI({ listLoading: true }));
		try {
			const result: AxiosApiResponse<IAgentData> = yield call(
				agentPlayersAPI.getAgentData,
				data.id
			);
			data = {
				...data,
				...result.data.data,
			};
			yield localStorage.setItem('user', JSON.stringify(data));
		} catch (e) {
			logger.log('e', e);
			notifications.showError(messages.error);
		}
		yield put(agentPlayersReducers.setUI({ listLoading: false }));
	});
}

function* getAgentPlayerSettings() {
	yield takeEvery(agentPlayersActions.GET_AGENT_PLAYER_SETTINGS, function* () {
		const agentData = getUser();
		try {
			const result: AxiosApiResponse<IAgentPlayerSettings> = yield call(
				agentPlayersAPI.getAgentPlayerSettings,
				agentData.websiteID
			);

			yield put(
				agentPlayersReducers.setAgentPlayerSettings(
					adaptSettingsData(result.data.data.user_required_fields)
				)
			);
		} catch (e) {
			logger.log('Fetch error:', e);
		}
	});
}

function* openAgentAdjustmentModal() {
	yield takeLatest(agentPlayersActions.OPEN_AGENT_ADJUSTMENT_MODAL, function* (action: ISagaActionType<IAgentAdjustmentModalArgument>) {
		try {
			const { data } = action;
			yield put(agentPlayersActions.updateMyData());
			yield put(agentPlayersReducers.setUI(data.adjustmentData));
			yield put(agentPlayersReducers.setPlayerAdjustmentData(data.adjustmentTitle));
      
		} catch (e) {
			logger.log('Fetch error:', e);
		}
	});
}

function* getAgentPlayerAdjustment() {
	yield takeLatest(
		agentPlayersActions.GET_AGENT_PLAYER_ADJUSTMENT,
		function* () {
			yield put(
				agentPlayersReducers.setUI({
					adjustmentInProgress: true,
				})
			);
			const { adjustmentData, playerID } = yield select(getStoreData);
			const partner_id = restorePartnerID();
			try {
				const params = { partner_id };
				const prepared: Partial<IAgentPlayersDepositAndWithdrawData> =
          prepareAgentPlayerAdjustmentData(adjustmentData);
				const url = `players/${playerID}/${adjustmentData.isDeposit ? 'deposit' : 'withdraw'}`;
				yield call(
					agentPlayersAPI.setAgentPlayerAdjustment,
					url,
					prepared,
					params
				);
				const result: AxiosApiResponse<IAgentPlayersBaseData> = yield call(
					agentPlayersAPI.getAgentPlayerByID,
					{ id: playerID }
				);
				const adapted: IAgentPlayersBaseData = adaptAgentPlayerData(
					result.data.data[0]
				);
				yield put(agentPlayersReducers.setAgentPlayerData(adapted));
				yield put(agentPlayersReducers.resetPlayerAdjustmentData());
				yield put(agentPlayersActions.updateMyData());
				yield put(
					agentPlayersReducers.setUI({
						adjustmentInProgress: false,
						depositAndWithdraw  : false,
					})
				);

				notifications.showSuccess(messages.update);
			} catch (e) {
				yield put(
					agentPlayersReducers.setUI({
						adjustmentInProgress: false,
					})
				);
	
				notifications.showError(`${e.response.data.text}`);
			}
		}
	);
}

export default function* agentsSaga() {
	yield all([
		fork(getAgentPlayersList),
		fork(createAgentPlayer),
		fork(getAgentPlayerInfo),
		fork(getAgentPlayerByID),
		fork(updateAgentPlayer),
		fork(updateAgentPlayerPassword),
		fork(getAgentPlayerSettings),
		fork(getAgentPlayerAdjustment),
		fork(updateMe),
		fork(openAgentAdjustmentModal),
		// fork(deleteAgentPlayer),
	]);
}
