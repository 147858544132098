import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isUndefined, isEmpty, isNull, toInteger } from 'lodash';
import { Select, Spin } from 'antd';
import { checkMultiselect, findFromList, useOnScreen, createPlaceholder, ShowSpinnerOption, messages } from './utils';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
export default function GamesListInfinite({
	multiSelect,
	list,
	style,
	onLoadData,
	onRefreshSelected,
	hasMore,
	searchLength,
	loading,
	label,
	returnId,
	limit,
	disabled,
	shouldLoadOnClick,
	outerVal,
	onBlur,
	editMode,
	isFilterBuilder,
	gameEntity,
	filterRefresh,
	isMassBonus,
	pageReset,
	uiRefresh,
}) {
	const antIcon = (
		<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }}/>} />
	);

	const initState = {
		selected        : checkMultiselect(multiSelect),
		loading         : false,
		pagination      : 1,
		optionsloading  : false,
		isCleared       : !(searchLength >= 0),
		value           : '',
		noDataFromLength: 320,
		selectedHistory : [],
		hasMore         : true,
	};

	const [state, setState] = useState(initState);

	// useEffect --------------------------------------------------------------------------------------
	useEffect(() => {
		if (pageReset) {
			setState(prevState => ({ ...prevState, pagination: 1 }));
		}
		if (typeof uiRefresh === 'function') {
			uiRefresh({ pageReset: false });
		}
	}, [pageReset]);

	useEffect(() => {

		const { selected } = state;

		let idsSelelcted;
		if (multiSelect) {
			if (returnId) {
				const transferToId = selected?.map(selectedItem => findFromList(list, selectedItem));
				idsSelelcted       = isEmpty(transferToId) ? null : transferToId;
			} else {
				idsSelelcted = isEmpty(selected) ? null : selected;
			}
		} else if (returnId) {
			const transferToId = findFromList(list, selected);
			idsSelelcted       = isUndefined(transferToId) ? null : [transferToId];
		} else {
			idsSelelcted = isEmpty(selected) ? null : [selected];
		}

		onRefreshSelected(idsSelelcted);

		return () => {
			if (isMassBonus) return;
			setState(initState);
		};
	}, [state.selected?.length]);


	useEffect(() => {

		let startLength;
		let lessLimit;

		if (list.length > 0) startLength = 320;
		else {
			startLength = state.value.length <= searchLength ? 320 : state.value.length;
		}

		if (isNull(hasMore)) {
			lessLimit = list.length >= limit;
		} else lessLimit = hasMore;

		setState(prevState => (
			{
				...prevState,
				loading       : false,
				optionsLoading: false,
				isCleared     : !(
					state.value.length >= searchLength || (
						shouldLoadOnClick && state.value.length === 0
					)
				),
				noDataFromLength: startLength,
				hasMore         : lessLimit,
			}
		));
	}, [list]);

	useEffect(() => {
		setState(prevState => (
			{
				...prevState,
				loading       : loading,
				optionsLoading: loading,
			}
		));
	}, [loading]);


	// Spinner with Ref -------------------------------------------------------------------------------

	const DropDownBottom = () => {

		const ref       = useRef();
		const isVisible = useOnScreen(ref);

		useEffect(() => {

			if (isVisible) {
				onLoadData(state.value, ++state.pagination, false, limit);
				setState(prevState => (
					{
						...prevState,
						loading   : true,
						pagination: prevState.pagination++,
					}
				));
			}
		}, [isVisible]);

		return <span ref={ref} />;
	};

	const [innserVal, setInnerVal] = useState(null);
	// events -----------------------------------------------------------------------------------------

	const onChange = (value) => {
		setInnerVal(value);
		setState(prevState => (
			{
				...prevState,
				selected: checkMultiselect(multiSelect, value, prevState.selected),
			}
		));
		if (!value) {
			
			if (typeof filterRefresh === 'function') {
				filterRefresh({ 'casinoGameID': null });
			}
			onRefreshSelected([null]);
		}

	};

	const onSearch = (value) => {
		if (value.length >= searchLength) {
			if (value.length < state.noDataFromLength) {

				onLoadData(value, state.pagination, true, limit);

				setState(prevState => (
					{
						...prevState,
						loading       : true,
						pagination    : 1,
						optionsLoading: true,
						value,
						isCleared     : false,
					}
				));

			} else {
				setState(prevState => {
					if (prevState.value !== value.slice(0, prevState.value.length)) {

						onLoadData(value, state.pagination, true, limit);
						return {
							...prevState,
							loading       : true,
							pagination    : 1,
							optionsLoading: true,
							value,
							isCleared     : false,
						};
					}
					return prevState;
				});
			}

		} else if (value.length === 0 && shouldLoadOnClick) {
			onLoadData(undefined, state.pagination, true, limit);
			setState(prevState => (
				{
					...prevState,
					loading       : true,
					optionsLoading: true,
					pagination    : 1,
					value,
					isCleared     : true,
				}
			));
		} else {

			setState(prevState => (
				{
					...prevState,
					loading       : false,
					optionsLoading: false,
					value,
					isCleared     : true,
				}
			));
		}


	};

	useEffect(() => {
		!outerVal && setInnerVal(null);
	}, [outerVal]);

	useEffect(() => {

		if (((editMode || isMassBonus) && outerVal && list.length) 
			|| (isFilterBuilder && outerVal) 
			|| (isMassBonus && outerVal && gameEntity)) {
			const findedGame = list.find(game => game.id === toInteger(outerVal)) || gameEntity || {};
	
			setInnerVal(findedGame.value);
		}
		
	}, [outerVal, list, gameEntity]);


	// return/render ----------------------------------------------------------------------------------

	return (
		<div
			style={{
				width: '100%',
			}}
			onBlur={() => {
				if (!isMassBonus) {
					setState(prevState => (
						{
							...prevState,
							loading       : false,
							optionsLoading: false,
							value         : '',
							isCleared     : !shouldLoadOnClick,
							pagination    : 1,
						}
					));
					if (onBlur) { onBlur(); }
				}
			}}
			onClick={() => {
				if (shouldLoadOnClick && list.length === 0) {
					setState(prevState => {
						return {
							...prevState,
							loading       : true,
							pagination    : 1,
							optionsLoading: true,
							value         : '',
							isCleared     : false,
						};
					});
					onLoadData(undefined, state.pagination, true, limit);
				}

			}}
		>
			<Select
				value={innserVal}
				mode={multiSelect ? 'multiple' : multiSelect}
				showSearch
				style={style}
				placeholder={label}
				onSearch={onSearch}
				onChange={onChange}
				onBlur={onBlur}
				allowClear
				loading={state.loading}
				dropdownStyle={isMassBonus ? {} : { position: 'absolute' }}
				disabled={disabled}
				dropdownRender={menu => (
					<div>
						{menu}
						{state.loading && <ShowSpinnerOption loading={state.loading} icon={antIcon} />}
					</div>
				)}
				notFoundContent={
					createPlaceholder(state.value, searchLength, state.loading, messages, shouldLoadOnClick)
				}
			>
				{!state.isCleared && list.map((item, index) => {
					return (
						<Option value={item.value} key={item.id} disabled={state.optionsloading}>
							<div className="demo-option-label-item">
								{item.icon && <span role="img"> {item.icon} </span>}
								{item.url && <img src={item.url} alt="avatar" />}
								{item.value}
							</div>
							{(
								list.length === (
									index + 1
								)
							// eslint-disable-next-line  no-mixed-operators
							) && !state.loading && list.length > 7 && state.hasMore && hasMore || (
								hasMore && state.value.length === 0 && shouldLoadOnClick && searchLength > 0 && !state.loading && list.length === (
									index + 1
								)
							) ? <DropDownBottom /> : null}
						</Option>
					);
				})}
			</Select>
		</div>
	);
}

GamesListInfinite.propTypes = {
	style            : PropTypes.object,
	label            : PropTypes.string,
	searchLength     : PropTypes.number,
	hasMore          : PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(null)]),
	returnId         : PropTypes.bool,
	loading          : PropTypes.bool,
	disabled         : PropTypes.bool,
	shouldLoadOnClick: PropTypes.bool,
	onBlur           : PropTypes.func,
	list             : PropTypes.array.isRequired,
	multiSelect      : PropTypes.bool.isRequired,
	onLoadData       : PropTypes.func.isRequired,
	onRefreshSelected: PropTypes.func.isRequired,
	limit            : PropTypes.number.isRequired,
	outerVal         : PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]).isRequired,
};

GamesListInfinite.defaultProps = {
	style            : {},
	label            : 'Type for search',
	searchLength     : 0,
	hasMore          : null,
	returnId         : false,
	loading          : false,
	disabled         : false,
	shouldLoadOnClick: false,
	onBlur           : () => {},
};

/*
 Props
 multiselect       = bool is required, I think you got it
 hasMore           = bool is required. if it's true: onLoadData will be called as user scroll to bottom of the dropDown
 list              = array [ { id? value url? icon? }, ...obj ] is required,
 style             = object and it's not required,
 onLoadData        = function is required, ( searched_value, pagination, search, limit )
 onRefreshSelected = function is required, ( [ ...ids ] )
 label             = string
 searchLength      = number it's minimum length of search value to call onLoadData
 returnId          = bool and if it's true will return ids of selected ( to make it work pass ids in list object )
 limit             = number limit for request from backEnd
 disable           = bool
 onBlur            = function is not requerd will be called when dropdown is unfocused
 shouldLoadOnClick = bool use this props if you must display data with onClick on Select

 PASS LIMIT (FOR LOADING) MORE THAN 7, OTHERWISE WILL NOT WORK

 InfinitiveDropDown can work as multiple select or as single select,
 Anyway it will return array with id(s)/value(s) of selected items.
 But if nothing is selected it will return NULL
 You can fined EXAMPLE: src/components/mail/ComposeMail/ComposeMail.js
 */
