const prefix = 'PARTNER_MODAL_';

export const actions = {

	STORE_RESET: `${prefix}STORE_RESET`,

	BASE_DATA_REFRESH                 : `${prefix}BASE_DATA_REFRESH`,
	UI_REFRESH                        : `${prefix}UI_REFRESH`,
	INTEGRATORS_WITH_PROVIDERS        : `${prefix}INTEGRATORS_WITH_PROVIDERS`,
	INTEGRATORS_WITH_PROVIDERS_REFRESH: `${prefix}INTEGRATORS_WITH_PROVIDERS_REFRESH`,
	PROVIDERS_WITH_GAMES_REFRESH      : `${prefix}PROVIDERS_WITH_GAMES_REFRESH`,
	PROVIDERS_WITH_GAMES_RELOAD       : `${prefix}PROVIDERS_WITH_GAMES_RELOAD`,

	UPDATE_SUSPEND: `${prefix}UPDATE_SUSPEND`,


	baseDataRefresh: data => (
		{
			type: actions.BASE_DATA_REFRESH,
			data,
		}
	),

	uiRefresh: data => (
		{
			type: actions.UI_REFRESH,
			data,
		}
	),

	integratorsWithProvidersReload: data => {
		return {
			type: actions.INTEGRATORS_WITH_PROVIDERS,
			data,
		};
	},

	integratorsWithProvidersRefresh: data => {
		return {
			type: actions.INTEGRATORS_WITH_PROVIDERS_REFRESH,
			data,
		};
	},

	providersWithGamesRefresh: data => (
		{
			type: actions.PROVIDERS_WITH_GAMES_REFRESH,
			data,
		}
	),

	providersWithGamesReload: (data) => (
		{
			type: actions.PROVIDERS_WITH_GAMES_RELOAD,
			data,
		}
	),

	resetStore: () => (
		{
			type: actions.STORE_RESET,

		}
	),

	updateSuspend: data => (
		{
			type: actions.UPDATE_SUSPEND,
			data,
		}
	),


};
