import { Icon } from 'react-icons-kit';
import { Icons } from './icons';

const md = 16; // standart
const style = { marginRight: '10px' };

export const createIcon = (icon, size = md, className = 'sidebar-icon', rotateAngle = 0) => {
	return (
		<Icon icon={icon} size={size} className={className} style={{ transform: `rotate(${rotateAngle}deg)` }} />
	);
};

export const sidebarIcons = {
	'rtm'               : <Icons.RtmIcon style={style} />,
	'riskManagement'    : <Icons.RiskManagementIcon style={style} />,
	'dashboard'         : <Icons.DashboardIcon style={style} />,
	'staff'             : <Icons.StaffIcon style={style} />,
	'partners'          : <Icons.PartnersIcon style={style} />,
	'users'             : <Icons.CustomerIcon style={style} />,
	'loyalty'           : <Icons.BonusIcon style={style} />,
	'casino'            : <Icons.CasinoIcon style={style} />,
	'sport'             : <Icons.SportIcon style={style} />,
	'settings'          : <Icons.SettingsIcon style={style} />,
	'cms'               : <Icons.CmsIcon style={style} />,
	'reports'           : <Icons.ReportsIcon style={style} />,
	'notificationCenter': <Icons.NotificationCenterIcon style={style} />,
	'affiliates'        : <Icons.AffiliatesIcon style={style} />,
	'agents-system'     : <Icons.AgentIcon style={style} />,
	'agent/players'     : <Icons.AgentPlayerIcon style={style} />,
};
