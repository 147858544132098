import { IAgentPlayersAutoCompletePayload } from './types';

const actions = {
	GET_AGENT_PLAYER_LIST          : 'GET_AGENT_PLAYER_LIST',
	AUTOCOMPLETE_DATA_SOURCE_RELOAD: 'AUTOCOMPLETE_DATA_SOURCE_RELOAD',
	CREATE_AGENT_PLAYER            : 'CREATE_AGENT_PLAYER',
	DELETE_AGENT_PLAYER            : 'DELETE_AGENT_PLAYER',
	GET_AGENT_PLAYER_INFO          : 'GET_AGENT_PLAYER_INFO',
	GET_AGENT_PLAYER_BY_ID         : 'GET_AGENT_PLAYER_BY_ID',
	UPDATE_AGENT_PLAYER            : 'UPDATE_AGENT_PLAYER',
	UPDATE_AGENT_PLAYER_PASSWORD   : 'UPDATE_AGENT_PLAYER_PASSWORD',
	GET_AGENT_PLAYER_SETTINGS      : 'GET_AGENT_PLAYER_SETTINGS',
	GET_AGENT_PLAYER_ADJUSTMENT    : 'GET_AGENT_PLAYER_ADJUSTMENT',
	UPDATE_MY_DATA                 : 'UPDATE_MY_DATA',
	OPEN_AGENT_ADJUSTMENT_MODAL    : 'OPEN_AGENT_ADJUSTMENT_MODAL',

	agentPlayerListReload: () => {
		return {
			type: actions.GET_AGENT_PLAYER_LIST,
		};
	},
	autocompleteDataSourceReload: (data: IAgentPlayersAutoCompletePayload) => {
		return {
			type: actions.AUTOCOMPLETE_DATA_SOURCE_RELOAD,
			data: data,
		};
	},
	createAgentPlayer: () => {
		return {
			type: actions.CREATE_AGENT_PLAYER,
		};
	},
	updateAgentPlayerPassword: () => {
		return {
			type: actions.UPDATE_AGENT_PLAYER_PASSWORD,
		};
	},
	getAgentPlayerInfo: () => { 
		return {
			type: actions.GET_AGENT_PLAYER_INFO,
		};
	},
	getAgentPlayerByID: (playerID) => { 
		return {
			type: actions.GET_AGENT_PLAYER_BY_ID,
			data: playerID,
		};
	},
	updateMyData: () => { 
		return {
			type: actions.UPDATE_MY_DATA,
		};
	},
	openAdjustmentModal: (data: {adjustmentData;adjustmentTitle}) => { 
		return {
			type: actions.OPEN_AGENT_ADJUSTMENT_MODAL,
			data,
		};
	},
	updateAgentPlayerData: () => { 
		return {
			type: actions.UPDATE_AGENT_PLAYER,
		};
	},
	getAgentPlayerSettings: () => { 
		return {
			type: actions.GET_AGENT_PLAYER_SETTINGS,
		};
	},
	setAgentPlayerAdjustment: () => { 
		return {
			type: actions.GET_AGENT_PLAYER_ADJUSTMENT,
		};
	},

};

export { actions as agentPlayersActions };