import { apiRequest } from './index';
import { IAgentDataResponse } from '../../redux/agents/types';

const agentsList = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/agents',
		params,
	};

	return apiRequest(req);
};

const createAgent = (data: Partial<IAgentDataResponse> = {}, params = {}) => {
	const req = {
		method: 'POST',
		url   : '/agents',
		data,
		params,
	};

	return apiRequest(req);
};

const getAgentsAutocomplete = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/getAgentsDataAutocomplete',
		params,
	};

	return apiRequest(req);
};

const archiveAgent = (agentID: number, params = {}) => {
	const req = {
		method: 'DELETE',
		url   : `/agents/${agentID}`,
		params,
	};

	return apiRequest(req);
};

const agentsTransaction = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/agentTransactions',
		params,
	};

	return apiRequest(req);
};

const agentBalanceAdjustment = (data: { agentID: number; amount: number }, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/agents/${data.agentID}/balance`,
		data  : { amount: data.amount },
		params,
	};

	return apiRequest(req);
};

const getAgentInfo = (agentID: number, params = {}) => {
	const req = {
		method: 'GET',
		url   : `/agents/${agentID}`,
		params,
	};

	return apiRequest(req);
};

const updateAgent = (agentID: number, data: Partial<IAgentDataResponse> = {}, params = {}) => {
	const req = {
		method: 'PUT',
		url   : `/agents/${agentID}`,
		data,
		params,
	};

	return apiRequest(req);
};

const changeAgentPassword = (agentID: number, data: { password: string }, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/agent/${agentID}/changePassword`,
		data,
		params,
	};

	return apiRequest(req);
};

export const AgentsApi = {
	agentsList,
	createAgent,
	getAgentsAutocomplete,
	archiveAgent,
	agentsTransaction,
	agentBalanceAdjustment,
	getAgentInfo,
	updateAgent,
	changeAgentPassword,
};