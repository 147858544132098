import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  caret-color: transparent !important;
  cursor: pointer !important;

  * {
    cursor: pointer;
  }

  .ant-select-selection-item {
    div > span {
      padding-left: ${props => props.withIcon ? '12px' : '0'};
    }
  }

  input {
    text-indent: ${props => {
		const { withIcon } = props;
		if (withIcon) {
			return '12px';
		}
		return '0px';
	}};
  }

  & .ant-select-selection-selected-value {

    padding-left: ${props => {
		const { withIcon } = props;
		if (withIcon) {
			return '24px';
		}
		return '0px';
	}};
  }

  .ant-select {
    overflow-y: auto;
    max-height: 75px;

    .ant-select-selector {
      height: auto;
			overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: #f2f2f2;
            margin: 2px 0;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: #989696;
            border-radius: 2px;
            cursor: pointer;
						
        }
				
    }
  }

  .ant-select-dropdown {
    top: 100% !important;
		min-height: max-content;
  }
`;

export const ContainerItem = styled.div`
  display: flex;
  align-items: center;
  
  & span {
    text-overflow: ellipsis;
     
  }

`;


export const SelectIconWrapper = styled.div`
	position: absolute;
	top: 6px;
	left: 3%;
	z-index: 1;
	padding-top: 2%;
	padding-right: 2%;
`;

export const SelectIconSpan = styled.span`
  //top: 100px;
`;

