import axios from 'axios';
import { API_URL } from '../../config';
import {
	getRawToken,
	getStoredDataBaseLangID,
	restorePartnerID, restoreWebsite,
} from '../utility';
import { HTTPStatusCodes } from '../../constants/HTTPStatusCodes';
import { handleError } from '../../redux/middleware';

export function apiRequest(req) {
	const url       = API_URL + req.url;
	const token     = getRawToken();
	const langID    = getStoredDataBaseLangID();
	const websiteID = restoreWebsite();
	const partnerID =  restorePartnerID();

	if (!req.headers) {
		req.headers = {};
	}


	if (token) {
		req.headers.Authorization = token;
	} else if (req.idToken) {
		req.headers.Authorization = req.idToken;
	}
	req.headers['X-Requested-With'] = 'XMLHttpRequest';

	if (!req.params) {
		req.params = {};
	}
	if (!req.data) {
		req.data = {};
	}

	// lang_id (only for GET)
	const withoutLangID = Boolean(req.withoutLangID);
	if (!withoutLangID && req.method === 'GET' && !req.params.lang_id) {
		req.params.lang_id = langID;
	}

	// website_id (only for GET)
	const withoutWebsiteID = Boolean(req.withoutWebsiteID);
	if (!withoutWebsiteID && req.method === 'GET' && !req.params.website_id) {
		req.params.website_id = websiteID;
	}
	if (!withoutWebsiteID && req.method === 'PUT' && !req.params.website_id) {
		req.params.website_id = websiteID;
	}
	// partner_id
	const withoutPartnerID = Boolean(req.withoutPartnerID);
	if (!withoutPartnerID  && !req.params.partner_id) {
		req.params.partner_id = partnerID;
	}

	const axiosData = {
		method          : req.method,
		url,
		headers         : req.headers,
		params          : req.params,
		data            : req.data,
		onUploadProgress: req.onUploadProgress,
	};
	const axiosRequest = axios(axiosData);
	axiosRequest.catch((error) => {
		handleError(error);
		if (error.response && error.response.status) {
			switch (error.response.status) {
				case (HTTPStatusCodes.Unauthorized && window.location.href !== '/signin'): {
					window.location.href = '/signin';
					return localStorage.clear();
				}
				default: return axiosRequest;
			}
		}
	});
	return axiosRequest;
}
