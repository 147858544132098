import { takeEvery, put, select, call, fork, all, takeLatest } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { sportRiskManagementAPI } from '../../../../helpers/api/sportRiskManagement';
import { logger } from '../../../../helpers/logger';
import { SuspendedMarkets } from '../../../../containers/SportRiskManagement/SportBets/Modal/types';
import { RootState } from '../../../store';
import { IApiResponse, ISagaActionType } from '../../../types';
import { IRiskBetsItem } from '../list/types';
import { actions as riskSportBetsListSlice } from '../list/slice';
import { actions as riskSportBetsModalSlice } from './slice';
import {
	IActionMarginUpdate,
	IActionMarginUpdateData,
	IActionSuspendUpdate,
	IActionSuspendUpdateData,
	IActionUpdate,
	ICancelConditioneData,
	IOddPriceUpdateData,
	IOddPriceUpdatePayload,
	IRiskModalDataGetStore,
	IUpdateRiskBetsDataParams,
} from './types';
import { getUpdateDataAdapt } from './utils';
import actionsSportRiskModal from './actions';
import { showError, showSuccess } from '../../../../helpers/notifications';

const messages = {
	errorSaveData   : 'riskBets.modal.save.error',
	errorSaveSuspend: 'riskBets.modal.save.error.suspend',
	errorSaveMargin : 'riskBets.modal.save.error.margin',

	successSaveMargin : 'riskBets.modal.save.margin.success',
	successSaveSuspend: 'riskBets.modal.save.suspend.success',
	successSaveData   : 'riskBets.modal.save.success',
	
	successCancelCredentials: 'riskBets.modal.cancel.credentials.success',
	errorCancelCredentials  : 'riskBets.modal.cancel.credentials.error',
};
const { NOT_SUSPENDED } = SuspendedMarkets;

function getStoreData(state: RootState) {
	const { SportRiskManagement, App } = state;
	const {
		riskManagementBetsSlice: { riskSportBetsModal, riskSportBetsList },
	} = SportRiskManagement;
	return {
		modalData            : riskSportBetsModal.modalData,
		sportRiskData        : riskSportBetsList.sportRiskData,
		sportRiskDataEntities: riskSportBetsList.entities,
		websiteID        			 : App.get('websiteID'),
		partnerID        			 : App.get('partnerID'),
	};
}

function* updateData() {
	yield takeEvery(
		actionsSportRiskModal.SPORT_RISK_BETS_MODAL_UPDATE,
		function* (action: IActionUpdate) {
			yield put(riskSportBetsModalSlice.uiRefresh({ loading: true }));
			const { modalData, sportRiskData } = yield select(getStoreData);
			const { closeModal } = action;
			const cloneRiskData = cloneDeep(sportRiskData);

			const updateData: IUpdateRiskBetsDataParams =
        getUpdateDataAdapt(modalData);
			try {
				const response: IApiResponse<IRiskBetsItem> = yield call(
					sportRiskManagementAPI.updateSportRisk,
					updateData,
					modalData.betID
				);
				if (response && response.status === 200) {
					cloneRiskData.find((risk: IRiskBetsItem) => {
						if (modalData.betID === risk.betID) {
							risk.statusID = updateData.status_id;
						}
					});
					yield put(riskSportBetsListSlice.setRiskData(cloneRiskData));
					if (closeModal) {
						yield put(riskSportBetsModalSlice.uiRefresh({ closeModal }));
						yield put(riskSportBetsModalSlice.resetSportModalData());
					}
					showSuccess(messages.successSaveData);
				}
			} catch (error) {
				showError(messages.errorSaveData);
				logger.log(error);
			} finally {
				yield put(riskSportBetsModalSlice.uiRefresh({ loading: false }));
			}
		}
	);
}

function* suspendUpdate() {
	yield takeEvery(
		actionsSportRiskModal.SPORT_RISK_BETS_MODAL_SUSPEND_UPDATE,
		function* (action: IActionSuspendUpdate) {
			const { marketID, suspend, reason } = action;
			const data: IActionSuspendUpdateData = {
				market_id: marketID,
				suspend,
				reason,
			};
			if (suspend === NOT_SUSPENDED) {
				delete data.reason;
			}

			try {
				const res: IApiResponse<object> = yield call(
					sportRiskManagementAPI.updateRiskSuspend,
					data
				);
				if (res && res.status === 200) {
					yield put(
						riskSportBetsModalSlice.riskModalDataDetailsRefresh({
							popoverVisible: false,
							suspendLoading: false,
							successSaved  : true,
							marketID,
							suspend,
							reason,
						})
					);
					showSuccess(messages.successSaveSuspend);
				}
			} catch (error) {
				showError(messages.errorSaveSuspend);
				logger.log(error);
			}
		}
	);
}

function* marginUpdate() {
	yield takeEvery(
		actionsSportRiskModal.SPORT_RISK_BETS_MODAL_MARGIN_UPDATE,
		function* (action: IActionMarginUpdate) {
			const { marketID, margin, betID } = action;
			const data: IActionMarginUpdateData = {
				id       : betID,
				market_id: marketID,
				margin,
			};

			try {
				const res: IApiResponse<object> = yield call(
					sportRiskManagementAPI.updateRiskMargin,
					data
				);
				if (res && res.status === 200) {
					showSuccess(messages.successSaveMargin);
				}
			} catch (error) {
				yield put(
					riskSportBetsModalSlice.riskModalDataDetailsRefreshMargin({
						marginPopoverVisible: true,
						marginLoading       : false,
						marketID,
					})
				);
				showError(messages.errorSaveMargin);
				logger.log(error);
			}
		}
	);
}

function* ODDPriceUpdate() {
	yield takeEvery(
		actionsSportRiskModal.SPORT_RISK_BETS_MODAL_ODD_PRICE_UPDATE,
		function* (action: ISagaActionType<IOddPriceUpdateData>) {
			const { marketID, selectionID, currentOdd } = action.data;
			const { modalData }: IRiskModalDataGetStore = yield select(getStoreData);
			yield put(riskSportBetsModalSlice.riskModalDataDetailsRefreshODDPrice({ oddPriceLoading: true, marketID }));
			const body: IOddPriceUpdatePayload = {
				current_odd : currentOdd,
				market_id   : marketID,
				selection_id: `${selectionID}`,
			};

			try {
				const res: IApiResponse<object> = yield call(
					sportRiskManagementAPI.updateRiskODDPrice,
					body
				);
				if (res && res.status === 200) {
					showSuccess(messages.successSaveMargin);
					yield put(riskSportBetsModalSlice.riskModalDataDetailsRefreshODDPrice({
						oddPopoverVisible: false,
						oddPrice         : currentOdd,
						currentOddPrice  : '',
						marketID,
						eventSelections 	: {
							...modalData.eventSelections,
							currentOdd: currentOdd,
						},
					}));
				}
			} catch (error) {
				yield put(
					riskSportBetsModalSlice.riskModalDataDetailsRefreshODDPrice({
						marginLoading: false,
						marketID,
					})
				);
				showError(messages.errorSaveMargin);
				logger.log(error);
			}

			yield put(riskSportBetsModalSlice.riskModalDataDetailsRefreshODDPrice({ oddPriceLoading: false, marketID }));
		}
	);
}

function* CancelConditions() {
	yield takeLatest(
		actionsSportRiskModal.SPORT_RISK_BETS_MODAL_CANCEL_CONDITIONS,
		function* (action: ISagaActionType<ICancelConditioneData>) {
			const { marketIDs } = action.data;
			const { websiteID, partnerID } = yield select(getStoreData);
			try {
				const res: IApiResponse<object> = yield call(
					sportRiskManagementAPI.cancelConditions,
					{
						market_ids: marketIDs,
					},
					partnerID,
					websiteID        			
					
				);
				if (res && res.status === 200) {
					showSuccess(messages.successCancelCredentials);
				}
			} catch (error) {
				showError(messages.errorCancelCredentials);
				logger.log(error);
			}
		}
	);
}

export default function* sportRiskModalSaga() {
	yield all([fork(updateData), fork(suspendUpdate), fork(marginUpdate), fork(ODDPriceUpdate), fork(CancelConditions)]);
}
