import { apiRequest } from './index';

// Casino -----------------------------------------------------------------------------------------
function casinoList(params = {}, websiteID) {
	const url = websiteID ? `/websites/${websiteID}/casino/list` : '/casinoProvider/list';
	const req = {
		method       : 'GET',
		url,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function platformCasinoList() {
	const req = {
		method          : 'GET',
		url             : '/casinoGlobal/list',
		withoutLangID   : true,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
}


function partnerCasinoList() {
	const req = {
		method       : 'GET',
		url          : '/casinoProvider/list',
		withoutLangID: true,
	};

	return apiRequest(req);

}

function casinoInfo(casinoID, params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casino/list',
		withoutLangID: true,
		params       : {
			...params,
			id: casinoID,
		},
	};

	return apiRequest(req);
}

function casinoCreate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/casino/create',
		data,
		params,
	};

	return apiRequest(req);
}

function casinoUpdate(casinoID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casino/update/${casinoID}`,
		data,
		params,
	};

	return apiRequest(req);
}
function platformCasinoUpdate(casinoID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casinoGlobal/update/${casinoID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function casinoUpdateList(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/casino/update',
		data,
		params,
	};

	return apiRequest(req);
}

function casinoDelete(casinoID) {

	const req = {
		method: 'DELETE',
		url   : `/casino/delete/${casinoID}`,
	};

	return apiRequest(req);
}

function casinoTags(casinoID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/casinoGame/tags/${casinoID}`,
		params,
	};

	return apiRequest(req);
}

// games By source

function casinoGamesbyTags(params = {}) {

	const req = {
		method: 'GET',
		url   : '/casinoGame/gamesbytags',
		params,
	};

	return apiRequest(req);
}

function casinoGamesbyProviders(params = {}) {

	const req = {
		method: 'GET',
		url   : '/casino/gamesbyproviders',
		params,
	};

	return apiRequest(req);
}

function casinoGamesbyCategories(params = {}) {

	const req = {
		method: 'GET',
		url   : '/casinogamesbycategories',
		params,
	};

	return apiRequest(req);
}

// 09/09/2022 we changed listRelod from casino/integrators/saga.ts and insted casinoGamesbyIntegrators we using integratorsList

// function casinoGamesbyIntegrators(params = {}) {

// 	const req = {
// 		method: 'GET',
// 		url: '/casino/gamesbyintegrators',
// 		params,
// 	};

// 	return apiRequest(req);
// }

// Casino Additional Data -------------------------------------------------------------------------

function casinoAuthList(casinoID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/casino/auth/${casinoID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function casinoAuthUpdate(casinoID, data, params = {}) {

	const req = {
		method       : 'POST',
		url          : `/casino/auth/${casinoID}`,
		withoutLangID: true,
		data,
		params,
	};

	return apiRequest(req);
}


function casinoMainDataGet(casinoID, websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/casino/${casinoID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function casinoNamesList(casinoID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/casino/names/${casinoID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function casinoNamesUpdate(casinoID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casino/names/${casinoID}`,
		data,
		params,
	};

	return apiRequest(req);
}

// Casino Games -----------------------------------------------------------------------------------

function gamesList(websiteID, params = {}) {
	const req = {
		method: 'GET',
		url   : '/casinoGame',
		params: {
			...params,
			website_id: websiteID,
		},
	};

	return apiRequest(req);
}
function casinoGamesList(params = {}) {
	const req = {
		method: 'GET',
		url   : '/casinoGame',
		params,
	};

	return apiRequest(req);
}

function gamesListByProvider(providerID, currencyID, params = {}) {
	const req = {
		method       : 'GET',
		url          : `/provider/${providerID}/games`,
		withoutLangID: false,
		params       : {
			provider_id: providerID,
			currency_id: currencyID,
			...params	,
		},
	};

	return apiRequest(req);
}

function gamesListUpdate(data, params = {}) {

	const req = {
		method       : 'POST',
		url          : '/casinoGame/updates',
		withoutLangID: true,
		data,
		params,
	};

	return apiRequest(req);
}

function gamesListRefresh(data, params = {}) {

	const req = {
		method       : 'POST',
		url          : '/casinoGame/refresh',
		withoutLangID: true,
		data         : {
			id        : data.providerID,
			website_id: data.websiteID,
		},
		params: {
			...params,
			website_id: data.websiteID,
		},
	};

	return apiRequest(req);
}

// Casino Game Additional Data --------------------------------------------------------------------

function gameNamesList(casinoGameID ) {

	const req = {
		method       : 'GET',
		url          : `/casinoGame/names/${casinoGameID}`,
		withoutLangID: true,
		// params,
	};

	return apiRequest(req);
}

function gameNamesUpdate(casinoGameID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casinoGame/names/${casinoGameID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function gamesDefaultGridList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/casinoGame/orders',
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
}

function gamesDefaultGridUpdate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/casinoGame/orders',
		data,
		params,
	};

	return apiRequest(req);
}

function gameBetLimits(casinoGameID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/casinoGame/betLimits/${casinoGameID}`,
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
}

// Casino integrators -------------------------------------------------------------------------

function integratorsList(params = {}) {
	const req = {
		method          : 'GET',
		url             : '/integrators/list',
		params,
		withoutLangID   : false,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
}

function integratorsListWithProviders(params = {}) {
	const req = {
		method       : 'GET',
		url          : '/integrators',
		params,
		withoutLangID: false,
	};

	return apiRequest(req);
}

function deleteIntegrator(id, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/integrator/delete/${id}`,
		params,
	};

	return apiRequest(req);
}

// function gamesListByProvider(providerID) {
//
// 	const req = {
// 		method: 'GET',
// 		url: '/casinoGame/list',
// 		params: { provider_id : providerID }
// 	};
//
// 	return apiRequest(req);
// }

function newIntegratorAPI({ body }) {

	const req = {
		method: 'POST',
		url   : '/integrator',
		data  : body,
	};

	return apiRequest(req);
}

function integratorUpdateAPI({ body, integratorID }) {

	const req = {
		method: 'POST',
		url   : `/integrator/edit/${integratorID}`,
		data  : body,
	};

	return apiRequest(req);
}

// RTM Casino -------------------------------------------------------------------------------------

function rtmCasinoList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/rtm/casino',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function rtmCSVLoad(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/casino',
		params,
	};

	return apiRequest(req);
}

function rtmCSVRecalculate(params = {}) {

	const req = {
		method: 'GET',
		url   : '/rtm/csv/recalculate/casino',
		params,
	};

	return apiRequest(req);
}

// Casino Game Categories -------------------------------------------------------------------------

function gameCategoryList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/casinoCategoriesList',
		params,
	};

	return apiRequest(req);
}

function gameCategoryInfo(id, params = {}) {

	const req = {
		method: 'GET',
		url   : `/casinoCategory/${id}`,
		params,
	};

	return apiRequest(req);
}

function gameCategoryCreate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/casinoCategory',
		data,
		params,
	};

	return apiRequest(req);
}

function gameCategoryUpdate(id, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casinoCategory/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}

function gameCategoryDelete(id, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/casinoCategory/${id}`,
		params,
	};

	return apiRequest(req);
}

function gameCategoryNamesList(id, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/casinoCategory/${id}/names`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function gameCategoryNamesUpdate(id, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casinoCategory/${id}/names`,
		data,
		params,
	};

	return apiRequest(req);
}

function categoriesForSimilarListReload(id, params = {}) {

	const req = {
		method: 'GET',
		url   : `/casinoCategory/${id}/categories`,
		params,
	};

	return apiRequest(req);
}

function categorySimilarListReload(id, params = {}) {

	const req = {
		method: 'GET',
		url   : `/casinoCategory/${id}/similarList`,
		params,
	};

	return apiRequest(req);
}

function categorySimilarDataDelete(id, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/casinoCategory/${id}/similar`,
		params,
	};

	return apiRequest(req);
}

function categorySimilarDataUpdate(id, data, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/casinoCategory/${id}/similar`,
		data,
		params,
	};
	return apiRequest(req);
}

// Providers --------------------------------------------------------------------------------------

function providersList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/casino/providers',
		params,
	};

	return apiRequest(req);
}

function customProvidersList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/casinoSubCategoriesList',
		params,
	};

	return apiRequest(req);
}

function customProvidersInfo(id, params = {}) {

	const req = {
		method: 'GET',
		url   : `/casinoSubCategory/${id}`,
		params,
	};

	return apiRequest(req);
}

function customProvidersCreate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/casinoSubCategory',
		data,
		params,
	};

	return apiRequest(req);
}

function customProvidersUpdate(id, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casinoSubCategory/edit/${id}`,
		data,
		params,
	};

	return apiRequest(req);
}

function customProvidersDelete(id) {

	const req = {
		method: 'DELETE',
		url   : `/casinoSubCategory/delete/${id}`,
	};

	return apiRequest(req);
}

function customProvidersNamesList(id, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/casinoSubCategory/${id}/names`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function customProvidersNamesUpdate(id, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/casinoSubCategory/${id}/names`,
		data,
		params,
	};

	return apiRequest(req);
}


function getTagNames( data, params = {}) {
	const req = {
		method: 'GET',
		url   : '/casinoGame/findgamesbytags',
		data,
		params,
	};

	return apiRequest(req);
}

function gamesBulkEdit( data ) {
	const req = {
		method: 'PUT',
		url   : '/casinoGame/bulkupdate',
		data,
	};

	return apiRequest(req);
}

function gameSeoSave( data, casinoGameID, params ) {
	const req = {
		method: 'POST',
		url   : `/casinoGame/seo/${casinoGameID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function casinoSeoSave(data, casinoID, params) {
	const req = {
		method: 'POST',
		url   : `/casino/seo/${casinoID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function casinoSeoGet(casinoID, params) {
	const req = {
		method       : 'GET',
		url          : `/casino/seo/${casinoID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function gameSeoGet( casinoGameID ) {
	const req = {
		method: 'GET',
		url   : `/casinoGame/seo/${casinoGameID}`,
	};

	return apiRequest(req);
}


export const casinoAPI = {
	casinoList,
	platformCasinoList,
	partnerCasinoList,
	casinoInfo,
	casinoCreate,
	casinoUpdate,
	platformCasinoUpdate,
	casinoDelete,
	casinoUpdateList,
	casinoTags,
	casinoGamesbyTags,
	casinoGamesbyProviders,
	casinoGamesbyCategories,
	//casinoGamesbyIntegrators,
	casinoSeoGet,
	casinoSeoSave,

	casinoAuthList,
	casinoAuthUpdate,
	casinoNamesList,
	casinoMainDataGet,
	casinoNamesUpdate,

	gamesList,
	casinoGamesList,
	gamesListUpdate,
	gamesListRefresh,
	gamesListByProvider,

	gameNamesList,
	gameNamesUpdate,

	gamesDefaultGridList,
	gamesDefaultGridUpdate,

	gameBetLimits,

	rtmCasinoList,
	rtmCSVLoad,
	rtmCSVRecalculate,

	gameCategoryList,
	gameCategoryInfo,
	gameCategoryCreate,
	gameCategoryUpdate,
	gameCategoryDelete,
	gameCategoryNamesList,
	gameCategoryNamesUpdate,
	getTagNames,


	categoriesForSimilarListReload,
	categorySimilarListReload,
	categorySimilarDataDelete,
	categorySimilarDataUpdate,

	providersList,

	customProvidersList,
	customProvidersInfo,
	customProvidersCreate,
	customProvidersUpdate,
	customProvidersDelete,
	customProvidersNamesList,
	customProvidersNamesUpdate,

	integratorsList,
	integratorsListWithProviders,
	deleteIntegrator,
	// gamesListByProvider,
	newIntegratorAPI,
	integratorUpdateAPI,

	gamesBulkEdit,

	gameSeoGet,
	gameSeoSave,
};
