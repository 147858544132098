import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	EAgentPlayerAutoCompleteType,
	IAgentPlayerFilter,
	IAgentPlayerInfo,
	IAgentPlayersBaseData,
	IAgentPlayersData,
	IAgentPlayersInitialState,
	IAgentPlayersUI,
	IUpdatedData,
} from './types';
import { RootState } from '../store';
import { createFilter, filtersURL } from '../../helpers/utils';
import { initFilterTypes } from './utils';
 
const initialState: IAgentPlayersInitialState = {
	playerID        : null,
	list            : [],	
	filter          : createFilter(filtersURL.agents, initFilterTypes),
	autoCompleteData: {
		username : [],
		email    : [],
		firstName: [],
		lastName : [],
	},
	baseData: {
		email           : '',
		firstName       : '',
		lastName        : '',
		username        : '',
		country         : null,
		city            : '',
		phone           : '',
		birthday        : '',
		gender          : null,
		password        : '',
		confirmPassword : '',
		currency        : null,
		language        : null,
		status          : null,
		address1        : '',
		address2        : '',
		nationalID      : '',
		balance         : null,
		domain          : '',
		registrationDate: '',
		age             : null,
		gamingStatus    : '',
		betsAmount      : null,
		betsCount       : null,
		lastLogin       : '',
		lastBetDate     : '',
		profitability   : null,
		isOnline        : false,
		commentForAdmin : '',
	},
	changePasswordData    : {},
	updatedData           : {},
	depositAndWithdrawData: {},
	agentPlayerSettings   : {},
	agentPlayerData       : null,
	UI                    : {
		visible             : false,
		listLoading         : false,
		modalLoading        : false,
		adjustmentLoading   : false,
		changePassword      : false,
		depositAndWithdraw  : false,
		adjustmentInProgress: false,
	},
	countries: [],
	languages: [],
	currency : [],
};
const agentPlayersSlice = createSlice({
	name        : 'agentPlayers',
	initialState: initialState,
	reducers    : {
		setBaseData(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IAgentPlayersBaseData>>) {
			state.baseData = { ...state.baseData, ...action.payload };
		},
		resetBaseData(state: Draft<IAgentPlayersInitialState>) {
			state.baseData = initialState.baseData;
		},
		setAgentPlayers(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<IAgentPlayersBaseData[]>) {
			state.list = action.payload;
		},
		setUI(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IAgentPlayersUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		setAutoCompleteData(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<{ type: EAgentPlayerAutoCompleteType; data: string[] }>) {
			state.autoCompleteData = { ...state.autoCompleteData, [action.payload.type]: action.payload.data };
		},
		setFilter(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IAgentPlayerFilter>>) {
			state.filter = { ...state.filter, ...action.payload };
		},
		resetFilter(state: Draft<IAgentPlayersInitialState>) {
			state.filter = initialState.filter;
		},
		setAgentPlayerInfo(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IAgentPlayerInfo>>) {
			state.countries = action?.payload.countries;
			state.languages = action?.payload.languages;
			state.currency = action?.payload.currency;
		},
		setAgentPlayerData(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IAgentPlayersData>>) {
			state.agentPlayerData = { ...state.agentPlayerData, ...action.payload };
		},
		setPlayerID(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<number>>) {
			state.playerID = action.payload;
		},
		setAgentPlayerSettings(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IAgentPlayersData>>) {
			state.agentPlayerSettings = action.payload;
		},
		setAgentPlayerInfoData(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IUpdatedData>>) {
			state.updatedData = { ...state.updatedData, ...action.payload };
		},
		setPlayerChangePasswordData(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IUpdatedData>>) {
			state.changePasswordData = { ...state.changePasswordData, ...action.payload };
		},
		resetPlayerChangePasswordData(state: Draft<IAgentPlayersInitialState>) {
			state.changePasswordData = {};
		},
		setPlayerAdjustmentData(state: Draft<IAgentPlayersInitialState>, action: PayloadAction<Partial<IUpdatedData>>) {
			state.depositAndWithdrawData = { ...state.depositAndWithdrawData, ...action.payload };
		},
		resetPlayerAdjustmentData(state: Draft<IAgentPlayersInitialState>) {
			state.depositAndWithdrawData = {};
		},
	},
});


export const agentPlayersReducers = {
	setBaseData                  : agentPlayersSlice.actions.setBaseData,
	resetBaseData                : agentPlayersSlice.actions.resetBaseData,
	setAgentPlayers              : agentPlayersSlice.actions.setAgentPlayers,
	setUI                        : agentPlayersSlice.actions.setUI,
	setAutoCompleteData          : agentPlayersSlice.actions.setAutoCompleteData,
	setFilter                    : agentPlayersSlice.actions.setFilter,
	resetFilter                  : agentPlayersSlice.actions.resetFilter,
	setAgentPlayerInfo           : agentPlayersSlice.actions.setAgentPlayerInfo,
	setAgentPlayerData           : agentPlayersSlice.actions.setAgentPlayerData,
	setAgentPlayerInfoData       : agentPlayersSlice.actions.setAgentPlayerInfoData,
	setPlayerChangePasswordData  : agentPlayersSlice.actions.setPlayerChangePasswordData,
	resetPlayerChangePasswordData: agentPlayersSlice.actions.resetPlayerChangePasswordData,
	setAgentPlayerSettings       : agentPlayersSlice.actions.setAgentPlayerSettings,
	setPlayerAdjustmentData      : agentPlayersSlice.actions.setPlayerAdjustmentData,
	resetPlayerAdjustmentData    : agentPlayersSlice.actions.resetPlayerAdjustmentData,
	setPlayerID                  : agentPlayersSlice.actions.setPlayerID,
};

export const agentPlayersSelectors = {
	selectBaseData          : (state: RootState) => state.AgentPlayers.baseData,
	selectAgentPlayers      : (state: RootState) => state.AgentPlayers.list,
	selectUI                : (state: RootState) => state.AgentPlayers.UI,
	selectFilter            : (state: RootState) => state.AgentPlayers.filter,
	selectAutoCompleteData  : (state: RootState) => state.AgentPlayers.autoCompleteData,
	selectCurrency          : (state: RootState) => state.AgentPlayers.currency,
	selectLanguages         : (state: RootState) => state.AgentPlayers.languages,
	selectCountries         : (state: RootState) => state.AgentPlayers.countries,
	selectAgentPlayerData   : (state: RootState) => state.AgentPlayers.agentPlayerData,
	selectUpdatedData       : (state: RootState) => state.AgentPlayers.updatedData,
	selectChangePasswordData: (state: RootState) => state.AgentPlayers.changePasswordData,
	selectSettings          : (state: RootState) => state.AgentPlayers.agentPlayerSettings,
	selectAdjustment        : (state: RootState) => state.AgentPlayers.depositAndWithdrawData,
};


export default agentPlayersSlice.reducer;