export const COMPONENTS = {
	dashboard     : 'dashboard',
	categoriesTree: 'categoriesTree',
	bettingRules  : 'bettingRules',

	rtmBets          : 'rtmBets',
	rtmDeposits      : 'rtmDeposits',
	rtmWithdrawals   : 'rtmWithdrawals',
	rtmCasino        : 'rtmCasino',
	rtmDocuments     : 'rtmDocuments',
	rtmRiskManagement: 'rtmRiskManagement',

	riskManagementBets: 'riskManagementBets',

	participantsList: 'participantsList',
	participantsAges: 'participantsAges',

	marketsTemplates   : 'marketsTemplates',
	marketsGroups      : 'marketsGroups',
	marketsLadders     : 'marketsLadders',
	marketsDisplayTypes: 'marketsDisplayTypes',

	events   : 'events',
	eventInfo: 'eventInfo',

	results   : 'results',
	resultInfo: 'resultInfo',

	liveStatisticTypes: 'liveStatisticTypes',

	currencies: 'currencies',

	usersList  : 'usersList',
	usersGroups: 'usersGroups',
	usersRoles : 'usersRoles',
	userInfo   : 'userInfo',

	personalList: 'personalList',

	contentInfoPages: 'contentInfoPages',
	contentInfoPage : 'contentInfoPage',

	contentNews    : 'contentNews',
	newsArticlePage: 'newsArticlePage',

	contentPromotion : 'contentPromotion',
	contentPromotions: 'contentPromotions',

	contentArticles           : 'contentArticles',
	contentHelpCenter         : 'contentHelpCenter',
	contentHeader             : 'contentHeader',
	contentFooter             : 'contentFooter',
	contentLandingPageGameGrid: 'contentLandingPageGameGrid',

	settingsScopes        : 'settingsScopes',
	settingsStatisticTypes: 'settingsStatisticTypes',
	settingsSidebarEditor : 'settingsSidebarEditor',

	websites    : 'websites',
	websiteInner: 'websitesInner',

	createMassBonus: 'createMassBonus',

	bonusesConditionalBonusCreate: 'bonusesConditionalBonusCreate',
	bonusesConditionalBonusEdit  : 'bonusesConditionalBonusEdit',

	bonusesWelcomeBonusCreate: 'bonusesWelcomeBonusCreate',

	payments      : 'payments',
	paymentPayways: 'paymentPayways',

	securityQuestions : 'securityQuestions',
	securityBlockedIps: 'securityBlockedIps',
	securityWhitelist : 'securityWhitelist',

	bannersList  : 'bannersList',
	bannersGroups: 'bannersGroups',

	emailsList     : 'emailsList',
	emailsTemplates: 'emailsTemplates',

	casinoList           : 'casinoList',
	casinoGames          : 'casinoGames',
	casinoGameCategories : 'casinoGameCategories',
	casinoCustomProviders: 'casinoCustomProviders',
	casinoIntegrators    : 'casinoIntegrators',

	reports           : 'reports',
	reportByPlayer    : 'reportByPlayer',
	reportByProvider  : 'reportByProvider',
	reportByGame      : 'reportByGame',
	reportByIntegrator: 'reportByIntegrator',

	homeManagePriorities: 'homeManagePriorities',
	sportPriorities     : 'sportPriorities',

	homeManageSpecialOffers: 'homeManageSpecialOffers',
	specialOffers          : 'specialOffers',

	homeManageUpcoming    : 'homeManageUpcoming',
	homeManageMatchesOfDay: 'homeManageMatchesOfDay',

	freeSpinsList         : 'freeSpinsList',
	welcomeBonusList      : 'welcomeBonusList',
	cashbackBonusList     : 'cashbackBonusList',
	cashbackUsersBonusList: 'cashbackUsersBonusList',
	freeBetsList          : 'freeBetsList',
	loyalty               : 'loyalty',
	tournaments           : 'tournaments',
	bonuses               : 'bonuses',
	bonusesTemplates      : 'bonusesTemplates',
	massBonus             : 'massBonus',


	transactions     : 'transactions',
	messages         : 'messages',
	sms              : 'sms',
	affiliates       : 'affiliates',
	agentsSystem     : 'agents-system',
	agents           : 'agents',
	pushNotifications: 'pushNotifications',

	partner        : 'partner',
	smsGateways    : 'smsGateways',
	smsTemplates   : 'smsTemplates',
	agentInfo      : 'agentInfo',
	agentPlayers   : 'agentPlayers',
	agentPlayerInfo: 'agentPlayerInfo',
};
