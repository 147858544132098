import defaultSidebar from '../../config/defaultSidebar';
import { APP_TABS, SIDEBAR_KEYS, DEFAULT_TABS, ADDITIONAL_TABS, additionalKeys } from '../../constants/appTabs';
import { getLastMultiParameterIDs, getLastParameterID } from '../../helpers/locationUtils';


const defaultTab = DEFAULT_TABS[0];

export function defineActiveTab(activeTabID) {

	if (activeTabID === APP_TABS.dashboard) {
		return defaultTab;
	}

	// active tab from Sidebar
	if (SIDEBAR_KEYS.includes(activeTabID)) {
		return createTabFromSidebar(activeTabID);
	}

	// additional tab
	if (additionalKeys.includes(activeTabID)) {
		return createAdditionalTab(activeTabID);
	}

	// nothing
	return defaultTab;
}
function createTabFromSidebar(sidebarKey, sideBar = defaultSidebar, activeTab = {}) {
	for (let i = 0; i < sideBar.length; i++) {
		const rootMenu = sideBar[i];
		const hasChildren = (rootMenu.menuItems && rootMenu.menuItems.length > 0);

		if (!hasChildren) {
			if (rootMenu.key === sidebarKey) {
				activeTab.id = sidebarKey;
				activeTab.titleID = `sidebar.${rootMenu.title}`;
				activeTab.componentName = rootMenu.component;
				activeTab.componentProps = {};
				activeTab.location = `/dashboard/${rootMenu.url}`;
			}
			continue; // eslint-disable-line no-continue
		} else {
			createTabFromSidebar(sidebarKey, rootMenu.menuItems, activeTab);
		}
	}
	if (activeTab.id) {
		return activeTab;
	}
	return defaultTab;
}

function createAdditionalTab(tabID) {

	const entityID = getLastParameterID();

	switch (tabID) {
		case APP_TABS.userInfo: {
			return {
				...ADDITIONAL_TABS.userInfo,
				componentProps: { userID: entityID },
				location      : `/dashboard/users/info/${entityID}`,
			};
		}
		case APP_TABS.resultInfo: {
			return {
				...ADDITIONAL_TABS.resultInfo,
				componentProps: { eventID: entityID },
				location      : `/dashboard/results/info/${entityID}`,
			};
		}
		case APP_TABS.eventInfo: {
			return {
				...ADDITIONAL_TABS.eventInfo,
				componentProps: { eventID: entityID },
				location      : `/dashboard/events/info/${entityID}`,
			};
		}
		case APP_TABS.infoPage: {
			return {
				...ADDITIONAL_TABS.infoPage,
				componentProps: { pageID: entityID },
				location      : `/dashboard/content/page/${entityID}`,
			};
		}
		case APP_TABS.newsArticlePage: {
			return {
				...ADDITIONAL_TABS.newsArticlePage,
				componentProps: { articleID: entityID },
				location      : `/dashboard/content/news/article/${entityID}`,
			};
		}
		case APP_TABS.promotion: {
			return {
				...ADDITIONAL_TABS.promotions,
				componentProps: { promotionID: entityID },
				location      : `/dashboard/content/newPromotion/${entityID}`,
			};
		}
		// case APP_TABS.paymentPayways: { move in Website inner Payments, now it is tab content 
		// 	return {
		// 		...ADDITIONAL_TABS.paymentPayways,
		// 		componentProps: { paymentID: entityID },
		// 		location      : `/dashboard/payment/payways/${entityID}`,
		// 	};
		// }
		case APP_TABS.casinoGames: {
			return {
				...ADDITIONAL_TABS.casinoGames,
				componentProps: { casinoID: entityID },
				location      : `/dashboard/casino/games/${entityID}`,
			};
		}
		case APP_TABS.sportPriorities: {
			return {
				...ADDITIONAL_TABS.sportPriorities,
				componentProps: { sportID: entityID },
				location      : `/dashboard/priorities/sport/${entityID}`,
			};
		}
		case APP_TABS.specialOffers: {
			return {
				...ADDITIONAL_TABS.specialOffers,
				componentProps: { sportID: entityID },
				location      : `/dashboard/specialOffers/sports/${entityID}`,
			};
		}
		case APP_TABS.reportByProvider: {
			return {
				...ADDITIONAL_TABS.reportByProvider,
				componentProps: {},
				location      : '/dashboard/reports/reportByProvider',
			};
		}
		case APP_TABS.personalInfo: {
			return {
				...ADDITIONAL_TABS.personalInfo,
				componentProps: { userID: entityID },
				location      : `/dashboard/personal/info/${entityID}`,
			};
		}
		case APP_TABS.websiteInner: {
			return {
				...ADDITIONAL_TABS.websiteInner,
				componentProps: { websiteID: entityID },
				location      : `/dashboard/partners/websitesInfo/${entityID}`,
			};
		}

		case APP_TABS.createMassBonus: {
			return {
				...ADDITIONAL_TABS.createMassBonus,
				componentProps: { bonusID: entityID },
				location      : `/dashboard/loyalty/bonusingSystem/massbonus/${entityID}`,
			};
		}
		case APP_TABS.bonusesConditionalBonusCreate: {
			return {
				...ADDITIONAL_TABS.bonusesConditionalBonusCreate,
				componentProps: { bonusID: entityID },
				location      : `/dashboard/loyalty/bonusingSystem/bonuses/create/${entityID}`,
			};
		}
		case APP_TABS.bonusesConditionalBonusEdit: {
			const params = getLastMultiParameterIDs(tabID);
			const location = params.length ? `/dashboard/loyalty/bonusingSystem/bonuses/edit/${params[0]}/${params[1]}` : '/dashboard/loyalty/bonusingSystem/bonuses';
			const componentProps = params.length ? { type: params[0], bonusID: params[1] } : {};
			return {
				...ADDITIONAL_TABS.bonusesConditionalBonusCreate,
				titleID: 'loyalty/bonusingSystem/bonuses.editTitle',
				componentProps,
				location,
			};
		}

		case APP_TABS.bonusesWelcomeBonusCreate: {
			return {
				...ADDITIONAL_TABS.bonusesWelcomeBonusCreate,
				componentProps: { bonusID: entityID },
				location      : `/dashboard/loyalty/bonusingSystem/welcome/create/${entityID}`,
			};
		}
		case APP_TABS.bonusesWelcomeBonusEdit: {
			const location = `/dashboard/loyalty/bonusingSystem/welcome/edit/${entityID}`;
			return {
				...ADDITIONAL_TABS.bonusesWelcomeBonusEdit,
				id            : APP_TABS.bonusesWelcomeBonusCreate,
				titleID       : 'loyalty/bonusingSystem/bonuses.editWelcomeTitle',
				componentProps: { bonusID: entityID },
				location,
			};
		}
		case APP_TABS.agentPlayersInnerPage: {
			const location = `/dashboard/agent/players/${entityID}`;
			return {
				id            : APP_TABS.agentPlayers,
				titleID       : '/agent/players/innerPage',
				componentProps: { playerID: entityID },
				location,
			};
		}
		case APP_TABS.agentPlayers: {
			const location = '/agent/players';
			return {
				...ADDITIONAL_TABS.agentPlayers,
				id     : APP_TABS.agentPlayers,
				titleID: '/agent/players',
				location,
			};
		}


		default: {
			return defaultTab;
		}
	}
}
