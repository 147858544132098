import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { Empty, Layout } from 'antd';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import IntlMessages from '../../components/utility/intlMessages';
import Menu from '../../components/uielements/menu';
import Logo from '../../components/utility/logo';
import TabLink from '../AppTabsManager/components/TabLink';

import appActions from '../../redux/app/actions';
import appTabsActions from '../../redux/appTabs/actions';
import { deriveMenu, deriveUI } from '../../selectors/sidebar';
import { deriveApp } from '../../selectors/app';
import { deriveActiveTabID } from '../../selectors/appTabs';
import { deriveSidebarTheme } from '../../selectors/theme';
import { deriveUser } from '../../selectors/auth';

import SidebarIcon from './SidebarIcon';
import { SidebarWrapper, Link } from './sidebar.style';
import {
	createNextedKeys,
	defineOpenKeysInDidMount,
} from '../../constants/appTabs';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

const { SubMenu } = Menu;
const MenuItem = Menu.Item;
const { Sider } = Layout;

const Sidebar = ({
	menu,
	loading,
	partnerLoading,
	app,
	customizedTheme,
	// user,
	activeTabID,
	toggleOpenDrawer,
	changeOpenKeys,
	toggleCollapsed,
	openTabUsers,
	openTabUsersGroups,
	openTabUsersRoles,
}) => {
	const [userMenuOpenedKey, setUserMenuOpenedKey] = useState(null);
	const [menuEntities] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		const openKeys = defineOpenKeys(menu, activeTabID);
		changeOpenKeys(openKeys);
		// eslint-disable-next-line
  }, [activeTabID, changeOpenKeys, menu]);

	const getAncestorKeys = (key) => {
		const map = {
			sub3: ['sub2'],
			sub4: ['sub3'],
		};
		return map[key] || [];
	};

	const defineOpenKeys = (menu, activeTabID) => {
		const { collapsed } = app;
		if (collapsed) {
			return [];
		}

		const currentTab = menuEntities[activeTabID];
		if (currentTab && currentTab.key) {
			const keys = currentTab.key.split('/');
			if (keys.length > 2) {
				return keys.slice(0, keys.length - 1);
			}
			return [keys[0]];
		}

		return defineOpenKeysInDidMount(menu, activeTabID) || [];
	};

	const defineSelectedKeys = () => {
		let selectedKeys = [activeTabID];
		if (activeTabID.includes('priorities/sports')) {
			selectedKeys = ['priorities'];
		}
		return selectedKeys;
	};

	const checkMenuOpen = (nextOpenKeys) => {
		if (nextOpenKeys.includes('usersMenu')) {
			switch (userMenuOpenedKey) {
				case 'users/groups':
					openTabUsersGroups();
					setUserMenuOpenedKey('users/groups');
					break;
				case 'users/roles':
					openTabUsersRoles();
					setUserMenuOpenedKey('users/roles');
					break;
				default:
					openTabUsers();
					setUserMenuOpenedKey('users/list');
			}
		}
	};

	const onMouseEnter = () => {
		const { openDrawer } = app;
		if (openDrawer === false) {
			toggleOpenDrawer();
		}
	};

	const onMouseLeave = () => {
		const { openDrawer } = app;
		if (openDrawer === true) {
			toggleOpenDrawer();
		}
	};

	const onMenuClick = ({ key, keyPath }) => {
		const isSideBar = key.startsWith('dashboard');
		if (isSideBar) {
			navigate(`/${key}`);
		} else {
			navigate(`/dashboard/${key}`);
		}
		const { view } = app;
		if (keyPath.includes('usersMenu')) {
			setUserMenuOpenedKey(key);
		}

		if (view === 'MobileView') {
			setTimeout(() => {
				toggleCollapsed();
				toggleOpenDrawer();
			}, 100);
		}
	};

	const onOpenChange = (newOpenKeys) => {
		const { openKeys } = app;
		const latestOpenKey = newOpenKeys.find((key) => !(openKeys.indexOf(key) > -1));
		const latestCloseKey = openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
		const { nextedKeys, parentKey } = createNextedKeys(menu, [], latestCloseKey);

		let nextOpenKeys = [];
		if (latestOpenKey) {
			nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
		}

		if (latestCloseKey) {
			if (!nextedKeys.includes(latestCloseKey)) {
				nextOpenKeys = getAncestorKeys(latestCloseKey);
			} else {
				nextOpenKeys = [parentKey];
			}
		}

		if (!nextedKeys.includes(latestOpenKey)) {
			changeOpenKeys(nextOpenKeys);
		} else {
			newOpenKeys.length > 2 && newOpenKeys.splice(1, 1);
			changeOpenKeys(newOpenKeys);
		}

		checkMenuOpen(nextOpenKeys);
	};

	const renderMenu = () => {

		if (partnerLoading || loading) {
			return (
				<MenuItem key="loading">
					<Link className="link">
						<span className="isoMenuHolder">
							<LoadingOutlined />
							<span className="nav-text">Loading...</span>
						</span>
					</Link>
				</MenuItem>
			);
		}
		if (!isArray(menu) || isEmpty(menu)) {
			return <Empty />;
		}
		const result = menu.map((rootItem) => {
			const children = rootItem.menuItems;

			if (children && children.length > 0) {
				return renderRootSubmenu(rootItem);
			}
			if (typeof rootItem !== 'string') {
				menuEntities[rootItem.url] = rootItem;
				return renderRootMenuItem(rootItem);
			}
		});

		return result;
	};

	const renderRootSubmenu = (submenu, isNoChild) => {
		const items = submenu.menuItems.map((menuItem) => {
			const children = menuItem.menuItems;
			if (children && children.length > 0) {
				return renderRootSubmenu(menuItem, true);
			}
			if (typeof rootItem !== 'string') {
				menuEntities[menuItem.url] = menuItem;
				return renderMenuItem(menuItem);
			}
		});

		return (
			<SubMenu
				key={submenu.key}
				title={
					<span className="isoMenuHolder">
						{!isNoChild && <SidebarIcon sidebarKey={submenu.key} iconName={submenu.icon} />}
						<span className={!isNoChild ? 'nav-text' : 'child-nav'}>
							<IntlMessages id={`sidebar.${submenu.title}`} />
						</span>
					</span>
				}
			>
				{items}
			</SubMenu>
		);
	};

	const renderRootMenuItem = (menuItem) => {
		const { key, icon, title, url, component } = menuItem;
		const titleID = `sidebar.${title}`;
		return (
			<MenuItem key={key}>
				<TabLink
					className="link"
					id={key}
					titleID={titleID}
					componentName={component}
					location={url}
				>
					<span className={`${titleID ==='sidebar.player'?'flex_item':''} isoMenuHolder`}>
						<SidebarIcon sidebarKey={key} iconName={icon} />
						<span className="nav-text">
							<IntlMessages id={titleID} />
						</span>
					</span>
				</TabLink>
			</MenuItem>
		);
	};

	const renderMenuItem = (menuItem) => {
		const { key, title, url, component } = menuItem;
		const submenuStyle = {
			color: customizedTheme.textColor,
		};
		const titleID = `sidebar.${title}`;

		return (
			<MenuItem style={submenuStyle} key={key}>
				<TabLink
					className="link"
					id={key}
					titleID={titleID}
					componentName={component}
					location={url}
				>
					<IntlMessages id={titleID} />
				</TabLink>
			</MenuItem>
		);
	};

	const collapsed = cloneDeep(app.collapsed) && !cloneDeep(app.openDrawer);
	const mode = collapsed === true ? 'vertical' : 'inline';
	const styling = {
		backgroundColor: customizedTheme.backgroundColor,
		overflow       : 'auto',
		height         : '100vh',
		position       : 'sticky',
		// left    : 0,
		top            : 0,
		bottom         : 0,
	};

	const sidebarMenu = renderMenu();
	const selectedKeys = defineSelectedKeys();

	return (
		<SidebarWrapper>
			<Sider
				trigger={null}
				collapsible
				collapsed={collapsed}
				width="240"
				className="isomorphicSidebar"
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				style={styling}
			>
				<Logo collapsed={collapsed} />
				<Menu
					onClick={onMenuClick}
					theme="dark"
					mode={mode}
					openKeys={collapsed ? [] : app.openKeys}
					selectedKeys={selectedKeys}
					onOpenChange={(key) => onOpenChange(key, menu)}
					className="isoDashboardMenu"
				>
					{sidebarMenu}
				</Menu>
			</Sider>
		</SidebarWrapper>
	);
};

Sidebar.propTypes = {
	menu              : PropTypes.array.isRequired,
	loading           : PropTypes.bool.isRequired,
	partnerLoading    : PropTypes.bool.isRequired,
	app               : PropTypes.object.isRequired,
	customizedTheme   : PropTypes.object.isRequired,
	user              : PropTypes.object.isRequired,
	activeTabID       : PropTypes.string,
	toggleOpenDrawer  : PropTypes.func.isRequired,
	changeOpenKeys    : PropTypes.func.isRequired,
	toggleCollapsed   : PropTypes.func.isRequired,
	openTabUsers      : PropTypes.func.isRequired,
	openTabUsersGroups: PropTypes.func.isRequired,
	openTabUsersRoles : PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
	activeTabID: '',
};

const mapStateToProps = (state) => {
	const UI = deriveUI(state);
	const { partnerLoading } = state.Staff.Person.get('UI');

	return {
		partnerLoading,
		loading        : UI.loading,
		menu           : deriveMenu(state),
		app            : deriveApp(state),
		activeTabID    : deriveActiveTabID(state),
		customizedTheme: deriveSidebarTheme(state),
		user           : deriveUser(state),
	};
};

export default connect(mapStateToProps, {
	toggleOpenDrawer  : appActions.toggleOpenDrawer,
	changeOpenKeys    : appActions.changeOpenKeys,
	toggleCollapsed   : appActions.toggleCollapsed,
	openTabUsers      : appTabsActions.openTabUsers,
	openTabUsersGroups: appTabsActions.openTabUsersGroups,
	openTabUsersRoles : appTabsActions.openTabUsersRoles,
})(Sidebar);
