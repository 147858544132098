import ParamsBuilder from '../../../helpers/paramsBuilder';
import { fields } from '../deposits/utils';

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'depositID', fields.depositID);
	builder.addField(rules.isString, 'depositIP', fields.depositIP);
	builder.addField(rules.isArrayID, 'depositChannelIDs', fields.depositChannelID);
	builder.addField(rules.isArrayID, 'depositBonusTypeIDs', fields.depositBonusTypeIDs);
	builder.addField(rules.isArrayString, 'depositIPCountry', fields.depositIPCountry);
	builder.addField(rules.isString, 'sourceID', fields.sourceID);
	builder.addField(rules.isID, 'sourceTypeID', fields.sourceTypeID);
	builder.addRangeField(rules.isNumberRange, filter.depositNumber, [
		fields.depositNumberFrom,
		fields.depositNumberTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.dateRange, [
		fields.depositPlacementTimeFrom,
		fields.depositPlacementTimeTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}
