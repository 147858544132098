
import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import cloneDeep from 'lodash/cloneDeep';
import { restorePartnerID } from '../../../../helpers/utility';
import actions from './actions';
import { adaptSmsGateweysList } from './utils';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { websitesAPI } from '../../../../helpers/api/websites';
import { makeRequestPartnersSmsGateways } from '../../../settings/sms/smsGateway/saga';
import { logger } from '../../../../helpers/logger';

const prefix = 'partner.websites.smsgateways';
const messages = {
	errorSmsGateweysLoad      : `${prefix}.errorSmsGateweysLoad`,
	errorSmsGateweysReordering: `${prefix}.errorSmsGateweysReordering`,
	errorSmsGatewaySave       : `${prefix}.errorSmsGatewaySave`,
	errorSmsGatewayDelete     : `${prefix}.errorSmsGatewayDelete`,
	errorValidation           : `${prefix}.errorValidation`,

	successSmsGatewaySave: `${prefix}.successSmsGatewaySave`,
	successItemDelete    : `${prefix}.successItemDelete`,
};

function getStoreData({ Settings, Partner: { Websites } }) {

	const mainSmsGateweysList = Object.values(Settings.SMS.SMSGateway.get('entity') || {});

	return {
		mainSmsGateweysList,
		websiteSmsGatewaysList: Websites.SmsGateways.get('smsGatewaysList'),
	};
}

function* listReload() {
	yield takeEvery(actions.WEBSITE_MODAL_SMS_GATEWAYS_RELOAD, function* (action) {
		
		yield put(actions.uiRefresh({ loading: true }));
		const { websiteID } = action.data;
		const smsGateweysList = yield call(makeRequestSmsGateways, websiteID, true); // websiteCall = true NEED in website Module

		yield put(actions.listRefresh(smsGateweysList));
		yield put(actions.uiRefresh({ loading: false }));
	});
}

export function* makeRequestSmsGateways(websiteID, websiteCall) {

	const partnerID = restorePartnerID();
	if (websiteCall) {
		yield call(makeRequestPartnersSmsGateways, partnerID);
	}
	let smsGateweysList = [];
	try {
		const res = yield call(websitesAPI.websiteSmsGatewaysList, websiteID);
		if (res && res.status === 200) {
			smsGateweysList = adaptSmsGateweysList(res.data.data);

			if (websiteCall) {
				const { mainSmsGateweysList } = yield select(getStoreData);

				smsGateweysList.forEach(item => {
					if (!item.sender) {
						const gateway = mainSmsGateweysList.find(gateway => gateway.id === item.smsGatewayId);
						if (gateway) {
							item.sender = gateway.sender;
						}
					}
				});
			}
		}
		return smsGateweysList;
	} catch (error) {
		showError(messages.errorSmsGateweysLoad);
		logger.log(error);
	}
}


function* reorderSmsGateways() {
	yield takeEvery(actions.WEBSITE_MODAL_SMS_GATEWAYS_REORDERING, function* ({ data : { websiteID } }) {
		try {
			const { websiteSmsGatewaysList } = yield select(getStoreData);
			const clonedList = cloneDeep(websiteSmsGatewaysList);
			yield put(actions.listRefresh(clonedList));

			const newUpdatedList = clonedList.map((smsGateway, i) => {

				const tempData = {
					type      : smsGateway.type,
					name      : smsGateway.name,
					website_id: smsGateway.websiteID,
					priority  : i + 1,
				};

				if (typeof smsGateway.id === 'number') {
					tempData.id = smsGateway.id;
				}
				return tempData;
			});
			const res = yield call(websitesAPI.reOrdermsGateways, newUpdatedList);
			if (res && res.status === 200) {
				showSuccess(messages.successSmsGatewaySave);
				yield put(actions.listReload(websiteID));
			}
		} catch (error) {
			showError(messages.errorSmsGateweysReordering);
		}
	});
}

function* onSave() {

	yield takeEvery(actions.WEBSITE_MODAL_SMS_GATEWAYS_SAVE, function* ({ data: { id, websiteID } }) {
		yield put(actions.uiRefresh({ loading: true }));

		const { websiteSmsGatewaysList, mainSmsGateweysList } = yield select(getStoreData);

		const websiteSmsGatewayItem = websiteSmsGatewaysList.find(item => item.id === id );
		const mainSmsGatewayItem = mainSmsGateweysList.find(item => item.url === websiteSmsGatewayItem.url);
		const clonedItem = cloneDeep(websiteSmsGatewayItem);
		const sender = clonedItem.sender !== mainSmsGatewayItem.sender ? clonedItem.sender : null;
		try {
			const preparedSmsGateway =  {
				id                       : typeof clonedItem.id === 'number' ? clonedItem.id :  null,
				website_id               : clonedItem.websiteID,
				priority                 : clonedItem.priority,
				sms_gateway_id           : clonedItem.name,
				url                      : clonedItem.url,
				telephone_number_prefixes: clonedItem.phonePrefixes,
				status                   : clonedItem.statusID,
				default                  : clonedItem.default,
				sender,
			};
			if (preparedSmsGateway.sender === '') {
				showError('Sender is required');
				yield put(actions.uiRefresh({ loading: false }));
				return;
			}

			if (clonedItem.defaultSender) {
				// preparedSmsGateway.default_sender = clonedItem.defaultSender; // TODO remove default_sender
				preparedSmsGateway.sender = null;
			}
			const res = yield call(websitesAPI.smsGatewaySave, preparedSmsGateway, websiteID, id);
			if (res && res.status === 200) {
				showSuccess(messages.successSmsGatewaySave);
				yield put(actions.listReload(websiteID));
			}

		} catch (error) {
			showError(messages.errorSmsGatewaySave);
			logger.log(error);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* deleteSmsGateway() {
	yield takeEvery(actions.WEBSITE_MODAL_SMS_GATEWAYS_DELETE_ITEM, function* ({ data : { id, websiteID } }) {
		yield put(actions.uiRefresh({ loading: true }));

		try {
			const res = yield call(websitesAPI.websiteSmsGatewayDelete, websiteID, id);
			if (res && res.status === 200) {
				showSuccess(messages.successItemDelete);
				yield put(actions.listReload(websiteID));
			}
		} catch (error) {
			showError(messages.errorSmsGatewayDelete);
			logger.log(error);
		}
	});
}

export default function* websiteSmsGatewaysSaga() {
	yield all([
		fork(listReload),
		fork(reorderSmsGateways),
		fork(onSave),
		fork(deleteSmsGateway),
	]);
}
