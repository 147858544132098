import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	EAgentAutoCompleteType,
	EAgentStatus,
	IAgentBaseData,
	IAgentData,
	IAgentFilter, IAgentInfo, IAgentInfoBaseData,
	IAgentsInitialState,
	IAgentsUI, IAgentTransaction, IAgentTransactionFilter,
} from './types';
import { RootState } from '../store';
import { createFilter, filtersURL } from '../../helpers/utils';
import { initFilterTypes } from './utils';


const initialState: IAgentsInitialState = {
	list             : [],	
	filter           : createFilter(filtersURL.agents, initFilterTypes),
	transactionFilter: {
		amount       : [null, null],
		date         : ['', ''],
		transactionID: null,
	},
	changePassBaseData: {
		password   			 : null,
		confirmPassword: null,
	},
	transactions: [],
	agentInfo   : {
		id             : null,
		email          : '',
		username       : '',
		firstName      : '',
		lastName       : '',
		status         : EAgentStatus.Active,
		playersCount   : null,
		balance        : null,
		countryID      : null,
		city           : '',
		address        : '',
		phone          : '',
		password       : '',
		totalBalanceOut: 0,
		totalBalanceIn : 0,

	},
	agentInfoBaseData: {
		amount : null,
		agentID: null,
	},
	autoCompleteData: {
		username : [],
		email    : [],
		firstName: [],
		lastName : [],
	},
	baseData: {
		city           : '',
		address        : '',
		email          : '',
		firstName      : '',
		lastName       : '',
		phone          : '',
		username       : '',
		status         : EAgentStatus.Active,
		password       : '',
		confirmPassword: '',
		countryID      : null,
		currency       : 'USD',
	},

	UI: {
		visible           : false,
		listLoading       : false,
		modalLoading      : false,
		transactionLoading: false,
		adjustmentLoading : false,
		adjustmentVisible : false,
		baseChanged       : false,
		agentInfoLoading 	: false,
		updateLoading     : false,
		changePassLoading : false,
		changePassVisible : false,
	},
};
const agentsSlice = createSlice({
	name        : 'agents',
	initialState: initialState,
	reducers    : {
		setBaseData(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentBaseData>>) {
			state.baseData = { ...state.baseData, ...action.payload };
		},
		resetBaseData(state: Draft<IAgentsInitialState>) {
			state.baseData = initialState.baseData;
		},
		setAgents(state: Draft<IAgentsInitialState>, action: PayloadAction<IAgentData[]>) {
			state.list = action.payload;
		},
		setTransactions(state: Draft<IAgentsInitialState>, action: PayloadAction<IAgentTransaction[]>) {
			state.transactions = action.payload;
		},
		setAgentInfoBaseData(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentInfoBaseData>>) {
			state.agentInfoBaseData = { ...state.agentInfoBaseData, ...action.payload };
		},
		setUI(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentsUI>>) {
			state.UI = { ...state.UI, ...action.payload };
		},
		setAutoCompleteData(state: Draft<IAgentsInitialState>, action: PayloadAction<{ type: EAgentAutoCompleteType; data: string[] }>) {
			state.autoCompleteData = { ...state.autoCompleteData, [action.payload.type]: action.payload.data };
		},
		setFilter(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentFilter>>) {
			state.filter = { ...state.filter, ...action.payload };
		},
		setTransactionFilter(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentTransactionFilter>>) {
			state.transactionFilter = { ...state.transactionFilter, ...action.payload };
		},
		resetTransactionFilter(state: Draft<IAgentsInitialState>) {
			state.transactionFilter = initialState.transactionFilter;
		},
		resetFilter(state: Draft<IAgentsInitialState>) {
			state.filter = initialState.filter;
			state.autoCompleteData = initialState.autoCompleteData;
		},
		setAgentInfo(state: Draft<IAgentsInitialState>, action: PayloadAction<IAgentInfo>) {
			state.agentInfo = action.payload;
		},
		refreshAgentInfo(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentInfo>>) {
			state.agentInfo = { ...state.agentInfo, ...action.payload };
		},
		resetAgentInfo(state: Draft<IAgentsInitialState>) {
			state.agentInfo = initialState.agentInfo;
		},
		setChangePassBaseData(state: Draft<IAgentsInitialState>, action: PayloadAction<Partial<IAgentBaseData>>) {
			state.changePassBaseData = { ...state.changePassBaseData, ...action.payload };
		},
		resetChangePassBaseData(state: Draft<IAgentsInitialState>) {
			state.changePassBaseData = initialState.changePassBaseData;
		},
	},
});


export const agentsReducers = {
	setBaseData            : agentsSlice.actions.setBaseData,
	resetBaseData          : agentsSlice.actions.resetBaseData,
	setAgents              : agentsSlice.actions.setAgents,
	setTransactions        : agentsSlice.actions.setTransactions,
	setUI                  : agentsSlice.actions.setUI,
	setAutoCompleteData    : agentsSlice.actions.setAutoCompleteData,
	setFilter              : agentsSlice.actions.setFilter,
	resetFilter            : agentsSlice.actions.resetFilter,
	setTransactionFilter   : agentsSlice.actions.setTransactionFilter,
	resetTransactionFilter : agentsSlice.actions.resetTransactionFilter,
	setAgentInfoBaseData   : agentsSlice.actions.setAgentInfoBaseData,
	setAgentInfo           : agentsSlice.actions.setAgentInfo,
	resetAgentInfo         : agentsSlice.actions.resetAgentInfo,
	refreshAgentInfo       : agentsSlice.actions.refreshAgentInfo,
	setChangePassBaseData  : agentsSlice.actions.setChangePassBaseData,
	resetChangePassBaseData: agentsSlice.actions.resetChangePassBaseData,
};

export const agentsSelectors = {
	selectBaseData          : (state: RootState) => state.Agents.baseData,
	selectAgents            : (state: RootState) => state.Agents.list,
	selectUI                : (state: RootState) => state.Agents.UI,
	selectFilter            : (state: RootState) => state.Agents.filter,
	selectAutoCompleteData  : (state: RootState) => state.Agents.autoCompleteData,
	selectTransactions      : (state: RootState) => state.Agents.transactions,
	selectAgentInfoBaseData : (state: RootState) => state.Agents.agentInfoBaseData,
	selectTransactionFilter : (state: RootState) => state.Agents.transactionFilter,
	selectAgentInfo         : (state: RootState) => state.Agents.agentInfo,
	selectChangePassBaseData: (state: RootState) => state.Agents.changePassBaseData,
};


export default agentsSlice.reducer;