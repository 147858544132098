import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import { usersAPI } from '../../helpers/api/users';
import { showError } from '../../helpers/notifications';
import { restoreWebsite } from '../../helpers/utility';

import actions from './actions';
import { adaptCount, adaptNotificationsList, getNotificationTypeName } from './utils';
import { eventAPI } from '../../helpers/sockets/api/eventMarkets';
import { createError } from '../../helpers/sockets/socket';
import { SUBJECT_TYPE_NOTIFICATIONS } from '../../constants/notifications';
import externalSaga from './externalSaga';
import { logger } from '../../helpers/logger';
import { getHeadersTotalCount } from '../../helpers/utils';
import { hasAgentRole } from '../../helpers/permissions/utils';

const prefix = 'global.notifications';

const messages = {
	errorDataLoad: `${prefix}.errorDataLoad`,
	errorMarkAll : `${prefix}.errorMarkAll`,
};

function getStoreData(state) {
	const { Settings, Notifications, Auth } = state;

	const user = Auth.get('user');
	return {
		page            : Notifications.get('page'),
		notifIDs        : Notifications.get('notifIDs'),
		currencyEntities: Settings.CurrencyModule.get('entities'),
		notifications  	: Notifications,
		messagesPage    : Notifications.get('UI').messagesPage,
		isAgent         : hasAgentRole(user?.userRoles),
	};
}

function* countReload() {
	yield takeEvery(actions.COUNT_RELOAD, function* () {
		const { isAgent } = yield select(getStoreData);
		if (isAgent) return;
		try {
			const res = yield call(usersAPI.adminNotificationsCount);
			const count = yield call(adaptCount, res.data.data);

			yield put(actions.countMerge(count));
		} catch (error) {
			// Nothing
		}
	});
}

function* dataReload() {
	yield takeEvery(actions.DATA_RELOAD, function* (action) {
		yield put(actions.uiRefresh({ loading: true }));

		const { typeID, id } = action.data;
		const { currencyEntities, notifIDs, messagesPage } = yield select(getStoreData);
		const sectionName = yield call(getNotificationTypeName, typeID);

		const params = {
			page : messagesPage,
			limit: 10,
		};
		try {
			const res = yield call(usersAPI.adminNotificationsList, typeID, params);
			const totalCount = getHeadersTotalCount(res.headers);
			const notificationsList = yield call(adaptNotificationsList, {
				typeID,
				currencyEntities,
				rawList: res.data.data,
			});
			const subscribeIDs = notifIDs.concat(notificationsList.map(notif => notif.id));

			yield put(actions.notifDataRefresh(subscribeIDs, {}));
			yield put(actions.dataRefresh(typeID, notificationsList, id, 'byApi'));

			yield put(actions.actualIDsSubscribe(SUBJECT_TYPE_NOTIFICATIONS[typeID], subscribeIDs));
			yield put(actions.uiRefresh({ totalCount }));
			if (!notificationsList.length) {
				const hasMore = {
					[sectionName]: false,
				};
				yield put(actions.hasMoreMerge(hasMore));
			}
			if (messagesPage === 1) {
				yield put(actions.uiRefresh({ messagesPage: 2 }));
			}
		} catch (error) {
			showError(messages.errorDataLoad);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* actualIDsSubscribe() {
	yield takeEvery(actions.ACTUAL_IDS_SUBSCRIBE, function* (action) {
		const { typeID, subscribeIDs } = action.data;
		try {
			const res = yield call(eventAPI.subscribeExistIDs, subscribeIDs, typeID);
			if (res) {
				if (res.isSuccess) {
				// showSuccess(messages.successSubscribeUpdate);
				} else {
					const error = createError(res);
					throw error;
				}
			}
		} catch (error) {
			showError(messages.errorSubscribeUpdate, error);
			logger.log(error);
		}
	});
}

function* dataMarkAll() {
	yield takeEvery(actions.DATA_MARK_ALL, function* (action) {
		const { typeID, websiteID } = action.data;
		const sectionName = yield call(getNotificationTypeName, typeID);
		const params = { website_id: websiteID };
		try {
			const res = yield call(usersAPI.adminNotificationsMarkAll, typeID, params);
			if (res && res.status === 200) {
				const { notifications } = yield select(getStoreData);
				const currentNotifications = notifications.get(sectionName);
				const seenList = currentNotifications.map(item => ({ ...item, seen: true }));

				yield put(actions.dataRefreshSeen(sectionName, seenList));
			}

			// reset pages and reload first page and count
			const page = {
				[sectionName]: 1,
			};
			yield put(actions.pageMerge(page));
			yield put(actions.countReload(typeID));
		} catch (error) {
			showError(messages.errorMarkAll);
		}
	});
}

function* dataMarkOne() {
	yield takeEvery(actions.DATA_MARK_ONE, function* (action) {
		const { typeID, id } = action.data;
		const params = { website_id: restoreWebsite() };
		try {
			yield call(usersAPI.adminNotificationsMarkOne, id, params);
			// yield put(actions.dataReload(typeID, id));
			yield put(actions.countReload(typeID));
		} catch (error) {
			showError(messages.errorMarkAll);
		}
	});
}

function* pageChange() {
	yield takeEvery(actions.PAGE_CHANGE, function* (action) {
		const { typeID, pageNumber } = action.data;

		const sectionName = yield call(getNotificationTypeName, typeID);
		const page = {
			[sectionName]: pageNumber,
		};

		yield put(actions.pageMerge(page));
		yield put(actions.dataReload(typeID));
	});
}

export default function* notificationsSaga() {
	yield all([
		fork(countReload),
		fork(dataReload),
		fork(actualIDsSubscribe),
		fork(dataMarkAll),
		fork(dataMarkOne),
		fork(externalSaga),
		fork(pageChange),
	]);
}
