import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select, { SelectOption } from '../../../components/uielements/select';

import actions from '../../../redux/languageSwitcher/actions';
import SelectIcon from '../SelectIcon/SelectIcon';
import config from './config';
import Wrapper, { LangOption, TextLang } from './index.style';
import { PERMISSIONS } from '../../../helpers/permissions/constants';
import { hasAgentRole } from '../../../helpers/permissions/utils';


class TopLanguageSwitcher extends Component {

	static propTypes = {
		hasSwitch     : PropTypes.bool.isRequired,
		language      : PropTypes.object.isRequired,
		changeLanguage: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.renderOptions = this.renderOptions.bind(this);
	}

	renderOptions() {
		const languageList = config.options;
		const renderList = languageList.map(item => {
			return (
				<SelectOption key={item.dataBaseLangID} value={item.dataBaseLangID.toString()}>
					<LangOption>
						<SelectIcon icon ={item.icon} />
						<TextLang>{item.text}</TextLang>
					</LangOption>
				</SelectOption>
			);
		});

		return renderList;
	}

	render() {
		const { language, changeLanguage, hasSwitch, isAgent } = this.props;
		const selectedLanguage = language.dataBaseLangID;
		const props = {};
		if (isAgent) {
			props.suffixIcon = null;
		}
		return (
			<Wrapper>
				<div className="control">
					<Select
						value={selectedLanguage.toString()}
						style={{ width: '100%' }}
						onChange={value => changeLanguage(+value)}
						disabled={!hasSwitch}
						{...props}
					>
						{this.renderOptions()}
					</Select>
				</div>
			</Wrapper>
		);
	}
}

export default connect(state => {
	const permissions = state.Auth.get('permissions').projectPermissions;
	const isSA        = permissions.platform_admin || permissions.super_admin;
	const hasSwitch   = Boolean(permissions[PERMISSIONS.accessHeaderLandSwitch]?.update) || Boolean(isSA);
	const user				= state.Auth.get('user');
	const isAgent = hasAgentRole(user?.userRoles);
	return {
		hasSwitch,
		isAgent,
		...state.LanguageSwitcher.toJS(),
	};
}, { changeLanguage: actions.changeLanguage })(TopLanguageSwitcher);
