import { EBonusTemplateTypes } from '../../../containers/LoyaltySystem/BonusTemplates/types';
import EntityAdapter from '../../../helpers/entityAdapter';
import {
	EBonusTemplateStatus, EBonusTemplateTypeIDs,
	IBonusTemplate,
	IBonusTemplateResponse, ICurrency,
	ITemplateBonusMoney,
	ITemplateFreeSpins, ITemplateTitle,
} from './types';
import queryToFilterValidaion from '../../../helpers/queryToFilterValidaion';
import ParamsBuilder from '../../../helpers/paramsBuilder';
import { BonusTemplatesLoggerModels, CasinoExtraSpinType } from '@ceo-betmakers/common-globals';
import { EBonusTemplateLogTabs } from '../../../containers/LoyaltySystem/BonusTemplates/modal/utils';
import { ICurrency as ICurrencyWithCode } from '../../settings/currency/types';
import { createEntities } from '../../utility';

const { isValidID, isValidString, isValidArray, isValidDateRange } = queryToFilterValidaion;

const fields = {
	id                : 'id',
	name              : 'name',
	websiteID         : 'website_id',
	templateType      : 'template_type',
	logoURL           : 'logo_url',
	templateStartDate : 'template_start_date',
	templateExpireDate: 'template_expire_date',
	forced            : 'forced',
	affiliateReference: 'affiliate_reference',
	daysToExpireBonus : 'days_to_expire_bonus',
	daysToExpireWallet: 'days_to_expire_wallet',
	wageringTurnover  : 'wagering_turnover',
	typeID            : 'type_id',
	statusID          : 'status_id',
	casinoGameID      : 'casino_game_id',
	casinoGame        : 'casino_game_name',
	casinoGameName    : 'casino_game_name',
	denominationKey   : 'denomination',
	denominationValue : 'denomination_value',
	denomination   			: 'denomination',
	betPerLine        : 'bet_per_line',
	lines             : 'lines',
	spinsTotal        : 'spins_total',
	spinsFrom         : 'spins_from',
	spinsTo           : 'spins_to',
	createDate        : 'create_date',
	overlap           : 'overlap',
	weight            : 'weight',
	usedTotalFreespins: 'used_total_freespins',

	countriesIDs  : 'country_ids',
	countriesAllow: 'country_allow',

	// Eligability
	allow        : 'allow',
	gameIDs      : 'game_ids',
	integratorIDs: 'integrator_ids',
	providerIDs  : 'provider_ids',
	categoryIDs  : 'category_ids',
	tagIDs       : 'tag_ids',

	// Currencies
	currencies      : 'currencies',
	currencyID      : 'currency_id',
	amountTo        :	'amount_to',
	amountFrom      : 'amount_from',
	amountTotal     : 'amount_total',
	maxPayoutAmount : 'max_payout_amount',
	maxPayoutPercent: 'max_payout_percent',
	usedTotalAmount : 'used_total_amount',

	// Titles
	titles     : 'titles',
	title      : 'title',
	description: 'description',
	langID     : 'lang_id',

	// Filter
	createDateFrom        : 'create_date_from',
	createDateTo          : 'create_date_to',
	templateExpireDateFrom: 'expire_date_from',
	templateExpireDateTo  : 'expire_date_to',
	wageringTurnoverFrom  : 'wagering_turnover_from',
	wageringTurnoverTo    : 'wagering_turnover_to',
	statusIDs             : 'status_ids',
};

const bonusTemplatesAdapter = createBonusTemplateAdapter();
const bonusTemplatesPreparer = createBonusTemplateAdapter();
const titlesAdapter			= createTitlePrepared();
const currenciesAdapter		= createCurrenciesPrepared();
const freeSpinCurrencyAdapter = freeSpinCurrencyPreparer();

export const adaptBonusTemplates = (data: IBonusTemplateResponse[], currencyList: ICurrencyWithCode[] = []): IBonusTemplate[] => {
	const adaptedData: IBonusTemplate[] = bonusTemplatesAdapter.adaptList(data) as IBonusTemplate[];
	const currencyEntities = createEntities(currencyList);
	adaptedData.forEach((item: IBonusTemplate) => {
		item.currencies = currenciesAdapter.adaptList(item.currencies) as ICurrency[];
		item.currencies.map(curItem => curItem.currencyCode = currencyEntities[curItem.currencyID] ? currencyEntities[curItem.currencyID].code : '');
	});
	return adaptedData;
};

export const adaptBonusTemplate = (data: IBonusTemplateResponse): IBonusTemplate => {
	const adapted = bonusTemplatesAdapter.adapt(data) as IBonusTemplate;

	adapted.showRemainingSpinsTotal = !!adapted.spinsTotal;
	adapted.remainingSpinsTotal		= adapted.spinsTotal - adapted.usedTotalFreespins;
	const adaptedCurrency: ICurrency[] = currenciesAdapter.adaptList(adapted.currencies) as ICurrency[];
	const updatedCurrencies = adaptedCurrency.map(item => {
		return {
			...item,
			// default 1 should be changed as BE is not implemented yet
			denom                   : `${item.denomination || 1}/${item.denominationValue}`,
			remainingTotalAmount    : item.amountTotal - item.usedTotalAmount,
			showRemainingTotalAmount: !!item.amountTotal,
		};
	});
	const adaptedTitles: ITemplateTitle[] = titlesAdapter.adaptList(adapted.titles) as ITemplateTitle[];
	return {
		...adapted,
		currencyID: adaptedCurrency.map(item => item.currencyID),
		currencies: updatedCurrencies,
		titles    : adaptedTitles,
	};
};


function createBonusTemplateAdapter ()  {
	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.number, 'id', fields.id);
	adapter.addField(rules.number, 'usedTotalFreespins', fields.usedTotalFreespins);
	adapter.addField(rules.string, 'name', fields.name);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'websiteID', fields.websiteID);
	adapter.addField(rules.number, 'templateType', fields.templateType);
	adapter.addField(rules.string, 'logoURL', fields.logoURL);
	adapter.addField(rules.ISOStringOrNull, 'templateExpireDate', fields.templateExpireDate);
	adapter.addField(rules.ISOStringOrNull, 'templateStartDate', fields.templateStartDate);
	adapter.addField(rules.boolean, 'forced', fields.forced);
	adapter.addField(rules.arrayString, 'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.number, 'daysToExpireBonus', fields.daysToExpireBonus);
	adapter.addField(rules.numberOrNull, 'daysToExpireWallet', fields.daysToExpireWallet);
	adapter.addField(rules.number, 'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.string, 'casinoGame', fields.casinoGame);
	adapter.addField(rules.string, 'casinoGameName', fields.casinoGameName);
	adapter.addField(rules.number, 'denominationValue', fields.denominationValue);
	adapter.addField(rules.number, 'betPerLine', fields.betPerLine);
	adapter.addField(rules.number, 'lines', fields.lines);
	adapter.addField(rules.numberOrNull, 'spinsTotal', fields.spinsTotal);
	adapter.addField(rules.numberOrNull, 'spinsFrom', fields.spinsFrom);
	adapter.addField(rules.numberOrNull, 'spinsTo', fields.spinsTo);
	adapter.addField(rules.arrayID, 'gameIDs', fields.gameIDs);
	adapter.addField(rules.boolean, 'allow', fields.allow);
	adapter.addField(rules.arrayID, 'integratorIDs', fields.integratorIDs);
	adapter.addField(rules.arrayID, 'providerIDs', fields.providerIDs);
	adapter.addField(rules.arrayID, 'categoryIDs', fields.categoryIDs);
	adapter.addField(rules.arrayID, 'tagIDs', fields.tagIDs);
	adapter.addField(rules.arrayObject, 'titles', fields.titles);
	adapter.addField(rules.arrayObject, 'currencies', fields.currencies);
	adapter.addField(rules.fullDate, 'createDate', fields.createDate);
	adapter.addField(rules.bool, 'overlap', fields.overlap);
	adapter.addField(rules.number, 'weight', fields.weight);
	adapter.addField(rules.arrayID, 'currencyID', fields.currencyID);
	adapter.addField(rules.id, 'typeID', fields.typeID);

	adapter.addField(rules.arrayID, 'countriesIDs', fields.countriesIDs);
	adapter.addField(rules.bool, 'countriesAllow', fields.countriesAllow);
	return adapter;
}

export const initBaseData = {
	id                     : null,
	name                   : null,
	spinsTotal             : null,
	spinsFrom              : null,
	spinsTo                : null,
	casinoGameID           : null,
	casinoGameName       		: '',
	denominationValue      : null,
	betPerLine             : null,
	lines                  : null,
	statusID               : EBonusTemplateStatus.ACTIVE,
	websiteID              : null,
	templateType           : null,
	logoURL                : null,
	templateStartDate      : null,
	templateExpireDate     : null,
	forced                 : null,
	affiliateReference     : [],
	daysToExpireBonus      : null,
	daysToExpireWallet     : null,
	wageringTurnover       : null,
	typeID                 : CasinoExtraSpinType.FREE_SPIN,
	remainingSpinsTotal    : null,
	usedTotalFreespins     : null,
	showRemainingSpinsTotal: null,

	allow         : true,
	countriesAllow: true,
	gameIDs       : [],
	integratorIDs : [],
	providerIDs   : [],
	categoryIDs   : [],
	tagIDs        : [],
	titles        : [],
	currencies    : [],
	currencyID    : [], // currencyIDs

	count: {
		providers : {},
		categories: {},
	},

};

function createTitlePrepared() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.id,     'langID', fields.langID);

	return adapter;
}

function createCurrenciesPrepared() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'currencyID', fields.currencyID);
	adapter.addField(rules.number, 'amountTo', fields.amountTo);
	adapter.addField(rules.number, 'amountFrom', fields.amountFrom);
	adapter.addField(rules.number, 'amountTotal', fields.amountTotal);
	adapter.addField(rules.number, 'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.number, 'maxPayoutPercent', fields.maxPayoutPercent);
	adapter.addField(rules.number, 'betPerLine', fields.betPerLine);
	adapter.addField(rules.number, 'denominationKey', fields.denominationKey);
	adapter.addField(rules.number, 'denominationValue', fields.denominationValue);
	adapter.addField(rules.number,'denomination', fields.denomination);
	adapter.addField(rules.number,'usedTotalAmount', fields.usedTotalAmount);
	adapter.addField(rules.number,'lines', fields.lines);
	
	return adapter;
}

function freeSpinCurrencyPreparer() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'currencyID', fields.currencyID);
	adapter.addField(rules.numberOrNull, 'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.number, 'betPerLine', fields.betPerLine);
	adapter.addField(rules.number, 'lines', fields.lines);
	adapter.addField(rules.number, 'denominationValue', fields.denominationValue);
	adapter.addField(rules.number, 'denominationKey', fields.denominationKey);

	return adapter;
}

export const bonusTemplateTypes: {
	[key in EBonusTemplateTypes]: EBonusTemplateTypeIDs;
} = {
	[EBonusTemplateTypes.MANUAL_BONUS_MONEY]: EBonusTemplateTypeIDs.MANUAL_BONUS_MONEY,
	[EBonusTemplateTypes.MANUAL_FREE_SPINS] : EBonusTemplateTypeIDs.MANUAL_FREE_SPINS,
};

export function prepareBonusMoney(rawData: ITemplateBonusMoney, editMode: boolean ) {

	bonusTemplatesPreparer.clearExcludes();
	if (!editMode) {
		bonusTemplatesPreparer.addExcludeField('id');
	}
	bonusTemplatesPreparer.addExcludeField('count');
	bonusTemplatesPreparer.addExcludeField('lines');
	bonusTemplatesPreparer.addExcludeField('denominationValue');
	bonusTemplatesPreparer.addExcludeField('affiliateReference');
	bonusTemplatesPreparer.addExcludeField('betPerLine');
	bonusTemplatesPreparer.addExcludeField('casinoGameID');
	bonusTemplatesPreparer.addExcludeField('spinsTotal');
	bonusTemplatesPreparer.addExcludeField('spinsFrom');
	bonusTemplatesPreparer.addExcludeField('spinsTo');
	bonusTemplatesPreparer.addExcludeField('createDate');

	if (rawData.forced) {
		bonusTemplatesPreparer.addExcludeField('daysToExpireBonus');
	}
	const preparedData			= bonusTemplatesPreparer.prepare(rawData);
	preparedData.titles			= titlesAdapter.prepareList(preparedData.titles);
	preparedData.currencies		= currenciesAdapter.prepareList(preparedData.currencies);


	return preparedData;
}

export function prepareFreeSpinTemplate(rawData: ITemplateFreeSpins, editMode: boolean) {
	bonusTemplatesPreparer.clearExcludes();
	if (!editMode) {
		bonusTemplatesPreparer.addExcludeField('id');
		bonusTemplatesPreparer.addExcludeField('createDate');
		bonusTemplatesPreparer.addExcludeField('templateStartDate');
	}
	if (rawData.forced) {
		bonusTemplatesPreparer.addExcludeField('daysToExpireBonus');
	}
	bonusTemplatesPreparer.addExcludeField('amountTo');
	bonusTemplatesPreparer.addExcludeField('amountFrom');
	bonusTemplatesPreparer.addExcludeField('maxPayoutPercent');
	const preparedData = bonusTemplatesPreparer.prepare(rawData);
	preparedData.titles = titlesAdapter.prepareList(preparedData.titles);
	preparedData.currencies = freeSpinCurrencyAdapter.prepareList(preparedData.currencies);

	return preparedData;
}

export function getListParams(filter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules   = builder.RULES;
	if (sorting) {
		builder.addValue('sort_by', sorting.sortBy ? fields[sorting.sortBy] : 'id');
		builder.addValue('sort_order', sorting.sortOrder);
	}

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
	}

	builder.addField(rules.isString, 'name', fields.name);
	builder.addField(rules.isArrayID, 'statusID', fields.statusIDs);
	builder.addField(rules.isID, 'templateType', fields.templateType);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);
	builder.addField(rules.isID, 'casinoGameID', fields.casinoGameID);
	builder.addField(rules.isArrayString, 'affiliateReference', fields.affiliateReference);

	builder.addRangeField(rules.isDateTimeRange, filter.createDate, [
		fields.createDateFrom,
		fields.createDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.templateExpireDate, [
		fields.templateExpireDateFrom,
		fields.templateExpireDateTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.wageringTurnover, [
		fields.wageringTurnoverFrom,
		fields.wageringTurnoverTo,
	]);
	const params = builder.biuldParams(filter);

	return params;
}

export const initFilterTypes = {
	name              : isValidString,
	statusID          : isValidArray,
	templateType      : isValidID,
	currencyID        : isValidID,
	createDate        : isValidDateRange,
	templateExpireDate: isValidDateRange,
	wageringTurnover  : isValidArray,
	affiliateReference: isValidArray,
	casinoGameID      : isValidID,
};

export const defaultValuesTemplateFilter = {
	statusID: [String(EBonusTemplateStatus.ACTIVE)],
};


export const BONUS_TEMPLATE_LOG_MODEL_MAP = {
	[EBonusTemplateLogTabs.MAIN]    : BonusTemplatesLoggerModels.BONUS_TEMPLATE,
	[EBonusTemplateLogTabs.CURRENCY]: BonusTemplatesLoggerModels.BONUS_TEMPLATE_CURRENCY,
	// [EBonusTemplateLogTabs.TITLE]    : BonusTemplatesLoggerModels.BONUS_TEMPLATE_TITLE,  // not implemented yet
};