import { toNumber, find, isArray, isEmpty, isNull } from 'lodash';
import EntityAdapter from '../../../../helpers/entityAdapter';
import ParamsBuilder from '../../../../helpers/paramsBuilder';
import { logger } from '../../../../helpers/logger';


export const BONUS_TABS = {
	bonuses  : 'casinoBonuses',
	freeSpins: 'casinoFreeSpins',
	freeBets : 'freeBets',
};

export const freeSpinsFields = {
	name                 : 'name',
	typeID               : 'type_id',
	userID               : 'user_id',
	bonusTypeID          : 'bonus_type_id',
	spinsTotal           : 'spins_total',
	casinoGameID         : 'casino_game_id',
	currencyID           : 'currency_id',
	days_to_expire_wallet: 'days_to_expire_wallet',
	days_to_expire_bonus : 'days_to_expire_bonus',
	wageringTurnover     : 'wagering_turnover',
	forced               : 'forced',
	overlap              : 'overlap',
	gameIDs              : 'game_ids',
	logoURL              : 'logo_url',
	affiliateReference   : 'affiliate_reference', // arrayString;
	allow                : 'allow',
	titles               : 'titles',
	title                : 'title',
	description          : 'description',
	langID               : 'lang_id',
	integratorIDs        : 'integrator_ids',
	providerIDs          : 'provider_ids',
	categoryIDs          : 'category_ids',
	tagIDs               : 'tag_ids',
	maxPayoutAmount      : 'max_payout_amount',
	maxPayoutPercent     : 'max_payout_percent', // 0-100 number
	templateName         : 'template_name',
	templateID           : 'template_id',
	count                : 'count',
	noteForAdmin         : 'comment',
	comment              : 'comment',
};

export const freeBetsFields = {
	selectedMarket    : 'market_id',
	maxBonusAmount    : 'amount',
	minWageringOdd    : 'min_wagering_odd',
	wageringTurnover  : 'wagering_turnover',
	currencyID        : 'currency_id',
	startDateFrom     : 'start_date_from',
	startDateTo       : 'start_date_to',
	// modal
	sportID           : 'category_id',
	tradingModeType   : 'trading_mode',
	forced            : 'forced',
	overlap           : 'overlap',
	affiliateReference: 'affiliate_reference', // arrayString;
};
export const manualBonusFields = {
	amount            : 'amount',
	userID            : 'user_id',
	comment           : 'comment',
	wageringTurnover  : 'wagering_turnover',
	daysToExpire      : 'days_to_expire',
	daysToExpireWallet: 'days_to_expire_wallet',
	daysToExpireBonus : 'days_to_expire_bonus',
	forced            : 'forced',
	logoURL           : 'logo_url',
	titles            : 'titles',

	
	// view Case
	givenDate   : 'given_date',
	balance     : 'balance',
	statusID    : 'status_id',
	templateID  : 'template_id',
	templateName: 'template_name',
};

export const betsFields = {
	betID             : 'bet_id',
	betPerLine        : 'bet_per_line',
	lines             : 'lines',
	denominationKey   : 'denomination',
	denominationValue : 'denomination_value',
	requiredBetPerLine: 'required_bet_per_line',
	requiredLines     : 'required_lines',
};

export const templatesFields = {
	templateType      : 'template_type',
	statusIDs         : 'status_ids',
	countryId         : 'country_id',
	currencyID        : 'currency_id',
	// adapt
	autoSendLog       : 'auto_send_log',
	id                : 'id',
	createDate        : 'create_date',
	name              : 'name',
	statusId          : 'status_id',
	websiteId         : 'website_id',
	logoUrl           : 'logo_url',
	templateStartDate : 'template_start_date',
	templateExpireDate: 'template_expire_date',
	overlap           : 'overlap',
	weight            : 'weight',
	forced            : 'forced',
	affiliateReference: 'affiliate_reference',
	daysToExpireBonus : 'days_to_expire_bonus',
	daysToExpireWallet: 'days_to_expire_wallet',
	wageringTurnover  : 'wagering_turnover',
	typeId            : 'type_id',
	casinoGameId      : 'casino_game_id',
	spinsTotal        : 'spins_total',
	spinsFrom         : 'spins_from',
	spinsTo           : 'spins_to',
	countryIds        : 'country_ids',
	countryAllow      : 'country_allow',
	currenciesIDs     : 'currencies',
	amountFrom        : 'amount_from',
	amountTo          : 'amount_to',
	amountTotal       : 'amount_total',
	usedTotalFreespins: 'used_total_freespins',
	usedTotalAmount   : 'used_total_amount',
	// prepare
	noteForAdmin      : 'comment',
	templateID        : 'template_id',
};

const freeSpinsAdapter        = createFreeSpinsAdapter();
const betsAdapter             = createBetsAdapter();
const freeBetsAdapter         = createFreeBetsAdapter();
// const titlesAdapter           = createTitleAdapter(false);
const titlesAdapterWithLang   = createTitleAdapter(true);
const manualBonus             = createManualBonusAdapter();
const lineAndDenomination     = createLineAndDenominationAdapter();
const adaptTemplates                       = createAdaptTemplatesAdapter();
const adaptCurrencies			= createAdaptTemplatesCurrenciesAdapter();
const adaptTemplatesSaveFreeSpin = createAdaptTemplatesFreeSpinSaveAdapter();


// Params
export function getUserBonusListParams(bonusData) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addField(rules.isID, 'sportID',         freeBetsFields.sportID);
	builder.addField(rules.isID, 'tradingModeType', freeBetsFields.tradingModeType);

	return builder.biuldParams(bonusData);
}

export function getUserBonusTemplateParams(rawData) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	builder.addValue('sort_by', 'asc');
	builder.addField(rules.isID, 'templateType', templatesFields.templateType);
	builder.addField(rules.isID, 'countryId', templatesFields.countryId);
	builder.addField(rules.isID, 'currencyID', templatesFields.currencyID);
	builder.addField(rules.isArrayID, 'statusIDs', templatesFields.statusIDs);

	return builder.biuldParams(rawData);
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptBets(rawData = []) {

	betsAdapter.clearExcludes();
	const adaptedData = betsAdapter.adaptList(rawData);

	return adaptedData;
}

export function adaptBonusMoney(rawData = {}) {
	manualBonus.clearExcludes();
	const adaptedData	= manualBonus.adapt(rawData);
	if (!Object.keys(adaptedData.count).length) {
		adaptedData.count = {
			providers : {},
			categories: {},
		};
	}
	adaptedData.titles	= titlesAdapterWithLang.adaptList(rawData.titles);
	adaptedData.amount	= rawData.initial_balance;
	adaptedData.daysToExpire = rawData.expire_date;

	return adaptedData;
}

export function adaptFreeSpins(rawData = {}) {
	freeSpinsAdapter.clearExcludes();
	const adaptedData	= freeSpinsAdapter.adapt(rawData);
	if (!Object.keys(adaptedData.count).length) {
		adaptedData.count = {
			providers : {},
			categories: {},
		};
	}
	adaptedData.titles	= titlesAdapterWithLang.adaptList(rawData.titles);
	adaptedData.givenDate = rawData.created;
	adaptedData.claimDate = rawData.claim_date;
	adaptedData.expireDate = rawData.wallet_expire_date;
	adaptedData.statusID = rawData.status_id;
	adaptedData.leftSpins = rawData.left_spins;
	adaptedData.wonAmount = rawData.won_amount;

	return adaptedData;
}


export function adaptLineAndDenomination(rawData = []) {

	lineAndDenomination.clearExcludes();
	const adaptedData = lineAndDenomination.adapt(rawData);

	return adaptedData;
}

export function adaptFotSend(providerIDs, integratorsList) {

	let providerIDsToArray;
	let integratorIDs;

	if ( !isEmpty(providerIDs) && integratorsList ) {
		providerIDsToArray = Object.values(providerIDs)
			.map( checkedProviders => Object.entries(checkedProviders)
				.map( checkedProvider => {
					if (checkedProvider[1]) {
						return checkedProvider[0];
					}
					return null;
				}))
			.flat()
			.filter( checkedProvidersID => checkedProvidersID )
			.map( allProviderID => Number(allProviderID) );

		integratorIDs = integratorsList.map( integrator => {

			if (integrator.providers.length === 0) { return null; }

			let isIntegratorChecked = true;

			integrator.providers.forEach( provider => {
				if ( !providerIDsToArray.includes(provider.id) ) {
					isIntegratorChecked = false;
				}
			});

			return isIntegratorChecked ? integrator.id : null;

		}).filter( integratorID => { if (!isNull(integratorID)) { return Number(integratorID) >= 0; } return integratorID; });
	}

	return { providerIDsToArray, integratorIDs };

}

export function adaptTemplatesList(rawData = []) {

	adaptTemplates.clearExcludes();
	const adaptedData = adaptTemplates.adaptList(rawData).map(template => {
		template.currenciesIDs = adaptCurrencies.adaptList(template.currenciesIDs);
		return template;
	});
	return adaptedData;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareFreeSpin(rawData = {}, limits = {}, showLineAndDenominationInputs) {
	freeSpinsAdapter.clearExcludes();
	const preparedData = freeSpinsAdapter.prepare(rawData);

	preparedData.titles = titlesAdapterWithLang.prepareList(rawData.titles);

	// limits
	const { bets, betID, denominationKey, denominationValue } = limits;

	preparedData[betsFields.denominationKey]   = toNumber(denominationKey);
	preparedData[betsFields.denominationValue] = toNumber(denominationValue);

	if (isArray(bets) && !showLineAndDenominationInputs) {
		const bet = find(bets, { betID });
		if (bet) {
			preparedData[betsFields.betID]      = betID;
			preparedData[betsFields.betPerLine] = bet.betPerLine;
			preparedData[betsFields.lines]      = bet.lines;
		}
	}

	return preparedData;
}

export function getUserBonusFreeSpinSaveTemplatePrepare(rawData = {}) {
	adaptTemplatesSaveFreeSpin.clearExcludes();
	const preparedData = adaptTemplatesSaveFreeSpin.prepare(rawData);

	return preparedData;
}


export function prepareManualBonus(rawData = {}) {
	const preparedData = manualBonus.prepare(rawData);
	preparedData.titles = titlesAdapterWithLang.prepareList(rawData.titles);
	logger.log('rawData ', rawData);

	return preparedData;
}

export function prepareFreeBet(rawData = {}) {
	const preparedData = freeBetsAdapter.prepare(rawData);

	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createFreeSpinsAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'name', freeSpinsFields.name);
	adapter.addField(rules.string, 'noteForAdmin', freeSpinsFields.noteForAdmin);
	
	adapter.addField(rules.id, 'typeID', freeSpinsFields.typeID);
	adapter.addField(rules.id, 'bonusTypeID', freeSpinsFields.bonusTypeID);
	adapter.addField(rules.id, 'casinoGameID', freeSpinsFields.casinoGameID);
	adapter.addField(rules.id, 'currencyID', freeSpinsFields.currencyID);

	adapter.addField(rules.positiveNumber, 'daysToExpireBonus', freeSpinsFields.days_to_expire_bonus);
	adapter.addField(rules.positiveNumber, 'daysToExpireWallet', freeSpinsFields.days_to_expire_wallet);
	adapter.addField(rules.positiveNumber, 'spinsTotal', freeSpinsFields.spinsTotal);
	adapter.addField(rules.positiveNumber, 'wageringTurnover', freeSpinsFields.wageringTurnover);
	adapter.addField(rules.bool, 'forced', freeSpinsFields.forced);
	adapter.addField(rules.bool, 'overlap', freeSpinsFields.overlap);

	adapter.addField(rules.positiveNumber, 'wageringTurnover', freeSpinsFields.wageringTurnover);
	adapter.addField(rules.arrayNumber, 'gameIDs', freeSpinsFields.gameIDs);
	adapter.addField(rules.arrayNumber, 'categoryIDs', freeSpinsFields.categoryIDs);
	adapter.addField(rules.arrayNumber, 'tagIDs', freeSpinsFields.tagIDs);
	adapter.addField(rules.string, 'logoURL', freeSpinsFields.logoURL);
	adapter.addField(rules.bool, 'allow', freeSpinsFields.allow);
	adapter.addField(rules.arrayString, 'affiliateReference', freeSpinsFields.affiliateReference);
	adapter.addField(rules.arrayID, 'integratorIDs', freeSpinsFields.integratorIDs);
	adapter.addField(rules.arrayID, 'providerIDs', freeSpinsFields.providerIDs);

	adapter.addField(rules.numberOrNull,  'maxPayoutAmount', freeSpinsFields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'maxPayoutPercent', freeSpinsFields.maxPayoutPercent);
	adapter.addField(rules.numberOrNull,  'lines', betsFields.lines);
	adapter.addField(rules.numberOrNull,  'betPerLine', betsFields.betPerLine);
	adapter.addField(rules.numberOrNull,  'maxPayoutPercent', freeSpinsFields.maxPayoutPercent);
	adapter.addField(rules.string,  'templateName', freeSpinsFields.templateName);
	adapter.addField(rules.numberOrNull,  'templateID', freeSpinsFields.templateID);
	adapter.addField(rules.object, 'count', freeSpinsFields.count);
	
	return adapter;
}

export function createFreeBetsAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'selectedMarket', freeBetsFields.selectedMarket);
	adapter.addField(rules.id, 'currencyID', freeBetsFields.currencyID);

	adapter.addField(rules.positiveNumber, 'maxBonusAmount',   freeBetsFields.maxBonusAmount);
	adapter.addField(rules.positiveNumber, 'minWageringOdd',   freeBetsFields.minWageringOdd);
	adapter.addField(rules.positiveNumber, 'wageringTurnover', freeBetsFields.wageringTurnover);

	adapter.addField(rules.date, 'startDateFrom', freeBetsFields.startDateFrom);
	adapter.addField(rules.date, 'startDateTo',   freeBetsFields.startDateTo);
	adapter.addField(rules.bool, 'forced', freeBetsFields.forced);
	adapter.addField(rules.bool, 'overlap', freeBetsFields.overlap);

	adapter.addField(rules.arrayString, 'affiliateReference', freeBetsFields.affiliateReference);

	return adapter;
}

export function createLineAndDenominationAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.bool, 'requiredBetPerLine', betsFields.requiredBetPerLine);
	adapter.addField(rules.bool, 'requiredLines', betsFields.requiredLines);

	return adapter;
}

export function createManualBonusAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id,              'userID', manualBonusFields.userID);
	adapter.addField(rules.number,          'amount', manualBonusFields.amount);
	adapter.addField(rules.string,          'comment', manualBonusFields.comment);
	adapter.addField(rules.positiveNumber,  'wageringTurnover', manualBonusFields.wageringTurnover);
	adapter.addField(rules.number,          'daysToExpire',   manualBonusFields.daysToExpire);
	adapter.addField(rules.number,          'daysToExpireWallet',   manualBonusFields.daysToExpireWallet);
	adapter.addField(rules.number,          'daysToExpireBonus',   manualBonusFields.daysToExpireBonus);

	
	adapter.addField(rules.bool,            'forced', manualBonusFields.forced);
	adapter.addField(rules.string,          'logoURL', manualBonusFields.logoURL);
	adapter.addField(rules.arrayObject,     'titles', manualBonusFields.titles);

	adapter.addField(rules.fullDate,          'givenDate', manualBonusFields.givenDate);
	adapter.addField(rules.number,          'balance', manualBonusFields.balance);
	adapter.addField(rules.number,          'statusID', manualBonusFields.statusID);

	adapter.addField(rules.number,          'templateID', manualBonusFields.templateID);
	adapter.addField(rules.string,          'templateName', manualBonusFields.templateName);


	adapter.addField(rules.numberOrNull,  'maxPayoutAmount', freeSpinsFields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'maxPayoutPercent', freeSpinsFields.maxPayoutPercent);

	adapter.addField(rules.arrayNumber, 'gameIDs', freeSpinsFields.gameIDs);
	adapter.addField(rules.arrayNumber, 'categoryIDs', freeSpinsFields.categoryIDs);
	adapter.addField(rules.arrayNumber, 'tagIDs', freeSpinsFields.tagIDs);
	adapter.addField(rules.bool, 'allow', freeSpinsFields.allow);
	adapter.addField(rules.arrayString, 'affiliateReference', freeSpinsFields.affiliateReference);
	adapter.addField(rules.arrayID, 'integratorIDs', freeSpinsFields.integratorIDs);
	adapter.addField(rules.arrayID, 'providerIDs', freeSpinsFields.providerIDs);
	adapter.addField(rules.object, 'count', freeSpinsFields.count);
	return adapter;
}

export function createBetsAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'betID', betsFields.betID);

	adapter.addField(rules.positiveNumber, 'betPerLine', betsFields.betPerLine);
	adapter.addField(rules.positiveNumber, 'lines', betsFields.lines);

	return adapter;
}

export function createTitleAdapter(withLang) {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;
	if (withLang) {
		adapter.addField(rules.number, 'langID', freeSpinsFields.langID);
	}
	adapter.addField(rules.string, 'title', freeSpinsFields.title);
	adapter.addField(rules.string, 'description', freeSpinsFields.description);

	return adapter;
}
export function createAdaptTemplatesCurrenciesAdapter () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'amountFrom', templatesFields.amountFrom);
	adapter.addField(rules.number, 'amountTo', templatesFields.amountTo);
	adapter.addField(rules.number, 'amountTotal', templatesFields.amountTotal);
	adapter.addField(rules.number, 'usedTotalAmount', templatesFields.usedTotalAmount);

	return adapter;
}
export function createAdaptTemplatesAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.bool, 'autoSendLog', templatesFields.autoSendLog);
	adapter.addField(rules.id, 'id', templatesFields.id);
	adapter.addField(rules.string, 'createDate', templatesFields.createDate);
	adapter.addField(rules.string, 'name', templatesFields.name);
	adapter.addField(rules.id, 'statusId', templatesFields.statusId);
	adapter.addField(rules.id, 'websiteId', templatesFields.websiteId);
	adapter.addField(rules.number, 'templateType', templatesFields.templateType);
	adapter.addField(rules.string, 'logoUrl', templatesFields.logoUrl);
	adapter.addField(rules.string, 'templateStartDate', templatesFields.templateStartDate);
	adapter.addField(rules.string, 'templateExpireDate', templatesFields.templateExpireDate);
	adapter.addField(rules.bool, 'overlap', templatesFields.overlap);
	adapter.addField(rules.number, 'weight', templatesFields.weight);
	adapter.addField(rules.bool, 'forced', templatesFields.forced);
	adapter.addField(rules.arrayString, 'affiliateReference', templatesFields.affiliateReference);
	adapter.addField(rules.number, 'daysToExpireBonus', templatesFields.daysToExpireBonus);
	adapter.addField(rules.number, 'daysToExpireWallet', templatesFields.daysToExpireWallet);
	adapter.addField(rules.number, 'wageringTurnover', templatesFields.wageringTurnover);
	adapter.addField(rules.number, 'typeId', templatesFields.typeId);
	adapter.addField(rules.number, 'spinsTotal', templatesFields.spinsTotal);
	adapter.addField(rules.number, 'spinsFrom', templatesFields.spinsFrom);
	adapter.addField(rules.number, 'spinsTo', templatesFields.spinsTo);
	adapter.addField(rules.arrayString, 'countryIds', templatesFields.countryIds);
	adapter.addField(rules.arrayObject, 'currenciesIDs', templatesFields.currenciesIDs);
	adapter.addField(rules.bool, 'countryAllow', templatesFields.countryAllow);

	adapter.addField(rules.number, 'usedTotalFreespins', templatesFields.usedTotalFreespins);

	return adapter;
}

export function createAdaptTemplatesFreeSpinSaveAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'templateID', templatesFields.templateID);
	adapter.addField(rules.number, 'spinsTotal', templatesFields.spinsTotal);
	adapter.addField(rules.string, 'noteForAdmin', templatesFields.noteForAdmin);

	return adapter;
}
