import { IAgentAutoCompletePayload } from './types';

const actions = {
	AGENTS_LIST_RELOAD             : 'AGENTS_LIST_RELOAD',
	CREATE_AGENT                   : 'CREATE_AGENT',
	ARCHIVE_AGENT                  : 'ARCHIVE_AGENT',
	AUTOCOMPLETE_DATA_SOURCE_RELOAD: 'AUTOCOMPLETE_DATA_SOURCE_RELOAD',
	AGENTS_TRANSACTIONS_RELOAD     : 'AGENTS_TRANSACTIONS_RELOAD',
	AGENT_BALANCE_ADJUSTMENT       : 'AGENT_BALANCE_ADJUSTMENT',
	AGENT_INFO_RELOAD              : 'AGENT_INFO_RELOAD',
	UPDATE_AGENT                   : 'UPDATE_AGENT',
	CHANGE_PASSWORD                : 'CHANGE_PASSWORD',

	agentsListReload: () => {
		return {
			type: actions.AGENTS_LIST_RELOAD,
		};
	},

	getAgentInfo: () => {
		return {
			type: actions.AGENT_INFO_RELOAD,
		};
	},

	createAgent: () => {
		return {
			type: actions.CREATE_AGENT,
		};
	},

	updateAgent: () => {
		return {
			type: actions.UPDATE_AGENT,
		};
	},

	changePassword: () => {
		return {
			type: actions.CHANGE_PASSWORD,
		};
	},

	archiveAgent: (agentID: number) => {
		return {
			type: actions.ARCHIVE_AGENT,
			data: agentID,
		};
	},

	autocompleteDataSourceReload: (data: IAgentAutoCompletePayload) => {
		return {
			type: actions.AUTOCOMPLETE_DATA_SOURCE_RELOAD,
			data: data,
		};
	},

	agentsTransactionsReload: () => {
		return {
			type: actions.AGENTS_TRANSACTIONS_RELOAD,
		};
	},

	agentBalanceAdjustment: () => {
		return {
			type: actions.AGENT_BALANCE_ADJUSTMENT,
		};
	},
};

export { actions as agentsActions };