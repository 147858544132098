import { apiRequest } from './index';

const getAgentPlayerList = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/agentPlayers',
		params,
	};

	return apiRequest(req);
};
 
const createAgentPlayer = ({ data,partner_id }, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/registerPlayersByAgent?partner_id=${partner_id}`,
		data,
		params,
	};

	return apiRequest(req);
};

const getAgentPlayerByID = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/agentPlayers',
		params,
	};

	return apiRequest(req);
};

const getAgentPlayersAutocomplete = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/getAgentsDataAutocomplete', 
		params,
	};

	return apiRequest(req);
};
 
const changePlayerPassword = (PlayerID, data, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/changePlayerPassword/${PlayerID}`,
		data  : data.data,
		params,
	};

	return apiRequest(req);
};
 
const updateAgentPlayer = (PlayerID, data, params = {}) => {
	const req = {
		method: 'POST',
		url   : `/agentPlayers/${PlayerID}`,
		data,
		params,
	};

	return apiRequest(req);
};
 
const getCountriesAgentPlayer = () => {
	const req = {
		method       : 'GET',
		url          : '/website/agent_countries',
		withoutLangID: true,
	};

	return apiRequest(req);
};
 
const getCurrencyAgentPlayer =  () => {
	const req = {
		method       : 'GET',
		url          : '/websites/agent_currency',
		withoutLangID: true,
	};

	return apiRequest(req);
};
const getLanguagesAgentPlayer =  () => {
	const req = {
		method       : 'GET',
		url          : '/website/agent_languages',
		withoutLangID: true,
	};

	return apiRequest(req);
};

const getAgentPlayerSettings =  (websiteID) => {
	const req = {
		method          : 'GET',
		url             : `/settings?website_id=${websiteID}`,
		withoutWebsiteID: true,
	};

	return apiRequest(req);
};
 
const setAgentPlayerAdjustment = (url, data, params = {}) => {

	const req = {
		method: 'POST',
		url   : `/${url}`,
		data,
		params,
	};

	return apiRequest(req);
};
 
const getAgentData = (agentID,  params = {}) => {

	const req = {
		method: 'GET',
		url   : `/agentInfo/${agentID}`,
		params,
	};

	return apiRequest(req);
};
 

export const agentPlayersAPI = {
	getAgentPlayerList,
	createAgentPlayer,
	getAgentPlayerByID,
	changePlayerPassword,
	updateAgentPlayer,
	getCountriesAgentPlayer,
	getCurrencyAgentPlayer,
	getLanguagesAgentPlayer,
	getAgentPlayersAutocomplete,
	getAgentPlayerSettings,
	setAgentPlayerAdjustment,
	getAgentData,
};