import { restoreLanguage } from '../../helpers/utility';

import Enlang from '../../languageProvider/locales/en_US.json';
import Rulang from '../../languageProvider/locales/ru_RU.json';
import Ualang from '../../languageProvider/locales/ua_UA.json';
import Trlang from '../../languageProvider/locales/it_IT.json';
import Delang from '../../languageProvider/locales/de_DE.json';

//import Zhlang from './entries/zh-Hans-CN';
//import Salang from './entries/ar_SA';
//import Itlang from './entries/it_IT';
//import Eslang from './entries/es_ES';
//import Frlang from './entries/fr_FR';


const AppLocale = {
	en: Enlang,
	ru: Rulang,
	ua: Ualang,
	tr: Trlang,
	de: Delang,

	//zh: Zhlang,
	//sa: Salang,
	//it: Itlang,
	//es: Eslang,
	//fr: Frlang,
};

const langData = restoreLanguage();
const lang = langData ? langData.locale : 'en';

const dataWords = AppLocale[lang];


export function returnObject(data = []) {
	return data.map((item) => {
		const obj = {};
		obj.id = item.key;
		obj.componentName = item.component;
		obj.componentProps = {};
		obj.location = `${String(item.url) === 'dashboard' ? '/' : '/dashboard/'}${String(item.url).replace(/^\/{1}/, '')}`;
		obj.titleID = `sidebar.${item.title}`;
		if (String(item.url) === 'dashboard') {
			obj.isSearch = true;
		}
		if (item && !item.menuItems?.length) {
			return obj;
		}
		return {
			subObj   : returnObject(item.menuItems),
			parentObj: obj,
		};
	});
}


const pathNameTree = (item, path) => {
	if (path || item.subObj) {
		let pathName = path;
		pathName = !path ? item.parentObj.titleID : `${path} / ${item.parentObj.titleID}`;
		return item.subObj.map(item1 => {
			if (item1.subObj) {
				return pathNameTree(item1, pathName);
			}
			const namePath = pathName.split(' / ').map(it => dataWords[it]).join(' / ');

			item1.name = `${namePath} / ${dataWords[item1.titleID]}`;
			return item1;
		});
	}
	item.name = dataWords[item.titleID];
	item.location = item.name === 'dashboard' ? `/${String(item.id).replace(/^\/{1}/, '')}` : item.location;
	return item;
};


export function checkPathName(data = []) {
	return data.map(item => pathNameTree(item));
}
