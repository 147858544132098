import React, { Component } from 'react';
import { connect } from 'react-redux';

import IntlMessages from '../../../../../../../components/utility/intlMessages';
import { AUTOCOMPLETE_SOURCES } from '../../../../../../../helpers/commonConstants';
import { genderList } from '../../../../../../../helpers/commonEnums';
import { countryList } from '../../../../../../../helpers/countries';

import filterActions from '../../../../../../../redux/loyalty/bonuses/userMassBonus/filter/actions';
import bonusActions from '../../../../../../../redux/loyalty/bonuses/userBonus/actions';


import { deriveGroupList } from '../../../../../../../selectors/common';
import { deriveCurrencyList } from '../../../../../../../selectors/currency';

import FilterBlock, { FilterBuilder } from '../../../../../../FilterBlock';

import { 
	userStatusList,
	gamingStatusList,
	verificationStatusList,
	validationWayList,
	profitabilityGradeList,
} from '../../../../../../CustomerManagement/enums';
import { autocompleteDebounce } from '../../../../../../../helpers/utils';
import CountriesAllowReject from '../../../../../../../components/CountriesAllowReject';
import { cloneDeep, isArray } from 'lodash';
import { formItemLabel } from '../../../../../../Partner/Websites/modals/Websites/TabIntegration/utils/utils';

const lang = {
	// primary
	userID             : <IntlMessages id = "usersList.filter.ID" />,
	userIP             : <IntlMessages id = "usersList.filter.userIP" />,
	userName           : <IntlMessages id = "usersList.filter.userName" />,
	userEmail          : <IntlMessages id = "usersList.filter.userEmail" />,
	registrationIP     : <IntlMessages id = "usersList.filter.registrationIP" />,
	status             : <IntlMessages id = "usersList.filter.status" />,
	gamingStatus       : <IntlMessages id = "usersList.filter.gamingStatus" />,
	verificationStatus : <IntlMessages id = "usersList.filter.verificationStatus" />,
	regDate            : <IntlMessages id = "usersList.filter.regDate" />,
	// advanced
	userFirstName      : <IntlMessages id = "usersList.filter.userFirstName" />,
	userLastName       : <IntlMessages id = "usersList.filter.userLastName" />,
	domain             : <IntlMessages id = "usersList.filter.domain" />,
	country            : <IntlMessages id = "usersList.filter.country" />,
	registrationCountry: <IntlMessages id = "usersList.filter.registrationCountry" />,
	currency           : <IntlMessages id = "usersList.filter.currency" />,
	verificationMethods: <IntlMessages id=  "usersList.columns.verificationMethods" />,
	gender             : <IntlMessages id = "usersList.filter.gender" />,
	profitabilityGrade : <IntlMessages id = "usersList.filter.profitabilityGrade" />,
	affiliateReference : <IntlMessages id = "usersList.filter.affiliateReference" />,
	birthday           : <IntlMessages id = "usersList.filter.birthday" />,
	firstDeposit       : <IntlMessages id = "usersList.filter.firstDeposit" />,
	firstWithdrawal    : <IntlMessages id = "usersList.filter.firstWithdrawal" />,
	riskGroup          : <IntlMessages id = "usersList.filter.riskGroup" />,
	age                : <IntlMessages id = "usersList.filter.age" />,
	depositsRange      : <IntlMessages id = "usersList.filter.depositsRangeCount" />,
	withdrawalsRange   : <IntlMessages id = "usersList.filter.withdrawalsRangeCount" />,
	withdrawalsAmount  : <IntlMessages id = "usersList.filter.withdrawalsAmount" />,
	depositsAmount     : <IntlMessages id = "usersList.filter.depositsAmount" />,
	betsCount          : <IntlMessages id = "usersList.filter.betsCount" />,
	lastBetAmount      : <IntlMessages id = "usersList.filter.lastBetAmount" />,
	betsAmount         : <IntlMessages id = "usersList.filter.betsAmount" />,
	minStake           : <IntlMessages id = "usersList.filter.minStake" />,
	maxStake           : <IntlMessages id = "usersList.filter.maxStake" />,
	lastBetDate        : <IntlMessages id = "usersList.filter.lastBetDate" />,
	lastLoginDate      : <IntlMessages id = "usersList.filter.lastLoginDate" />,
	documentNumber     : <IntlMessages id = "usersList.filter.documentNumber" />,
};

class UserFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			inputAutocompleteValue: '',
		};
		this.reloadComponentData       = this.reloadComponentData.bind(this);
		this.initUsersFilter           = this.initUsersFilter.bind(this);
		this.onApplyFilter             = this.onApplyFilter.bind(this);
		this.onChangeFilterValue       = this.onChangeFilterValue.bind(this);
		this.onChangeAutocompleteField = this.onChangeAutocompleteField.bind(this);
		this.onClickSearch             = this.onClickSearch.bind(this);
		this.onClickReset              = this.onClickReset.bind(this);
		this.onChangeMultiField			= this.onChangeMultiField.bind(this);
		this.debouncedDataSourceReload = autocompleteDebounce(this.dataSourceReload, 300);
	}

	// Service ------------------------------------------------------------------------------------
	dataSourceReload = (value, source) => {
		const { dataSourceReload } = this.props;
		dataSourceReload(value, source);
	};

	reloadComponentData(clearFilter = false) {
		const { filter, filterRefresh, riskGoupsReload } = this.props;

		const realEventsFilter = clearFilter ? this.initUsersFilter() : filter;

		if (clearFilter) {
			filterRefresh(realEventsFilter);
			riskGoupsReload();
		}

		this.onApplyFilter(realEventsFilter);
	}

	initUsersFilter() {
		const filter = {
			userID              : '',
			customerIP          : '',
			userName            : '',
			userEmail           : '',
			documentNumber      : '',
			registrationIP      : '',
			statusID            : null,
			gamingStatusID      : null,
			verificationStatusID: null,
			regDateRange        : [],
			
			userFirstName       : '',
			userLastName        : '',
			domain              : '',
			country             : '',
			registrationCountry : '',
			currency            : [],
			validationStatus    : [],
			genderID            : null,
			profitabilityGradeID: null,
			countriesAllow      : true,

			affiliateReference: '',
			birthday          : [],
			firstDeposit      : [],
			firstWithdrawal   : [],
			riskGroup         : [],
			age               : [],
			depositsRange     : [],
			withdrawalsRange  : [],

			withdrawalsAmount: [],
			depositsAmount   : [],
			betsCount        : [],
			lastBetAmount    : [],
			betsAmount       : [],
			minStake         : [],
			maxStake         : [],
			lastBetDate      : [],
			lastLoginDate    : [],
		};

		return filter;
	}

	prepareFilterOptions() {
		const {
			filter,
			riskGroupList,
			currencyList,
			dataSourceUserName,
			dataSourceUserEmail,
			dataSourceFirstName,
			dataSourceLastName,
			massBonusViewMode,
		} = this.props;
		
		const disabledFields = massBonusViewMode && Object.keys(this.initUsersFilter());
		const filterProps = {
			filter,
			disabledFields,
			onClickSearch: this.onClickSearch,
			onClickReset : this.onClickReset,
			onChangeValue: this.onChangeFilterValue,
		};

		const Builder = new FilterBuilder(filterProps);
		if (massBonusViewMode) {
			Builder.init({ separateDisabledButtons: ['reset'] });
		}
		const elements = {
			userID: Builder.InputNumber('userID', lang.userID, null, 0, 1, true),

			customerIP        : Builder.Input('customerIP', lang.userIP),
			registrationIP    : Builder.Input('registrationIP', lang.registrationIP),
			domain            : Builder.Input('domain', lang.domain),
			affiliateReference: Builder.Input('affiliateReference', lang.affiliateReference),
			documentNumber    : Builder.Input('documentNumber', lang.documentNumber),

			userName     : Builder.InputAutoComplete('userName', lang.userName, dataSourceUserName, this.onChangeAutocompleteField),
			userEmail    : Builder.InputAutoComplete('userEmail', lang.userEmail, dataSourceUserEmail, this.onChangeAutocompleteField),
			userFirstName: Builder.InputAutoComplete('userFirstName', lang.userFirstName, dataSourceFirstName, this.onChangeAutocompleteField),
			userLastName : Builder.InputAutoComplete('userLastName', lang.userLastName, dataSourceLastName, this.onChangeAutocompleteField),

			statusID            : Builder.Select('statusID', lang.status, userStatusList),
			gamingStatusID      : Builder.Select('gamingStatusID', lang.gamingStatus, gamingStatusList),
			verificationStatusID: Builder.Select('verificationStatusID', lang.verificationStatus, verificationStatusList),
			genderID            : Builder.Select('genderID', lang.gender, genderList),
			profitabilityGradeID: Builder.Select('profitabilityGradeID', lang.profitabilityGrade, profitabilityGradeList),

			// country            : Builder.Multiselect('country', lang.country, countryList),

			registrationCountry: Builder.Multiselect('registrationCountry', lang.registrationCountry, countryList),
			currency           : Builder.Multiselect('currency', formItemLabel(lang.currency, true), currencyList),
			validationStatus   : Builder.Multiselect('validationStatus', lang.verificationMethods, validationWayList),
			riskGroup          : Builder.Multiselect('riskGroup', lang.riskGroup, riskGroupList),

			regDateRange   : Builder.DateRange('regDateRange', lang.regDate),
			birthday       : Builder.DateRange('birthday', lang.birthday),
			firstDeposit   : Builder.DateRange('firstDeposit', lang.firstDeposit),
			firstWithdrawal: Builder.DateRange('firstWithdrawal', lang.firstWithdrawal),
			lastBetDate    : Builder.DateRange('lastBetDate', lang.lastBetDate),
			lastLoginDate  : Builder.DateRange('lastLoginDate', lang.lastLoginDate),

			age              : Builder.NumberRange('age', lang.age, null, 18, 1),
			depositsRange    : Builder.NumberRange('depositsRange', lang.depositsRange, null, 0, 0.01),
			withdrawalsRange : Builder.NumberRange('withdrawalsRange', lang.withdrawalsRange, null, 0, 1),
			withdrawalsAmount: Builder.NumberRange('withdrawalsAmount', lang.withdrawalsAmount),
			depositsAmount   : Builder.NumberRange('depositsAmount', lang.depositsAmount),
			betsCount        : Builder.NumberRange('betsCount', lang.betsCount),
			lastBetAmount    : Builder.NumberRange('lastBetAmount', lang.lastBetAmount),
			betsAmount       : Builder.NumberRange('betsAmount', lang.betsAmount),
			minStake         : Builder.NumberRange('minStake', lang.minStake),
			maxStake         : Builder.NumberRange('maxStake', lang.maxStake),

			country: Builder.Component('country', lang.country,
				<CountriesAllowReject
					data={{
						countriesIDs  : filter.country,
						countriesAllow: filter.countriesAllow,
					}}
					isFilter
					span={18}
					disabled={massBonusViewMode}
					onSelect={value => this.onChangeMultiField(value, 'country', true)}
					onDeselect={value => this.onChangeMultiField(value, 'country', false)}
					onChange={value => this.onChangeFilterValue('countriesAllow', value)}
				/>, filter.country
			),
		};

		const primary = [
			elements.userID,
			elements.customerIP,
			elements.userName,
			elements.country,
			elements.domain,

			elements.currency,
			elements.statusID,
			elements.validationStatus,
			elements.verificationStatusID,
			elements.documentNumber,

			elements.gamingStatusID,
			elements.riskGroup,
			elements.registrationIP,
			elements.regDateRange,
		];

		const advanced = [
			elements.userFirstName,
			elements.userLastName,
			elements.age,
			elements.birthday,

			elements.genderID,
			elements.affiliateReference,
			elements.firstDeposit,
			elements.firstWithdrawal,

			elements.depositsRange,
			elements.withdrawalsRange,
			elements.profitabilityGradeID,
			elements.userEmail,

			elements.registrationCountry,

			elements.withdrawalsAmount,
			elements.depositsAmount,
			elements.betsCount,
			elements.lastBetAmount,
			elements.betsAmount,
			elements.minStake,
			elements.maxStake,
			elements.lastBetDate,
			elements.lastLoginDate,
		];

		const options = Builder.build(primary, advanced);

		return options;
	}

	// Events -------------------------------------------------------------------------------------
	onApplyFilter(filter) {
		const { onApplyFilter } = this.props;
		if (onApplyFilter) {
			onApplyFilter(filter);
		}
	}
	onChangeMultiField(value, dataName, selected) {
		const { filter, filterRefresh } = this.props;
		if (!dataName) {
			return;
		}
		const newFilter = cloneDeep(filter);
		let valuesList = newFilter[dataName];
		if (!isArray(valuesList)) {
			valuesList = [];
		}
		const intValue = value;
		if (selected) {
			valuesList.push(intValue);
		} else {
			valuesList = valuesList.filter(item => item !== intValue);
		}
		newFilter[dataName] = valuesList;
		filterRefresh(newFilter);

	}

	onChangeFilterValue(filterName, value) {
		const { filterSetValue, filter, bonusDataReset } = this.props;	
		if (filterName === 'currency' && Object.keys(filter).includes('currency')) {
			bonusDataReset();
		}
		filterSetValue(filterName, value);
	}

	onChangeAutocompleteField(filterName, value) {
		const { filterSetValue } = this.props;

		filterSetValue(filterName, value);

		if (value.length < 3) {
			return;
		}
		this.debouncedDataSourceReload(value, AUTOCOMPLETE_SOURCES[filterName]);
	}

	onClickSearch() {
		const { filter } = this.props;
		this.onApplyFilter(filter);
	}

	onClickReset() {
		this.reloadComponentData(true);
	}

	// Renders ------------------------------------------------------------------------------------
	render() {
		const filterOptions = this.prepareFilterOptions();
		return (
			<div>
				<FilterBlock settings={filterOptions} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	return {
		filter             : state.Loyalty.Bonuses.UserMassBonus.UserMassBonusFilter.get('filter'),
		massBonusViewMode  : state.Loyalty.Bonuses.MassBonus.MassBonusList.get('UI').editMode,
		dataSourceUserName : state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('dataSourceUserName'),
		dataSourceUserEmail: state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('dataSourceUserEmail'),
		dataSourceFirstName: state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('dataSourceFirstName'),
		dataSourceLastName : state.Loyalty.Bonuses.UserMassBonus.UserMassBonusList.get('dataSourceLastName'),
		riskGroupList      : deriveGroupList(state),
		currencyList       : deriveCurrencyList(state),
	};
};

const mapDispatchToProps = {
	filterSetValue  : filterActions.filterSetValue,
	filterRefresh   : filterActions.filterRefresh,
	riskGoupsReload : filterActions.riskGoupsReload,
	dataSourceReload: filterActions.dataSourceReload,
	bonusDataReset  : bonusActions.bonusDataReset,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserFilter);
