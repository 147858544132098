import QueryToFilterValidaion from '../../../helpers/queryToFilterValidaion';


const { isValidID, isValidString, isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	userID  : isValidID,
	statusID: isValidID,

	userName : isValidString,
	userEmail: isValidString,
	firstName: isValidString,
	lastName : isValidString,

	role      : isValidArray,
	partnerIDs: isValidArray,
	websiteIDs: isValidArray,
    
	birthday: isValidDateRange,
};
