import { AxiosError, AxiosResponse } from 'axios';
import { ErrorCodes } from '@ceo-betmakers/common-globals';
import { showError } from '../helpers/notifications';

interface IAppError extends AxiosError {
	response: AxiosResponse<{ text_code: ErrorCodes }>;
}

export const handleAppError = (error: IAppError, defaultError?: string) => {
	const textCode = error.response.data.text_code;
	const errorMessage = error.message;

	const knownError = ErrorCodes[textCode];
	if (knownError) {
		showError(knownError);
		return;
	}

	if (defaultError) {
		return showError(defaultError);
	}

	if (errorMessage) {
		return showError(errorMessage);
	}

	showError('Unknown error');
};