import { isArray } from 'lodash';
import EntityAdapter from '../../helpers/entityAdapter';
import ParamsBuilder from '../../helpers/paramsBuilder';
import QueryToFilterValidation from '../../helpers/queryToFilterValidaion';
import {
	IAgentPlayerAdjustmentData,
	IAgentPlayerData, IAgentPlayerFields, IAgentPlayerFilter, IAgentPlayerFilterValidation,
	IAgentPlayersBaseData, IAgentPlayersDataResponse, IAgentPlayersDepositAndWithdrawData, IUpdatedData,
} from './types';

const fields: IAgentPlayerFields = {
	playerID               : 'id',
	email                  : 'email',
	username               : 'username',
	firstName              : 'first_name',
	lastName               : 'last_name',
	status                 : 'status_id',
	country                : 'country_id',
	nationalID             : 'national_id',
	currency               : 'currency_id',
	currencyID             : 'currency_ids',
	language               : 'language_id',
	address1               : 'address_1',
	address2               : 'address_2',
	city                   : 'city',
	phone                  : 'phone',
	balance                : 'balance',
	domain                 : 'domain',
	gender                 : 'gender',
	birthday               : 'birth',
	age                    : 'age',
	password               : 'password',
	countryID              : 'country_id',
	registrationDate       : 'created',
	gamingStatus           : 'gaming_status',
	betsAmount             : 'bets_amount',
	betsCount              : 'bets_count',
	lastLogin              : 'last_login_date',
	lastBetDate            : 'last_bet_date',
	profitability          : 'profitability',
	isOnline               : 'is_online',
	commentForAdmin        : 'comment',
	id                     : 'id',
	confirmPassword        : 'confirm_password',
	lastLoginCountry       : 'last_login_country',
	registrationIP         : 'registration_ip',
	registrationCountryCode: 'registration_country_code',
	countryCode            : 'country',
	amount                 : 'amount',
	userComment            : 'user_comment',
	adminComment           : 'admin_comment',
	dateFrom               : 'date_from',
	dateTo                 : 'date_to',
};

const listAdapter = createAgentPlayerListAdapter();
const baseDataAdapter = createAgentPlayersBaseDataAdapter();
const agentPlayerDataAdapter = createAgentPlayerDataAdapter();
const agentPlayerAdjustmentDataAdapter = createAgentPlayerAdjustmentDataAdapter();

export const adaptAgentData = (data: IAgentPlayersDataResponse) => baseDataAdapter.adapt(data) as IAgentPlayerData;

export const prepareAgentPlayerData = (data: IAgentPlayerData) => {
	const prepared =  baseDataAdapter.prepare(data) as IAgentPlayerData;
	return clearObject(prepared);
};
export const prepareAgentPlayerAdjustmentData = (data: IAgentPlayersDepositAndWithdrawData) => {
	const prepared =  agentPlayerAdjustmentDataAdapter.prepare(data) as IAgentPlayerAdjustmentData;
	return clearObject(prepared);
};

export const prepareAgentPlayerInfo = (data: IUpdatedData) => {
	const prepared = agentPlayerDataAdapter.prepare(data) as IAgentPlayersDataResponse;
  
	return clearObject(prepared);
};


const { isValidID, isValidString } = QueryToFilterValidation;
export const initFilterTypes: IAgentPlayerFilterValidation = {
	email      	: isValidString,
	firstName   : isValidString,
	status      : isValidID,
	username    : isValidString,
	currencyID  : isArray,
	countryID   : isArray,
	playerID    : isValidID,
	dateFrom    : isValidString,
	dateTo      : isValidString,
	registration: isValidString,
};


export function getListParams (filter: IAgentPlayerFilter, pagination, sorting = null) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'playerID', fields.id);
	builder.addField(rules.isID, 'status', fields.status);
	builder.addField(rules.isArrayID, 'currencyID', fields.currencyID);
	builder.addField(rules.isArrayString, 'countryID', 'country');
	builder.addField(rules.isString, 'email', fields.email);
	builder.addField(rules.isString, 'username', fields.username);
	builder.addField(rules.string, 'dateFrom', fields.dateFrom);
	builder.addField(rules.string, 'dateTo', fields.dateTo);


	const params = builder.biuldParams(filter);
	return params;
}

export const adaptAgentPlayersList = (data: IAgentPlayersBaseData[]) => listAdapter.adaptList(data) as IAgentPlayersBaseData[];
export const adaptAgentPlayerData = (data: IAgentPlayersBaseData) => agentPlayerDataAdapter.adapt(data) as IAgentPlayersBaseData;

export function createAgentPlayerAdjustmentDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.string, 'userComment', fields.userComment);
	adapter.addField(rules.stringOrNull, 'adminComment', fields.adminComment);
	return adapter;
}
export function createAgentPlayersBaseDataAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.stringOrNull, 'email', fields.email);
	adapter.addField(rules.stringOrNull, 'firstName', fields.firstName);
	adapter.addField(rules.stringOrNull, 'lastName', fields.lastName);
	adapter.addField(rules.stringOrNull, 'username', fields.username);
	adapter.addField(rules.number, 'countryID', fields.countryID);
	adapter.addField(rules.numberOrNull, 'country', fields.country);
	adapter.addField(rules.stringOrNull, 'city', fields.city);
	adapter.addField(rules.stringOrNull, 'phone', fields.phone);
	adapter.addField(rules.date, 'birthday', fields.birthday);
	adapter.addField(rules.numberOrNull, 'gender', fields.gender);
	adapter.addField(rules.string, 'password', fields.password);
	adapter.addField(rules.number, 'currency', fields.currency);
	adapter.addField(rules.numberOrNull, 'language', fields.language);
	adapter.addField(rules.numberOrNull, 'status', fields.status);
	adapter.addField(rules.stringOrNull, 'address1', fields.address1);
	adapter.addField(rules.stringOrNull, 'address2', fields.address2);
	adapter.addField(rules.stringOrNull, 'nationalID', fields.nationalID);
	adapter.addField(rules.string, 'domain', fields.domain);

	return adapter;
}

export function createAgentPlayerListAdapter  () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'username', fields.username);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.number, 'status', fields.status);
	adapter.addField(rules.string, 'nationalID', fields.nationalID);
	adapter.addField(rules.number, 'country', fields.country);
	adapter.addField(rules.number, 'currency', fields.currency);
	adapter.addField(rules.id, 'id', fields.playerID);
	adapter.addField(rules.id, 'playerID', fields.id);
	adapter.addField(rules.number, 'language', fields.language);
	adapter.addField(rules.stringOrNull, 'address1', fields.address1);
	adapter.addField(rules.stringOrNull, 'address2', fields.address2);
	adapter.addField(rules.stringOrNull, 'city', fields.city);
	adapter.addField(rules.stringOrNull, 'age', fields.age);
	adapter.addField(rules.stringOrNull, 'phone', fields.phone);
	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.stringOrNull, 'domain', fields.domain);
	adapter.addField(rules.stringOrNull, 'gender', fields.gender);
	adapter.addField(rules.date, 'birthday', fields.birthday);
	adapter.addField(rules.stringOrNull, 'registrationDate', fields.registrationDate);
	adapter.addField(rules.stringOrNull, 'gamingStatus', fields.gamingStatus);
	adapter.addField(rules.number, 'betsAmount', fields.betsAmount);
	adapter.addField(rules.number, 'betsCount', fields.betsCount);
	adapter.addField(rules.stringOrNull, 'lastLogin', fields.lastLogin);
	adapter.addField(rules.stringOrNull, 'lastBetDate', fields.lastBetDate);
	adapter.addField(rules.stringOrNull, 'profitability', fields.profitability);
	adapter.addField(rules.bool, 'isOnline', fields.isOnline);
	adapter.addField(rules.stringOrNull, 'commentForUser', fields.userComment);

	return adapter;

}


export function createAgentPlayerDataAdapter () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.stringOrNull, 'email', fields.email);
	adapter.addField(rules.stringOrNull, 'username', fields.username);
	adapter.addField(rules.stringOrNull, 'firstName', fields.firstName);
	adapter.addField(rules.stringOrNull, 'lastName', fields.lastName);
	adapter.addField(rules.numberOrNull, 'status', fields.status);
	adapter.addField(rules.stringOrNull, 'nationalID', fields.nationalID);
	adapter.addField(rules.numberOrNull, 'country', fields.country);
	adapter.addField(rules.number, 'currency', fields.currency);
	adapter.addField(rules.id, 'id', fields.playerID);
	adapter.addField(rules.id, 'playerID', fields.id);
	adapter.addField(rules.numberOrNull, 'language', fields.language);
	adapter.addField(rules.stringOrNull, 'address1', fields.address1);
	adapter.addField(rules.stringOrNull, 'address2', fields.address2);
	adapter.addField(rules.stringOrNull, 'city', fields.city);
	adapter.addField(rules.stringOrNull, 'age', fields.age);
	adapter.addField(rules.stringOrNull, 'phone', fields.phone);
	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.string, 'domain', fields.domain);
	adapter.addField(rules.numberOrNull, 'gender', fields.gender);
	adapter.addField(rules.date, 'birthday', fields.birthday);
	adapter.addField(rules.stringOrNull, 'registrationDate', fields.registrationDate);
	adapter.addField(rules.stringOrNull, 'gamingStatus', fields.gamingStatus);
	adapter.addField(rules.number, 'betsAmount', fields.betsAmount);
	adapter.addField(rules.number, 'betsCount', fields.betsCount);
	adapter.addField(rules.string, 'lastLogin', fields.lastLogin);
	adapter.addField(rules.stringOrNull, 'lastBetDate', fields.lastBetDate);
	adapter.addField(rules.stringOrNull, 'profitability', fields.profitability);
	adapter.addField(rules.bool, 'isOnline', fields.isOnline);
	adapter.addField(rules.string, 'commentForAdmin', fields.commentForAdmin);
	adapter.addField(rules.string, 'commentForAdmin', fields.commentForAdmin);
	adapter.addField(rules.string, 'lastLoginCountry', fields.lastLoginCountry);
	adapter.addField(rules.string, 'registrationIP', fields.registrationIP);
	adapter.addField(rules.string, 'registrationCountryCode', fields.registrationCountryCode);
	adapter.addField(rules.string, 'countryCode', fields.countryCode);

	return adapter;
}
export const clearObject = <T>(obj: T): Partial<T> => {
	return Object.fromEntries(Object.entries(obj).filter(([, value]) => (!!value || value === 0 || typeof value === 'boolean'))) as Partial<T>;
};


export const adaptSettingsData=(keys: string[]): Record<string, boolean> =>{
	return keys.reduce((acc, key) => {
		acc[key] = true;
		return acc;
	}, {} as Record<string, boolean>);
};