import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import find from 'lodash/find';

import langConfig from '../../containers/Topbar/LanguageSwitcher/config';

import actions from './actions';
import appActions from '../app/actions';
import socketActions from '../socket/actions';
import { authApi } from '../../helpers/api/auth';
import { restoreLanguage } from '../../helpers/utility';

import { adaptUser } from './utils';
import languageActions from '../languageSwitcher/actions';
import { routes } from '../../routes/routesData';
import { makeResetAllOpenTabsFilter } from '../appTabs/saga';
import { logger } from '../../helpers/logger';
import { handleAppError } from '../ErrorHandler';

const getStoreData = ({ Auth }) => ({
	rememberMe: Auth.get('UI').rememberMe,
});

export function clearLocalStorage() {
	localStorage.removeItem('id_token');
	localStorage.removeItem('token');
	localStorage.removeItem('permissions');
	localStorage.removeItem('user');
	localStorage.removeItem('sidebar');
	localStorage.removeItem('language');
	localStorage.removeItem('appTabs');
	localStorage.removeItem('usersFilter');
	localStorage.removeItem('transactions');
	localStorage.removeItem('websiteID');
	localStorage.removeItem('tabIDS');
	localStorage.removeItem('adminID');
	localStorage.removeItem('partnerID');
}

export function clearSessionStorage() {
	sessionStorage.clear();
}

export const setSessionCommon = (token, remember) => {
	sessionStorage.setItem('id_token', token);
	localStorage.setItem('remember', JSON.stringify(remember));
};

export function* loginRequest() {

	yield takeEvery(actions.LOGIN_REQUEST, function* (authData) {
		yield put(actions.uiRefresh({ loading: true }));
		const reqData = authData.data;
		try {
			const res = yield call(authApi.apiLogin, reqData);

			if (res && res.status === 200) {
				const token = res.data.data.access_token;
				const { adaptedData : user, permissions } = adaptUser(res.data.data.user);
				yield put(actions.loginSucces(token, user, permissions));
				yield put(actions.permissionsRefresh(permissions));
			} else {
				yield put(actions.loginError());
			}

		} catch (error) {
			handleAppError(error, 'Login failed');
			yield put(actions.loginError());
		}

		yield put(actions.uiRefresh({ loading: false }));

	});
}

export const setStorageCommon = (token, remember) => {
	localStorage.setItem('id_token', token);
	localStorage.setItem('remember', JSON.stringify(remember));
};


export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function* (action) {
		const { rememberMe } = yield select(getStoreData);
		const { token, user, permissions } = action.data;

		yield localStorage.setItem('user', JSON.stringify(user));
		yield localStorage.setItem('permissions', JSON.stringify(permissions));
		yield localStorage.setItem('remember', JSON.stringify(rememberMe));

		if (rememberMe) {
			setStorageCommon(token, rememberMe);
		} else {
			setSessionCommon(token, rememberMe);
		}

		yield applyUserLanguage(user.langID);
		yield put(socketActions.reconnect());
	});
}

export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function* () {
		yield call(clearLocalStorage);
		yield call(clearSessionStorage);
		yield put(socketActions.reconnect());
	});
}

export function* logOut() {
	yield takeEvery(actions.LOGOUT, function* () {
		const { rememberMe } = yield select(getStoreData);
		let tabs = [];
		try {
			tabs = JSON.parse(localStorage.getItem('appTabs'));
		} catch (e) {
			return logger.log(e);
		}
		const localToken = localStorage.getItem('id_token');
		const sessionToken = sessionStorage.getItem('id_token');
		const oldToken = rememberMe ? localToken : sessionToken;
		
		try {
			yield call(makeResetAllOpenTabsFilter, tabs);
			yield put(appActions.appDataReset());
			yield put(languageActions.resetLanguage());
			yield put(actions.logoutCase());
			yield put(appActions.appModalReset());
			yield put(socketActions.reconnect());
			yield call(clearLocalStorage);
			yield call(clearSessionStorage);
			
			yield call(authApi.logout, oldToken);
			routes.navigate('/signin');
		} catch (e) {
			// showError('Logout failed', e);
		}

	});
}

function* applyUserLanguage(langID) {
	const storedLanguage = yield call(restoreLanguage);
	if (storedLanguage) {
		return;
	}

	let language = find(langConfig.options, { dataBaseLangID: langID });
	if (!language) {
		language = yield select( ({ LanguageSwitcher }) => LanguageSwitcher.get('language') );
	}
}

export default function* authSaga() {
	yield all([
		fork(loginRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(logOut),
	]);
}
