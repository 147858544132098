import { useLayoutEffect, useRef, useState } from 'react';
import { TextAreaComponentWrapper } from './TextAreaComponent.style';

export const TextAreaComponent = (props) => {
	const { value, onChange, ...rest } = props;
	const [cursor, setCursor] = useState<number | null>(null);
	const ref = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		ref.current?.setSelectionRange(cursor, cursor);
	}, [ref, cursor, value]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCursor(e.target.selectionStart);
		onChange?.(e);
	};

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	return <TextAreaComponentWrapper ref={ref} value={value} onChange={handleChange} {...rest} />;
};

