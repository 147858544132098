import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius } from '../../config/style-util';

export const SidebarWrapper = styled.div`
  .ant-empty-description {
    color: #fff;
  }
  .isomorphicSidebar {

    z-index: 1000;
    background: ${palette('secondary', 0)};
    width: 280px;
    flex: 0 0 280px;

    @media only screen and (max-width: 767px) {
      width: 240px !important;
      flex: 0 0 240px !important;
    }

    &.ant-layout-sider-collapsed {
      @media only screen and (max-width: 767px) {
        width: 0;
        min-width: 0 !important;
        max-width: 0 !important;
        flex: 0 0 0 !important;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: rgb(31 36 72);
    }
    &::-webkit-scrollbar-track {
        border-radius: 3px;
        background: rgb(78 79 86);
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
        margin: 4px 0 ;
    } 
    
    .isoLogoWrapper {
      height: 70px;
      background: rgba(0, 0, 0, 0.3);
      margin: 0;
      padding: 0 10px;
      text-align: center;
      overflow: hidden;
      ${borderRadius()};

      h3 {
        a, .link {
          font-size: 21px;
          font-weight: 300;
          line-height: 70px;
          letter-spacing: 3px;
          text-transform: uppercase;
          color: ${palette('grayscale', 6)};
          display: block;
          text-decoration: none;
        }
      }
    }

    &.ant-layout-sider-collapsed {
      .isoLogoWrapper {
        padding: 0;

        h3 {
          a {
            font-size: 27px;
            font-weight: 500;
            letter-spacing: 0;
          }
        }
      }
    }

    .isoDashboardMenu {
      padding-top: 35px;
      padding-bottom: 35px;
      background: transparent;
      
      .link {
        text-decoration: none;
        font-weight: 400;
        width:180px;
      }

      .ant-menu-item {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin:0;
      }
      
      .flex_item { 
        display: flex !important;
      }
      
      .isoMenuHolder {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;

      }

      .anticon {
        font-size: 18px;
        margin-right: 30px;
        color: inherit;
        ${transition()};
      }

      .sidebar-icon {
        font-size: 19px;
        color: inherit;
        margin: 0 20px 0 0;
        width: 18px;
        ${transition()};
      }

      .nav-text {
        font-size: 14px;
        color: inherit;
        font-weight: 400;
        ${transition()};
      }
      .nav-text span {
        font-size:14px;
      }
      .child-nav-text {
        font-size: 12px;
        color: inherit;
        font-weight: 400;
        ${transition()};
      }
      .child-nav-text span {
        font-size:12px;
      }

      .ant-menu-item-selected {
        background-color: rgba(0, 0, 0, 0.4) !important;
        .anticon {
          color: #fff;
        }
        > li .isoMenuHolder svg path {
          fill: #fff;
          transition: all 0.6s;
        }

        i, .sidebar-icon {
          color: #fff;
        }

        .nav-text {
          color: #fff;
        }

        .link {
          color: #fff;
          font-weight: bold;
        }
      }

      > li {
        &:hover {
          i,
          .sidebar-icon
          .nav-text {
            color: #ffffff;
          }
          .isoMenuHolder svg path {
            fill: #fff;
          }
        }
        
      }
    }

    .ant-menu-submenu-inline,
    .ant-menu-submenu-vertical {
      > .ant-menu-submenu-title {
        margin:0;
        width: 100%;
        font-size: 12px;
        line-height: 42px;
        height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        > span {
          display: flex;
          align-items: center;
        }

        &:after {
          content: '\f123';
          font-family: 'Ionicons' !important;
          font-size: 16px;
          color: inherit;
          display:none;
          left: auto;
          right: 16px;
          ${transition()};
        }

        &:hover {
          &:after {
            color: #ffffff;
          }
        }

       
      }
  
      .ant-menu-inline,
      .ant-menu-submenu-vertical {
        > li:not(.ant-menu-item-group) {
          padding-right: 0px !important;
          font-size: 13px;
          padding-bottom:0;
          font-weight: 400;
          color: inherit;
          ${transition()};

          &:hover {
            color: #ffffff;
          }
        }

        .ant-menu-item-group {
          padding-left: 0;

          .ant-menu-item-group-title {
            padding-left: 100px !important;
          }
          .ant-menu-item-group-list {
            .ant-menu-item {
              padding-left: 125px !important;
            }
          }
        }
      }

     
    }

    &.ant-layout-sider-collapsed {
      .nav-text {
        display: none;
      }

      .ant-menu-submenu-inline >  {
        .ant-menu-submenu-title:after {
          display: none;
        }
      }

      .ant-menu-submenu-vertical {
        > .ant-menu-submenu-title:after {
          display: none;
        }

        
          .ant-menu-item {
            height: 35px;
          }
        }
      }
    }
    .ant-menu-dark .ant-menu-sub {
      background : #001529 !important;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title .isoMenuHolder {
      svg path {
        fill: #fff;
      }
    } 
  }
  .isoMenuHolder {
    svg path {
      transition: all 0.6s;
    }
  } 
  
`;

export const Link = styled.div`
  cursor: pointer;
`;
// padding-left: 74px !important;
