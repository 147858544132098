import { apiRequest } from './index';

// get list of currencies
function currencyList(filter) {
	if (!filter) filter = {};

	const req = {
		method: 'GET',
		url   : '/currency',
		params: filter,
	};

	return apiRequest(req);
}

function currencyListByWebsite(params = {}) {
	const req = {
		method: 'GET',
		url   : '/allCurrencies',
		params,
	};

	return apiRequest(req);
}

function currencyListForUser(params = {}) {
	const req = {
		method: 'GET',
		url   : '/currencies',
		params,
	};

	return apiRequest(req);
}

function currencyListByAdmin(params = {}) {
	const req = {
		method: 'GET',
		url   : '/currency',
		params,
	};

	return apiRequest(req);
}

// add new currency
function currencyAdd(data) {
	const req = {
		method: 'POST',
		url   : '/currency',
		data,
	};

	return apiRequest(req);
}

// update currency
function currencyUpdate(currencyID, data) {
	const req = {
		method: 'POST',
		url   : `/currency/${currencyID}`,
		data,
	};

	return apiRequest(req);
}

// delete currency
function currencyDelete(currencyID) {
	const req = {
		method: 'DELETE',
		url   : `/currency/${currencyID}`,
	};

	return apiRequest(req);
}

// GET: exchange rates list
function exchangeRatesList(currencyID, additionalParams = {}) {
	const req = {
		method: 'GET',
		url   : `/currency/${currencyID}/rates`,
		params: { ...additionalParams },
	};
	return apiRequest(req);
}

// POST: update exchange rates list
function exchangeRatesUpdate(currencyID, data, additionalParams = {}) {
	const req = {
		method: 'POST',
		url   : `/currency/${currencyID}/rates`,
		params: { ...additionalParams },
		data,
	};
	return apiRequest(req);
}

function currencyPaywaysPlatformList(params = {}) {

	const req = {
		method: 'GET',
		url   : '/currency_payway_list',
		params,
	};
	return apiRequest(req);
}

function currencyPaywaysUpdate(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/currency_payway',
		data,
		params,
	};
	return apiRequest(req);
}

const agentsCurrency = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/websites/agent_currency',
		params,
	};

	return apiRequest(req);
};


export const currencyAPI = {
	currencyList,
	currencyListByWebsite,
	currencyListByAdmin,
	currencyListForUser,
	currencyAdd,
	currencyUpdate,
	currencyDelete,

	exchangeRatesList,
	exchangeRatesUpdate,

	currencyPaywaysPlatformList,
	currencyPaywaysUpdate,

	agentsCurrency,
};
