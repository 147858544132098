import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';
import isArray from 'lodash/isArray';

import toInteger from 'lodash/toInteger';
import { showError, showSuccess, showWarning } from '../../../helpers/notifications';
import { usersAPI } from '../../../helpers/api/users';
import { currencyAPI } from '../../../helpers/api/currency';
import { URL_TYPE } from '../../profile/utils';
import sportstatisticActions from '../sportStatistic/actions';
import casinoStatisticActions from '../casinoStatistic/actions';
import tabsActions from '../../appTabs/actions';
import actions from './actions';

import {
	adaptBaseData,
	adaptAdminData,
	prepareBaseData,
	adaptFinance,
	adaptUserCurrenciesList,
	adaptNotesData,
	adaptNoteItem, adaptMatchingPassword, adaptMatchingDocument,
} from './utils';
import { statusIDs } from '../../../containers/CustomerManagement/enums';
import { logger } from '../../../helpers/logger';

const prefix = 'users.user';

const messages = {
	errorBaseDataLoad    : `${prefix}.errorBaseDataLoad`,
	errorAdminDataLoad   : `${prefix}.errorAdminDataLoad`,
	errorCurrencyListLoad: `${prefix}.errorCurrencyListLoad`,
	errorUserUpdate      : `${prefix}.errorUserUpdate`,
	errorRolesUpdate     : `${prefix}.errorRolesUpdate`,
	errorResetPassword   : `${prefix}.errorResetPassword`,
	errorUserSearch      : `${prefix}.errorUserSearch`,

	successUserUpdate   : `${prefix}.successUserUpdate`,
	successRolesUpdate  : `${prefix}.successRolesUpdate`,
	successNotesUpdate  : `${prefix}.successNotesUpdate`,
	successResetPassword: `${prefix}.successResetPassword`,
};

function getUserData({ Users, Auth, App }) {

	return {
		baseData           : Users.User.get('baseData'),
		adminData          : Users.User.get('adminData'),
		permissions        : Users.User.get('permissions'),
		UI                 : Users.User.get('UI'),
		newNote            : Users.User.get('newNote'),
		notes              : Users.User.get('notes'),
		admin              : Auth.get('user'),
		userQuickSearchData: Users.User.get('userQuickSearchData'),
		partnerEntities    : App.get('partnerEntity'),
	};
}

function* userDataReload() {
	yield takeEvery(actions.USER_DATA_RELOAD, function* (action) {
		yield put(actions.setValueUI('loading', true));
		const { userID, type } = action.data;
		const userOrAdmin = type === URL_TYPE.users ? URL_TYPE.users : URL_TYPE.admins;

		const { partnerEntities } = yield select(getUserData);
		let rawBaseData = {};
		let rawAdminData = {};
		let currencyList = [];

		// base data
		try {
			const response = yield call(usersAPI.usersList, userOrAdmin, {}, userID);
			if (response && response.status === 200) {

				response.data.data.length ? [rawBaseData] = response.data.data : rawBaseData = response.data.data;
			}
		} catch (error) {
			showError(messages.errorBaseDataLoad, error);
			logger.log(error);
		}

		// admin data
		try {
			const response = yield call(usersAPI.getUserAdminInfo, userID, userOrAdmin);

			if (response && response.status === 200) {
				rawAdminData = response.data.data;
			}
		} catch (error) {
			showError(messages.errorAdminDataLoad, error);
			logger.log(error);
		}
		const baseData = adaptBaseData(rawBaseData, partnerEntities);
		const adminData = adaptAdminData(rawAdminData);
		baseData.roles.concat();
		// Currency list
		try {
			const response = yield call(currencyAPI.currencyListForUser);
			if (response && response.status === 200) {
				currencyList = adaptUserCurrenciesList(response.data.data);
			}

		} catch (error) {
			showError(messages.errorCurrencyListLoad, error);
			logger.log(error);
		}
		const { enableTwoFAApp, enableTwoFASms } = baseData;
		const staticData = { enableTwoFAApp, enableTwoFASms };
		yield put(actions.baseDataRefresh(baseData));
		yield put(actions.staticDataRefresh(staticData));
		yield put(actions.adminDataRefresh(adminData));
		yield put(actions.currencyListRefresh(currencyList));
		yield put(actions.setValueUI('isBaseDataChanged', false));
		yield put(actions.setValueUI('statusID', baseData.statusID));
		yield put(actions.setValueUI('loading', false));
	});
}

function* baseDataReload() {
	const takeAction = actions.USER_BASE_DATA_RELOAD;
	const errorMessage = 'Loading user base data failed';

	yield takeEvery(takeAction, function* (action) {
		const { userID } = action.data;
		const params = { id: userID };
		try {
			const response = yield call(usersAPI.usersList, null, params);
			if (response && response.status === 200) {
				const list = response.data.data;
				if (isArray(list) && list.length > 0) {
					const baseData = list[0];
					yield put(actions.baseDataRefresh(baseData));
				}
			}

		} catch (error) {
			showError(errorMessage, error);
			logger.log(error);
		}
	});
}


// TODO this function is not used. Probably this should be deleted.
function* adminDataReload() {

	const takeAction = actions.USER_ADMIN_DATA_RELOAD;
	const errorMessage = 'Loading user data for Admin failed';

	yield takeEvery(takeAction, function* (action) {

		const { userID } = action.data;
		try {
			const response = yield call(usersAPI.getUserAdminInfo, userID);
			if (response && response.status === 200) {
				const { data } = response.data;
				yield put(actions.adminDataRefresh(data));
			}

		} catch (error) {
			showError(errorMessage, error);
			logger.log(error);
		}
	});
}

function* financeDataReload() {

	const takeAction = actions.USER_FINANCE_DATA_RELOAD;
	const errorMessage = 'Loading user finance data failed';

	yield takeEvery(takeAction, function* (action) {

		const { userID } = action.data;
		try {
			const response = yield call(usersAPI.getUserFinance, userID);
			if (response && response.status === 200) {
				const { data } = response.data;
				const adaptedData = adaptFinance(data);
				yield put(actions.finaneDataRefresh(adaptedData));
			}

		} catch (error) {
			showError(errorMessage, error);
			logger.log(error);
		}
	});
}

function* userNotesReload() {

	const takeAction = actions.USER_NOTES_RELOAD;
	const errorMessage = 'Loading user notes failed';

	yield takeEvery(takeAction, function* (action) {
		const { userID } = action.data;
		try {
			const response = yield call(usersAPI.getUserNotes, userID);
			if (response && response.status === 200) {
				const notes = adaptNotesData(response.data.data);
				yield put(actions.userNotesRefresh(notes));
			}

		} catch (error) {
			showError(errorMessage, error);
			logger.log(error);
		}
	});
}

function* userNoteDelete() {

	const takeAction = actions.USER_NOTE_DELETE;
	const errorMessage = 'Deleting user note failed';

	yield takeEvery(takeAction, function* (action) {
		const { noteID, userID } = action.data;
		const { notes } = yield select(getUserData);
		try {
			const response = yield call(usersAPI.deleteUserNote, userID, noteID);
			if (response && response.status === 200) {
				const refreshedNotes = notes.filter(note => note.id !== noteID);
				yield put(actions.userNotesRefresh(refreshedNotes));
			}
		} catch (error) {
			showError(errorMessage, error);
			logger.log(error);
		}
	});
}

function* userUpdate() {

	yield takeEvery(actions.USER_UPDATE, function* (action) {

		yield put(actions.setValueUI('loading', true));

		const userData = yield select(getUserData);
		const { userID, type } = action.data;
		const userOrAdmin = type === URL_TYPE.users ? URL_TYPE.users : URL_TYPE.admins;
		const { baseData, adminData, UI, newNote, admin, notes, partnerEntities } = userData;
		const { logout } = UI;
		const { roles } = baseData;
		const preparedData = prepareBaseData(baseData, adminData);
		let rawBaseData = {};
		let rawAdminData = {};

		try {
			// roles
			if (UI.isRolesChanged) {
				const data = {
					roles,
					logout,
				};
				yield call(usersAPI.userRolesUpdate, userID, data);
				yield put(actions.setValueUI('isRolesChanged', false));
			}

			// notes
			if (UI.isNotesChanged) {
				const data = {
					admin_id: admin.id,
					text    : newNote,
				};
				const res = yield call(usersAPI.updateUserNotes, userID, data);
				if (res && res.status === 200) {
					const noteData = adaptNoteItem(res.data.data);
					yield put(actions.userNotesRefresh(notes));
					notes.push(noteData);
					yield put(actions.setValueUI('isNotesChanged', false));
				}
			}

			// base data
			const response = yield call(usersAPI.updateUserData, userID, preparedData, userOrAdmin);
			if (response && response.status === 200) {
				rawBaseData = response.data.data;
				rawAdminData = response.data.data;
				const updatedBaseData = adaptBaseData(rawBaseData, partnerEntities);
				const updatedAdminData = adaptAdminData(rawAdminData);
				updatedBaseData.roles.concat();
				const { enableTwoFAApp, enableTwoFASms } = updatedBaseData;
				const staticData = { enableTwoFAApp, enableTwoFASms };
				yield put(actions.setValueUI('loading', false));
				yield put(actions.baseDataRefresh(baseData));
				yield put(actions.baseDataRefresh(updatedBaseData));
				yield put(actions.staticDataRefresh(staticData));
				yield put(actions.adminDataRefresh({
					...updatedAdminData,
					...adminData,
				}));
				yield put(actions.userNotesReload(action.data.userID));
				yield put(actions.setValueUI('isBaseDataChanged', false));
				yield put(actions.setValueUI('isLimitsAndRestrict', false));
				yield put(actions.setValueUI('statusID', updatedBaseData.statusID));
			}

			showSuccess(messages.successUserUpdate);

		} catch (error) {
			showError(messages.errorUserUpdate, error);
			logger.log(error);
		}
		yield put(actions.setValueUI('loading', false));
	});
}

function* resetPassword() {

	yield takeEvery(actions.USER_RESET_PASSWORD, function* (action) {

		yield put(actions.setValueUI('loading', true));
		const { email, admin } = action.data;
		const userData = yield select(getUserData);
		const { adminData, baseData: { id: userID } } = userData;
		const { websiteID } = adminData;
		const userOrAdminValue = window.location.pathname.split('/')[2];

		const sendData = {
			email,
		};
		const params = {
			website_id: websiteID,
		};
		try {
			const res = yield call(usersAPI.userResetPassword, sendData, admin, params);
			if (res && res.status === 200) {
				showSuccess(messages.successResetPassword);
				yield put(actions.setValueUI('statusID', statusIDs.PENDING));
				yield put(actions.userDataReload(userID, userOrAdminValue));
			}

		} catch (error) {
			showError(messages.errorResetPassword, error);
			logger.log(error);
		}

		yield put(actions.setValueUI('loading', false));
	});
}

function* userQuickSearch() {

	yield takeEvery(actions.USER_QUICK_SEARCH, function* () {
		yield put(actions.setValueUI('loading', true));

		const { userQuickSearchData } = yield select(getUserData);
		const { userID, userName, userEmail } = userQuickSearchData;
		const params = {};
		if (userID) {
			params.id = userID;
		}
		if (userName) {
			params.username = userName;
		}
		if (userEmail) {
			params.email = userEmail;
		}
		params.exact_search = true;

		try {
			const res = yield call(usersAPI.findUsers, params);
			if (res && res.status === 200) {
				const userList = res.data.data;
				if (userList.length) {
					const foundUser = userList[0];
					const userID = toInteger(foundUser.id);
					yield put(tabsActions.openTabUser(userID));

					yield put(tabsActions.setTabUserId(userID));

					sportstatisticActions.dataReload(userID);
					casinoStatisticActions.dataReload(userID);

					// user not found
				} else {
					showWarning('User not found');
				}
			}

		} catch (error) {
			showError(messages.errorUserSearch, error);
		}

		yield put(actions.setValueUI('loading', false));
		yield put(actions.setValueUI('modalVisible', false));
		yield put(actions.userQuickSearchDataReset());
	});
}

function* depositAndWithdrawBlockUpdate() {
	yield takeEvery(actions.USER_DEPOSIT_AND_WITHDRAW_BLOCK, function* (action) {
		const userData = yield select(getUserData);
		try {
			yield put(actions.setValueUI('isLimitsAndRestrict', true));
			const { checked, fieldName } = action.data;
			const { baseData } = userData;
			const baseDataUpdate = { ...baseData, [fieldName]: checked };
			yield put(actions.baseDataRefresh(baseDataUpdate));
		} catch (e) {
			logger.log(e);
		}

	});
}

function* kycPasswordMatchReload() {
	yield takeEvery(actions.USER_KYC_PASSWORD_RELOAD, function* () {
		yield put(actions.setValueUI('kycPasswordLoading', true));
		const baseData = yield select(getUserData);
		const userID = baseData.baseData.id;
		try {
			const response = yield call(usersAPI.getUserKycPasswordMatch, userID);
			if (response && response.status === 200) {
				const { data } = response.data;
				const adapted = adaptMatchingPassword(data.matched_passwords);
				yield put(actions.kycPasswordRefresh(adapted));
			}
		} catch (error) {
			logger.log(error);
		}
		yield put(actions.setValueUI('kycPasswordLoading', false));
	});
}

function* kycDocumentMatchReload() {
	yield takeEvery(actions.USER_KYC_DOCUMENTS_RELOAD, function* () {
		yield put(actions.setValueUI('kycDocumentLoading', true));
		const baseData = yield select(getUserData);
		const userID = baseData.baseData.id;

		try {
			const params = {
				only_matched: true,
			};
			const response = yield call(usersAPI.userDocumentsList, userID, params);
			if (response && response.status === 200) {
				const { data } = response.data;
				const adapted = adaptMatchingDocument(data);
				yield put(actions.kycDocumentsRefresh(adapted));
			}
		} catch (e) {
			logger.log(e);
		}

		yield put(actions.setValueUI('kycDocumentLoading', false));
	});
}

export default function* userSaga() {
	yield all([
		fork(userDataReload),
		fork(baseDataReload),
		fork(adminDataReload), // TODO this is unused, this should probably be removed
		fork(userUpdate),
		fork(financeDataReload),
		fork(resetPassword),
		fork(userNotesReload),
		fork(userNoteDelete),
		fork(userQuickSearch),
		fork(depositAndWithdrawBlockUpdate),
		fork(kycPasswordMatchReload),
		fork(kycDocumentMatchReload),
	]);
}
