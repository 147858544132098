import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultValuesTemplateFilter, initBaseData, initFilterTypes } from './utils';
import { LANGUAGES } from '../../../helpers/commonConstants';
import {
	IBonusTemplate, IBonusTemplateLog,
	IBonusTemplatesInitState,
	IBonusTemplateUI,
	IFilterData,
} from './types';
// import { restoreWebsiteID } from '../../../helpers/utility';
import { createFilter } from '../../../helpers/utils';
const initState: IBonusTemplatesInitState = {
	list       : [],
	baseData   : initBaseData,
	filterData : createFilter('loyalty/bonusTemplates', initFilterTypes, defaultValuesTemplateFilter), // TODO: fix import issue 'filtersURL' Avet Attention please
	historyLogs: [],
	UI         : {
		loading       : false,
		isChanged     : false,
		saveSuccess   : false,
		langID        : LANGUAGES.en,
		betID         : null,
		gameName      : null,
		betCurrencyTab: null,
		uiChanged     : false,
		logsLoading   : false,
		modalVisible  : false,
		templateID    : null,
	},
};

const bonusTemplatesSlice = createSlice({
	name        : 'templates',
	initialState: initState,
	reducers    : {
		setTemplates: (state, action: PayloadAction<IBonusTemplate[]>) => {
			state.list = action.payload;
		},
		setFilterData: (state, action: PayloadAction<IFilterData>) => {
			state.filterData = action.payload;
		},
		resetFilterData: (state) => {
			state.filterData = initState.filterData;
		},
		setBaseData: (state, action: PayloadAction<IBonusTemplate>) => {
			state.baseData = action.payload;
		},
		setHistoryLogs: (state, action: PayloadAction<IBonusTemplateLog[]>) => {
			state.historyLogs = action.payload;
		},
		setUI: (state, action: PayloadAction<Partial<IBonusTemplateUI>>) => {
			state.UI = { ...state.UI, ...action.payload };
		},
	},
});

export const bonusTemplatesReducers = {
	setTemplates   : bonusTemplatesSlice.actions.setTemplates,
	setFilterData  : bonusTemplatesSlice.actions.setFilterData,
	resetFilterData: bonusTemplatesSlice.actions.resetFilterData,
	setBaseData    : bonusTemplatesSlice.actions.setBaseData,
	setHistoryLogs : bonusTemplatesSlice.actions.setHistoryLogs,
	setUI          : bonusTemplatesSlice.actions.setUI,
};

export default bonusTemplatesSlice.reducer;
