import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import toInteger from 'lodash/toInteger';

import EntityAdapter from '../../helpers/entityAdapter';
import { isID } from '../../helpers/utils';
import { canUseSidebar, isSuperOrPlatformAdmin } from '../../helpers/permissions/utils';
import defaultSidebar from '../../config/defaultSidebar';
import { nonPlatformModules } from '../../helpers/permissions/constants';

export const fields = {
	setting  : 'setting',
	settingID: 'setting_id',
	menus    : 'menus',
	menuItems: 'submenus',
	key      : 'name',
	order    : 'order',
	itemID   : 'id',
	menuID   : 'menu_id',
	userID   : 'user_id',
};

const menuAdapter    = createMenuAdapter();
const submenuAdapter = createSubmenuAdapter();

// Adapt ------------------------------------------------------------------------------------------
export const createSidebar = (menu = defaultSidebar, user, permissions, adaptedMenu, settingID, result = []) => {
	for (let i = 0; i < menu.length; i++) {
		const rootMenu = menu[i];
		const hasChildren = (rootMenu.menuItems && rootMenu.menuItems.length);
		const nonPlatform = nonPlatformModules.includes(rootMenu.key);
		const isPlatform = isSuperOrPlatformAdmin();
		if (nonPlatform && isPlatform) {
			return result;

		}
		if (!hasChildren) {
			const menuKey    = rootMenu.key;
			const canUseMenu = canUseSidebar(menuKey, user, permissions);
			const adaptedMenuItem = find(adaptedMenu, { key: menuKey }) || {};
			rootMenu.order         = toInteger(adaptedMenuItem.order) || rootMenu.order;
			rootMenu.itemID        = adaptedMenuItem.itemID || null;
			rootMenu.settingID     = settingID;
			if (canUseMenu) {
				result.push(rootMenu);
			}
		} else {
			const menuItems = createSidebar(rootMenu.menuItems, user, permissions, adaptedMenu, settingID, []);
			const curentObj = {
				key  : rootMenu.key,
				order: rootMenu.order,
				title: rootMenu.title,
				menuItems,
			};
			if (curentObj.menuItems.length) {
				result.push(curentObj);
			}
		}
	}
	return result;
};

export function adaptSidebar(rawData = {}, user, permissions) {

	menuAdapter.clearExcludes();
	submenuAdapter.clearExcludes();
	const settingID   = rawData.setting ? toInteger(rawData.setting.id) : null;
	const adaptedMenu = menuAdapter.adaptList(rawData.menus);

	adaptedMenu.forEach(menuItem => {
		menuItem.menuItems = submenuAdapter.adaptList(menuItem.menuItems);
	});

	const sidebar = cloneDeep(defaultSidebar);
	const result = createSidebar(sidebar, user, permissions, adaptedMenu, settingID);

	return result;
}

export function createAgentMenu(menu = defaultSidebar) {
	const result = [];
	for (let i = 0; i < menu.length; i++) {
		const rootMenu = menu[i];
		const hasChildren = (rootMenu.menuItems && rootMenu.menuItems.length);
		if (!hasChildren) {
			const agentOnly = rootMenu.agentOnly;
			if (agentOnly) {
				result.push(rootMenu);
			}
		} else {
			const menuItems = createAgentMenu(rootMenu.menuItems);
			if (menuItems.length) {
				const curentObj = {
					key      : rootMenu.key,
					order    : rootMenu.order,
					title    : rootMenu.title,
					menuItems: menuItems,
				};
				result.push(curentObj);
			}
		}
	}
	return result;
}

// Prepare ----------------------------------------------------------------------------------------

export function prepareSidebar(sidebarData, userID) {

	const settingID = getSettingID(sidebarData);

	submenuAdapter.addExcludeField('itemID');
	menuAdapter.addExcludeField('itemID');

	const menus = sidebarData.map(rootMenuItem => {
		const menuItems = rootMenuItem.menuItems.map(item => {
			const preparedItem = submenuAdapter.prepare(item);
			preparedItem[fields.settingID] = settingID;
			if (isID(item.itemID)) {
				preparedItem.id = toInteger(item.itemID);
			}

			return preparedItem;
		});

		const preparedMenuItem = menuAdapter.prepare(rootMenuItem);
		preparedMenuItem[fields.settingID] = settingID;
		preparedMenuItem[fields.menuItems] = menuItems;

		if (isID(rootMenuItem.itemID)) {
			preparedMenuItem.id = toInteger(rootMenuItem.itemID);
		}

		return preparedMenuItem;
	});

	return {
		[fields.setting]: {
			[fields.settingID]: settingID,
			[fields.userID]   : userID,
		},
		[fields.menus]: menus,
	};
}

// Adapters ---------------------------------------------------------------------------------------

export function createMenuAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'itemID', fields.itemID);
	adapter.addField(rules.id, 'settingID', fields.settingID);
	adapter.addField(rules.id, 'order', fields.order);

	adapter.addField(rules.string, 'key', fields.key);

	adapter.addField(rules.arrayObject, 'menuItems', fields.menuItems);

	return adapter;
}

export function createSubmenuAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'itemID', fields.itemID);
	adapter.addField(rules.id, 'settingID', fields.settingID);
	adapter.addField(rules.id, 'menuID', fields.menuID);
	adapter.addField(rules.id, 'order', fields.order);

	adapter.addField(rules.string, 'key', fields.key);

	return adapter;
}

// Others -----------------------------------------------------------------------------------------

export function getSettingID(sidebarData) {

	let settingID = null;
	sidebarData.forEach(menuItem => {
		if (isID(settingID)) {
			return;
		}

		settingID = toInteger(menuItem.settingID);
	});

	return settingID;
}

export function createDefaultMenu(editMenu, user) {

	const defaultMenu = cloneDeep(defaultSidebar);
	const settingID   = getSettingID(editMenu);

	const result = [];

	defaultMenu.forEach(rootMenuItem => {
		rootMenuItem.settingID = settingID;
		const rootKey = rootMenuItem.key;
		const canUseMenu = canUseSidebar(rootKey, user);
		if (!canUseMenu) {
			return;
		}

		const editRootMenuItem = editMenu.find(item => item.key === rootKey);
		if (editRootMenuItem) {
			rootMenuItem.itemID = editRootMenuItem.itemID;
		}

		const { menuItems } = rootMenuItem;
		const editMenuItems = editRootMenuItem
			? editRootMenuItem.menuItems
			: [];

		const submenus = [];

		menuItems.forEach(menuItem => {
			const menuKey = menuItem.key;
			const canUseSubMenu = canUseSidebar(menuKey, user);
			if (!canUseSubMenu) {
				return;
			}

			menuItem.settingID = settingID;

			const editMenuItem = editMenuItems.find(item => item.key === menuKey);
			if (editMenuItem) {
				menuItem.itemID = editMenuItem.itemID;
			}

			submenus.push(menuItems);
		});

		if (!isEmpty(menuItems) && isEmpty(submenus)) {
			return;
		}

		rootMenuItem.menuItems = submenus;
		result.push(rootMenuItem);
	});

	return result;
}
