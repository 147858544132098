import { apiRequest } from './index';

// Websites ---------------------------------------------------------------------------------------

function websitesList(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/websites',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}
function websitesMailingList(websiteID, params = {}) {
	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/integrations`,
		withoutLangID: true,
		params,
	};
	return apiRequest(req);
}


function sendTestEmail(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/website/email',
		data,
		params,
	};

	return apiRequest(req);
}

function websiteInfo(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function websiteAdd(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/websites',
		data,
		params,
	};

	return apiRequest(req);
}

function websiteUpdate(websiteID, data, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/websites/${websiteID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function websiteDelete(websiteID) {

	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}`,
	};

	return apiRequest(req);
}

function websiteSettings(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/settings',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function websiteAdditionalDomainslist(params = {}) {

	const req = {
		method       : 'GET',
		url          : '/website_domains',
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function websiteAdditionalDomainsUpdate(params = {}, data) {

	const req = {
		method       : 'POST',
		url          : '/website_domains',
		withoutLangID: true,
		data,
		params,
	};

	return apiRequest(req);
}

function websiteAdditionalDomainSave( domainID, params = {}, data) {

	const req = {
		method       : 'PUT',
		url          : `/website_domains/${domainID}`,
		withoutLangID: true,
		data,
		params,
	};

	return apiRequest(req);
}

function websiteAdditionalDomainDelete( domainID, params = {}, data) {

	const req = {
		method       : 'DELETE',
		url          : `/website_domains/${domainID}`,
		withoutLangID: true,
		data,
		params,
	};

	return apiRequest(req);
}

// Payments ---------------------------------------------------------------------------------------

function paymentsList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/payments`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}
function paymentsListNames(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/payments/list`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentAdd(websiteID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/payments`,
		data,
		params,
	};

	return apiRequest(req);
}

function paymentUpdate(websiteID, paymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/payments/${paymentID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function paymentCurrencyListNew(websiteID, paymentID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/websites/${websiteID}/payment/${paymentID}/currency`,
		params,
	};

	return apiRequest(req);
}

function paymentCurrencyListUpdate(websiteID, paymentID, data, params = {}) {

	const req = {
		method: 'PUT',
		url   : `/websites/${websiteID}/payment/${paymentID}/currency`,
		data,
		params,
	};

	return apiRequest(req);
}


function paymentsListUpdate(websiteID, paymentsList, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/payments/${websiteID}`,
		data  : paymentsList,
		params,
	};

	return apiRequest(req);
}

function paymentDelete(websiteID, paymentID) {

	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/payments/${paymentID}`,
	};

	return apiRequest(req);
}

function paymentLimitsList(websitePaymentID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websitePayments/${websitePaymentID}/limits`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentLimitsUpdate(websitePaymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websitePayments/${websitePaymentID}/limits`,
		data,
		params,
	};

	return apiRequest(req);
}

function paymentDescriptionsList(websitePaymentID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websitePayments/${websitePaymentID}/description`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function paymentDescriptionsUpdate(websitePaymentID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websitePayments/${websitePaymentID}/description`,
		data,
		params,
	};

	return apiRequest(req);
}

// Casino -----------------------------------------------------------------------------------------

function casinoList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/casino`,
		// url          : `/websites/${websiteID}/casino/list`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function casinoAdd(websiteID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/casino`,
		data,
		params,
	};

	return apiRequest(req);
}

function casinoUpdate(websiteID, casinoID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/casino/${casinoID}`,
		data,
		params,
	};

	return apiRequest(req);
}

function casinoDelete(websiteID, casinoID) {

	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/casino/${casinoID}`,
	};

	return apiRequest(req);
}

// Currency ---------------------------------------------------------------------------------------

function currencyList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/currency`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function avalibleCurrenciesList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/website_currency`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}


function currencyAdd(websiteID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/currency`,
		data,
		params,
	};

	return apiRequest(req);
}

function currencyUpdate(websiteID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/currency`,
		data,
		params,
	};

	return apiRequest(req);
}

function currencyDelete(websiteID, currencyID, params = {}) {

	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/currency/${currencyID}`,
		params,
	};

	return apiRequest(req);
}

// Scripts ----------------------------------------------------------------------------------------

function scriptsList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/scripts`,
		withoutLangID: true,
		params,
	};

	return apiRequest(req);
}

function scriptUpdate(websiteID, data, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/scripts`,
		data,
		params,
	};

	return apiRequest(req);
}
// Sms Gateweys
function websiteSmsGatewaysList(websiteID, params = {}) {

	const req = {
		method          : 'GET',
		url             : `/websites/${websiteID}/sms_gateways`,
		withoutWebsiteID: true,
		params,
	};

	return apiRequest(req);
}

function reOrdermsGateways(data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/website_sms_gateway/update',
		data,
		params,
	};

	return apiRequest(req);
}

function smsGatewaySave(data, websiteID, id, params = {}) {
	const url = typeof id === 'number' ? `/websites/${websiteID}/sms_gateways/${id}` :  `/websites/${websiteID}/sms_gateways`;
	const method =  typeof id === 'number' ? 'PUT' : 'POST';
	const req = {
		method,
		url,
		data,
		params,
	};

	return apiRequest(req);
}

function websiteSmsGatewayDelete(websiteID, smsGatewayID) {
	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/sms_gateways/${smsGatewayID}`,
	};
	return apiRequest(req);
}


function countryList(websiteID, data, params = {}) {

	const req = {
		method: 'GET',
		url   : `/common/website/${websiteID}/countries`,
		params,
	};

	return apiRequest(req);
}

function allCountries(websiteID, data, params = {}) {

	const req = {
		method: 'GET',
		url   : '/country',
		params,
	};

	return apiRequest(req);
}

function websiteAvailableCountries(websiteID, data, params = {}) {

	const req = {
		method: 'GET',
		url   : `/websites/${websiteID}/website_country`,
		params,
	};

	return apiRequest(req);
}

function allLanguages(websiteID, data, params = {}) {

	const req = {
		method: 'GET',
		url   : '/language',
		params,
	};

	return apiRequest(req);
}

function getSeoList(websiteID, params = {}) {

	const req = {
		method: 'GET',
		url   : `/websites/${websiteID}/seo`,
		params,
		// withoutWebsiteID : true  TODO : check
	};

	return apiRequest(req);
}

function seoSave(data, websiteID, params = {}) {

	const req = {
		method: 'POST',
		url   : `/websites/${websiteID}/seo`,
		data,
		params,
		// withoutWebsiteID : true  TODO : check
	};

	return apiRequest(req);
}

function allLanguagesPartner(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/websites/${websiteID}/languages`,
		params,
		withoutLangID: true,

	};

	return apiRequest(req);
}

function saveCountry( data, params = {}) {

	const req = {
		method: 'POST',
		url   : '/common/website/country',
		data,
		params,
	};

	return apiRequest(req);
}


function languageList(websiteID, params = {}) {

	const req = {
		method       : 'GET',
		url          : `/common/website/${websiteID}/languages`,
		params,
		withoutLangID: true,
	};

	return apiRequest(req);
}
function saveLanguage( data ) {
	const req = {
		method: 'POST',
		url   : '/common/website/language',
		data,
	};

	return apiRequest(req);
}

function deleteLanguage(data, websiteID, languageID) {
	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/language/${languageID}`,
		data,
	};

	return apiRequest(req);
}
function deleteCountry( data, websiteID, countryID ) {
	const req = {
		method: 'DELETE',
		url   : `/websites/${websiteID}/country/${countryID}`,
		data,
	};

	return apiRequest(req);
}


// Fetch countries lists for an agent per website
const agentsCountries = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/website/agent_countries',
		params,
	};

	return apiRequest(req);
};

const agentsCurrency = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/website/agent_currency',
		params,
	};

	return apiRequest(req);
};

const agentsLanguages = (params = {}) => {
	const req = {
		method: 'GET',
		url   : '/website/agent_languages',
		params,
	};

	return apiRequest(req);
};


export const websitesAPI = {
	websitesList,
	websiteInfo,
	websiteAdd,
	websiteUpdate,
	websiteAdditionalDomainslist,
	websiteAdditionalDomainsUpdate,
	websiteAdditionalDomainSave,
	websiteAdditionalDomainDelete,
	websiteDelete,
	websiteSettings,
	websitesMailingList,
	sendTestEmail,
	paymentsList,
	paymentAdd,
	paymentUpdate,
	paymentCurrencyListUpdate,
	paymentCurrencyListNew,
	paymentsListUpdate,
	paymentDelete,
	paymentsListNames,

	paymentLimitsList,
	paymentLimitsUpdate,

	paymentDescriptionsList,
	paymentDescriptionsUpdate,

	casinoList,
	casinoAdd,
	casinoUpdate,
	casinoDelete,

	currencyList,
	currencyAdd,
	currencyUpdate,
	currencyDelete,

	scriptsList,
	scriptUpdate,

	websiteSmsGatewaysList,
	reOrdermsGateways,
	smsGatewaySave,
	websiteSmsGatewayDelete,

	avalibleCurrenciesList,
	countryList,
	saveCountry,
	languageList,
	saveLanguage,
	deleteLanguage,
	deleteCountry,
	allCountries,
	websiteAvailableCountries,
	allLanguages,
	allLanguagesPartner,

	getSeoList,
	seoSave,

	agentsCountries,
	agentsCurrency,
	agentsLanguages,

};
