import { fork, takeEvery, put, all, call, select } from 'redux-saga/effects';
import { agentsActions } from './actions';
import { agentsReducers } from './slice';
import { logger } from '../../helpers/logger';
import websiteActions from '../partner/websites/website/actions';
import { restoreWebsite } from '../../helpers/utility';
import { AgentsApi } from '../../helpers/api/agents';
import { TABLE_TYPES } from '../../constants/tableTypes';
import { RootState } from '../store';
import { AxiosApiResponse } from '../../helpers/api/types';
import {
	IAgentAutoCompletePayload,
	IAgentData,
	IAgentDataResponse, IAgentGetStore, IAgentInfo,
	IAgentTransaction,
	IAgentTransactionResponse,
} from './types';
import {
	adaptAgentData,
	adaptAgentsList,
	adaptAgentTransaction,
	getListParams,
	getTransactionsListParams,
	prepareAgentData, prepareAgentInfo,
} from './utils';
import { ISagaActionType } from '../types';
import isArray from 'lodash/isArray';
import { AUTOCOMPLETE_SOURCES } from '../../helpers/commonConstants';
import { showSuccess } from '../../helpers/notifications';
import tableActions from '../tables/actions';
import { getHeadersTotalCount } from '../../helpers/utils';
import { handleAppError } from '../ErrorHandler';


const tableType     = TABLE_TYPES.agentsList;
const transactionTableType = TABLE_TYPES.agentTransactions;

const messages = {
	adjustedSuccess   : 'agent.balance.adjusted.success',
	adjustError       : 'agent.balance.adjusted.error',
	changePassSuccess : 'agent.password.changed.success',
	changePassError   : 'agent.password.changed.error',
	agentCreateSuccess: 'agent.create.success',
	agentCreateError  : 'agent.create.error',
	agentUpdateSuccess: 'agent.update.success',
	agentUpdateError  : 'agent.update.error',
};


function getStoreData(state: RootState): IAgentGetStore {
	const { Agents, Tables, App } = state;

	return {
		filter               : Agents.filter,
		transactionFilter    : Agents.transactionFilter,
		pagination           : Tables.get(tableType).pagination,
		transactionPagination: Tables.get(transactionTableType).pagination,
		sorting              : Tables.get(tableType).sorting,
		transactionSorting   : Tables.get(transactionTableType).sorting,
		websiteID            : App.get('websiteID'),
		baseData             : Agents.baseData,
		agentInfoBaseData    : Agents.agentInfoBaseData,
		agentInfo            : Agents.agentInfo,
		changePassBaseData 	 : Agents.changePassBaseData,
	};
}


function* getAgentsList() {
	yield takeEvery(agentsActions.AGENTS_LIST_RELOAD, function* () {
		yield put(agentsReducers.setUI({ listLoading: true }));
		const { filter, pagination, sorting } = yield select(getStoreData);
		const websiteID = restoreWebsite();
		const params = getListParams(filter, pagination, sorting);
		try {
			const result: AxiosApiResponse<IAgentDataResponse[]> = yield call(AgentsApi.agentsList, params);
			const adapted: IAgentData[] = adaptAgentsList(result.data.data);
			const totalCount = getHeadersTotalCount(result.headers);
			yield put(tableActions.paginationRefresh(tableType, { totalCount }));
			yield put(websiteActions.getAllCountries({ websiteID }));
			yield put(agentsReducers.setAgents(adapted));
		} catch (e) {
			logger.log('e', e);
		}
		yield put(agentsReducers.setUI({ listLoading: false }));

	});
}

function* createAgent() {
	yield takeEvery(agentsActions.CREATE_AGENT, function* () {
		yield put(agentsReducers.setUI({ modalLoading: true }));
		const { baseData } = yield select(getStoreData);
		const websiteID = restoreWebsite();
		try {
			const params = { website_id: websiteID };
			const prepared: Partial<IAgentDataResponse> = prepareAgentData(baseData);
			yield call(AgentsApi.createAgent,prepared, params);
			yield put(agentsActions.agentsListReload());
			yield put(agentsReducers.resetBaseData());
			showSuccess(messages.agentCreateSuccess);
			yield put(agentsReducers.setUI({ visible: false }));

		} catch (e) {
			logger.log('e', e);
			handleAppError(e, messages.agentCreateError);
		}
		yield put(agentsReducers.setUI({ modalLoading: false }));

	});
}

function* archiveAgent() {
	yield takeEvery(agentsActions.ARCHIVE_AGENT, function* (action: ISagaActionType<number>) {
		const { data: agentID } = action;
		try {
			const params = { website_id: restoreWebsite() };
			yield call(AgentsApi.archiveAgent, agentID, params);
			yield put(agentsActions.agentsListReload());
		} catch (e) {
			logger.log('e', e);
			handleAppError(e);
		}
	});
}

function* getAgentsAutocomplete() {
	yield takeEvery(agentsActions.AUTOCOMPLETE_DATA_SOURCE_RELOAD, function* (action: ISagaActionType<IAgentAutoCompletePayload>) {
		const { data } = action;
		const { type, value } = data;
		try {
			const field = AUTOCOMPLETE_SOURCES[type];
			const res: AxiosApiResponse<string[]> = yield call(AgentsApi.getAgentsAutocomplete, { field, value });
			const adapted = isArray(res.data.data) ? res.data.data : [];
			yield put(agentsReducers.setAutoCompleteData({ type, data: adapted }));
		} catch (e) {
			logger.log('e', e);
		}
	});
}

function* getAgentsTransactions() {
	yield takeEvery(agentsActions.AGENTS_TRANSACTIONS_RELOAD, function* () {
		yield put(agentsReducers.setUI({ transactionLoading: true }));
		const { transactionFilter, transactionPagination, transactionSorting, agentInfoBaseData }: IAgentGetStore = yield select(getStoreData);
		const params = getTransactionsListParams(transactionFilter, transactionPagination, transactionSorting);
		const { agentID } = agentInfoBaseData;
		if (agentID) {
			params.user_id = agentID;
		}
		try {
			const result: AxiosApiResponse<IAgentTransactionResponse[]> = yield call(AgentsApi.agentsTransaction, params);
			const adapted: IAgentTransaction[] = adaptAgentTransaction(result.data.data);
			const totalCount = getHeadersTotalCount(result.headers);
			yield put(agentsReducers.setTransactions(adapted));
			yield put(tableActions.paginationRefresh(transactionTableType, { totalCount }));

		} catch (e) {
			logger.log('e', e);
		}
		yield put(agentsReducers.setUI({ transactionLoading: false }));
	});
}

function* adjustAgentBalance() {
	yield takeEvery(agentsActions.AGENT_BALANCE_ADJUSTMENT, function* () {
		yield put(agentsReducers.setUI({ adjustmentLoading: true }));
		const { agentInfoBaseData }: IAgentGetStore = yield select(getStoreData);
		const { agentID, amount } = agentInfoBaseData;
		try {
			const params = { website_id: restoreWebsite() };
			yield call(AgentsApi.agentBalanceAdjustment, { agentID, amount: Number(amount) }, params);
			yield put(agentsActions.agentsListReload());
			yield put(agentsActions.getAgentInfo());
			yield put(agentsReducers.setUI({ adjustmentVisible: false }));
			yield put(agentsReducers.setAgentInfoBaseData({ amount: null }));
			showSuccess(messages.adjustedSuccess);
		} catch (e) {
			logger.log('e', e);
			handleAppError(e, messages.adjustError);
		}

		yield put(agentsReducers.setUI({ adjustmentLoading: false }));
	});
}

function* getAgentInfo() {
	yield takeEvery(agentsActions.AGENT_INFO_RELOAD, function* () {
		yield put(agentsReducers.setUI({ agentInfoLoading: true }));
		const { agentInfoBaseData }: IAgentGetStore = yield select(getStoreData);
		const { agentID } = agentInfoBaseData;
		try {
			const params = { website_id: restoreWebsite() };
			yield put(websiteActions.getAllCountries({ websiteID: restoreWebsite() }));
			const result: AxiosApiResponse<IAgentDataResponse> = yield call(AgentsApi.getAgentInfo, agentID, params);
			const adapted: IAgentInfo = adaptAgentData(result.data.data);
			yield put(agentsReducers.setAgentInfo(adapted));
		} catch (e) {
			logger.log('e', e);
		}
		yield put(agentsReducers.setUI({ agentInfoLoading: false }));
	});
}

function* updateAgent() {
	yield takeEvery(agentsActions.UPDATE_AGENT, function* () {
		yield put(agentsReducers.setUI({ updateLoading: true }));
		const { agentInfo }: IAgentGetStore = yield select(getStoreData);
		const { id: agentID } = agentInfo;
		const prepared: Partial<IAgentDataResponse> = prepareAgentInfo(agentInfo);
		try {
			const params = { website_id: restoreWebsite() };
			yield call(AgentsApi.updateAgent, agentID, prepared, params);
			yield put(agentsActions.agentsListReload());
			yield put(agentsActions.getAgentInfo());
			yield put(agentsReducers.setUI({ baseChanged: false }));
			showSuccess(messages.agentUpdateSuccess);
		} catch (e) {
			logger.log('e', e);
			handleAppError(e, messages.agentUpdateError);
		}
		yield put(agentsReducers.setUI({ updateLoading: false }));
	});
}

function* changePassword() {
	yield takeEvery(agentsActions.CHANGE_PASSWORD, function* () {
		yield put(agentsReducers.setUI({ changePassLoading: true }));
		const { changePassBaseData, agentInfoBaseData  }: IAgentGetStore = yield select(getStoreData);
		const { password } = changePassBaseData;
		const { agentID } = agentInfoBaseData;
		try {
			const params = { website_id: restoreWebsite() };
			yield call(AgentsApi.changeAgentPassword, agentID, { password: (password || '') as string }, params);
			yield put(agentsActions.getAgentInfo());
			yield put(agentsReducers.setUI({ changePassVisible: false }));
			yield put(agentsReducers.resetChangePassBaseData());
			showSuccess(messages.changePassSuccess);
		} catch (e) {
			logger.log('e', e);
			handleAppError(e, messages.changePassError);
		}
		yield put(agentsReducers.setUI({ changePassLoading: false }));
	});
}


export default function* agentsSaga() {
	yield all([
		fork(getAgentsList),
		fork(createAgent),
		fork(archiveAgent),
		fork(getAgentsAutocomplete),
		fork(getAgentsTransactions),
		fork(adjustAgentBalance),
		fork(getAgentInfo),
		fork(updateAgent),
		fork(changePassword),
	]);
}