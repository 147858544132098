import { FC } from 'react';
import { Icon } from 'react-icons-kit';
import { CSSProperties } from 'styled-components';
import { ic_people_alt } from 'react-icons-kit/md/ic_people_alt';

interface IAgentPlayerIconProps {
  style?: CSSProperties;
}
export const AgentPlayerIcon: FC<IAgentPlayerIconProps> = (props) => {
	const { style } = props;
	return (
		<div>
			<Icon icon={ic_people_alt} size={20}   style={{ ...style, paddingBottom: '2px' , zIndex: 1111 }}/>
		</div>
	);
};


