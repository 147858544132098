import React from 'react';

export const PARTNER_TABS = {
	partner        : '1',
	partnerOperator: '2',
	integrators    : '3',
	languages      : '4',
	paymentMethods : '5',
	currency       : '6',
	smsGateways    : '7',
	countries      : '8',
};


export const SUSPEND = {
	preMatch: 1,
	live    : 2,
	all     : 3,
};


export const maskList = [
	{ 'code': '+247 ####' },
	{ 'code': '+290 ####' },
	{ 'code': '+683 ####' },
	{ 'code': '+690 ####' },
	{ 'code': '+500 #####' },
	{ 'code': '+676 #####' },
	{ 'code': '+677 #####' },
	{ 'code': '+678 #####' },
	{ 'code': '+688 2####' },
	{ 'code': '+49 ### ###' },
	{ 'code': '+682 ## ###' },
	{ 'code': '+686 ## ###' },
	{ 'code': '+688 90####' },
	{ 'code': '+95 ### ###' },
	{ 'code': '+298 ### ###' },
	{ 'code': '+376 ### ###' },
	{ 'code': '+387 ## ####' },
	{ 'code': '+508 ## ####' },
	{ 'code': '+597 ### ###' },
	{ 'code': '+672 1## ###' },
	{ 'code': '+672 3## ###' },
	{ 'code': '+681 ## ####' },
	{ 'code': '+685 ## ####' },
	{ 'code': '+687 ## ####' },
	{ 'code': '+850 ### ###' },
	{ 'code': '+230 ### ####' },
	{ 'code': '+239 ## #####' },
	{ 'code': '+245 # ######' },
	{ 'code': '+246 ### ####' },
	{ 'code': '+263 # ######' },
	{ 'code': '+269 ## #####' },
	{ 'code': '+297 ### ####' },
	{ 'code': '+299 ## ## ##' },
	{ 'code': '+354 ### ####' },
	{ 'code': '+387 ## #####' },
	{ 'code': '+49 ### ## ##' },
	{ 'code': '+501 ### ####' },
	{ 'code': '+507 ### ####' },
	{ 'code': '+592 ### ####' },
	{ 'code': '+597 ### ####' },
	{ 'code': '+599 ### ####' },
	{ 'code': '+599 ### ####' },
	{ 'code': '+599 ### ####' },
	{ 'code': '+60 # ### ###' },
	{ 'code': '+62 ## ### ##' },
	{ 'code': '+65 #### ####' },
	{ 'code': '+670 ### ####' },
	{ 'code': '+673 ### ####' },
	{ 'code': '+674 ### ####' },
	{ 'code': '+677 ### ####' },
	{ 'code': '+678 ## #####' },
	{ 'code': '+679 ## #####' },
	{ 'code': '+680 ### ####' },
	{ 'code': '+689 ## ## ##' },
	{ 'code': '+691 ### ####' },
	{ 'code': '+692 ### ####' },
	{ 'code': '+95 # ### ###' },
	{ 'code': '+960 ### ####' },
	{ 'code': '+220 ### ## ##' },
	{ 'code': '+232 ## ######' },
	{ 'code': '+234 ## ### ##' },
	{ 'code': '+237 #### ####' },
	{ 'code': '+238 ### ## ##' },
	{ 'code': '+248 # ### ###' },
	{ 'code': '+252 # ### ###' },
	{ 'code': '+252 # ### ###' },
	{ 'code': '+265 1 ### ###' },
	{ 'code': '+291 # ### ###' },
	{ 'code': '+350 ### #####' },
	{ 'code': '+356 #### ####' },
	{ 'code': '+372 #### ####' },
	{ 'code': '+373 #### ####' },
	{ 'code': '+47 ### ## ###' },
	{ 'code': '+49 ### ## ###' },
	{ 'code': '+504 #### ####' },
	{ 'code': '+505 #### ####' },
	{ 'code': '+506 #### ####' },
	{ 'code': '+52 ## ## ####' },
	{ 'code': '+53 # ### ####' },
	{ 'code': '+599 9### ####' },
	{ 'code': '+60 ## ### ###' },
	{ 'code': '+62 ## ### ###' },
	{ 'code': '+64 ## ### ###' },
	{ 'code': '+66 ## ### ###' },
	{ 'code': '+670 77# #####' },
	{ 'code': '+670 78# #####' },
	{ 'code': '+850 #### ####' },
	{ 'code': '+852 #### ####' },
	{ 'code': '+853 #### ####' },
	{ 'code': '+886 #### ####' },
	{ 'code': '+95 ## ### ###' },
	{ 'code': '+961 # ### ###' },
	{ 'code': '+965 #### ####' },
	{ 'code': '+967 # ### ###' },
	{ 'code': '+973 #### ####' },
	{ 'code': '+974 #### ####' },
	{ 'code': '+975 # ### ###' },
	{ 'code': '+1 ### ### ####' },
	{ 'code': '+1 242 ### ####' },
	{ 'code': '+1 246 ### ####' },
	{ 'code': '+1 264 ### ####' },
	{ 'code': '+1 268 ### ####' },
	{ 'code': '+1 284 ### ####' },
	{ 'code': '+1 340 ### ####' },
	{ 'code': '+1 345 ### ####' },
	{ 'code': '+1 441 ### ####' },
	{ 'code': '+1 473 ### ####' },
	{ 'code': '+1 649 ### ####' },
	{ 'code': '+1 664 ### ####' },
	{ 'code': '+1 670 ### ####' },
	{ 'code': '+1 671 ### ####' },
	{ 'code': '+1 684 ### ####' },
	{ 'code': '+1 721 ### ####' },
	{ 'code': '+1 758 ### ####' },
	{ 'code': '+1 767 ### ####' },
	{ 'code': '+1 784 ### ####' },
	{ 'code': '+1 809 ### ####' },
	{ 'code': '+1 829 ### ####' },
	{ 'code': '+1 849 ### ####' },
	{ 'code': '+1 868 ### ####' },
	{ 'code': '+1 869 ### ####' },
	{ 'code': '+1 876 ### ####' },
	{ 'code': '+216 ## ### ###' },
	{ 'code': '+218 ## ### ###' },
	{ 'code': '+222 ## ## ####' },
	{ 'code': '+223 ## ## ####' },
	{ 'code': '+224 ## ### ###' },
	{ 'code': '+225 ## ### ###' },
	{ 'code': '+226 ## ## ####' },
	{ 'code': '+227 ## ## ####' },
	{ 'code': '+228 ## ### ###' },
	{ 'code': '+229 ## ## ####' },
	{ 'code': '+231 ## ### ###' },
	{ 'code': '+234 ## ### ###' },
	{ 'code': '+236 ## ## ####' },
	{ 'code': '+241 # ## ## ##' },
	{ 'code': '+252 ## ### ###' },
	{ 'code': '+254 ### ######' },
	{ 'code': '+257 ## ## ####' },
	{ 'code': '+258 ## ### ###' },
	{ 'code': '+262 ##### ####' },
	{ 'code': '+262 ##### ####' },
	{ 'code': '+266 # ### ####' },
	{ 'code': '+267 ## ### ###' },
	{ 'code': '+268 ## ## ####' },
	{ 'code': '+27 ## ### ####' },
	{ 'code': '+31 ## ### ####' },
	{ 'code': '+32 ### ### ###' },
	{ 'code': '+33 ### ### ###' },
	{ 'code': '+34 ### ### ###' },
	{ 'code': '+357 ## ### ###' },
	{ 'code': '+36 ### ### ###' },
	{ 'code': '+370 ### ## ###' },
	{ 'code': '+371 ## ### ###' },
	{ 'code': '+374 ## ## ## ##' },
	{ 'code': '+377 ## ### ###' },
	{ 'code': '+382 ## ### ###' },
	{ 'code': '+385 ## ### ###' },
	{ 'code': '+386 ## ### ###' },
	{ 'code': '+389 ## ### ###' },
	{ 'code': '+39 6 698 #####' },
	{ 'code': '+40 ## ### ####' },
	{ 'code': '+41 ## ### ####' },
	{ 'code': '+45 ## ## ## ##' },
	{ 'code': '+46 ## ### ####' },
	{ 'code': '+48 ### ### ###' },
	{ 'code': '+49 ### ## ####' },
	{ 'code': '+502 # ### ####' },
	{ 'code': '+503 ## ## ####' },
	{ 'code': '+509 ## ## ####' },
	{ 'code': '+51 ### ### ###' },
	{ 'code': '+56 # #### ####' },
	{ 'code': '+591 # ### ####' },
	{ 'code': '+593 # ### ####' },
	{ 'code': '+594 ##### ####' },
	{ 'code': '+60 ## ### ####' },
	{ 'code': '+60 ### ### ###' },
	{ 'code': '+61 # #### ####' },
	{ 'code': '+62 ## ### ####' },
	{ 'code': '+62 8## ### ###' },
	{ 'code': '+64 ### ### ###' },
	{ 'code': '+66 ## ### ####' },
	{ 'code': '+675 ### ## ###' },
	{ 'code': '+81 ### ### ###' },
	{ 'code': '+82 ## ### ####' },
	{ 'code': '+84 ## #### ###' },
	{ 'code': '+850 ## ### ###' },
	{ 'code': '+855 ## ### ###' },
	{ 'code': '+856 ## ### ###' },
	{ 'code': '+880 ## ### ###' },
	{ 'code': '+93 ## ### ####' },
	{ 'code': '+94 ## ### ####' },
	{ 'code': '+961 ## ### ###' },
	{ 'code': '+966 # ### ####' },
	{ 'code': '+967 ## ### ###' },
	{ 'code': '+968 ## ### ###' },
	{ 'code': '+971 # ### ####' },
	{ 'code': '+972 # ### ####' },
	{ 'code': '+975 17 ### ###' },
	{ 'code': '+976 ## ## ####' },
	{ 'code': '+977 ## ### ###' },
	{ 'code': '+993 # ### ####' },
	{ 'code': '+20 ### ### ####' },
	{ 'code': '+211 ## ### ####' },
	{ 'code': '+212 ## #### ###' },
	{ 'code': '+213 ## ### ####' },
	{ 'code': '+218 21 ### ####' },
	{ 'code': '+221 ## ### ####' },
	{ 'code': '+233 ### ### ###' },
	{ 'code': '+235 ## ## ## ##' },
	{ 'code': '+240 ## ### ####' },
	{ 'code': '+242 ## ### ####' },
	{ 'code': '+243 ### ### ###' },
	{ 'code': '+244 ### ### ###' },
	{ 'code': '+249 ## ### ####' },
	{ 'code': '+250 ### ### ###' },
	{ 'code': '+251 ## ### ####' },
	{ 'code': '+253 ## ## ## ##' },
	{ 'code': '+255 ## ### ####' },
	{ 'code': '+256 ### ### ###' },
	{ 'code': '+260 ## ### ####' },
	{ 'code': '+261 ## ## #####' },
	{ 'code': '+264 ## ### ####' },
	{ 'code': '+265 # #### ####' },
	{ 'code': '+30 ### ### ####' },
	{ 'code': '+351 ## ### ####' },
	{ 'code': '+352 ### ### ###' },
	{ 'code': '+353 ### ### ###' },
	{ 'code': '+355 ### ### ###' },
	{ 'code': '+359 ### ### ###' },
	{ 'code': '+377 ### ### ###' },
	{ 'code': '+378 #### ######' },
	{ 'code': '+381 ## ### ####' },
	{ 'code': '+39 ### #### ###' },
	{ 'code': '+420 ### ### ###' },
	{ 'code': '+421 ### ### ###' },
	{ 'code': '+43 ### ### ####' },
	{ 'code': '+44 ## #### ####' },
	{ 'code': '+49 ### ### ####' },
	{ 'code': '+52 ### ### ####' },
	{ 'code': '+54 ### ### ####' },
	{ 'code': '+55 ## #### ####' },
	{ 'code': '+55 ## 7### ####' },
	{ 'code': '+57 ### ### ####' },
	{ 'code': '+58 ### ### ####' },
	{ 'code': '+590 ### ### ###' },
	{ 'code': '+593 ## ### ####' },
	{ 'code': '+595 ### ### ###' },
	{ 'code': '+598 # ### ## ##' },
	{ 'code': '+62 8## ### ####' },
	{ 'code': '+63 ### ### ####' },
	{ 'code': '+64 ### ### ####' },
	{ 'code': '+7 ### ### ## ##' },
	{ 'code': '+7 6## ### ## ##' },
	{ 'code': '+7 7## ### ## ##' },
	{ 'code': '+81 ## #### ####' },
	{ 'code': '+84 ### #### ###' },
	{ 'code': '+86 ### #### ###' },
	{ 'code': '+886 # #### ####' },
	{ 'code': '+90 ### ### ####' },
	{ 'code': '+91 #### ### ###' },
	{ 'code': '+92 ### ### ####' },
	{ 'code': '+962 # #### ####' },
	{ 'code': '+963 ## #### ###' },
	{ 'code': '+966 5 #### ####' },
	{ 'code': '+967 ### ### ###' },
	{ 'code': '+970 ## ### ####' },
	{ 'code': '+971 5# ### ####' },
	{ 'code': '+972 5# ### ####' },
	{ 'code': '+98 ### ### ####' },
	{ 'code': '+992 ## ### ####' },
	{ 'code': '+995 ### ### ###' },
	{ 'code': '+996 ### ### ###' },
	{ 'code': '+998 ## ### ####' },
	{ 'code': '+234 ### ### ####' },
	{ 'code': '+234 ### ### ####' },
	{ 'code': '+375 ## ### ## ##' },
	{ 'code': '+380 ## ### ## ##' },
	{ 'code': '+423 ### ### ####' },
	{ 'code': '+49 #### ### ####' },
	{ 'code': '+55 ## 9#### ####' },
	{ 'code': '+596 ### ## ## ##' },
	{ 'code': '+850 ### #### ###' },
	{ 'code': '+850 191 ### ####' },
	{ 'code': '+856 20## ### ###' },
	{ 'code': '+86 ### #### ####' },
	{ 'code': '+964 ### ### ####' },
	{ 'code': '+994 ## ### ## ##' },
	{ 'code': '+358 ### ### ## ##' },
	{ 'code': '+62 8## ### ## ###' },
	{ 'code': '+86 ## ##### #####' },
	{ 'code': '+850 #### #############' },
];
const phonePrefixesByFlags = [
	{
		'name'     : 'Afghanistan',
		'dial_code': '+93',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/2a/Afghanistan_Flag.svg',
		'code'     : 'AF',
	},
	{
		'name'     : 'Aland Islands',
		'dial_code': '+358',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg',
		'code'     : 'AX',
	},
	{
		'name'     : 'Albania',
		'dial_code': '+355',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg',
		'code'     : 'AL',
	},
	{
		'name'     : 'Algeria',
		'dial_code': '+213',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg',
		'code'     : 'DZ',
	},
	{
		'name'     : 'AmericanSamoa',
		'dial_code': '+1684',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg',
		'code'     : 'AS',
	},
	{
		'name'     : 'Andorra',
		'dial_code': '+376',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg',
		'code'     : 'AD',
	},
	{
		'name'     : 'Angola',
		'dial_code': '+244',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg',
		'code'     : 'AO',
	},
	{
		'name'     : 'Anguilla',
		'dial_code': '+1264',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg',
		'code'     : 'AI',
	},
	{
		'name'     : 'Antarctica',
		'dial_code': '+672',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Proposed_flag_of_Antarctica_%28Graham_Bartram%29.svg',
		'code'     : 'AQ',
	},
	{
		'name'     : 'Antigua and Barbuda',
		'dial_code': '+1268',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg',
		'code'     : 'AG',
	},
	{
		'name'     : 'Argentina',
		'dial_code': '+54',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg',
		'code'     : 'AR',
	},
	{
		'name'     : 'Armenia',
		'dial_code': '+374',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg',
		'code'     : 'AM',
	},
	{
		'name'     : 'Aruba',
		'dial_code': '+297',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg',
		'code'     : 'AW',
	},
	{
		'name'     : 'Australia',
		'dial_code': '+61',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_(converted).svg',
		'code'     : 'AU',
	},
	{
		'name'     : 'Austria',
		'dial_code': '+43',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg',
		'code'     : 'AT',
	},
	{
		'name'     : 'Azerbaijan',
		'dial_code': '+994',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg',
		'code'     : 'AZ',
	},
	{
		'name'     : 'Bahamas',
		'dial_code': '+1242',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg',
		'code'     : 'BS',
	},
	{
		'name'     : 'Bahrain',
		'dial_code': '+973',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg',
		'code'     : 'BH',
	},
	{
		'name'     : 'Bangladesh',
		'dial_code': '+880',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg',
		'code'     : 'BD',
	},
	{
		'name'     : 'Barbados',
		'dial_code': '+1246',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg',
		'code'     : 'BB',
	},
	{
		'name'     : 'Belarus',
		'dial_code': '+375',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg',
		'code'     : 'BY',
	},
	{
		'name'     : 'Belgium',
		'dial_code': '+32',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg',
		'code'     : 'BE',
	},
	{
		'name'     : 'Belize',
		'dial_code': '+501',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg',
		'code'     : 'BZ',
	},
	{
		'name'     : 'Benin',
		'dial_code': '+229',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg',
		'code'     : 'BJ',
	},
	{
		'name'     : 'Bermuda',
		'dial_code': '+1441',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg',
		'code'     : 'BM',
	},
	{
		'name'     : 'Bhutan',
		'dial_code': '+975',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg',
		'code'     : 'BT',
	},
	{
		'name'     : 'Bolivia, Plurinational State of',
		'dial_code': '+591',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Bolivia_Flag.svg',
		'code'     : 'BO',
	},
	{
		'name'     : 'Bosnia and Herzegovina',
		'dial_code': '+387',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg',
		'code'     : 'BA',
	},
	{
		'name'     : 'Botswana',
		'dial_code': '+267',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg',
		'code'     : 'BW',
	},
	{
		'name'     : 'Brazil',
		'dial_code': '+55',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg',
		'code'     : 'BR',
	},
	{
		'name'     : 'British Indian Ocean Territory',
		'dial_code': '+246',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg',
		'code'     : 'IO',
	},
	{
		'name'     : 'Brunei Darussalam',
		'dial_code': '+673',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg',
		'code'     : 'BN',
	},
	{
		'name'     : 'Bulgaria',
		'dial_code': '+359',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg',
		'code'     : 'BG',
	},
	{
		'name'     : 'Burkina Faso',
		'dial_code': '+226',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg',
		'code'     : 'BF',
	},
	{
		'name'     : 'Burundi',
		'dial_code': '+257',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg',
		'code'     : 'BI',
	},
	{
		'name'     : 'Cambodia',
		'dial_code': '+855',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg',
		'code'     : 'KH',
	},
	{
		'name'     : 'Cameroon',
		'dial_code': '+237',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg',
		'code'     : 'CM',
	},
	{
		'name'     : 'Canada',
		'dial_code': '+1',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_(Pantone).svg',
		'code'     : 'CA',
	},
	{
		'name'     : 'Cape Verde',
		'dial_code': '+238',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg',
		'code'     : 'CV',
	},
	{
		'name'     : 'Cayman Islands',
		'dial_code': '+345',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg',
		'code'     : 'KY',
	},
	{
		'name'     : 'Central African Republic',
		'dial_code': '+236',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg',
		'code'     : 'CF',
	},
	{
		'name'     : 'Chad',
		'dial_code': '+235',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg',
		'code'     : 'TD',
	},
	{
		'name'     : 'Chile',
		'dial_code': '+56',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg',
		'code'     : 'CL',
	},
	{
		'name'     : 'China',
		'dial_code': '+86',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
		'code'     : 'CN',
	},
	{
		'name'     : 'Christmas Island',
		'dial_code': '+61',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg',
		'code'     : 'CX',
	},
	{
		'name'     : 'Cocos (Keeling) Islands',
		'dial_code': '+61',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_(Keeling)_Islands.svg',
		'code'     : 'CC',
	},
	{
		'name'     : 'Colombia',
		'dial_code': '+57',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg',
		'code'     : 'CO',
	},
	{
		'name'     : 'Comoros',
		'dial_code': '+269',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/df/Flag_of_the_Comoros_(3-2).svg',
		'code'     : 'KM',
	},
	{
		'name'     : 'Congo',
		'dial_code': '+242',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg',
		'code'     : 'CG',
	},
	{
		'name'     : 'Congo, The Democratic Republic of the Congo',
		'dial_code': '+243',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_the_Democratic_Republic_of_the_Congo_%282-3%29.svg',
		'code'     : 'CD',
	},
	{
		'name'     : 'Cook Islands',
		'dial_code': '+682',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg',
		'code'     : 'CK',
	},
	{
		'name'     : 'Costa Rica',
		'dial_code': '+506',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_(state).svg',
		'code'     : 'CR',
	},
	{
		'name'     : 'Cote d\'Ivoire',
		'dial_code': '+225',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Côte_d%27Ivoire.svg',
		'code'     : 'CI',
	},
	{
		'name'     : 'Croatia',
		'dial_code': '+385',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg',
		'code'     : 'HR',
	},
	{
		'name'     : 'Cuba',
		'dial_code': '+53',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg',
		'code'     : 'CU',
	},
	{
		'name'     : 'Cyprus',
		'dial_code': '+357',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg',
		'code'     : 'CY',
	},
	{
		'name'     : 'Czech Republic',
		'dial_code': '+420',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg',
		'code'     : 'CZ',
	},
	{
		'name'     : 'Denmark',
		'dial_code': '+45',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',
		'code'     : 'DK',
	},
	{
		'name'     : 'Djibouti',
		'dial_code': '+253',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg',
		'code'     : 'DJ',
	},
	{
		'name'     : 'Dominica',
		'dial_code': '+1767',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg',
		'code'     : 'DM',
	},
	{
		'name'     : 'Dominican Republic',
		'dial_code': '+1849',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg',
		'code'     : 'DO',
	},
	{
		'name'     : 'Ecuador',
		'dial_code': '+593',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg',
		'code'     : 'EC',
	},
	{
		'name'     : 'Egypt',
		'dial_code': '+20',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg',
		'code'     : 'EG',
	},
	{
		'name'     : 'El Salvador',
		'dial_code': '+503',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg',
		'code'     : 'SV',
	},
	{
		'name'     : 'Equatorial Guinea',
		'dial_code': '+240',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg',
		'code'     : 'GQ',
	},
	{
		'name'     : 'Eritrea',
		'dial_code': '+291',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg',
		'code'     : 'ER',
	},
	{
		'name'     : 'Estonia',
		'dial_code': '+372',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg',
		'code'     : 'EE',
	},
	{
		'name'     : 'Ethiopia',
		'dial_code': '+251',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg',
		'code'     : 'ET',
	},
	{
		'name'     : 'Falkland Islands (Malvinas)',
		'dial_code': '+500',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg',
		'code'     : 'FK',
	},
	{
		'name'     : 'Faroe Islands',
		'dial_code': '+298',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg',
		'code'     : 'FO',
	},
	{
		'name'     : 'Fiji',
		'dial_code': '+679',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg',
		'code'     : 'FJ',
	},
	{
		'name'     : 'Finland',
		'dial_code': '+358',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
		'code'     : 'FI',
	},
	{
		'name'     : 'France',
		'dial_code': '+33',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
		'code'     : 'FR',
	},
	{
		'name'     : 'French Guiana',
		'dial_code': '+594',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_France_%28Pantone%29.svg',
		'code'     : 'GF',
	},
	{
		'name'     : 'French Polynesia',
		'dial_code': '+689',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg',
		'code'     : 'PF',
	},
	{
		'name'     : 'Gabon',
		'dial_code': '+241',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg',
		'code'     : 'GA',
	},
	{
		'name'     : 'Gambia',
		'dial_code': '+220',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg',
		'code'     : 'GM',
	},
	{
		'name'     : 'Georgia',
		'dial_code': '+995',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg',
		'code'     : 'GE',
	},
	{
		'name'     : 'Germany',
		'dial_code': '+49',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg',
		'code'     : 'DE',
	},
	{
		'name'     : 'Ghana',
		'dial_code': '+233',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg',
		'code'     : 'GH',
	},
	{
		'name'     : 'Gibraltar',
		'dial_code': '+350',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg',
		'code'     : 'GI',
	},
	{
		'name'     : 'Greece',
		'dial_code': '+30',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg',
		'code'     : 'GR',
	},
	{
		'name'     : 'Greenland',
		'dial_code': '+299',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg',
		'code'     : 'GL',
	},
	{
		'name'     : 'Grenada',
		'dial_code': '+1473',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg',
		'code'     : 'GD',
	},
	{
		'name'     : 'Guadeloupe',
		'dial_code': '+590',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_France_%287x10%29.svg',
		'code'     : 'GP',
	},
	{
		'name'     : 'Guam',
		'dial_code': '+1671',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg',
		'code'     : 'GU',
	},
	{
		'name'     : 'Guatemala',
		'dial_code': '+502',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg',
		'code'     : 'GT',
	},
	{
		'name'     : 'Guernsey',
		'dial_code': '+44',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg',
		'code'     : 'GG',
	},
	{
		'name'     : 'Guinea',
		'dial_code': '+224',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg',
		'code'     : 'GN',
	},
	{
		'name'     : 'Guinea-Bissau',
		'dial_code': '+245',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg',
		'code'     : 'GW',
	},
	{
		'name'     : 'Guyana',
		'dial_code': '+595',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg',
		'code'     : 'GY',
	},
	{
		'name'     : 'Haiti',
		'dial_code': '+509',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg',
		'code'     : 'HT',
	},
	{
		'name'     : 'Holy See (Vatican City State)',
		'dial_code': '+379',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg',
		'code'     : 'VA',
	},
	{
		'name'     : 'Honduras',
		'dial_code': '+504',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg',
		'code'     : 'HN',
	},
	{
		'name'     : 'Hong Kong',
		'dial_code': '+852',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg',
		'code'     : 'HK',
	},
	{
		'name'     : 'Hungary',
		'dial_code': '+36',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg',
		'code'     : 'HU',
	},
	{
		'name'     : 'Iceland',
		'dial_code': '+354',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg',
		'code'     : 'IS',
	},
	{
		'name'     : 'India',
		'dial_code': '+91',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg',
		'code'     : 'IN',
	},
	{
		'name'     : 'Indonesia',
		'dial_code': '+62',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg',
		'code'     : 'ID',
	},
	{
		'name'     : 'Iran, Islamic Republic of Persian Gulf',
		'dial_code': '+98',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg',
		'code'     : 'IR',
	},
	{
		'name'     : 'Iraq',
		'dial_code': '+964',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg',
		'code'     : 'IQ',
	},
	{
		'name'     : 'Ireland',
		'dial_code': '+353',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Republic_of_Ireland_Flag.svg',
		'code'     : 'IE',
	},
	{
		'name'     : 'Isle of Man',
		'dial_code': '+44',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg',
		'code'     : 'IM',
	},
	{
		'name'     : 'Israel',
		'dial_code': '+972',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg',
		'code'     : 'IL',
	},
	{
		'name'     : 'Italy',
		'dial_code': '+39',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg',
		'code'     : 'IT',
	},
	{
		'name'     : 'Jamaica',
		'dial_code': '+1876',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg',
		'code'     : 'JM',
	},
	{
		'name'     : 'Japan',
		'dial_code': '+81',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Japan%28bordered%29.svg',
		'code'     : 'JP',
	},
	{
		'name'     : 'Jersey',
		'dial_code': '+44',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg',
		'code'     : 'JE',
	},
	{
		'name'     : 'Jordan',
		'dial_code': '+962',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg',
		'code'     : 'JO',
	},
	{
		'name'     : 'Kazakhstan',
		'dial_code': '+77',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg',
		'code'     : 'KZ',
	},
	{
		'name'     : 'Kenya',
		'dial_code': '+254',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg',
		'code'     : 'KE',
	},
	{
		'name'     : 'Kiribati',
		'dial_code': '+686',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg',
		'code'     : 'KI',
	},
	{
		'name'     : 'Korea, Democratic People\'s Republic of Korea',
		'dial_code': '+850',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg',
		'code'     : 'KP',
	},
	{
		'name'     : 'Korea, Republic of South Korea',
		'dial_code': '+82',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg',
		'code'     : 'KR',
	},
	{
		'name'     : 'Kuwait',
		'dial_code': '+965',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg',
		'code'     : 'KW',
	},
	{
		'name'     : 'Kyrgyzstan',
		'dial_code': '+996',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg',
		'code'     : 'KG',
	},
	{
		'name'     : 'Laos',
		'dial_code': '+856',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg',
		'code'     : 'LA',
	},
	{
		'name'     : 'Latvia',
		'dial_code': '+371',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg',
		'code'     : 'LV',
	},
	{
		'name'     : 'Lebanon',
		'dial_code': '+961',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg',
		'code'     : 'LB',
	},
	{
		'name'     : 'Lesotho',
		'dial_code': '+266',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg',
		'code'     : 'LS',
	},
	{
		'name'     : 'Liberia',
		'dial_code': '+231',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg',
		'code'     : 'LR',
	},
	{
		'name'     : 'Libyan Arab Jamahiriya',
		'dial_code': '+218',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg',
		'code'     : 'LY',
	},
	{
		'name'     : 'Liechtenstein',
		'dial_code': '+423',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg',
		'code'     : 'LI',
	},
	{
		'name'     : 'Lithuania',
		'dial_code': '+370',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg',
		'code'     : 'LT',
	},
	{
		'name'     : 'Luxembourg',
		'dial_code': '+352',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg',
		'code'     : 'LU',
	},
	{
		'name'     : 'Macao',
		'dial_code': '+853',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg',
		'code'     : 'MO',
	},
	{
		'name'     : 'Macedonia',
		'dial_code': '+389',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg',
		'code'     : 'MK',
	},
	{
		'name'     : 'Madagascar',
		'dial_code': '+261',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg',
		'code'     : 'MG',
	},
	{
		'name'     : 'Malawi',
		'dial_code': '+265',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg',
		'code'     : 'MW',
	},
	{
		'name'     : 'Malaysia',
		'dial_code': '+60',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg',
		'code'     : 'MY',
	},
	{
		'name'     : 'Maldives',
		'dial_code': '+960',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg',
		'code'     : 'MV',
	},
	{
		'name'     : 'Mali',
		'dial_code': '+223',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg',
		'code'     : 'ML',
	},
	{
		'name'     : 'Malta',
		'dial_code': '+356',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg',
		'code'     : 'MT',
	},
	{
		'name'     : 'Marshall Islands',
		'dial_code': '+692',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg',
		'code'     : 'MH',
	},
	{
		'name'     : 'Martinique',
		'dial_code': '+596',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_the_Territorial_Collectivity_of_Martinique.svg',
		'code'     : 'MQ',
	},
	{
		'name'     : 'Mauritania',
		'dial_code': '+222',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg',
		'code'     : 'MR',
	},
	{
		'name'     : 'Mauritius',
		'dial_code': '+230',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg',
		'code'     : 'MU',
	},
	{
		'name'     : 'Mayotte',
		'dial_code': '+262',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg',
		'code'     : 'YT',
	},
	{
		'name'     : 'Mexico',
		'dial_code': '+52',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg',
		'code'     : 'MX',
	},
	{
		'name'     : 'Micronesia, Federated States of Micronesia',
		'dial_code': '+691',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg',
		'code'     : 'FM',
	},
	{
		'name'     : 'Moldova',
		'dial_code': '+373',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg',
		'code'     : 'MD',
	},
	{
		'name'     : 'Monaco',
		'dial_code': '+377',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg',
		'code'     : 'MC',
	},
	{
		'name'     : 'Mongolia',
		'dial_code': '+976',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg',
		'code'     : 'MN',
	},
	{
		'name'     : 'Montenegro',
		'dial_code': '+382',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg',
		'code'     : 'ME',
	},
	{
		'name'     : 'Montserrat',
		'dial_code': '+1664',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg',
		'code'     : 'MS',
	},
	{
		'name'     : 'Morocco',
		'dial_code': '+212',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg',
		'code'     : 'MA',
	},
	{
		'name'     : 'Mozambique',
		'dial_code': '+258',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg',
		'code'     : 'MZ',
	},
	{
		'name'     : 'Myanmar',
		'dial_code': '+95',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg',
		'code'     : 'MM',
	},
	{
		'name'     : 'Namibia',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg',
		'dial_code': '+264',
		'code'     : 'NA',
	},
	{
		'name'     : 'Nauru',
		'dial_code': '+674',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg',
		'code'     : 'NR',
	},
	{
		'name'     : 'Nepal',
		'dial_code': '+977',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg',
		'code'     : 'NP',
	},
	{
		'name'     : 'Netherlands',
		'dial_code': '+31',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
		'code'     : 'NL',
	},
	{
		'name'     : 'Netherlands Antilles',
		'dial_code': '+599',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/eb/Flag_of_the_Netherlands_Antilles_(1959%E2%80%931986).svg',
		'code'     : 'AN',
	},
	{
		'name'     : 'New Caledonia',
		'dial_code': '+687',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_FLNKS.svg',
		'code'     : 'NC',
	},
	{
		'name'     : 'New Zealand',
		'dial_code': '+64',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg',
		'code'     : 'NZ',
	},
	{
		'name'     : 'Nicaragua',
		'dial_code': '+505',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg',
		'code'     : 'NI',
	},
	{
		'name'     : 'Niger',
		'dial_code': '+227',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg',
		'code'     : 'NE',
	},
	{
		'name'     : 'Nigeria',
		'dial_code': '+234',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg',
		'code'     : 'NG',
	},
	{
		'name'     : 'Niue',
		'dial_code': '+683',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg',
		'code'     : 'NU',
	},
	{
		'name'     : 'Norfolk Island',
		'dial_code': '+672',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg',
		'code'     : 'NF',
	},
	{
		'name'     : 'Northern Mariana Islands',
		'dial_code': '+1670',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg',
		'code'     : 'MP',
	},
	{
		'name'     : 'Norway',
		'dial_code': '+47',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
		'code'     : 'NO',
	},
	{
		'name'     : 'Oman',
		'dial_code': '+968',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg',
		'code'     : 'OM',
	},
	{
		'name'     : 'Pakistan',
		'dial_code': '+92',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg',
		'code'     : 'PK',
	},
	{
		'name'     : 'Palau',
		'dial_code': '+680',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg',
		'code'     : 'PW',
	},
	{
		'name'     : 'Palestinian Territory, Occupied',
		'dial_code': '+970',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Palestine_Flag.svg',
		'code'     : 'PS',
	},
	{
		'name'     : 'Panama',
		'dial_code': '+507',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg',
		'code'     : 'PA',
	},
	{
		'name'     : 'Papua New Guinea',
		'dial_code': '+675',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg',
		'code'     : 'PG',
	},
	{
		'name'     : 'Paraguay',
		'dial_code': '+595',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg',
		'code'     : 'PY',
	},
	{
		'name'     : 'Peru',
		'dial_code': '+51',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg',
		'code'     : 'PE',
	},
	{
		'name'     : 'Philippines',
		'dial_code': '+63',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg',
		'code'     : 'PH',
	},
	{
		'name'     : 'Pitcairn',
		'dial_code': '+872',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg',
		'code'     : 'PN',
	},
	{
		'name'     : 'Poland',
		'dial_code': '+48',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg',
		'code'     : 'PL',
	},
	{
		'name'     : 'Portugal',
		'dial_code': '+351',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg',
		'code'     : 'PT',
	},
	{
		'name'     : 'Puerto Rico',
		'dial_code': '+1939',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg',
		'code'     : 'PR',
	},
	{
		'name'     : 'Qatar',
		'dial_code': '+974',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg',
		'code'     : 'QA',
	},
	{
		'name'     : 'Romania',
		'dial_code': '+40',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg',
		'code'     : 'RO',
	},
	{
		'name'     : 'Russia',
		'dial_code': '+7',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg',
		'code'     : 'RU',
	},
	{
		'name'     : 'Rwanda',
		'dial_code': '+250',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg',
		'code'     : 'RW',
	},
	{
		'name'     : 'Reunion',
		'dial_code': '+262',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Proposed_flag_of_R%C3%A9union_%28VAR%29.svg',
		'code'     : 'RE',
	},
	{
		'name'     : 'Saint Barthelemy',
		'dial_code': '+590',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/03/Saint-Barthelémy_Icône.svg',
		'code'     : 'BL',
	},
	{
		'name'     : 'Saint Helena, Ascension and Tristan Da Cunha',
		'dial_code': '+290',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Saint_Helena.svg',
		'code'     : 'SH',
	},
	{
		'name'     : 'Saint Kitts and Nevis',
		'dial_code': '+1869',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg',
		'code'     : 'KN',
	},
	{
		'name'     : 'Saint Lucia',
		'dial_code': '+1758',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg',
		'code'     : 'LC',
	},
	{
		'name'     : 'Saint Martin',
		'dial_code': '+590',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Saint-Martin_%28fictional%29.svg',
		'code'     : 'MF',
	},
	{
		'name'     : 'Saint Pierre and Miquelon',
		'dial_code': '+508',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg',
		'code'     : 'PM',
	},
	{
		'name'     : 'Saint Vincent and the Grenadines',
		'dial_code': '+1784',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg',
		'code'     : 'VC',
	},
	{
		'name'     : 'Samoa',
		'dial_code': '+685',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg',
		'code'     : 'WS',
	},
	{
		'name'     : 'San Marino',
		'dial_code': '+378',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg',
		'code'     : 'SM',
	},
	{
		'name'     : 'Sao Tome and Principe',
		'dial_code': '+239',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe.svg',
		'code'     : 'ST',
	},
	{
		'name'     : 'Saudi Arabia',
		'dial_code': '+966',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg',
		'code'     : 'SA',
	},
	{
		'name'     : 'Senegal',
		'dial_code': '+221',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg',
		'code'     : 'SN',
	},
	{
		'name'     : 'Serbia',
		'dial_code': '+381',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg',
		'code'     : 'RS',
	},
	{
		'name'     : 'Seychelles',
		'dial_code': '+248',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg',
		'code'     : 'SC',
	},
	{
		'name'     : 'Sierra Leone',
		'dial_code': '+232',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg',
		'code'     : 'SL',
	},
	{
		'name'     : 'Singapore',
		'dial_code': '+65',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg',
		'code'     : 'SG',
	},
	{
		'name'     : 'Slovakia',
		'dial_code': '+421',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg',
		'code'     : 'SK',
	},
	{
		'name'     : 'Slovenia',
		'dial_code': '+386',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg',
		'code'     : 'SI',
	},
	{
		'name'     : 'Solomon Islands',
		'dial_code': '+677',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg',
		'code'     : 'SB',
	},
	{
		'name'     : 'Somalia',
		'dial_code': '+252',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg',
		'code'     : 'SO',
	},
	{
		'name'     : 'South Africa',
		'dial_code': '+27',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg',
		'code'     : 'ZA',
	},
	{
		'name'     : 'South Sudan',
		'dial_code': '+211',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg',
		'code'     : 'SS',
	},
	{
		'name'     : 'South Georgia and the South Sandwich Islands',
		'dial_code': '+500',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg',
		'code'     : 'GS',
	},
	{
		'name'     : 'Spain',
		'dial_code': '+34',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg',
		'code'     : 'ES',
	},
	{
		'name'     : 'Sri Lanka',
		'dial_code': '+94',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg',
		'code'     : 'LK',
	},
	{
		'name'     : 'Sudan',
		'dial_code': '+249',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg',
		'code'     : 'SD',
	},
	{
		'name'     : 'Suriname',
		'dial_code': '+597',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg',
		'code'     : 'SR',
	},
	{
		'name'     : 'Svalbard and Jan Mayen',
		'dial_code': '+47',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
		'code'     : 'SJ',
	},
	{
		'name'     : 'Swaziland',
		'dial_code': '+268',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg',
		'code'     : 'SZ',
	},
	{
		'name'     : 'Sweden',
		'dial_code': '+46',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg',
		'code'     : 'SE',
	},
	{
		'name'     : 'Switzerland',
		'dial_code': '+41',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg',
		'code'     : 'CH',
	},
	{
		'name'     : 'Syrian Arab Republic',
		'dial_code': '+963',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg',
		'code'     : 'SY',
	},
	{
		'name'     : 'Taiwan',
		'dial_code': '+886',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg',
		'code'     : 'TW',
	},
	{
		'name'     : 'Tajikistan',
		'dial_code': '+992',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg',
		'code'     : 'TJ',
	},
	{
		'name'     : 'Tanzania, United Republic of Tanzania',
		'dial_code': '+255',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg',
		'code'     : 'TZ',
	},
	{
		'name'     : 'Thailand',
		'dial_code': '+66',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg',
		'code'     : 'TH',
	},
	{
		'name'     : 'Timor-Leste',
		'dial_code': '+670',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg',
		'code'     : 'TL',
	},
	{
		'name'     : 'Togo',
		'dial_code': '+228',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg',
		'code'     : 'TG',
	},
	{
		'name'     : 'Tokelau',
		'dial_code': '+690',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg',
		'code'     : 'TK',
	},
	{
		'name'     : 'Tonga',
		'dial_code': '+676',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg',
		'code'     : 'TO',
	},
	{
		'name'     : 'Trinidad and Tobago',
		'dial_code': '+1868',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg',
		'code'     : 'TT',
	},
	{
		'name'     : 'Tunisia',
		'dial_code': '+216',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg',
		'code'     : 'TN',
	},
	{
		'name'     : 'Turkey',
		'dial_code': '+90',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg',
		'code'     : 'TR',
	},
	{
		'name'     : 'Turkmenistan',
		'dial_code': '+993',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg',
		'code'     : 'TM',
	},
	{
		'name'     : 'Turks and Caicos Islands',
		'dial_code': '+1649',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg',
		'code'     : 'TC',
	},
	{
		'name'     : 'Tuvalu',
		'dial_code': '+688',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg',
		'code'     : 'TV',
	},
	{
		'name'     : 'Uganda',
		'dial_code': '+256',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg',
		'code'     : 'UG',
	},
	{
		'name'     : 'Ukraine',
		'dial_code': '+380',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg',
		'code'     : 'UA',
	},
	{
		'name'     : 'United Arab Emirates',
		'dial_code': '+971',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg',
		'code'     : 'AE',
	},
	{
		'name'     : 'United Kingdom',
		'dial_code': '+44',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg',
		'code'     : 'GB',
	},
	{
		'name'     : 'United States',
		'dial_code': '+1',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg',
		'code'     : 'US',
	},
	{
		'name'     : 'Uruguay',
		'dial_code': '+598',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg',
		'code'     : 'UY',
	},
	{
		'name'     : 'Uzbekistan',
		'dial_code': '+998',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg',
		'code'     : 'UZ',
	},
	{
		'name'     : 'Vanuatu',
		'dial_code': '+678',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Vanuatu_(official).svg',
		'code'     : 'VU',
	},
	{
		'name'     : 'Venezuela, Bolivarian Republic of Venezuela',
		'dial_code': '+58',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_(state).svg',
		'code'     : 'VE',
	},
	{
		'name'     : 'Vietnam',
		'dial_code': '+84',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg',
		'code'     : 'VN',
	},
	{
		'name'     : 'Virgin Islands, British',
		'dial_code': '+1284',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg',
		'code'     : 'VG',
	},
	{
		'name'     : 'Virgin Islands, U.S.',
		'dial_code': '+1340',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands.svg',
		'code'     : 'VI',
	},
	{
		'name'     : 'Wallis and Futuna',
		'dial_code': '+681',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/6c/Flag_of_Wallis_and_Futuna_%282004_World_Factbook%29.svg',
		'code'     : 'WF',
	},
	{
		'name'     : 'Yemen',
		'dial_code': '+967',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg',
		'code'     : 'YE',
	},
	{
		'name'     : 'Zambia',
		'dial_code': '+260',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/d/dc/Flag_of_Zambia_%281964%E2%80%931996%29.svg',
		'code'     : 'ZM',
	},
	{
		'name'     : 'Zimbabwe',
		'dial_code': '+263',
		'emoji'    : 'https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg',
		'code'     : 'ZW',
	},
];

export const phonePrefixesFlags = phonePrefixesByFlags.map(item => ({
	id  : item.dial_code,
	name: (
		<div key={item.code} style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
			<img style={{ maxWidth: '18px' }} src={item.emoji} alt={item.name} />
			<span>{item.name}</span>
		</div>
	),
}));

export const safeDecodeURIComponent = (value) => {
	try {
		return decodeURIComponent(value);
	} catch (e) {
		return value;
	}
};

// export const phonePrefixes = maskList.map(item => {
//     const name  = item.code.split('#')[0].trim();
//     const id    = name.replace(/ /g, '');
//     return { id, name };
// });

export const SUSPEND_OPTIONS = [
	{
		value: SUSPEND.all,
		label: 'All',
	},
	{
		value: SUSPEND.live,
		label: 'InPlay',
	},
	{
		value: SUSPEND.preMatch,
		label: 'PreMatch',
	},
];