import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Layout } from 'antd';
import { API_KEY } from '../../helpers/permissions/constants';

import appActions from '../../redux/app/actions';
import notificationsActions from '../../redux/notifications/actions';
import { checkNotificationTimeout } from '../../config';
import { NOTIFICATION_TYPES } from '../../constants/notifications';

import NotificationBadge from '../../components/connected/NotificationBadge';
import TopbarSearch from '../../components/connected/TopbarSearch';

import TopbarUser from '../Profile/TopbarUser';
import LanguageSwitcher from './LanguageSwitcher';
import PartnerSwitcher from './PartnerSwitcher/PartnerSwitcher';
import WebsiteSwitcher from './WebsiteSwitcher';

import { canRequest, hasAgentRole, isSuperAdmin } from '../../helpers/permissions/utils';
import { MenuOutlined } from '@ant-design/icons';
import { TopBarContainer } from './topbar.style';
import Intl from '../../components/utility/intlMessages';
import { getUser } from '../../helpers/utility';
// const { Header } = Layout;

const lang = {
	myBalance: <Intl id="agent.header.title.myBalance" />,
};
class Topbar extends Component {

	static propTypes = {
		collapsed      : PropTypes.bool.isRequired,
		openDrawer     : PropTypes.bool.isRequired,
		partnerLoading : PropTypes.bool.isRequired,
		isAgent        : PropTypes.bool.isRequired,
		locale         : PropTypes.string.isRequired,
		customizedTheme: PropTypes.object.isRequired,
		userPermissions: PropTypes.array.isRequired,
		toggleCollapsed: PropTypes.func.isRequired,
		countReload    : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.timeInterval = null;
	}

	componentDidMount() {
		const { countReload, partnerLoading, userPermissions } = this.props;
		const canReq = canRequest(userPermissions, API_KEY.count);
		// !partnerLoading && canReq && countReload();
		this.timeInterval = setInterval(() => {
			!partnerLoading && canReq && countReload();
		}, checkNotificationTimeout);

		if (!canReq) {
			clearInterval(this.timeInterval);
		}
	}

	componentWillUnmount() {
		clearInterval(this.timeInterval);
	}


	render() {
		const { toggleCollapsed, locale, isAgent,
		} = this.props;
		const agentBallance = getUser().balance;
		const headerStyle = {
			position       : 'fixed',
			width          : '100%',
			height         : 70,
			backgroundColor: 'white',
		};

		return (
			<TopBarContainer
				className={'topbarwrapper'}
				style={headerStyle}
			>
				<div className="isoLeft">
					<MenuOutlined className={'hamburger'} onClick={toggleCollapsed} />
					{!isAgent && <TopbarSearch />}
				</div>


				<ul className="iso-right" >
					{!isAgent && <li><PartnerSwitcher /></li>}
					{!isAgent && <li><WebsiteSwitcher /></li>}
					<li> <LanguageSwitcher /></li>
					{isAgent && <li className="isoNotify"> <> {lang.myBalance} :<span style={{ fontWeight: 'bold' }}> {agentBallance} (USD) </span> </> </li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.riskManagement} /></li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.documents} /></li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.casino} /></li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.deposits} /></li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.withdrawal} /></li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.bets} /></li>}
					{!isAgent && <li className="isoNotify"><NotificationBadge typeID={NOTIFICATION_TYPES.messages} /></li>}
					<li className="isoUser"><TopbarUser locale={locale} /></li>
				</ul>
				{/*</Header>*/}
			</TopBarContainer>
		// </div>

		);
	}
}

export default connect(
	state => ({
		collapsed      : state.App.toJS().collapsed,
		openDrawer     : state.App.toJS().openDrawer,
		locale         : state.LanguageSwitcher.toJS().language.locale,
		customizedTheme: state.ThemeSwitcher.toJS().topbarTheme,
		superAdmin     : isSuperAdmin(),
		partnerLoading : state.Staff.Person.get('UI').loading,
		userPermissions: state?.Auth?.get('user')?.userRoles,
		isAgent        : hasAgentRole(state?.Auth?.get('user')?.userRoles),
	}), {
		toggleCollapsed: appActions.toggleCollapsed,
		countReload    : notificationsActions.countReload,
	}
)(Topbar);

