import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';

import { casinoAPI } from '../../../helpers/api/casino';
import notifications from '../../../helpers/notifications';

import { adaptProvidersList, adaptGamesListByProvider } from './utils';
import { logger } from '../../../helpers/logger';
import { CasinoExtraSpinType } from '@ceo-betmakers/common-globals';


const messages = {
	errorListLoad     : 'casinoProviders.error.list.load',
	errorGamesListLoad: 'casinoProviders.error.gamesList.load',
};

function* listReload() {

	yield takeEvery(actions.CASINO_PROVIDERS_LIST_RELOAD, function* (isPartnerCasinoList) {
		yield put(actions.uiRefresh({ loading: true }));

		let entities = {};
		const providersApi = isPartnerCasinoList ? casinoAPI.partnerCasinoList : casinoAPI.providersList;
		try {
			const res = yield call(providersApi);
			if (res && res.status === 200) {
				entities = adaptProvidersList(res.data.data);
			}
		} catch (error) {
			notifications.showError(messages.errorListLoad);
		} finally {
			yield put(actions.uiRefresh({ loading: false }));
		}

		yield put(actions.listRefresh(entities));
	});
}

function* listReloadGames() {

	yield takeEvery(actions.CASINO_INTEGRATORS_PROVIDERS_LIST_RELOAD_GAMES, function* (action) {


		const { providerID, currencyID, typeID } = action.data;
		const params = {};
		if (typeID) {
			if (typeID === CasinoExtraSpinType.FREE_SPIN) {
				params.free_spins = true;
			} else if (typeID === CasinoExtraSpinType.BONUS_SPIN) {
				params.bonus_spins = true;
			}
		}
		yield put(actions.listRefreshGames({ [providerID]: { games: null, providerID, loading: true } }));

		let entities = {};
		try {
			const res = yield call(casinoAPI.gamesListByProvider, providerID, currencyID, params);
			if (res && res.status === 200) {
				entities = adaptGamesListByProvider(res.data.data, providerID);
			}
		} catch (error) {
			notifications.showError(messages.errorGamesListLoad);
			logger.log(error);
		}
		yield put(actions.listRefreshGames({ [providerID]: entities }));
	});
}

export default function* casinoListSaga() {
	yield all([
		fork(listReload),
		fork(listReloadGames),
	]);
}
