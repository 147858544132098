import { Icon } from 'react-icons-kit';
import { ic_support_agent } from 'react-icons-kit/md/ic_support_agent';
import { CSSProperties, FC } from 'react';

interface IAgentIconProps {
	style?: CSSProperties;
}
export const AgentIcon: FC<IAgentIconProps> = (props) => {
	const { style } = props;
	return <Icon icon={ic_support_agent} size={20} style={style} />;
};