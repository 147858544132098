import {
	BannerDisplayStatus, BetSlipStatus, BetSlipType, CasinoBetType, CasinoGameWeight, CategoryType,
	ChannelType, DisplayStatus, GeneralStatus, MenuTypes, PartnerStatus, PaymentID, PaywaysStatus, SourceType,
	TradingMode, TransactionType,
	UserLoginStatus,
	ValueType, VerifySourceType, WithdrawalType,
} from '@ceo-betmakers/common-globals';
import { AdminTableType } from '@ceo-betmakers/common-globals/lib/enums/admin_table_settings.enum';


export const BANNER_DISPLAY_STATUS = {
	inactive: BannerDisplayStatus.INACTIVE,
	active  : BannerDisplayStatus.ACTIVE,
};

export const BANNER_USER_STATUS = {
	LOGGEDIN                                    : UserLoginStatus.LOGGEDIN,
	FIRST_TIME_VISITOR                          : UserLoginStatus.FIRST_TIME_VISITOR,
	RETURNING_VISITOR                           : UserLoginStatus.RETURNING_VISITOR,
	LOGGEDIN_RETURNING_VISITOR                  : UserLoginStatus.LOGGEDIN_RETURNING_VISITOR,
	[UserLoginStatus.LOGGEDIN]                  : 'LOGGED IN',
	[UserLoginStatus.FIRST_TIME_VISITOR]        : 'FIRST TIME VISITOR',
	[UserLoginStatus.RETURNING_VISITOR]         : 'RETURNING VISITOR',
	[UserLoginStatus.LOGGEDIN_RETURNING_VISITOR]: 'LOGGEDIN_RETURNING_VISITOR',
};

export const BANNER_USER_TEXT_STATUS = {
	[UserLoginStatus.LOGGEDIN]                  : 'Logged In',
	[UserLoginStatus.FIRST_TIME_VISITOR]        : 'First Time Visitor',
	[UserLoginStatus.RETURNING_VISITOR]         : 'Returning Visitor',
	[UserLoginStatus.LOGGEDIN_RETURNING_VISITOR]: 'Logged In Returning Visitor',
};

export const TRADING_MODE = {
	[TradingMode.BOTH]    : 'Both',
	[TradingMode.PREMATCH]: 'Prematch',
	[TradingMode.LIVE]    : 'Inplay',
	both                  : TradingMode.BOTH,
	prematch              : TradingMode.PREMATCH,
	live                  : TradingMode.LIVE,
};

export const TRADING_MODE_OPTIONS = [
	{ id: TradingMode.BOTH, name: 'Both' },
	{ id: TradingMode.PREMATCH, name: 'PreMatch' },
	{ id: TradingMode.LIVE, name: 'InPlay' },
];

export const DURATIONS = {
	minutesInDay : 1440,
	minutesInHour: 60,
};

export const LIMIT_TYPES = {
	absolute: ValueType.ABSOLUTE,
	percent : ValueType.PERCENT,
};

export const ACCEPT_BETS_TIMES = {
	minutes: 1,
	hours  : 2,
	days   : 3,
	weeks  : 4,
	months : 5,
	always : 6,
};

export const CATEGORIES_TYPES = {
	sport      : CategoryType.SPORT,
	country    : CategoryType.SPORT_COUNTRY,
	league     : CategoryType.LEAGUE,
	subleague  : CategoryType.SUB_LEAGUE,
	event      : 5, // TODO add after @ceo-betmakers/common-globals update
	eventFolder: 6,
	market     : 7,
	selection  : 8,
};

export const DYNAMIC_LIMITS_TIMES = {
	minutes: 1,
	hours  : 2,
	days   : 3,
};

export const FORMATS = {
	// for dayjs
	date          : 'YYYY-MM-DD',
	dateDot       : 'DD.MM.YYYY',
	dateSlash     : 'DD/MM/YYYY',
	fullDateSlash : 'YYYY/MM/DD HH:mm:ss',
	dateHyphen    : 'DD-MM-YYYY',
	dateTime      : 'YYYY-MM-DD HH:mm',
	time          : 'HH:mm:ss',
	timeHM        : 'HH:mm',
	fullDateTime  : 'YYYY-MM-DD HH:mm:ss',
	fullDateMsTime: 'YYYY-MM-DD HH:mm:ss.SSS',
	monthsAndYear : 'MMMM YYYY',

	// for numeral
	integer  : '0',
	decimal  : '0.00',
	sum      : '0,0.00',
	millenial: '0.000',
	finance  : '$ 0,0.00',
	percent  : '0 %',
};

export const TIME_UNITS = {
	years  : 'years',
	months : 'months',
	days   : 'days',
	hours  : 'hours',
	minutes: 'minutes',
	seconds: 'seconds',
};


export const AUTOCOMPLETE_SOURCES = {
	userName     : 'username',
	userEmail    : 'email',
	userFirstName: 'first_name',
	userLastName : 'last_name',
	roleName     : 'role_name',
	firstName    : 'first_name',
	lastName   	 : 'last_name',
	email        : 'email',
	username     : 'username',
};

export const CHANNEL_TYPES = {
	web    : ChannelType.WEB,
	mobile : ChannelType.MOBILE,
	tablet : ChannelType.TABLET,
	desktop: ChannelType.WEB,
	backend: ChannelType.BACKEND,
};

export const DISPLAY_STATUS = {
	visible      : DisplayStatus.VISIBLE,
	invisible    : DisplayStatus.INVISIBLE,
	partlyVisible: DisplayStatus.PARTLY_VISIBLE,
};

export const GENERAL_STATUS = {
	active  : GeneralStatus.ACTIVE,
	inactive: GeneralStatus.INACTIVE,
	archived: GeneralStatus.ARCHIVED,
};

export const VERIFICATION_SOUSCE_TYPES = {
	admin : VerifySourceType.ADMIN,
	system: VerifySourceType.SYSTEM,
};

export const SOURCE_TYPES = {
	feed                       : SourceType.FEED,
	admin                      : SourceType.ADMIN,
	casino                     : SourceType.CASINO,
	paymentSystem              : SourceType.PAYMENT_SYSTEM,
	system                     : SourceType.SYSTEM,
	[SourceType.FEED]          : 'feed',
	[SourceType.ADMIN]         : 'admin',
	[SourceType.CASINO]        : 'casino',
	[SourceType.PAYMENT_SYSTEM]: 'paymentSystem',
	[SourceType.SYSTEM]        : 'system',
};

export const PRICE_PROVIDERS = {
	1: 'Azuro',
};

export const SOCKET_STATUS = {
	connected   : 'Connected',
	disconnected: 'Disconnected',
};

export const YES_NO = {
	yes: 1,
	no : 2,
	1  : { name: 'yes', value: 1 },
	2  : { name: 'no',  value: 2 },
};

export const BET_TYPES = {
	single                     : BetSlipType.SINGLE,
	express                    : BetSlipType.EXPRESS,
	system                     : BetSlipType.SYSTEM,
	special_offer              : BetSlipType.SPECIAL_OFFER,
	[BetSlipType.SINGLE]       : 'Single',
	[BetSlipType.EXPRESS]      : 'Express',
	[BetSlipType.SYSTEM]       : 'System',
	[BetSlipType.SPECIAL_OFFER]: 'Special Offer',
};

export const CASINO_BET_TYPES = {
	spin     : CasinoBetType.SPIN,
	reSpin   : CasinoBetType.RE_SPIN,
	freeSpin : CasinoBetType.FREE_SPIN,
	bonusSpin: CasinoBetType.BONUS_SPIN,
	tip      : CasinoBetType.TIP,
	promo    : CasinoBetType.PROMO,
};

export const PARTNER_FILTER_STATUS = {
	active: PartnerStatus.ACTIVE,
	block : PartnerStatus.BLOCKED,
	delete: PartnerStatus.DELETED,
};

export const CASINO_BET_TYPES_NAMES = {
	[CasinoBetType.SPIN]      : 'Spin',
	[CasinoBetType.FREE_SPIN] : 'Free Spin',
	[CasinoBetType.RE_SPIN]   : 'Re Spin',
	[CasinoBetType.BONUS_SPIN]: 'Bonus Spin',
	[CasinoBetType.TIP]       : 'tip',
};

export const DEPOSIT_BONUS_TYPES = {
	registrationBonus: 1,
	depositBonus     : 2,
	gamingBonus      : 3,
	specialOfferBonus: 4,
	manualAdjustment : 5,
	other            : 6,
};

export const DEPOSIT_TYPES = {
	manual       : 3,
	paymentSystem: 4,
	bonus        : 5,
	cashback     : 6,
	agent        : 9,
	// cashback           : 7,
};


export const WITHDRAWAL_TYPES = {
	manual               : WithdrawalType.MANUAL,
	paymentSystem        : WithdrawalType.PAYMENT_SYSTEM,
	transferFromOtherUser: WithdrawalType.TRANSFER_FROM_OTHER_USER,
	bonus                : WithdrawalType.BONUS,
	agent                : WithdrawalType.AGENT,
};


export const WITHDRAWAL_ELIGIBILITY = {
	yes : 1,
	no  : 2,
	auto: 3,
};

export const ACTIVE_STATUS = {
	active  : 1,
	inactive: 0,
};

export const CONTENT_MENU_TYPES = {
	topMenu     : MenuTypes.TOP_MENU,
	footerMenu  : MenuTypes.MAIN_FOOTER_MENU,
	articlesMenu: MenuTypes.SUB_FOOTER,
};

export const BET_STATUS = {
	[BetSlipStatus.OPEN]           : 'Open',
	[BetSlipStatus.LOST]           : 'Lost',
	[BetSlipStatus.PARTIAL_LOST]   : 'PartialLost',
	[BetSlipStatus.WIN]            : 'Win',
	[BetSlipStatus.PARTIAL_WIN]    : 'PartialWin',
	[BetSlipStatus.CANCELLED]      : 'Cancelled',
	[BetSlipStatus.VOID]           : 'Void',
	[BetSlipStatus.IN_ADMIN_REVIEW]: 'In Admin Review',
	[BetSlipStatus.IN_USER_REVIEW] : 'In User Review',
	[BetSlipStatus.ADMIN_REJECTED] : 'Admin Rejected',
	[BetSlipStatus.USER_REJECTED]  : 'User Rejected',
	[BetSlipStatus.AUTO_REJECTED]  : 'Auto Rejected',
	
};

export const LOG_TYPES = {
	category           : 1,
	market             : 2,
	selection          : 3,
	user               : 4,
	bonuses            : 5,
	participant        : 7,
	cashback           : 8,
	welcomeBonus       : 9,
	responsibleGambling: 10,
	sportRiskManagement: 11,
};


export const CATEGORY_LOG_MODELS = {
	all               : 0,
	category          : 1,
	settings          : 2,
	cms               : 3,
	dynamicLimits     : 4,
	markets           : 5,
	names             : 6,
	orders            : 7,
	leagueRestrictions: 8,
	leagueTournaments : 9,
	sportRegulations  : 10,
	marketsMargin     : 11,
};

export const MARKET_LOG_MODELS = {
	all          : 0,
	market       : 1,
	names        : 2,
	cms          : 3,
	orders       : 4,
	settings     : 5,
	dynamicLimits: 6,
};

export const SELECTION_LOG_MODELS = {
	all          : 0,
	selection    : 1,
	names        : 2,
	orders       : 3,
	settings     : 4,
	dynamicLimits: 5,
};

export const USER_LOG_MODELS = {
	all     : 0,
	user    : 1,
	gambling: 2,
};

export const SPORT_RISK_MANAGMENT_LOG_MODELS = {
	betslip        : 0,
	betslipDetails : 1,
	eventMarkets   : 2,
	eventSelections: 3,
};

export const CASHBACK_LOG_MODELS = {
	main     : 0,
	ggrLimits: 1,
};

export const WELCOME_BONUS_LOG_MODELS = {
	main: '0',
};

export const BET_HISTORY_TYPES = {
	event    : 1,
	market   : 2,
	selection: 3,
};

export const RESULT_TYPES = {
	won        : 1,
	lost       : 2,
	cancelled  : 3,
	void       : 4,
	partialWin : 5,
	partialLost: 6,
};

export const RESULT_SELECTION_LOG_MODELS = {
	eventSelections: 1,
	eventStatistic : 2,
};

export const STATISTIC_SORTS = {
	home: 0,
	away: 1,
};

export const WEBSITE_STATUS = {
	active : 1,
	blocked: 2,
};
export const SUB_CATEGORY_STATUS = {
	provider      : 1,
	customProvider: 2,
	both          : 3,
};


export const WEBSITE_USER_CONFIGS = {
	none        : 0,
	deposit     : 1,
	withdrawal  : 2,
	registration: 3,
};

export const PROMOTION_STATUS = {
	active  : 1,
	inactive: 2,
	1       : 'active',
	2       : 'inactive',
};

export const LANGUAGES = {
	en: 1,
	ru: 2,
	ua: 3,
	tr: 4,
	de: 5,
};

export const PRODUCTS = {
	sport : 1,
	casino: 2,
	system: 3,
};

export const CASINO_API_VERSIONS = {
	one: 1,
	two: 2,
};

export const CASINO_API_HOSTS = {
	europe: 0,
	asia  : 1,
};

export const WEBSITE_CASINO_STATUS = {
	inactive: 0,
	active  : 1,
};

export const CASINO_GAMES_CATEGORY = {
	all: 0,
};

export const CONTENT_GAMES_CATEGORY = {
	all: 0,
};

export const CASINO_GAMES_WEIGHT = {
	halfSmall: 0.5, // Draft Size

	tabletSmallest: 0.4,
	tabletSmaller : 0.675,

	tabletMedium      : 0.8,
	tabletMediumMiddle: 1.35,

	tabletBig   : 1.6,
	tabletBigest: 2.7,

	small : CasinoGameWeight.SMALL,
	medium: CasinoGameWeight.MEDIUM,
	big   : CasinoGameWeight.BIG,
};

export const CASINO_GAMES_GRID_MODE = {
	byDefault: 'byDefault',
	byGames  : 'byGames',
};

export const CASINO_GAMES_VIEW_TABS = {
	grid: 'grid',
	list: 'list',
};

export const TRANSACTION_TYPES = {
	all              : 0,
	bet              : TransactionType.BET,
	withdraw         : TransactionType.WITHDRAW,
	manual           : TransactionType.MANUAL,
	deposit          : TransactionType.DEPOSIT,
	userToUser       : TransactionType.USER_TO_USER,
	bonus            : TransactionType.BONUS,
	betReverse       : TransactionType.BET_REVERSE,
	wonReverse       : TransactionType.WON_REVERSE,
	won              : TransactionType.WON,
	balanceAdjustment: TransactionType.BALANCE_ADJUSTMENT,
	cashback         : TransactionType.CASHBACK,
	debitDecrease    : TransactionType.DEBIT_DECREASE,
	debitIncrease    : TransactionType.DEBIT_INCREASE,
};

export const PAYWAY_STATUS = {
	visible: PaywaysStatus.VISIBLE,
	hidden : PaywaysStatus.HIDDEN,
};

export const USER_INFO_VIEW_TABS = {
	personal             : 'personal',
	transactions         : 'transactions',
	bets                 : 'bets',
	deposits             : 'deposits',
	withdrawals          : 'withdrawals',
	casino               : 'casino',
	balanceAdjustments   : 'balanceAdjustments',
	documents            : 'documents',
	logins               : 'logins',
	actions              : 'actions',
	statistic            : 'statistic',
	roles                : 'roles',
	group                : 'group',
	bonus                : 'bonus',
	sportStatistic       : 'sportStatistic',
	casinoStatistic      : 'casinoStatistic',
	limitsAndRestrictions: 'limitsAndRestrictions',
	responsibleGambling  : 'responsibleGambling',
	notes                : 'notes',
	sendSMS              : 'sendSMS',
	riskManagement       : 'riskManagement',
	partnerWebsite       : 'partnerWebsite',
};

export const CASINO_GAMES_STATUS = {
	active  : 1,
	inactive: 2,
};

export const TRANSACTION_SOURCES = {
	[PaymentID.INTERKASSA_MASTER_CARD]              : 'Interkassa',
	[PaymentID.PAYMEGA_CARD]                        : 'PayMega Visa/Master',
	[PaymentID.SKRILL]                              : 'Skrill',
	[PaymentID.PAYMEGA_PRIVAT24]                    : 'Paymega Privat24',
	[PaymentID.SKRILL_CARDS]                        : 'Skrill Credit Cards',
	[PaymentID.SKRILL_VISA]                         : 'Skrill Visa',
	[PaymentID.SKRILL_MASTER]                       : 'Skrill MasterCard',
	[PaymentID.SKRILL_AMEX]                         : 'Skrill American Express',
	[PaymentID.SKRILL_NETELLER]                     : 'Skrill Neteller',
	[PaymentID.WINPAY]                              : 'WinPay',
	[PaymentID.CARDPAY]                             : 'CardPay',
	[PaymentID.NETELLER]                            : 'Neteller',
	[PaymentID.WINPAY_VISA_OR_MASTERCARD_RU]        : 'Winpay Visa or Mastercard RU',
	[PaymentID.WINPAY_VISA_OR_MASTERCARD_CRD_OR_P2P]: 'Winpay Visa or Mastercard CRD or P2P',
	[PaymentID.CARDS_OF_MIR]                        : 'Winpay Replenishment of World Card',
	[PaymentID.WINPAY_VISA_OR_MASTERCARD_MIR]       : 'Winpay Visa or Mastercard MIR',
	[PaymentID.WINPAY_VISA_OR_MASTERCARD_UA]        : 'Winpay Visa or Mastercard UA',
	[PaymentID.WINPAY_VISA_OR_MASTERCARD_KZ]        : 'Winpay Visa or Mastercard KZ',

	[PaymentID.WINPAY_AUTODETECTION_OF_MOBILE_OPERATOR]       : 'Winpay Autodetection of Mobile Operator',
	[PaymentID.WINPAY_WEBMONEY_R]                             : 'Winpay Webmoney R',
	[PaymentID.WINPAY_YANDEX_MONEY]                           : 'Winpay Yandex Money',
	[PaymentID.WINPAY_QIWI_WALLET]                            : 'Winpay Qiwi Wallet',
	[PaymentID.WINPAY_QIWI_VISA_WALLET]                       : 'Winpay Qiwi Visa Wallet',
	[PaymentID.WINPAY_MTC_RU]                                 : 'Winpay MTC ru',
	[PaymentID.WINPAY_MEGAFON_RU]                             : 'Winpay Megafon ru',
	[PaymentID.WINPAY_BEELINE_RU]                             : 'Winpay Beeline ru',
	[PaymentID.WINPAY_TELE2_RU]                               : 'Winpay Tele2 ru',
	[PaymentID.WINPAY_MOTIVE_RU]                              : 'Winpay Motive ru',
	[PaymentID.WINPAY_SMARTS_ASTRAKHAN_RU]                    : 'Winpay Smarts Astrakhan ru',
	[PaymentID.WINPAY_SMARTS_IVANOVO_RU]                      : 'Winpay Smarts Ivanovo ru',
	[PaymentID.WINPAY_SMARTS_KAZAN_RU]                        : 'Winpay Smarts Kazan ru',
	[PaymentID.WINPAY_SMARTS_PENZA_RU]                        : 'Winpay Smarts Penza ru',
	[PaymentID.WINPAY_SMARTS_SAMARA_IVANOVO_KAZAN_UFA_RU]     : 'Winpay Smarts Samara Ivanovo Kazan Ufa ru',
	[PaymentID.WINPAY_SMARTS_CHUVASHIA_RU]                    : 'Winpay Smarts Chuvashia ru',
	[PaymentID.WINPAY_SMARTS_YAROSLAVL_RU]                    : 'Winpay Smarts Yaroslavl ru',
	[PaymentID.WINPAY_SMARTS_ULAN_UDE_RU]                     : 'Winpay Smarts Ulan Ude ru',
	[PaymentID.WINPAY_YOTA_RU]                                : 'Winpay Yota ru',
	[PaymentID.WINPAY_SKYLINK_VOLGOGRAD_RU]                   : 'Winpay Skylink Volgograd ru',
	[PaymentID.WINPAY_SKYLINK_VLADIMIR_RU]                    : 'Winpay Skylink Vladimir ru',
	[PaymentID.WINPAY_TINKOFF_MOBILE_RU]                      : 'Winpay Tinkoff Mobile ru',
	[PaymentID.WINPAY_ROSTELECOM]                             : 'Winpay Rostelecom',
	[PaymentID.WINPAY_MONEY_TRANSFERS_BEST]                   : 'Winpay Money Transfers Best',
	[PaymentID.WINPAY_MONEY_TRANSFERS_TO_BANK_RU]             : 'Winpay Money Transfers to Bank RU',
	[PaymentID.WINPAY_MOSENERGOSBYT]                          : 'Winpay Mosenergosbyt',
	[PaymentID.WINPAY_GAMES_MAILRU_WARFACE]                   : 'Winpay Games Mailru Warface',
	[PaymentID.WINPAY_GAMES_WARGAMING_WORLD_OF_TANKS]         : 'Winpay Games Wargaming World of Tanks',
	[PaymentID.WINPAY_GAMES_WARGAMING_WORLD_OF_WARPLANES]     : 'Winpay Games Wargaming World of Warplanes',
	[PaymentID.WINPAY_GAMES_WARGAMING_WORLD_OF_SHIPS]         : 'Winpay Games Wargaming World of Ships',
	[PaymentID.WINPAY_GAMES_TANKS_ONLINE]                     : 'Winpay Games Tanks Online',
	[PaymentID.WINPAY_GAMES_STEAM]                            : 'Winpay Games Steam',
	[PaymentID.WINPAY_GAMES_NIKITA_ONLINE]                    : 'Winpay Games Nikita Online',
	[PaymentID.WINPAY_GAMES_XSOLLA_WARTHUNDER]                : 'Winpay Games Xsolla Warthunder',
	[PaymentID.WINPAY_GAMES_XSOLLA_BLACK_DESERT]              : 'Winpay Games Xsolla Black Desert',
	[PaymentID.WINPAY_GAMES_MAILRU_PERFECT_WORLD]             : 'Winpay Games Mailru Perfect World',
	[PaymentID.WINPAY_GAMES_MAILRU_ALLODS_ONLINE]             : 'Winpay Games Mailru Allods Online',
	[PaymentID.WINPAY_GAMES_MAILRU_CROSSFIRE]                 : 'Winpay Games Mailru Crossfire',
	[PaymentID.WINPAY_GAMES_MAILRU_RIOT]                      : 'Winpay Games Mailru Riot',
	[PaymentID.WINPAY_GAMES_MAILRU_SKYFORGE]                  : 'Winpay Games Mailru Skyforge',
	[PaymentID.WINPAY_GAMES_MAILRU_ARMORED_WARFACE]           : 'Winpay Games Mailru Armored Warface',
	[PaymentID.ACCENTPAY_CARD]                                : 'Accent Pay - Card',
	[PaymentID.ACCENTPAY_ALPHA_BANK]                          : 'Accent Pay - Alpha Bank',
	[PaymentID.ACCENTPAY_ALI_PAY]                             : 'Accent Pay - Ali Pay',
	[PaymentID.ACCENTPAY_APPLE_PAY]                           : 'Accent Pay - Apple Pay',
	[PaymentID.ACCENTPAY_APPLE_PAY]                           : 'Accent Pay - Apple Pay',
	[PaymentID.ACCENTPAY_ATF_24]                              : 'Accent Pay - ATF 24',
	[PaymentID.ACCENTPAY_BANK_OF_INDONESIA]                   : 'Accent Pay - Bank of Indonesia',
	[PaymentID.ACCENTPAY_BANK_OF_MALAYSIA]                    : 'Accent Pay - Bank of Malaysia',
	[PaymentID.ACCENTPAY_BANK_OF_THAILAND]                    : 'Accent Pay - Bank of Thailand',
	[PaymentID.ACCENTPAY_BANK_OF_VIETNAM]                     : 'Accent Pay - Bank of Vietnam',
	[PaymentID.ACCENTPAY_BANK_OF_CHINA]                       : 'Accent Pay - Bank of China',
	[PaymentID.ACCENTPAY_CHINA_UNION_PAY]                     : 'Accent Pay - China Union Pay',
	[PaymentID.ACCENTPAY_EPS]                                 : 'Accent Pay - EPS',
	[PaymentID.ACCENTPAY_FORTE_BANK]                          : 'Accent Pay - Forte Bank',
	[PaymentID.ACCENTPAY_GIRO_BANK]                           : 'Accent Pay - Giro Bank',
	[PaymentID.ACCENTPAY_HOME_BANK]                           : 'Accent Pay - Home Bank',
	[PaymentID.ACCENTPAY_IDEAL]                               : 'Accent Pay - Ideal',
	[PaymentID.ACCENTPAY_IWALLET]                             : 'Accent Pay - IWallet',
	[PaymentID.ACCENTPAY_JETSON_WALLET]                       : 'Accent Pay - Jetson Wallet',
	[PaymentID.ACCENTPAY_KASSA_24]                            : 'Accent Pay - Kassa 24',
	[PaymentID.ACCENTPAY_KAZPOST]                             : 'Accent Pay - Kazpost',
	[PaymentID.ACCENTPAY_NETELLER]                            : 'Accent Pay - Neteller',
	[PaymentID.ACCENTPAY_NORDEA_SOLO]                         : 'Accent Pay - Nordea Solo',
	[PaymentID.ACCENTPAY_PAYSAFE_CARD]                        : 'Accent Pay - Paysafe Card',
	[PaymentID.ACCENTPAY_POLI]                                : 'Accent Pay - Poli',
	[PaymentID.ACCENTPAY_PRZELEWY_24]                         : 'Accent Pay - Przelewy 24',
	[PaymentID.ACCENTPAY_QIWI]                                : 'Accent Pay - Qiwi',
	[PaymentID.ACCENTPAY_RAPID_TRANSFER]                      : 'Accent Pay - Rapid Transfer',
	[PaymentID.ACCENTPAY_RBK_BANK]                            : 'Accent Pay - RBK Bank',
	[PaymentID.ACCENTPAY_SKRILL_WALLET]                       : 'Accent Pay - Skrill Wallet',
	[PaymentID.ACCENTPAY_SOFORT_UBERWEISUNG]                  : 'Accent Pay - Sofort Uberweisung',
	[PaymentID.ACCENTPAY_TRUSTLY]                             : 'Accent Pay - Trustly',
	[PaymentID.ACCENTPAY_WEB_MONEY]                           : 'Accent Pay - Web Money',
	[PaymentID.ACCENTPAY_WE_CHAT]                             : 'Accent Pay - We Chat',
	[PaymentID.ACCENTPAY_MOBILE]                              : 'Accent Pay - Mobile',
	[PaymentID.ACCENTPAY_MONETA_RU]                           : 'Accent Pay - Moneta RU',
	[PaymentID.ACCENTPAY_YANDEX_MONEY]                        : 'Accent Pay - Yandex Money',
	[PaymentID.ACCENTPAY_JETSON_VOUCHER]                      : 'Accent Pay - Jetson Voucher',
	[PaymentID.CARDPAY_QIWI]                                  : 'Card Pay - QIWI',
	[PaymentID.CARDPAY_YANDEX_MONEY]                          : 'Card Pay - Yandex Money',
	[PaymentID.CARDPAY_ALIPAY]                                : 'Card Pay - Alipay',
	[PaymentID.CARDPAY_AGRICULTURAL_BANK_OF_CHINA]            : 'Card Pay - Agricultural Bank China',
	[PaymentID.CARDPAY_BANK_OF_CHINA]                         : 'Card Pay - Bank China',
	[PaymentID.CARDPAY_BANK_OF_COMMUNICATIONS]                : 'Card Pay - Bank Communications',
	[PaymentID.CARDPAY_CITIC_BANK]                            : 'Card Pay - Citic Bank',
	[PaymentID.CARDPAY_CONSTRUCTION_BANK]                     : 'Card Pay - Construction Bank',
	[PaymentID.CARDPAY_EVERBRIGHT_BANK]                       : 'Card Pay - Everbright Bank',
	[PaymentID.CARDPAY_MERCHANTS_BANK]                        : 'Card Pay - Merchants Bank',
	[PaymentID.CARDPAY_MINSHENG_BANK]                         : 'Card Pay - Minsheng Bank',
	[PaymentID.CARDPAY_POSTAL_SAVINGS_BANK]                   : 'Card Pay - Postal Savings Bank',
	[PaymentID.CARDPAY_GUANGFA_BANK]                          : 'Card Pay - Guangfa Bank',
	[PaymentID.CARDPAY_ICBC_CHINA]                            : 'Card Pay - ICBC China',
	[PaymentID.CARDPAY_INDUSTRIAL_BANK]                       : 'Card Pay - Industrial Bank',
	[PaymentID.CARDPAY_PING_AN_BANK]                          : 'Card Pay - Ping An Bank',
	[PaymentID.CARDPAY_SHANGHAI_PUDONG_DEV_BANK]              : 'Card Pay - Shanghai Pudong Development Bank',
	[PaymentID.CARDPAY_BANK_CENTRAL_ASIA]                     : 'Card Pay - Bank Central Asia',
	[PaymentID.CARDPAY_BANK_DANAMON]                          : 'Card Pay - Bank Danamon',
	[PaymentID.CARDPAY_BANK_MANDIRI]                          : 'Card Pay - Bank Mandiri',
	[PaymentID.CARDPAY_BANK_NEGARA_INDONESIA]                 : 'Card Pay - Bank Negara Indonesia',
	[PaymentID.CARDPAY_BANK_TABUNGAN_NEGARA]                  : 'Card Pay - Bank Tabungan Negara',
	[PaymentID.CARDPAY_PERMATA_BANK]                          : 'Card Pay - Bank Permata',
	[PaymentID.CARDPAY_BANK_RAKYAT]                           : 'Card Pay - Bank Rakyat',
	[PaymentID.CARDPAY_CIMB_BANK]                             : 'Card Pay - CIMB Bank',
	[PaymentID.CARDPAY_HONG_LEONG_BANK]                       : 'Card Pay - Hong Leong Bank',
	[PaymentID.CARDPAY_MAYBANK]                               : 'Card Pay - Maybank',
	[PaymentID.CARDPAY_PUBLIC_BANK_BERHAD]                    : 'Card Pay - Public Bank Berhad',
	[PaymentID.CARDPAY_RHB_BANK]                              : 'Card Pay - RHB Bank',
	[PaymentID.CARDPAY_UNITED_OVERSEAS_BANK]                  : 'Card Pay - United Overseas Bank',
	[PaymentID.CARDPAY_BANGKOK_BANK]                          : 'Card Pay - Bangkok Bank',
	[PaymentID.CARDPAY_BANK_OF_AYUDHYA_KRUNGSRI_BANK]         : 'Card Pay - Bank Ayudhya',
	[PaymentID.CARDPAY_KASIKORN_BANK]                         : 'Card Pay - Kasikorn Bank',
	[PaymentID.CARDPAY_KRUNG_THAI_BANK]                       : 'Card Pay - Krung Thai Bank',
	[PaymentID.CARDPAY_SIAM_COMMERCIAL_BANK]                  : 'Card Pay - Siam Commercial Bank',
	[PaymentID.CARDPAY_ASIA_COMMERCIAL_BANK]                  : 'Card Pay - Asia Commercial Bank',
	[PaymentID.CARDPAY_BIDV_BANK]                             : 'Card Pay - BIDV Bank',
	[PaymentID.CARDPAY_DONGA_BANK]                            : 'Card Pay - Dong A Bank',
	[PaymentID.CARDPAY_EXIM_BANK]                             : 'Card Pay - Exim Bank',
	[PaymentID.CARDPAY_SACOM_BANK]                            : 'Card Pay - Sacom Bank',
	[PaymentID.CARDPAY_TECHCOM_BANK]                          : 'Card Pay - Techcom Bank',
	[PaymentID.CARDPAY_VIETCOM_BANK]                          : 'Card Pay - Vietcom Bank',
	[PaymentID.CARDPAY_VIETIN_BANK]                           : 'Card Pay - Vietin Bank',
	[PaymentID.CARDPAY_RAPI_PAGO]                             : 'Card Pay - Rapi Pago',
	[PaymentID.CARDPAY_BOLETO]                                : 'Card Pay - Boleto',
	[PaymentID.CARDPAY_BRADESCO]                              : 'Card Pay - Bradesco',
	[PaymentID.CARDPAY_BANCO_DO_BRASIL]                       : 'Card Pay - Banco Brasil',
	[PaymentID.CARDPAY_CAIXA]                                 : 'Card Pay - Caixa',
	[PaymentID.CARDPAY_SANTANDER]                             : 'Card Pay - Santander',
	[PaymentID.CARDPAY_WEBPAY]                                : 'Card Pay - WebPay',
	[PaymentID.CARDPAY_BANCO_DE_CHILE]                        : 'Card Pay - Banco Chile',
	[PaymentID.CARDPAY_SERVIPAG]                              : 'Card Pay - Servipag',
	[PaymentID.CARDPAY_ITAU]                                  : 'Card Pay - ITAU',
	[PaymentID.CARDPAY_CORPBANCA]                             : 'Card Pay - Corpbanca',
	[PaymentID.CARDPAY_BCI_TBANC]                             : 'Card Pay - BCITBANC',
	[PaymentID.CARDPAY_BANCO_FALABELLA]                       : 'Card Pay - Banco Falabella',
	[PaymentID.CARDPAY_BANCO_ESTADO]                          : 'Card Pay - Banco Estado',
	[PaymentID.CARDPAY_BANCO_BICE]                            : 'Card Pay - Banco Bice',
	[PaymentID.CARDPAY_BANCO_SECURITY]                        : 'Card Pay - Banco Security',
	[PaymentID.CARDPAY_BANCO_CONSORCIO]                       : 'Card Pay - Banco Consorcio',
	[PaymentID.CARDPAY_BANCO_RIPLEY]                          : 'Card Pay - Banco Ripley',
	[PaymentID.CARDPAY_SCOTIABANK]                            : 'Card Pay - ScotiaBank',
	[PaymentID.CARDPAY_COOPEUCH]                              : 'Card Pay - Coopeuch',
	[PaymentID.CARDPAY_EFECTY]                                : 'Card Pay - Efecty',
	[PaymentID.CARDPAY_EFECTY]                                : 'Card Pay - Efecty',
	[PaymentID.CARDPAY_DAVIVIENDA]                            : 'Card Pay - Davivienda',
	[PaymentID.CARDPAY_PSE]                                   : 'Card Pay - PSE',
	[PaymentID.CARDPAY_ALMACENES_EXITO]                       : 'Card Pay - Almacenes Exito',
	[PaymentID.CARDPAY_BALOTO]                                : 'Card Pay - Baloto',
	[PaymentID.CARDPAY_CARULLA]                               : 'Card Pay - Carulla',
	[PaymentID.CARDPAY_EMPRESA_DE_ENERGI_A_DEL_QUINDIO]       : 'Card Pay - Empresa de Energia del Quindio',
	[PaymentID.CARDPAY_SURTIMAX]                              : 'Card Pay - Surtimax',
	[PaymentID.CARDPAY_BOLETO_BANCOLOMBIA]                    : 'Card Pay - Boleto Bancolombia',
	[PaymentID.CARDPAY_BANCO_AV_VILLAS]                       : 'Card Pay - Banco AV Villas',
	[PaymentID.CARDPAY_BANCO_CAJA_SOCIAL]                     : 'Card Pay - Banco Caja Social',
	[PaymentID.CARDPAY_BANCO_COLPATRIA]                       : 'Card Pay - Banco Colpatria',
	[PaymentID.CARDPAY_BANCO_DE_BOGOTA]                       : 'Card Pay - Banco de Bogota',
	[PaymentID.CARDPAY_BANCO_DE_OCCIDENTE]                    : 'Card Pay - Banco de Occidente',
	[PaymentID.CARDPAY_BANCO_GNB_SUDAMERIS]                   : 'Card Pay - Banco GNB Sudameris',
	[PaymentID.CARDPAY_BANCO_PICHINCHA_SA]                    : 'Card Pay - Banco Pichincha SA',
	[PaymentID.CARDPAY_BANCO_POPULAR]                         : 'Card Pay - Banco Popular',
	[PaymentID.CARDPAY_BANCO_PROCREDIT]                       : 'Card Pay - Banco Procredit',
	[PaymentID.CARDPAY_BANCOLOMBIA]                           : 'Card Pay - Bancolombia',
	[PaymentID.CARDPAY_BANCOOMEVA_SA]                         : 'Card Pay - Bancoomeva SA',
	[PaymentID.CARDPAY_BBVA_COLOMBIA_SA]                      : 'Card Pay - BBVA Colombia SA',
	[PaymentID.CARDPAY_OXXO]                                  : 'Card Pay - OXXO',
	[PaymentID.CARDPAY_SPEI]                                  : 'Card Pay - SPEI',
	[PaymentID.CARDPAY_BBVA_BANCOMER]                         : 'Card Pay - BBVA Bancomer',
	[PaymentID.CARDPAY_BANAMEX]                               : 'Card Pay - Banamex',
	[PaymentID.CARDPAY_PAGO_EFECTIVO]                         : 'Card Pay - Pago Efectivo',
	[PaymentID.CARDPAY_BCP]                                   : 'Card Pay - BCP',
	[PaymentID.CARDPAY_INTERBANK]                             :	'Card Pay - Interbank',
	[PaymentID.CARDPAY_BBVA]                                  : 'Card Pay - BBVA',
	[PaymentID.CARDPAY_RED_PAGOS]                             :	'Card Pay - Red Pagos',
	[PaymentID.CARDPAY_BITCOIN]                               : 'Card Pay - Bitcoin',
	[PaymentID.CARDPAY_NETELLER]                              : 'Card Pay - Neteller',
	[PaymentID.CARDPAY_ONLINEPAY]                             : 'Card Pay - Onlinepay',
	[PaymentID.CARDPAY_WEBMONEY]                              : 'Card Pay - Webmoney',
	[PaymentID.CARDPAY_BANK_CARD]                             : 'Card Pay - Bank Card',
	[PaymentID.CARDPAY_CHINA_UNIONPAY]                        : 'Card Pay - China Unionpay',
	[PaymentID.ACCENTPAY_CASH_IN]                             : 'Accent Pay - Cash In',
	[PaymentID.ACCENTPAY_PROMSVYAZ_BANK]                      : 'Accent Pay - Promsvyaz Bank',
	[PaymentID.ACCENTPAY_ALFA_CLICK]                          : 'Accent Pay - Alfa Click',
	[PaymentID.GMBL_WMZ]                                      : 'Gamble - WMZ',
	[PaymentID.GMBL_WMR]                                      : 'Gamble - WMR',
	[PaymentID.GMBL_WME]                                      : 'Gamble - WME',
	[PaymentID.GMBL_CARD]                                     : 'Gamble - Card',
	[PaymentID.GMBL_QIWI]                                     : 'Gamble - Qiwi',
	[PaymentID.GMBL_YANDEX]                                   : 'Gamble - Yandex',
	[PaymentID.GMBL_WMR_WITHDRAW]                             : 'Gamble - WMR Withdraw',
	[PaymentID.GMBL_MTC]                                      : 'Gamble - MTC',
	[PaymentID.GMBL_WARFACE]                                  : 'Gamble - Warface',
	[PaymentID.GMBL_WMZ_WITHDRAW]                             : 'Gamble - WMZ Withdraw',
	[PaymentID.GMBL_CARD_WITHDRAW]                            : 'Gamble - Card Withdraw',
	[PaymentID.INTERKASSA_PERFECTMONEY_USD]                   : 'Interkassa - Perfect Money USD',
	[PaymentID.INTERKASSA_PERFECTMONEY_EUR]                   : 'Interkassa - Perfect Money EUR',
	[PaymentID.INTERKASSA_ADVCASH_RUB]                        : 'Interkassa - Advcash RUB',
	[PaymentID.INTERKASSA_ADVCASH_USD]                        : 'Interkassa - Advcash USD',
	[PaymentID.INTERKASSA_ADVCASH_EUR]                        : 'Interkassa - Advcash EUR',
	[PaymentID.INTERKASSA_ADVCASH_UAH]                        : 'Interkassa - Advcash UAH',
	[PaymentID.INTERKASSA_EXMO_ADVCASH_RUB]                   : 'Interkassa - Exmo Advcash RUB',
	[PaymentID.INTERKASSA_EXMO_ADVCASH_USD]                   : 'Interkassa - Exmo Advcash USD',
	[PaymentID.INTERKASSA_EXMO_ADVCASH_EUR]                   : 'Interkassa - Exmo Advcash EUR',
	[PaymentID.INTERKASSA_EXMO_ADVCASH_UAH]                   : 'Interkassa - Exmo Advcash UAH',
	[PaymentID.INTERKASSA_BTCASH_ADVCASH_RUB]                 : 'Interkassa - BTCash Advcash RUB',
	[PaymentID.INTERKASSA_BTCASH_ADVCASH_USD]                 : 'Interkassa - BTCash Advcash USD',
	[PaymentID.INTERKASSA_BTCASH_ADVCASH_EUR]                 : 'Interkassa - BTCash Advcash EUR',
	[PaymentID.INTERKASSA_LITECOIN_ADVCASH_RUB]               : 'Interkassa - Litecoin Advcash RUB',
	[PaymentID.INTERKASSA_LITECOIN_ADVCASH_USD]               : 'Interkassa - Litecoin Advcash USD',
	[PaymentID.INTERKASSA_LITECOIN_ADVCASH_EUR]               : 'Interkassa - Litecoin Advcash EUR',
	[PaymentID.INTERKASSA_QIWI_RUB]                           : 'Interkassa - Qiwi RUB',
	[PaymentID.INTERKASSA_QIWI_TERMINAL_RUB]                  : 'Interkassa - Qiwi Terminal RUB',
	[PaymentID.INTERKASSA_SVYAZNOY_RUB]                       : 'Interkassa - Svyaznoy RUB',
	[PaymentID.INTERKASSA_ALFACLICK_RUB]                      : 'Interkassa - Alfa Click RUB',
	[PaymentID.INTERKASSA_YANDEXMONEY_RUB]                    : 'Interkassa - Yandex Money RUB',
	[PaymentID.INTERKASSA_MASTER_CARD_UAH]                    : 'Interkassa - Master Card UAH',
	[PaymentID.INTERKASSA_VISA_UAH]                           : 'Interkassa - Visa UAH',
	[PaymentID.INTERKASSA_WORLD_TERMINAL_RUB]                 : 'Interkassa - World Terminal RUB',
	[PaymentID.INTERKASSA_MTS_RUB]                            : 'Interkassa - MTS RUB',
	[PaymentID.INTERKASSA_MEGAFON_RUB]                        : 'Interkassa - Megafon RUB',
	[PaymentID.INTERKASSA_BEELINE_RUB]                        : 'Interkassa - Beeline RUB',
	[PaymentID.INTERKASSA_TELE2_RUB]                          : 'Interkassa - Tele2 RUB',
	[PaymentID.INTERKASSA_NIXMONEY_USD]                       : 'Interkassa - Nixmoney USD',
	[PaymentID.INTERKASSA_NIXMONEY_EUR]                       : 'Interkassa - Nixmoney EUR',
	[PaymentID.MERCURYO]                                      : 'Mercuryo',
	[PaymentID.ACCENTPAY_USD_WORLD]                           : 'Accent Pay - USD World',
	[PaymentID.PAYMEGA_CARD_UAH]                              : 'Paymega Card UAH',
	[PaymentID.ROYAL_PAY_CARD]                                : 'Royal Pay Card',
	[PaymentID.ROYAL_PAY_QIWI]                                : 'Royal Pay Qiwi',
	[PaymentID.ROYAL_PAY_YANDEX]                              : 'Royal Pay Yandex',
	[PaymentID.ROYAL_PAY_WEBMONEY]                            : 'Royal Pay Webmoney',
	[PaymentID.ROYAL_PAY_ECOMPAYZ]                            : 'Royal Pay EcomPayz',
	[PaymentID.ROYAL_PAY_PRIVAT24]                            : 'Royal Pay Privat24',
	[PaymentID.ROYAL_PAY_CARD_TEST]                           : 'Royal Pay Card Test',
	[PaymentID.PAYEER]                                        : 'Payeer',
	[PaymentID.PIASTRIX_WALLET_KZT]                           : 'Piastrix Wallet KZT',
	[PaymentID.PIASTRIX_WALLET_RUB]                           : 'Piastrix Wallet RUB',
	[PaymentID.PIASTRIX_WALLET_USD]                           : 'Piastrix Wallet USD',
	[PaymentID.PIASTRIX_WALLET_EUR]                           : 'Piastrix Wallet EUR',
	[PaymentID.PIASTRIX_VISA_MC_UAH]                          : 'Piastrix Visa/MasterCard UAH',
	[PaymentID.MUCHBETTER]                                    : 'MuchBetter',
	[PaymentID.API_PAY]                                       : 'API Pay',
	[PaymentID.CRYPTO_PROCESSING]                             : 'Crypto Processing',
	[PaymentID.APPEX]                                         : 'Appex',
	[PaymentID.MALDO_PAY_DEPOSIT]                             : 'Maldopay Deposit',
	[PaymentID.MALDO_PAY_BANK]                                : 'Maldopay Bank',
	[PaymentID.MALDO_PAY_PAPARA]                              : 'Maldopay Papara',
	[PaymentID.MALDO_PAY_PAPARAPAY_DEPOSIT]                   : 'Maldopay Paparapay Deposit',
	[PaymentID.MALDO_PAY_PAYKWIK_PREPAID]                     : 'Maldopay Paykwik Prepaid',
	[PaymentID.MALDO_PAY_PAYFIX]                              : 'Maldopay Payfix',
	[PaymentID.MALDO_PAY_MEFETE]                              : 'Maldopay Mefete',
	[PaymentID.MALDO_PAY_ASTROPAY_DEPOSIT]                    : 'Maldopay Astropay Deposit',
	[PaymentID.MALDO_PAY_ASTROPAY_WITHDRAW]                   : 'Maldopay Astropay Withdraw',
	[PaymentID.MALDO_PAY_CEPBANK_DEPOSIT]                     : 'Maldopay Cepbank Deposit',
	[PaymentID.MALDO_PAY_COMMUNITY_BANKING_DEPOSIT]           : 'Maldopay Community Banking Deposit',
	[PaymentID.MALDO_PAY_ANINDA_HAVALE_DEPOSIT]               : 'Maldopay Aninda Havale Deposit',
	[PaymentID.MALDO_PAY_MKAREKOD_QR_CODE_DEPOSIT]            : 'Maldopay Mkarekod QR Code Deposit',
	[PaymentID.MALDO_PAY_FATURAPAY_DEPOSIT]                   : 'Maldopay Faturapay Deposit',
	[PaymentID.JETON_CASH_DEPOSIT]                            : 'Jeton Cash Deposit',
	[PaymentID.JETON_WALLET_MANUEL_BANK_TRANSFER]             : 'Jeton Wallet Manuel Bank Transfer',
	[PaymentID.JETON_WALLET_P24]                              : 'Jeton Wallet P24',
	[PaymentID.JETON_CASH_WITHDRAW]                           : 'Jeton Cash Withdraw',
	[PaymentID.INTERKASSA_INPS_CPAYTRZ_MERCHANTUPI_INR]       : 'Interkassa Inps Cpaytrz Merchant UPI INR',
	[PaymentID.INTERKASSA_INPS_CPAYTRZ_MERCHANTWALLET_INR]    : 'Interkassa Inps Cpaytrz Merchant Wallet INR',
	[PaymentID.INTERKASSA_INPS_CPAYTRZ_MERCHANTNETBANKING_INR]: 'Interkassa Inps Cpaytrz Merchant Netbanking INR',
	[PaymentID.INTERKASSA_CARD_CPAYTRZ_MERCHANT_INR]          : 'Interkassa Card Cpaytrz Merchant INR',
	[PaymentID.INTERKASSA_MASTER_CARD_EUR]                    : 'Interkassa Master Card EUR',
	[PaymentID.INTERKASSA_MASTER_CARD_RUB]                    : 'Interkassa Master Card RUB',
	[PaymentID.PIASTRIX_VISA_MC_RUB]                          : 'Piastrix Visa/MasterCard RUB',
	[PaymentID.INTERKASSA_INPS_CPAYTRZ_TRANSFERBANK_INR]      : 'Interkassa Inps Cpaytrz Transfer Bank INR',
	[PaymentID.INTERKASSA_INPS_CPAYTRZ_TRANSFERUPI_INR]       : 'Interkassa Inps Cpaytrz Transfer UPI INR',
	[PaymentID.TRONLINK]                                      : 'Tronlink',
	[PaymentID.MUCHBETTERGATEWAY]                             : 'MuchBetter Gateway',
	[PaymentID.TEST_PAYMENT]                                  :	'Test Payment',
	[PaymentID.ANINDA_BANKA]                                  : 'Aninda Banka',
	[PaymentID.ANINDA_HAVALE]                                 : 'Aninda Havale',
	[PaymentID.ANINDA_KREDI_KARTI_CEKIM]                      : 'Aninda Kredi Karti Cekim',
	[PaymentID.ANINDA_PAYPAY]                                 : 'Aninda Paypay',
	[PaymentID.ANINDA_PARAZULA]                               : 'Aninda Parazula',
	[PaymentID.ANINDA_KREDI_KARTI]                            : 'Aninda Kredi Karti',
	[PaymentID.ANINDA_PAPARA]                                 : 'Aninda Papara',
	[PaymentID.ANINDA_KRIPTO]                                 : 'Aninda Kripto',
	[PaymentID.ANINDA_QR]                                     : 'Aninda QR',
	[PaymentID.PAY_FIX]                                       : 'Pay Fix',
	[PaymentID.PAYCO]                                         : 'Payco',
};


export const CASINO_PROVIDERS = {
	EVOPLAY        : 1,
	SLOTEGRATOR    : 2,
	BETGAMES       : 3,
	BETSOFT        : 4,
	PLAYSON        : 5,
	SPINOMENAL     : 6,
	IGROSOFT       : 7,
	REDRAKE        : 8,
	GAMEART        : 9,
	HABANERO       : 10,
	PLATIPUS       : 11,
	VIVOGAMING     : 12,
	TOMHORN        : 13,
	ENDORPHINA     : 14,
	AMATIC         : 15,
	BOOONGO        : 16,
	MICROGAMING    : 17,
	BIG_TIME_GAMING: 18,
	GAMSHY         : 19,
	MASCOT         : 20,
	PRAGMATIC      : 21,
	DLV            : 22,
	B2BSlots       : 23,
	IGSlots        : 24,
	AlgNet         : 25,
};

export const BET_PROVIDERS = {
	all         : 0,
	ladBrokers  : 1,
	redKings    : 2,
	myBet       : 3,
	sbTech      : 4,
	williamHills: 5,
	betFair     : 6,
	betWay      : 7,
	coral       : 8,
	pinnacle    : 9,
	bet365      : 10,
	betSafe     : 11,
	bWin        : 12,
	sports888   : 13,
	betAtHome   : 14,
	intertops   : 15,
	tonyBet     : 16,
	tipico      : 17,
	tenBet      : 18,
	arcaneBet   : 19,
	tipBet      : 20,
	nordicBet   : 21,
	betsson     : 22,
	betOnline   : 23,
	oneXBet     : 24,
	lsports     : 25,
};

export const DASHBOARD_PERIOD_MODE = {
	arbitrary         : 'arbitrary',
	lastWeek          : 'lastWeek',
	lastMonth         : 'lastMonth',
	lastThreeMonth    : 'lastThreeMonth',
	lastYear          : 'lastYear',
	previousWeek      : 'previousWeek',
	previousMonth     : 'previousMonth',
	previousThreeMonth: 'previousThreeMonth',
	previousYear      : 'previousYear',
};

export const DASHBOARD_TABS = {
	players       : 'players',
	deposits      : 'deposits',
	withdrawals   : 'withdrawals',
	placedBets    : 'placedBets',
	betsByProvider: 'betsByProvider',
};


export const BONUS_TYPES = {
	depositBonus        : 1,
	sportsbookBonus     : 2,
	welcome             : 3,
	firstDeposit        : 4,
	freeSpin            : 5,
	birthday            : 6,
	bonusSpin           : 7,
	registrationFreeSpin: 8,
};

export const RISK_MANAGEMENT_COMMENT = {
	resolved  : 1,
	unResolved: 2,
};


export const ADMIN_TABLE_TYPES = {
	BET_RTM                       : AdminTableType.BET_RTM,
	BET_PROFILE                   : AdminTableType.BET_PROFILE,
	DEPOSIT_RTM                   : AdminTableType.DEPOSIT_RTM,
	DEPOSIT_PROFILE               : AdminTableType.DEPOSIT_PROFILE,
	WITHDRAW_RTM                  : AdminTableType.WITHDRAW_RTM,
	WITHDRAW_PROFILE              : AdminTableType.WITHDRAW_PROFILE,
	CASINO_RTM                    : AdminTableType.CASINO_RTM,
	CASINO_PROFILE                : AdminTableType.CASINO_PROFILE,
	EVENTS                        : AdminTableType.EVENTS,
	RESULTS                       : AdminTableType.RESULTS,
	CUSTOMERS_SEARCH              : AdminTableType.CUSTOMERS_SEARCH,
	CUSTOMERS_GROUPS              : AdminTableType.CUSTOMERS_GROUPS,
	TRANSACTIONS                  : AdminTableType.TRANSACTIONS,
	INFO_PAGES                    : AdminTableType.INFO_PAGES,
	NEWS                          : AdminTableType.NEWS,
	BANNERS                       : AdminTableType.BANNERS,
	BONUSES_FREE_SPINS            : AdminTableType.BONUSES_FREE_SPINS,
	BONUSES_REGISTRATION_FREE_SPIN: AdminTableType.BONUSES_REGISTRATION_FREE_SPIN,
	BONUSES_WELCOME_DEPOSIT       : AdminTableType.BONUSES_WELCOME_DEPOSIT,
	BONUSES                       : AdminTableType.BONUSES_FIRST_DEPOSIT,
	PERSONAL                      : AdminTableType.PERSONAL,
	CONTENT_INFO_PAGES            : AdminTableType.CONTENT_INFO_PAGES,
	CONTENT_NEWS                  : AdminTableType.CONTENT_NEWS,
	WEBSITES                      : AdminTableType.WEBSITES,
	BANNERS_GROUPS                : AdminTableType.BANNERS_GROUPS,
	EMAILS_LIST                   : AdminTableType.EMAILS_LIST,
	EMAIL_TEMPLATES               : AdminTableType.EMAIL_TEMPLATES,
	CASHBACK                      : AdminTableType.CASHBACK,
	CASHBACK_USERS                : AdminTableType.CASHBACK_USERS,
	HOMEPAGE_SPORTS_PRIORITIES    : AdminTableType.HOMEPAGE_SPORTS_PRIORITIES,
	HOMEPAGE_TOP_LIVE_EVENTS      : AdminTableType.HOMEPAGE_TOP_LIVE_EVENTS,
	HOMEPAGE_MATCH_OF_THE_DAY     : AdminTableType.HOMEPAGE_MATCH_OF_THE_DAY,
	HOMEPAGE_UPCOMING_EVENTS      : AdminTableType.HOMEPAGE_UPCOMING_EVENTS,
	BONUSES_FREE_BETS             : AdminTableType.BONUSES_FREE_BETS,
	REPORT_BY_PLAYER              : AdminTableType.REPORT_BY_PLAYER,
	PROMOTIONS                    : AdminTableType.PROMOTIONS,
	HELP_CENTER                   : AdminTableType.HELP_CENTER,
	REPORT_BY_GAME                : AdminTableType.REPORT_BY_GAME,
	REPORT_BY_PROVIDER            : AdminTableType.REPORT_BY_PROVIDER,
	REPORT_BY_INTEGRATOR          : AdminTableType.REPORT_BY_INTEGRATOR,
	PARTNERS                      : AdminTableType.PARTNER_LIST,
	NOTIFICATION                  : AdminTableType.NOTIFICATION_TABLE,
	GAME                          : AdminTableType.GAME_LIST,
	DOCUMENTS_RTM                 : AdminTableType.DOCUMENT_RTM,
	RISK_MANAGEMENT_RTM           : AdminTableType.RISK_MANAGEMENT_RTM,
	SMS_LISTING                   : AdminTableType.SMS_LIST,
	TOURNAMENTS                   : AdminTableType.TOURNAMENT,
	SPORT_RISK_MANAGEMENT         : AdminTableType.SPORT_RISK_MANAGEMENT,
	USER_ALL_TRANSACTION          : AdminTableType.TRANSACTIONS_PROFILE,
	MASS_BONUSES                  : AdminTableType.MASS_BONUSES,
	BONUSES_TEMPLATES             : AdminTableType.BONUS_TEMPLATES,
	AGENTS_LIST                   : AdminTableType.AGENT_LIST,
	AGENT_PLAYER_LIST             : AdminTableType.AGENT_PLAYER_LIST,
	AGENT_TRANSACTIONS            : AdminTableType.AGENT_TRANSACTIONS,
};

export const POINT_GENERATION_RULE_TYPE = {
	allStakes: 'allStakes',
	winStakes: 'winStakes',
};

export const POINT_GENERATION_LOGIC_TYPE = {
	totalSpins: 'totalSpins',
	totalSpent: 'totalSpent',
};
