import { apiRequest } from './index';

export function apiLogin(authData, params = {}) {
	const req = {
		method: 'POST',
		url   : '/admin/login',
		data  : authData,
		params,
	};
	return apiRequest(req);
}
export function logout(idToken) {

	const req = {
		method: 'POST',
		url   : '/logout',
		idToken,
	};
	return apiRequest(req);
}

export function newPasswordSet(authData) {
	const req = {
		method: 'POST',
		url   : '/admin/changePassword',
		data  : authData,
	};
	return apiRequest(req);
}


export const authApi = {
	apiLogin,
	newPasswordSet,
	logout,
};
