import {
	CHANNEL_TYPES,
	SOURCE_TYPES,
	YES_NO,
	TRADING_MODE,
	BET_TYPES,
	DEPOSIT_BONUS_TYPES,
	DEPOSIT_TYPES,
	WITHDRAWAL_TYPES,
	WITHDRAWAL_ELIGIBILITY,
	ACTIVE_STATUS,
	WEBSITE_STATUS,
	LANGUAGES,
	PRODUCTS,
	CASINO_API_VERSIONS,
	CASINO_API_HOSTS,
	WEBSITE_CASINO_STATUS,
	TRANSACTION_TYPES,
	CASINO_GAMES_STATUS,
	CASINO_PROVIDERS,
	BET_PROVIDERS,
	DASHBOARD_PERIOD_MODE,
	CASINO_BET_TYPES,
	TRANSACTION_SOURCES,
	BANNER_USER_STATUS,
	WEBSITE_USER_CONFIGS,
	SUB_CATEGORY_STATUS,
	PARTNER_FILTER_STATUS,
	POINT_GENERATION_RULE_TYPE,
	POINT_GENERATION_LOGIC_TYPE,
} from './commonConstants';
import { BONUS_TYPES } from '../constants/bonuses';
import config from '../containers/Topbar/LanguageSwitcher/config';
import {
	BetSlipStatus, CasinoBetStatus,
	EmailCategory,
	EmailSendStatus,
	EmailTemplateType, Gender,
	InformationPageType,
	LinkAction,
	MailingProtocol,
	MoneyType,
	PaymentStatus, PaywaysStatus,
	PromotionStatus, RiskAlertResolutionStatus, RiskManagementPriority, RiskType,
	SmsStatus,
	TransactionDecision,
	TransactionStatus,
	UserDocumentReviewStatusType,
	UserDocumentType,
	WebsiteGameLoadType,
	WebsiteScriptPosition,
	WebsiteScriptType,
	MessagingType,
} from '@ceo-betmakers/common-globals';

// languages used for user interface of Admin site
export const languagesList = [
	{ id: LANGUAGES.en, name: 'English' },
	{ id: LANGUAGES.ru, name: 'Russian' },
	{ id: LANGUAGES.ua, name: 'Ukrainian' },
];

export const gameListLoadTypes = [
	{ id: WebsiteGameLoadType.INFINITE_SCROLL,  name: 'Infinitive scroll' },
	{ id: WebsiteGameLoadType.LOAD_MORE_BUTTON,          name: 'Load more' },
];

// languages used for menu in database (lang_id)
export const contentLanguageList = [
	{ id: LANGUAGES.en, name: 'English' },
	{ id: LANGUAGES.ru, name: 'Russian' },
	{ id: LANGUAGES.ua, name: 'Ukrainian' },
	{ id: LANGUAGES.tr, name: 'Turkish' },
	{ id: LANGUAGES.de, name: 'German' },
];
export const langList = config.options.map(item => ({ id: item.dataBaseLangID, name: item.text }));
// user statuses for banners
export const bannerUserStatusList = [
	{ id: BANNER_USER_STATUS.LOGGEDIN, name: 'Logged In' },
	{ id: BANNER_USER_STATUS.FIRST_TIME_VISITOR, name: 'First Time Visitor' },
	{ id: BANNER_USER_STATUS.RETURNING_VISITOR, name: 'Returning Visitor' },
	{ id: BANNER_USER_STATUS.LOGGEDIN_RETURNING_VISITOR, name: 'Logged in Returning Visitor' },
];

export const channelTypeList = [
	{ id: CHANNEL_TYPES.web, name: 'Web' },
	{ id: CHANNEL_TYPES.mobile, name: 'Mobile' },
	{ id: CHANNEL_TYPES.tablet, name: 'Tablet' },
	{ id: CHANNEL_TYPES.desktop, name: 'Desktop' },
	{ id: CHANNEL_TYPES.backend, name: 'Backend' },
];

export const channelTypeListByID = [
	{ channelID: CHANNEL_TYPES.web },
	{ channelID: CHANNEL_TYPES.mobile },
	{ channelID: CHANNEL_TYPES.tablet },
	{ channelID: CHANNEL_TYPES.desktop },
	{ channelID: CHANNEL_TYPES.backend },
];

export const tradingModeList = [
	{ id: TRADING_MODE.both, name: 'Both' },
	{ id: TRADING_MODE.prematch, name: 'Prematch' },
	{ id: TRADING_MODE.live, name: 'In-Play' },
];

export const tradingModeSportRiskList = [
	{ id: TRADING_MODE.both, name: 'All' },
	{ id: TRADING_MODE.prematch, name: 'Prematch' },
	{ id: TRADING_MODE.live, name: 'In-Play' },
];
export const bettorGroupRiskList = [
	{ id: 1, name: 'Farmer' },
	{ id: 2, name: 'Value Bettor' },
	{ id: 3, name: 'Highroller' },
];

export const sourceTypeList = [
	{ id: SOURCE_TYPES.feed,  name: 'Feed' },
	{ id: SOURCE_TYPES.admin, name: 'Admin' },
	{ id: SOURCE_TYPES.casino,  name: 'Casino' },
	{ id: SOURCE_TYPES.paymentSystem, name: 'Payment System' },
	{ id: SOURCE_TYPES.system, name: 'System' },
];

export const sourceTypeListData = [
	{ id: 1,  name: 'Feed' },
	{ id: 2,  name: 'Admin' },
	{ id: 3,  name: 'Casino' },
	{ id: 4,  name: 'Payment System' },
	{ id: 5,  name: 'System' },
	{ id: 6,  name: 'Cashback' },
	{ id: 7,  name: 'Sport' },
	{ id: 8,  name: 'User' },
	{ id: 9,  name: 'Agent' },
];

export const betsSourceTypeList = [
	{ id: SOURCE_TYPES.feed,  name: 'Feed' },
	{ id: SOURCE_TYPES.admin, name: 'Admin' },
];

export const generalStatusList = [
	{ id: 1, name: 'Active' },
	{ id: 2, name: 'Inactive' },
	{ id: 3, name: 'Archived' },
];

export const displayStatusList = [
	{ id: 1, name: 'Visible' },
	{ id: 2, name: 'Invisible' },
	{ id: 3, name: 'Partly visible' },
];

export const tradingStatusList = [
	{ id: 1, name: 'Active' },
	{ id: 2, name: 'Suspended' },
];

export const genderList = [{ id: Gender.MALE, name: 'Male' }, { id: Gender.FEMALE, name: 'Female' }];

export const limitTypesList = [
	{ id: 1, name: 'Absolute' },
	{ id: 2, name: 'Percent' },
];

export const promotionStatuses = [
	{ id: PromotionStatus.ACTIVE, name: 'Active' },
	{ id: PromotionStatus.INACTIVE, name: 'Inactive' },
];

export const timesAcceptBetsList = [
	{ id: 1, name: 'minutes' },
	{ id: 2, name: 'hours' },
	{ id: 3, name: 'days' },
	{ id: 4, name: 'weeks' },
	{ id: 6, name: 'always' },
];

export const timesDinamicLimitsList = [
	{ id: 1, name: 'minutes' },
	{ id: 2, name: 'hours' },
	{ id: 3, name: 'days' },
];

export const tournamentTypesList = [
	{ id: 1, name: 'Classic Tournament' },
	{ id: 2, name: 'Cup Tournament' },
	{ id: 3, name: 'Race' },
	{ id: 4, name: 'One event' },
	{ id: 5, name: 'Other (input box)' },
];

export const yesNoList = [
	{ id: YES_NO.yes, name: 'Yes' },
	{ id: YES_NO.no, name: 'No' },
];

export const betTypesList = [
	{ id: BET_TYPES.single,  name: 'Single' },
	{ id: BET_TYPES.express, name: 'Multibet' },
	{ id: BET_TYPES.system,  name: 'System' },
	{ id: BET_TYPES.special_offer, name: 'Special Offer' },
];

export const betSettlementStatusList = [
	{ id: 1, name: 'Win' },
	{ id: 2, name: 'Lost' },
	{ id: 3, name: 'Cancel' },
	{ id: 4, name: 'Void' },
	{ id: 5, name: 'Partial win' },
	{ id: 6, name: 'Partial lost' },
];

export const depositBonusTypeList = [
	{ id: DEPOSIT_BONUS_TYPES.registrationBonus, name: 'Registration Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.depositBonus, name: 'Deposit Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.gamingBonus, name: 'Gaming Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.specialOfferBonus, name: 'Special Offer Bonus' },
	{ id: DEPOSIT_BONUS_TYPES.manualAdjustment, name: 'Manual Adjustment' },
	{ id: DEPOSIT_BONUS_TYPES.other, name: 'Other' },
];

export const depositTypeList = [
	{ id: DEPOSIT_TYPES.paymentSystem, name: 'Payment System' },
	{ id: DEPOSIT_TYPES.manual, name: 'Manual' },
	{ id: DEPOSIT_TYPES.bonus, name: 'Bonus' },
	{ id: DEPOSIT_TYPES.transferFromOtherUser, name: 'Transferred from other customer' },
	{ id: DEPOSIT_TYPES.cashback, name: 'Cashback' },
	{ id: DEPOSIT_TYPES.agent, name: 'Agent' },
];

export const depositStatusList = [
	{ id: TransactionStatus.SUCCESS, name: 'Success' },
	{ id: TransactionStatus.PENDING, name: 'Pending' },
	{ id: TransactionStatus.FAILED, name: 'Failed' },
	{ id: TransactionStatus.IN_REVIEW, name: 'In Admin Review' },
	{ id: TransactionStatus.CANCELLED, name: 'Cancelled' },
	{ id: TransactionStatus.CANCELLED_BY_ADMIN, name: 'Cancelled by Admin' },
	{ id: TransactionStatus.REFUND, name: 'Refund' },
	{ id: TransactionStatus.REFUNDING, name: 'Refunding' },
	{ id: TransactionStatus.EXPIRED, name: 'Expired' },
	{ id: TransactionStatus.PROCCESSING, name: 'Processing' },
	{ id: TransactionStatus.PARTIAL_REFUND, name: 'Partial Refund' },
	{ id: TransactionStatus.APPROVED, name: 'Approved by Admin' },
];

export const withdrawalTypeList = [
	{ id: WITHDRAWAL_TYPES.paymentSystem, name: 'Payment System' },
	{ id: WITHDRAWAL_TYPES.manual, name: 'Manual' },
	{ id: WITHDRAWAL_TYPES.bonus, name: 'Bonus' },
	{
		id  : WITHDRAWAL_TYPES.transferFromOtherUser,
		name: 'Transferred to other customer',
	},
	{ id: WITHDRAWAL_TYPES.agent, name: 'Agent' },
];

export const withdrawalStatusList = [
	{ id: TransactionStatus.SUCCESS, name: 'Success' },
	{ id: TransactionStatus.PENDING, name: 'Pending' },
	{ id: TransactionStatus.FAILED, name: 'Failed' },
	{ id: TransactionStatus.IN_REVIEW, name: 'In Admin Review' },
	{ id: TransactionStatus.CANCELLED, name: 'Cancelled' },
	{ id: TransactionStatus.CANCELLED_BY_ADMIN, name: 'Cancelled by Admin' },
	{ id: TransactionStatus.REFUND, name: 'Refund' },
	{ id: TransactionStatus.REFUNDING, name: 'Refunding' },
	{ id: TransactionStatus.EXPIRED, name: 'Expired' },
	{ id: TransactionStatus.PROCCESSING, name: 'Processing' },
	{ id: TransactionStatus.PARTIAL_REFUND, name: 'Partial Refund' },
	{ id: TransactionStatus.APPROVED, name: 'Approved by Admin' },
];

export const withdrawalEligibilityList = [
	{ id: WITHDRAWAL_ELIGIBILITY.yes, name: 'Yes' },
	{ id: WITHDRAWAL_ELIGIBILITY.no, name: 'No' },
	{ id: WITHDRAWAL_ELIGIBILITY.auto, name: 'Auto' },
];

export const withdrawalDecisionList = [
	{ id: TransactionDecision.APPROVED, name: 'Approved' },
	{ id: TransactionDecision.DECLINED, name: 'Rejected' },
];

export const activeStatusList = [
	{ id: ACTIVE_STATUS.active, name: 'Active' },
	{ id: ACTIVE_STATUS.inactive, name: 'Inactive' },
];

export const infoPageTypeList = [
	{ id: InformationPageType.INTERNAL, name: 'Internal' },
	{ id: InformationPageType.EXTERNAL, name: 'External' },
];

export const betStatusList = [
	{ id: BetSlipStatus.OPEN, name: 'Open' },
	{ id: BetSlipStatus.LOST, name: 'Lost' },
	{ id: BetSlipStatus.PARTIAL_LOST, name: 'Partial lost' },
	{ id: BetSlipStatus.WIN, name: 'Win' },
	{ id: BetSlipStatus.PARTIAL_WIN, name: 'Partial win' },
	{ id: BetSlipStatus.CANCELLED, name: 'Cancelled' },
	{ id: BetSlipStatus.VOID, name: 'Void' },
	{ id: BetSlipStatus.IN_ADMIN_REVIEW, name: 'In Admin review' },
	{ id: BetSlipStatus.IN_USER_REVIEW, name: 'In User review' },
	{ id: BetSlipStatus.ADMIN_REJECTED, name: 'Admin rejected' },
	{ id: BetSlipStatus.USER_REJECTED, name: 'User rejected' },
	{ id: BetSlipStatus.AUTO_REJECTED, name: 'Auto rejected' },
];

export const linkActionTypeList = [
	{ id: LinkAction.SELF, name: 'Self' },
	{ id: LinkAction.BLANK, name: 'Blank' },
];

export const websiteStatusList = [
	{ id: WEBSITE_STATUS.active, name: 'Active' },
	{ id: WEBSITE_STATUS.blocked, name: 'Blocked' },
];

export const subCategoryList = [
	{ id: SUB_CATEGORY_STATUS.both, name: 'Both' },
	{ id: SUB_CATEGORY_STATUS.provider, name: 'Provider' },
	{ id: SUB_CATEGORY_STATUS.customProvider, name: 'Custom Provider' },
];


export const websiteUserConfigsList = [
	{ id: WEBSITE_USER_CONFIGS.none, name: 'None' },
	{ id: WEBSITE_USER_CONFIGS.deposit, name: 'Deposit' },
	{ id: WEBSITE_USER_CONFIGS.withdrawal, name: 'Withdrawal' },
];

export const websiteProtocolList = [
	{ id: MailingProtocol.NONE, name: 'None' },
	{ id: MailingProtocol.SSL, name: 'SSL' },
	{ id: MailingProtocol.TLS, name: 'TLS' },
];

export const websitePaymentsStatusList = [
	{ id: PaymentStatus.ACTIVE, name: 'Active' },
	{ id: PaymentStatus.INACTIVE, name: 'Blocked' },
];

export const productsList = [
	{ id: PRODUCTS.sport, name: 'Sport' },
	{ id: PRODUCTS.system, name: 'System' },
	{ id: PRODUCTS.casino, name: 'Casino' },
];

export const emailActionsList = [
	{ id: EmailTemplateType.VERIFY_EMAIL, name: 'Verify Email' },
	{ id: EmailTemplateType.EMAIL_VERIFIED, name: 'Email Verified' },
	{ id: EmailTemplateType.FORGET_PASSWORD_REQUEST, name: 'Forget Password Request' },
	{ id: EmailTemplateType.FORGET_PASSWORD_SUCCESS, name: 'Forget Password Complete' },
	{ id: EmailTemplateType.CHANGE_PASSWORD_SUCCESS, name: 'Change Password Complete' },
	{ id: EmailTemplateType.NEW_IP_LOGIN, name: 'New IP Login' },
	{ id: EmailTemplateType.WITHDRAWAL_NOT_APPROVED, name: 'Withdraw Not Approved' },
	{ id: EmailTemplateType.PENDING_WITHDRAWAL, name: 'Pending Withdraw' },
	{ id: EmailTemplateType.APPROVED_WITHDRAWAL, name: 'Approved Withdraw' },
	{ id: EmailTemplateType.WITHDRAWAL_CANCELLATION, name: 'Withdraw Cancellation' },
	{ id: EmailTemplateType.BONUS, name: 'Bonus' },
	{ id: EmailTemplateType.PROMO_CODE, name: 'Promo Code' },
	{ id: EmailTemplateType.VERIFICATION_KEY, name: 'Verification Key' },
	{ id: EmailTemplateType.WELCOME_MESSAGE, name: 'Welcome Message' },
	{ id: EmailTemplateType.CHANGE_EMAIL, name: 'Change Email' },
	{ id: EmailTemplateType.FREE_SPIN_BONUS, name: '${freeSpin_count} Free Spins' },
	{ id: EmailTemplateType.FIRST_DEPOSIT_BONUS, name: '${percent}% First deposit bonus' },
	{ id: EmailTemplateType.JETON_CASH_WITHDRAW_RESPONSE, name: 'Jeton Cash Withdraw Response' },
	{ id: EmailTemplateType.SET_NEW_PASSWORD, name: 'SET PARTNER PASSWORD' },
	{ id: EmailTemplateType.CHECK_EMAIL, name: 'CHECK EMAIL TEST' },
	{ id: EmailTemplateType.SUSPEND_PARTNER, name: 'SUSPEND PARTNER' },
];

export const casinoApiVersionList = [
	{ id: CASINO_API_VERSIONS.one, name: 'Version 1' },
	{ id: CASINO_API_VERSIONS.two, name: 'Version 2' },
];

export const casinoApiHostList = [
	{ id: CASINO_API_HOSTS.europe, name: 'Europe' },
	{ id: CASINO_API_HOSTS.asia, name: 'Asia' },
];

export const websiteCasinoStatusList = [
	{ id: WEBSITE_CASINO_STATUS.inactive, name: 'Inactive' },
	{ id: WEBSITE_CASINO_STATUS.active, name: 'Active' },
];


export const transactionTypeList = [
	{ id: TRANSACTION_TYPES.all,               name: 'All' },
	{ id: TRANSACTION_TYPES.bet,               name: 'Bet' },
	{ id: TRANSACTION_TYPES.withdraw,          name: 'Withdraw' },
	{ id: TRANSACTION_TYPES.manual,            name: 'Manual' },
	{ id: TRANSACTION_TYPES.deposit,           name: 'Deposit' },
	{ id: TRANSACTION_TYPES.userToUser,        name: 'User-to-User' },
	{ id: TRANSACTION_TYPES.bonus,             name: 'Bonus' },
	{ id: TRANSACTION_TYPES.betReverse,        name: 'Bet Reverse' },
	{ id: TRANSACTION_TYPES.wonReverse,        name: 'Won Reverse' },
	{ id: TRANSACTION_TYPES.won,               name: 'Won' },
	{ id: TRANSACTION_TYPES.balanceAdjustment, name: 'Balance Adjustment' },
	{ id: TRANSACTION_TYPES.cashback,          name: 'Cashback' },
];

export const transactionStatusList = [
	{ id: TransactionStatus.SUCCESS,       			name: 'Success' },
	{ id: TransactionStatus.PENDING,       			name: 'Pending' },
	{ id: TransactionStatus.FAILED,        			name: 'Failed' },
	{ id: TransactionStatus.IN_REVIEW,     			name: 'In Review' },
	{ id: TransactionStatus.CANCELLED,     			name: 'Cancelled' },
	{ id: TransactionStatus.CANCELLED_BY_ADMIN, name: 'Cancelled By Admin' },
	{ id: TransactionStatus.REFUND,        			name: 'Refund' },
	{ id: TransactionStatus.REFUNDING,     			name: 'Refunding' },
	{ id: TransactionStatus.EXPIRED,       			name: 'Expired' },
	{ id: TransactionStatus.PROCCESSING,   			name: 'Processing' },
	{ id: TransactionStatus.PARTIAL_REFUND,			name: 'Partial Refund' },
	{ id: TransactionStatus.APPROVED, 					name: 'Approved by Admin' },
];

export const paywayStatusList = [
	{ id: PaywaysStatus.VISIBLE,  name: 'Visible' },
	{ id: PaywaysStatus.HIDDEN,   name: 'Hidden' },
];

export const emailCategoryList = [
	{ id: EmailCategory.INBOX,     name: 'Inbox' },
	{ id: EmailCategory.DRAFT,     name: 'Draft' },
	{ id: EmailCategory.SPAM,      name: 'Spam' },
	{ id: EmailCategory.SENT,      name: 'Sent' },
	{ id: EmailCategory.TRASH,     name: 'Trash' },
	{ id: EmailCategory.IMPORTANT, name: 'Important' },
];

export const emailStatusList = [
	{ id: EmailSendStatus.IN_PROCESS, name: 'In Process' },
	{ id: EmailSendStatus.SUCCESS,   name: 'Success' },
	{ id: EmailSendStatus.ERROR,     name: 'Error' },
];

export const SMSStatusList = [
	{ id: SmsStatus.FAILED, name: 'Failed' },
	{ id: SmsStatus.SUCCESS, name: 'Success' },
];

export const invertedSMSStatus = {
	[SmsStatus.FAILED] : 'common.failed',
	[SmsStatus.SUCCESS]: 'common.success',
	[SmsStatus.PENDING]: 'common.pending',
};

export const userDocumentTypesList = [
	{ id: UserDocumentType.INTERNATIONAL_PASSPORT,	name: 'International Passport' },
	{ id: UserDocumentType.NATIONAL_PASSPORT,     	name: 'National Passport' },
	{ id: UserDocumentType.DRIVING_LICENCE,       	name: 'Driving License' },
	{ id: UserDocumentType.ADDRESS,               	name: 'Bill' },
	{ id: UserDocumentType.OTHER,                		name: 'Other' },
	{ id: UserDocumentType.SELFIE,               		name: 'Selfie' },
];

export const userDocumentStatusList = [
	{ id: UserDocumentReviewStatusType.AWAITING_REVIEW, name: 'Awaiting Review' },
	{ id: UserDocumentReviewStatusType.ACCEPTED,        name: 'Accepted' },
	{ id: UserDocumentReviewStatusType.DECLINED,        name: 'Declined' },
];

export const userDocumentStatusEntities = {
	[UserDocumentReviewStatusType.AWAITING_REVIEW]: 'Awaiting Review' ,
	[UserDocumentReviewStatusType.ACCEPTED]       : 'Accepted' ,
	[UserDocumentReviewStatusType.DECLINED]       : 'Declined',
}
;

export const websiteScriptPositionList = [
	{ id: WebsiteScriptPosition.HEADER, name: 'Head' },
	{ id: WebsiteScriptPosition.BODY, name: 'Body' },
];

export const websiteScriptCookiesTypeList = [
	{ id: WebsiteScriptType.NONE, name: 'None' },
	{ id: WebsiteScriptType.STRICTLY_NECESSARY, name: 'Strictly Necessary' },
	{ id: WebsiteScriptType.PERFORMANCE_COOKIES, name: 'Performance Cookies' },
	{ id: WebsiteScriptType.FUNCTIONAL_COOKIES, name: 'Functional Cookies' },
	{ id: WebsiteScriptType.TARGETING_COOKIES, name: 'Targeting Cookies' },
];

export const moneyTypesList = [
	{ id: MoneyType.REAL,    name: 'Real' },
	{ id: MoneyType.VIRTUAL, name: 'Virtual' },
];

export const casinoGamesStatusList = [
	{ id: CASINO_GAMES_STATUS.active,   name: 'Active' },
	{ id: CASINO_GAMES_STATUS.inactive, name: 'Inactive' },
];

export const casinoGamesStatusListEntities = {
	[CASINO_GAMES_STATUS.active]  : 'Active',
	[CASINO_GAMES_STATUS.inactive]: 'Inactive',
};


export const casinoProvidersList = [
	{ id: CASINO_PROVIDERS.EVOPLAY,         name: 'EvoPlay' },
	{ id: CASINO_PROVIDERS.BETGAMES,        name: 'BetGames' },
	{ id: CASINO_PROVIDERS.BETSOFT,         name: 'BetSoft' },
	{ id: CASINO_PROVIDERS.PLAYSON,         name: 'PlaySon' },
	{ id: CASINO_PROVIDERS.SPINOMENAL,      name: 'Spinomenal' },
	{ id: CASINO_PROVIDERS.IGROSOFT,        name: 'IgroSoft' },
	{ id: CASINO_PROVIDERS.GAMEART,         name: 'GameArt' },
	{ id: CASINO_PROVIDERS.HABANERO,        name: 'HabaNero' },
	{ id: CASINO_PROVIDERS.PLATIPUS,        name: 'Platipus' },
	{ id: CASINO_PROVIDERS.VIVOGAMING,      name: 'VivoGaming' },
	{ id: CASINO_PROVIDERS.TOMHORN,         name: 'Tomhorn' },
	{ id: CASINO_PROVIDERS.ENDORPHINA,      name: 'Endorphina' },
	{ id: CASINO_PROVIDERS.AMATIC,          name: 'Amatic' },
	{ id: CASINO_PROVIDERS.BOOONGO,         name: 'Booongo' },
	{ id: CASINO_PROVIDERS.MICROGAMING,     name: 'MicroGaming' },
	{ id: CASINO_PROVIDERS.BIG_TIME_GAMING, name: 'BigTimeGaming' },
	{ id: CASINO_PROVIDERS.GAMSHY,          name: 'Gamshy' },
	{ id: CASINO_PROVIDERS.MASCOT,          name: 'Mascot' },
	{ id: CASINO_PROVIDERS.PRAGMATIC,       name: 'Pragmatic' },
	{ id: CASINO_PROVIDERS.DLV,             name: 'DLV' },
	{ id: CASINO_PROVIDERS.B2BSlots,        name: 'B2B Slots' },
	{ id: CASINO_PROVIDERS.IGSlots,         name: 'IG Slots' },
	{ id: CASINO_PROVIDERS.AlgNet,          name: 'AlgNet' },
];

export const betProvidersList = [
	{ id: BET_PROVIDERS.all,          name: 'All providers' },
	{ id: BET_PROVIDERS.ladBrokers,   name: 'Lad Brokers' },
	{ id: BET_PROVIDERS.redKings,     name: 'Red Kings' },
	{ id: BET_PROVIDERS.myBet,        name: 'My Bet' },
	{ id: BET_PROVIDERS.sbTech,       name: 'Sb Tech' },
	{ id: BET_PROVIDERS.williamHills, name: 'William Hills' },
	{ id: BET_PROVIDERS.betFair,      name: 'Bet Fair' },
	{ id: BET_PROVIDERS.betWay,       name: 'Bet Way' },
	{ id: BET_PROVIDERS.coral,        name: 'Coral' },
	{ id: BET_PROVIDERS.pinnacle,     name: 'Pinnancle' },
	{ id: BET_PROVIDERS.bet365,       name: 'Bet 365' },
	{ id: BET_PROVIDERS.betSafe,      name: 'Bet Safe' },
	{ id: BET_PROVIDERS.bWin,         name: 'Bwin' },
	{ id: BET_PROVIDERS.sports888,    name: 'Sports 888' },
	{ id: BET_PROVIDERS.betAtHome,    name: 'Bet At Home' },
	{ id: BET_PROVIDERS.intertops,    name: 'Intertops' },
	{ id: BET_PROVIDERS.tonyBet,      name: 'Tony Bet' },
	{ id: BET_PROVIDERS.tipico,       name: 'Tipico' },
	{ id: BET_PROVIDERS.tenBet,       name: '10 Bet' },
	{ id: BET_PROVIDERS.arcaneBet,    name: 'Arcane Bet' },
	{ id: BET_PROVIDERS.tipBet,       name: 'Tip Bet' },
	{ id: BET_PROVIDERS.nordicBet,    name: 'Nordic Bet' },
	{ id: BET_PROVIDERS.betsson,      name: 'Betsson' },
	{ id: BET_PROVIDERS.betOnline,    name: 'Bet Online' },
	{ id: BET_PROVIDERS.oneXBet,      name: '1XBet' },
	{ id: BET_PROVIDERS.lsports,      name: 'LSports' },
];

export const dashboardPeriodModeList = [
	{ id: DASHBOARD_PERIOD_MODE.arbitrary,          name: 'Arbitrary' },
	{ id: DASHBOARD_PERIOD_MODE.lastWeek,           name: 'Last week' },
	{ id: DASHBOARD_PERIOD_MODE.lastMonth,          name: 'Last month' },
	{ id: DASHBOARD_PERIOD_MODE.lastThreeMonth,     name: 'Last 3 months' },
	{ id: DASHBOARD_PERIOD_MODE.lastYear,           name: 'Last year' },
	{ id: DASHBOARD_PERIOD_MODE.previousWeek,       name: 'Previous week' },
	{ id: DASHBOARD_PERIOD_MODE.previousMonth,      name: 'Previous month' },
	{ id: DASHBOARD_PERIOD_MODE.previousThreeMonth, name: 'Previous 3 months' },
	{ id: DASHBOARD_PERIOD_MODE.previousYear,       name: 'Previous year' },
];

export const casinoBetStatusList = [
	{ id: CasinoBetStatus.ACTIVE,     name: 'Active' },
	{ id: CasinoBetStatus.WON,        name: 'Won' },
	{ id: CasinoBetStatus.LOST,       name: 'Lost' },
	{ id: CasinoBetStatus.BET_REVERSE, name: 'Bet Reverse' },
];

export const casinoBetTypeList = [
	{ id: CASINO_BET_TYPES.spin, name: 'Spin' },
	{ id: CASINO_BET_TYPES.freeSpin, name: 'Free Spin' },
	{ id: CASINO_BET_TYPES.reSpin, name: 'Re Spin' },
	{ id: CASINO_BET_TYPES.bonusSpin, name: 'Bonus Spin' },
	{ id: CASINO_BET_TYPES.tip, name: 'Tip' },
	{ id: CASINO_BET_TYPES.promo, name: 'Promo' },
];

export const PartnerFilterStatusList = [
	{ id: PARTNER_FILTER_STATUS.active, name: 'Active' },
	{ id: PARTNER_FILTER_STATUS.block,  name: 'Block' },
	{ id: PARTNER_FILTER_STATUS.delete, name: 'Deleted' },
];

export const bonusTypesList = [
	{ id: BONUS_TYPES.depositBonus,         name: 'Deposit Bonus' },
	{ id: BONUS_TYPES.sportsBookBonus,      name: 'Sports Book Bonus' },
	{ id: BONUS_TYPES.welcome,              name: 'Welcome Bonus' },
	{ id: BONUS_TYPES.firstDeposit,         name: 'First Deposit' },
	{ id: BONUS_TYPES.freeSpin,             name: 'Free Spin' },
	{ id: BONUS_TYPES.birthday,             name: 'Birthday' },
	{ id: BONUS_TYPES.bonusSpin,            name: 'Bonus Spin' },
	{ id: BONUS_TYPES.registrationFreeSpin, name: 'Registration Free Spin' },
	{ id: BONUS_TYPES.balanceAdjustment,    name: 'Balance Adjustment' },
];

export const transactionSourceList = Object.keys(TRANSACTION_SOURCES).map(id => (
	{ id, name: TRANSACTION_SOURCES[id] }
));

export const notificationSentTypeList = [
	{ id: MessagingType.GENERAL_NOTIFICATIONS,  name: 'General Notifications' },
	{ id: MessagingType.PUSH_NOTIFICATIONS,  name: 'Push notification' },
	{ id: MessagingType.MESSAGES,  name: 'Messages' },
	{ id: MessagingType.SMS,  name: 'SMS' },

];

export const riskManagementPriorityTypeList = [
	{ id: RiskManagementPriority.LOW,    name: 'Low' },
	{ id: RiskManagementPriority.MEDIUM, name: 'Medium' },
	{ id: RiskManagementPriority.HIGH,   name: 'High' },
];

export const riskManagementRiskTypeList = [
	{ id: RiskType.MAX_DEPOSIT,   name: 'Max Deposit' },
	{ id: RiskType.MAX_WIN,       name: 'Max Win' },
];

export const riskManagementCommentList = [
	{ id: RiskAlertResolutionStatus.RESOLVED,     name: 'Resolved' },
	{ id: RiskAlertResolutionStatus.PENDING,   name: 'Unresolved' },
];

export const riskManagementCommentEntities = {
	[RiskAlertResolutionStatus.RESOLVED]: 'Resolved',
	[RiskAlertResolutionStatus.PENDING] : 'Unresolved',
};

export const loyaltyTournamentsPointsRuleTypes	 = [
	{ id: POINT_GENERATION_RULE_TYPE.allStakes, name: 'All Stakes' },
	{ id: POINT_GENERATION_RULE_TYPE.winStakes, name: 'Win Stakes' },
];

export const loyaltyTournamentsPointsLogicTypes	 = [
	{ id: POINT_GENERATION_LOGIC_TYPE.totalSpins, name: 'Total Spins' },
	{ id: POINT_GENERATION_LOGIC_TYPE.totalSpent, name: 'Total Spent' },
];
