import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Skeleton, Empty } from 'antd';
import { ADMIN_TABLE_TYPES } from '../../../helpers/commonConstants';
import ResizablePanels from '../ResizablePanels';
import tablesActions from '../../../redux/tables/actions';
import { Wrapper } from './TableDragResize.style';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { LayoutContentStyle } from '../../../containers/CustomerManagement/UserInfo/UserInfo.style';
import Spinner from '../../Spinner/Spinner';


class TableDragResize extends Component {

	static propTypes = {
		rowKey          : PropTypes.string,
		activeTabID     : PropTypes.string.isRequired,
		defWidth        : PropTypes.number,
		columns         : PropTypes.arrayOf(PropTypes.object).isRequired,
		dataSource      : PropTypes.arrayOf(PropTypes.object).isRequired,
		customColorsRows: PropTypes.bool,
		loading         : PropTypes.bool.isRequired,
		sortableColumns : PropTypes.arrayOf(PropTypes.string),
		tableType       : PropTypes.string.isRequired,
		saveColumns     : PropTypes.func.isRequired,
		footer          : PropTypes.func,
		cutCells        : PropTypes.bool,
		sortedColumn    : PropTypes.string,
		rowColors       : PropTypes.string,
		rowColorKey     : PropTypes.string,
		expandingData   : PropTypes.object,
		storeTables     : PropTypes.object,
		sortingRefresh  : PropTypes.func,
		loadingUI       : PropTypes.bool,
		highlightID     : PropTypes.number,
	};

	static defaultProps = {
		rowKey          : 'id',
		footer          : null,
		cutCells        : true,
		defWidth        : 120,
		sortableColumns : [],
		sortedColumn    : '',
		rowColors       : '',
		rowColorKey     : '',
		customColorsRows: null,
		expandingData   : {},
		storeTables     : {},
		sortingRefresh  : () => {
		},
		loadingUI           : true,
		tableRowSocketUpdate: -1,
	};

	constructor(props) {
		super(props);

		this.state = {
			sortDirection: 'NONE',
			rows         : [],
			draggingCol  : '',
			droppingIndex: null,
			actualColumns: [],
		};
	}

	componentDidMount() {
		document.addEventListener('scroll', this.handleScroll, true);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { activeTabID } = this.props;
		const newTabID = nextProps.activeTabID;
		if (!isEqual(newTabID, activeTabID)) {
			this.handleScroll();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleScroll, true);
	}

	handleScroll = () => {
		const { tableType } = this.props;
		const wrap = document.getElementById(`${tableType}-wrap`);
		const head = document.getElementById('head-row');
		const bb = wrap ? wrap.getBoundingClientRect() : null;
		const bHead = head ? head.getBoundingClientRect() : null;

		if (tableType !== TABLE_TYPES.homepageSpecialOffers) {
			if (!bb || !bHead) return;
			if (bb.y !== 0 && bb.y < 106 - bHead.height && !wrap.classList.contains('fix-head')) {
				wrap.classList.add('fix-head');
			}
			if (wrap.classList.contains('fix-head') && bb.y > 106) {
				wrap.classList.remove('fix-head');
			}
		}
	};

	dragStart = event => {
		const { tableType, columns } = this.props;

		if (!event.target.id || event.target.id === 'check') return;
		event.dataTransfer.setData('text', event.target.id);
		this.setState({ draggingCol: event.target.id, actualColumns: columns });
		const table = document.getElementById(`${tableType}-wrap`);
		if (table) {
			table.addEventListener('mouseleave', this.drop, true);
		}
	};

	dragOver = event => {
		event.preventDefault();
		const { draggingCol, droppingIndex, actualColumns } = this.state;
		const bb = event.currentTarget.getBoundingClientRect();
		if (draggingCol === event.currentTarget.id) return;

		const column = actualColumns.find(col => {
			return col.key === draggingCol;
		});
		const realColumns = actualColumns.filter(col => {
			return col.key !== draggingCol;
		});
		const columnsNew = [];
		let index = 0;
		for (let i = 0, j = realColumns.length; i < j; i++) {
			if (realColumns[i].key === event.currentTarget.id && column) {
				if (event.clientX > bb.x + parseFloat(bb.width) / 2) {
					columnsNew.push(realColumns[i]);
					columnsNew.push(column);
					index = i + 1;
				} else {
					columnsNew.push(column);
					columnsNew.push(realColumns[i]);
					index = i;
				}
			} else {
				columnsNew.push(realColumns[i]);
			}
		}
		if (droppingIndex !== index) {
			this.setState({ droppingIndex: index, actualColumns: columnsNew });
		}
	};

	drop = event => {
		const parent = event.target?.parentElement;

		if (parent && !parent.className.includes('droppingCol')) return;
		const { tableType } = this.props;
		const { actualColumns } = this.state;
		event.preventDefault();
		this.setState({ draggingCol: '' });
		const table = document.getElementById(`${tableType}-wrap`);
		if (table) {
			table.removeEventListener('mouseleave', this.drop, true);
		}
		this.postSettings(actualColumns);
	};

	dragEnd = () => {
		this.setState({ draggingCol: '' });
	};

	postSettings = value => {
		const { tableType, saveColumns, storeTables } = this.props;
		value = value.map(val => {
			return { key: val.key, width: val.width };
		});
		const typeID = ADMIN_TABLE_TYPES[tableType];
		const table = storeTables.get(tableType);
		saveColumns([
			{
				type_id : typeID,
				settings: {
					columns     : value,
					itemsPerPage: table.pagination.itemsPerPage,
				},
			},
		]);
	};

	resize = (idx, width) => {
		if (!width || width < 10) return;
		const { tableType, columns } = this.props;
		const wrap = document.getElementById(`${tableType}-wrap`);
		columns[idx].width = wrap ? `${width * 100 / wrap.offsetWidth}%` : width;
		this.postSettings(columns);
	};

	sort = event => {
		event.preventDefault();
		const { sortableColumns, sortingRefresh, tableType, rowKey } = this.props;
		if (!sortableColumns.length) return;

		let { sortDirection } = this.state;

		sortDirection = sortDirection === 'NONE' || sortDirection === 'ASC' ? 'DESC' : 'ASC';
		const sortOrder = sortDirection === 'ASC' ? 'asc' : 'desc';
		sortingRefresh(tableType, {
			sortBy: rowKey,
			sortOrder,
		});
	};

	loader(loadingUI, active = true, loading = true, paragraph = false) {

		if (loadingUI) {
			return (
				<div className="skelton">
					<Skeleton active={active} loading={loading} paragraph={paragraph} />
				</div>
			);
		}

		return <div style={{ height: '16px' }} />;
	}

	render() {
		const {
			dataSource,
			footer,
			rowKey,
			cutCells,
			defWidth,
			sortedColumn,
			tableType,
			expandingData,
			loadingUI,
			customColorsRows,
			loading,
			highlightID,
		} = this.props;
		let { rows } = this.state;
		let { columns, rowColors, rowColorKey } = this.props;
		const { draggingCol, actualColumns } = this.state;
		const showEmpty = !loading && (dataSource && !dataSource.length);
		const expandingDataListLength = (expandingData.list && expandingData.list.length) ? expandingData.list.length : 0;

		if (loading) {
			return (
				<LayoutContentStyle>
					<Spinner size="middle" />
				</LayoutContentStyle>
			);
		}

		if (showEmpty) {
			return (
				<Wrapper>
					<Empty />
				</Wrapper>
			);
		}

		columns = actualColumns.length ? actualColumns : columns;
		rows = rows.length ? rows : dataSource;
		const panels = columns.length ? columns.map(col => {
			return col.width ? col.width : defWidth;
		}) : [];
		const columnsList = columns.map(col => {
			const width = col.width ? `${col.width}px` : `${defWidth}px`;
			if (col.key === 'action' || col.key === 'actions') {
				col.noDrag = true;
				col.noResize = true;
			}


			const className = classnames('table-cell', {
				sorted     : col.key === sortedColumn,
				droppingCol: col.key === draggingCol,
			});
			return (
				<div
					className={className}
					key={`${tableType}-${col.key}`}
					id={col.key}
					style={{ 'width': width }}
					draggable={!col.noDrag}
					onDrop={this.drop}
					onDragStart={this.dragStart}
					onDragOver={this.dragOver}
					onDragEnd={this.dragEnd}
				>
					{col.title && col.title.props && col.title.props.title && col.title.props.title.$$typeof ? col.title : ''}
				</div>
			);
		});
		const RowsList = rows && rows.map((row, index) => {
			let customRowColor;
			if (customColorsRows) {
				switch (row.priority) {
					case 1: {
						customRowColor = 'lowClassName';
						break;
					}
					case 2: {
						customRowColor = 'mediumClassName';
						break;
					}
					case 3: {
						customRowColor = 'highClassName';
						break;
					}
					default:
						break;
				}
			}
			const backgroundColor = rowColors[row[rowColorKey]] ? rowColors[row[rowColorKey]] : 'white';
			const keyForRow = `${row[rowKey]}-${index}`;
			let expanding = '';

			if (expandingData.id && expandingData.id === row.id && expandingData.list && expandingDataListLength) {
				expanding = expandingData.list.map((exprow, index) => {
					const keyForRow = `exp${exprow[rowKey]}-${index}`;
					const classNameRow = classnames('table-row', 'table-body', 'table-expanded', {
						last: index === (expandingDataListLength - 1),
					});

					return (
						<div className={classNameRow} key={keyForRow}>
							{columns.map(col => {
								const width = col.width ? `${col.width}px` : `${defWidth}px`;
								const className = classnames('table-cell', {
									droppingCol: col.key === draggingCol,
								});
								const keyForCell = `Exp${exprow[rowKey]}-${index}-${col.key}`;
								const cellValue = col.render ? col.render(col.key, exprow) : exprow[col.key];
								const altTitle = typeof cellValue === 'object' ? exprow[col.key] : cellValue;

								return (
									<div
										className={className}
										id={col.key}
										key={keyForCell}
										onDrop={this.drop}
										onDragOver={this.dragOver}
										onDragEnd={this.dragEnd}
									>
										<div
											className={cutCells || col.cut ? 'cell-cut' : 'cell-wrap'}
											title={String(altTitle) || ''}
											style={{ 'width': width }}>
											{col.key !== 'action' && cellValue}
										</div>
									</div>
								);
							})}
						</div>
					);
				});
			}

			return (
				<Fragment key={keyForRow}>
					<div
						className={!customRowColor ? 'table-row table-body' : ` table-row table-body ${customRowColor}`}
						style={{ backgroundColor }}
					>
						{columns.map(col => {
							const width = col.width ? `${col.width}px` : `${defWidth}px`;
							const className = classnames('table-cell', {
								sorted                   : col.key === sortedColumn,
								droppingCol              : col.key === draggingCol,
								'table-row-socket-update': highlightID === row[rowKey],
							});
							const keyForCell = `${row[rowKey]}-${index}-${col.key}`;
							const cellValue = col.render ? col.render(col.key, row) : row[col.key];

							let altTitle = typeof cellValue === 'object' ? row[col.key] : cellValue;

							if (col.key === 'matchedDocuments') {
								altTitle = row[col.key].map(doc => ' ' + doc.userID);
							}

							// FIXME: This is a hack to prevent rendering of non-React elements in the table cells
							// This is a temporary solution until the table is refactored to use React components
							if (Array.isArray(cellValue)) {
								const nonJSX = cellValue.some(item => typeof item == 'object' && item.$$typeof !== Symbol.for('react.element'));
								if (nonJSX) {
									return  null;
								}
							}

							const renderedValue = typeof cellValue === 'object' && cellValue && cellValue.length ? String(altTitle || '') : cellValue; // TODO we need check this logic

							return (
								<div
									className={className}
									id={col.key}
									key={keyForCell}
									onDrop={this.drop}
									onDragOver={this.dragOver}
									onDragEnd={this.dragEnd}
								>
									<div
										className={cutCells || col.cut ? 'cell-cut' : 'cell-wrap'}
										title={String(altTitle || '')}
										style={{ 'width': width }}>
										{renderedValue}
									</div>
								</div>
							);
						})}
					</div>
					{expanding}
				</Fragment>
			);
		});

		return rows && rows.length && columns.length ? (
			<Wrapper className="ant-table-wrapper">
				<div id={`${tableType}-wrap`} className="ant-table">
					<div id="head-row" className="table-row table-head">
						<ResizablePanels panels={panels} resize={this.resize}>
							{columnsList}
						</ResizablePanels>
					</div>
					{this.loader(loadingUI)}
					{RowsList}
				</div>
				{footer ? footer() : ''}
			</Wrapper>
		) : (
			<LayoutContentStyle>
				<Spinner size="middle" />
			</LayoutContentStyle>
		);
	}
}

function mapStateToProps({ AppTabs, Tables }) {

	return {
		activeTabID: AppTabs.get('activeTabID'),
		storeTables: Tables,
	};
}

const mapDispatchToProps = () => {
	return {
		sortingRefresh: tablesActions.sortingRefresh,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TableDragResize);
