import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import { createFilter, fill, filtersURL } from '../../../helpers/utils';
import actions from './actions';
import { initFilterTypes } from './utils';

const initState = new Map({
	filter: createFilter(filtersURL.staffList, initFilterTypes),

	// dataSource for Inputs with autocomplete function
	dataSource: {
		userName : [],
		userEmail: [],
		firstName: [],
		lastName : [],
	},
});

export default function personalFilterReducer(state = initState, action) {

	switch (action.type) {

		case actions.FILTER_REFRESH: {
			const { filterData } = action.data;
			const target = state.get('filter');
			const result = fill(filterData, target, true);

			return state.set('filter', result);
		}

		case actions.AUTOCOMPLETE_DATA_SOURCE_REFRESH: {
			const { dataSource, type } = action.data;
			const result = cloneDeep(state.get('dataSource'));
			result[type] = dataSource;

			return state.set('dataSource', result);
		}
		case actions.FILTER_RESET: {
			return state.set('filter', {});
		}

		default:
			return state;
	}
}
