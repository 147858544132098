import QueryToFilterValidation from '../../helpers/queryToFilterValidaion';
import {
	IAgentData,
	IAgentDataResponse,
	IAgentFields,
	IAgentFilter,
	IAgentFilterValidation, IAgentInfo, IAgentTransaction, IAgentTransactionResponse,
	TAgentTransactionFields,
} from './types';
import EntityAdapter from '../../helpers/entityAdapter';
import ParamsBuilder from '../../helpers/paramsBuilder';

const fields: IAgentFields = {
	id             : 'id',
	email          : 'email',
	username       : 'username',
	firstName      : 'first_name',
	lastName       : 'last_name',
	status         : 'status_id',
	playersCount   : 'players_count',
	balance        : 'balance',
	password       : 'password',
	countryID      : 'country_id',
	currencyID     : 'currency_id',
	city           : 'city',
	address        : 'address',
	phone          : 'phone',
	totalBalanceIn : 'total_balance_in',
	totalBalanceOut: 'total_balance_out',

};


const transactionFields: TAgentTransactionFields = {
	amount       : 'amount',
	transactionID: 'id',
	balanceAfter : 'balance_after',
	balanceBefore: 'balance_before',
	date         : 'date',
	currencyID   : 'currency_id',
	sourceID     : 'source_id',

};


const { isValidID, isValidString } = QueryToFilterValidation;
export const initFilterTypes: IAgentFilterValidation = {
	email   	: isValidString,
	firstName: isValidString,
	status   : isValidID,
	username : isValidString,
	lastName : isValidString,
	id       : isValidID,
};

const listAdapter = createAgentsListAdapter();
const baseDataAdapter = createAgentBaseDataAdapter();
const agentInfoAdapter = createAgentInfoAdapter();
const transactionAdapter = createAgentsTransactionsAdapter();

export const adaptAgentData = (data: IAgentDataResponse) => agentInfoAdapter.adapt(data) as IAgentInfo;

export const prepareAgentData = (data: IAgentData) => {
	const prepared =  baseDataAdapter.prepare(data) as IAgentDataResponse;
	return clearObject(prepared);
};

export const prepareAgentInfo = (data: IAgentInfo) => {
	const prepared =  agentInfoAdapter.prepare(data) as IAgentDataResponse;
	return clearObject(prepared);
};

export const adaptAgentTransaction = (data: IAgentTransactionResponse[]) => transactionAdapter.adaptList(data) as IAgentTransaction[];

export const adaptAgentsList = (data: IAgentDataResponse[]) => listAdapter.adaptList(data) as IAgentData[];


export function getListParams (filter: IAgentFilter, pagination, sorting = null) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	if (sorting) {
		builder.addValue('sort_by', fields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'status', fields.status);
	builder.addField(rules.isString, 'email', fields.email);
	builder.addField(rules.isString, 'username', fields.username);
	builder.addField(rules.isString, 'firstName', fields.firstName);
	builder.addField(rules.isString, 'lastName', fields.lastName);

	const params = builder.biuldParams(filter);

	return params;
}

export function getTransactionsListParams (filter, pagination, sorting = null) {
	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	if (sorting) {
		builder.addValue('sort_by', transactionFields[sorting.sortBy]);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'transactionID', transactionFields.transactionID);
	builder.addRangeField(rules.isNumberRange, filter.amount, [
		'amount_from',
		'amount_to',
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.date, [
		'date_from',
		'date_to',
	]);

	return builder.biuldParams(filter);

}

export function createAgentBaseDataAdapter () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'username', fields.username);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.id, 'countryID', fields.countryID);
	adapter.addField(rules.string, 'city', fields.city);
	adapter.addField(rules.string, 'address', fields.address);
	adapter.addField(rules.string, 'phone', fields.phone);
	adapter.addField(rules.string, 'password', fields.password);
	adapter.addField(rules.intOrNull, 'status', fields.status);

	return adapter;
}

export function createAgentInfoAdapter () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.string, 'username', fields.username);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.id, 'countryID', fields.countryID);
	adapter.addField(rules.string, 'city', fields.city);
	adapter.addField(rules.string, 'address', fields.address);
	adapter.addField(rules.string, 'phone', fields.phone);
	adapter.addField(rules.string, 'password', fields.password);
	adapter.addField(rules.intOrNull, 'status', fields.status);
	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.number, 'totalBalanceIn', fields.totalBalanceIn);
	adapter.addField(rules.number, 'totalBalanceOut', fields.totalBalanceOut);

	return adapter;
}

export function createAgentsListAdapter  () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.email, 'email', fields.email);
	adapter.addField(rules.username, 'username', fields.username);
	adapter.addField(rules.firstName, 'firstName', fields.firstName);
	adapter.addField(rules.lastName, 'lastName', fields.lastName);
	adapter.addField(rules.status, 'status', fields.status);
	adapter.addField(rules.playersCount, 'playersCount', fields.playersCount);
	adapter.addField(rules.balance, 'balance', fields.balance);
	adapter.addField(rules.countryID, 'countryID', fields.countryID);
	adapter.addField(rules.currencyID, 'currencyID', fields.currencyID);

	return adapter;

}

export function createAgentsTransactionsAdapter () {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'transactionID', transactionFields.transactionID);
	adapter.addField(rules.number, 'amount', transactionFields.amount);
	adapter.addField(rules.number, 'balanceAfter', transactionFields.balanceAfter);
	adapter.addField(rules.number, 'balanceBefore', transactionFields.balanceBefore);
	adapter.addField(rules.date, 'date', transactionFields.date);
	adapter.addField(rules.number, 'sourceID', transactionFields.sourceID);
	adapter.addField(rules.string, 'currencyID', transactionFields.currencyID);

	return adapter;

}


export const clearObject = <T>(obj: T): Partial<T> => {
	return Object.fromEntries(Object.entries(obj).filter(([, value]) => (!!value || value === 0 || typeof value === 'boolean'))) as Partial<T>;
};