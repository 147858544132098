
export const PERMISSIONS = {

	// Common
	// ---------------------------------------------------------------------------------------
	superAdmin   : 'super_admin',
	platformAdmin: 'platform_admin',
	admin        : 'admin',
	user         : 'user',
	guest        : 'guest',

	// Custom
	// ---------------------------------  ------------------------------------------------------
	accessLoyaltyDepoaitBonus: 'admin_loyalty_bonus-engine_deposit',
	accessLoyaltyPackBonus   : 'admin_loyalty_bonus-engine_pack',

	accessHeaderLandSwitch: 'admin_operator-management_personal-data',

	accessRtmBetsExport       : 'admin_rtm_export-bets',
	accessRtmCasinoExport     : 'admin_rtm_export-casino',
	accessRtmDepositExport    : 'admin_rtm_export-deposits',
	accessRtmWithdrawalsExport: 'admin_rtm_export-withdrawals',

	accessUserDocuments: 'admin_user_documents',

	accessBonusSpin              : 'admin_customer-management_bonus-spin',
	accessBonusFreeBet           : 'admin_customer-management_bonus-free-bet',
	accessCustomerLimits         : 'admin_customer-management_limits',
	accessCustomerTransactionsAll: 'admin_customer-management_transactions',

	accessBalanceAdjustment: 'admin_customer-management_balance-adjustment_balance-adjustment',
	accessManualDeposit    : 'admin_customer-management_balance-adjustment_manual-deposit',
	accessNextDeposit      : 'admin_loyalty_bonus-engine_next-deposit',

	accessCasinoGameGrid         : 'admin_cms_pages_home-page_game-grid',
	accessCasinoReloadGames      : 'admin_casino-management_game-refresh',
	accessSettingsCurrencyPayways: 'admin_settings_currency-payways',
	sendPushNotifications        : 'admin_send_push_notification',

	// website [MODAL] tabs Permissions
	accessWebsiteIntegrationTab   : 'admin_partners_websites_integration',
	accessWebsitePaymentsTab      : 'admin_partners_websites_payments',
	accessWebsiteProviderTab      : 'admin_partners_websites_provider',
	accessWebsiteCurrencyTab      : 'admin_partners_websites_currency',
	accessWebsiteScriptsTab       : 'admin_partners_websites_scripts',
	accessWebsiteAutoWithdrawTab  : 'admin_partners_websites_auto-withdraw',
	accessWebsiteSmsGatewayTab    : 'admin_partners_websites_sms-gateway',
	accessWebsiteRiskManagementTab: 'admin_partners_websites_risk-management',
	accessWebsiteCountryTab       : 'admin_partners_websites_country',
	accessWebsiteLanguagesTab     : 'admin_partners_websites_languages',

	accessSMSTemplates: 'admin_settings_sms-templates',

	// Sidebar
	// ---------------------------------  -----------------------------------------------------
	sidebarMainCategory           : 'admin_sport-management_categories',
	sidebarMainCategory_Categories: 'admin_sport-management_categories',

	sidebarMainRTM               : 'admin_rtm',
	sidebarMainRTM_Bets          : 'admin_rtm_bets',
	sidebarMainRTM_Deposit       : 'admin_rtm_deposits',
	sidebarMainRTM_Withdrawals   : 'admin_rtm_withdrawals',
	sidebarMainRTM_Casino        : 'admin_rtm_casino',
	sidebarMainRTM_Documents     : 'admin_rtm_documents',
	sidebarMainRTM_RiskManagement: 'admin_rtm_risk-alerts',

	sidebarRiskManagement     : 'admin_risk-management',
	sidebarRiskManagement_bets: 'admin_risk-management_sport-bets',

	sidebarMainParticipant     : 'admin_sport-management_participants',
	sidebarMainParticipant_List: 'admin_sport-management_participants_team-players',
	sidebarMainParticipant_Ages: 'admin_sport-management_participants_ages',

	sidebarMainMarket               : 'admin_sport-management_markets',
	sidebarMainMarket_StatisticTypes: 'admin_sport-management_markets_statistic-types',
	sidebarMainMarket_Templates     : 'admin_sport-management_markets_selections',
	sidebarMainMarket_Groups        : 'admin_sport-management_markets_combination-group',
	sidebarMainMarket_DisplayTypes  : 'admin_sport-management_markets_markets-display-type',

	sidebarMainSport_Settings             : 'admin_sport-management_settings',
	sidebarMainSport_Settings_BettingRules: 'admin_sport-management_settings_betslip',
	sidebarMainSport_Settings_Scopes      : 'admin_sport-management_settings_scopes',
	sidebarMainSport_Settings_Ladders     : 'admin_sport-management_settings_ladder',


	sidebarMainEvents : 'admin_sport-management_event_events',
	sidebarMainResults: 'admin_sport-management_results',
	liveStatisticTypes: 'admin_sport-management_live-statistic-types',

	sidebarMainUsers       : 'admin_customer-management',
	sidebarMainUsers_Search: 'admin_customer-management_user-data',
	sidebarMainUsers_Groups: 'admin_customer-management_risk-group',

	sidebarMain        : 'admin_operator-management',
	sidebarMainPersonal: 'admin_operator-management_personal-data',

	sidebarMainUsers_Roles: 'admin_operator-management_roles',

	sidebarMainTransactions: 'admin_reports_transactions',

	sidebarMainContent           : 'sidebar_main_content',
	sidebarMainContent_InfoPages : 'admin_cms_pages_info-pages',
	sidebarMainContent_News      : 'admin_cms_pages_news',
	sidebarMainContent_Promotions: 'admin_cms_pages_promotion',
	sidebarMainContent_HelpCenter: 'admin_cms_pages_help-center',
	sidebarMainContent_Articles  : 'admin_cms_menu_header-footer-article',
	sidebarMainContent_Header    : 'admin_cms_menu_header-footer-article',
	sidebarMainContent_Footer    : 'admin_cms_menu_header-footer-article',

	sidebarMainContent_LandingPageGameGrid: 'admin_cms_pages_home-page_game-grid',

	sidebarMainSettings              : 'admin_settings',
	sidebarMainSettings_SidebarEditor: 'admin_settings_general',
	sidebarSmsGateways               : 'admin_settings_sms-gateway',
	sidebarSmsTemplates              : 'admin_settings_sms-templates',
	sidebarMainCurrencies            : 'admin_settings_currencies',
	sidebarMainPayments              : 'admin_settings_payments',

	// sidebarMainHistory                    : 'sidebar_main_history',
	// sidebarMainHistory_Category           : 'sidebar_main_history_sub_category',


	sidebarMainSecurity           : 'admin_settings_security',
	sidebarMainSecurity_Questions : 'admin_settings_security_questions',
	sidebarMainSecurity_BlockedIps: 'admin_settings_security_blocked-ips',
	sidebarMainSecurity_Whitelist : 'admin_settings_security_domain-whitelist',

	sidebarMainBanners       : 'admin_cms_banners',
	sidebarMainBanners_List  : 'admin_cms_banners_banners',
	sidebarMainBanners_Groups: 'admin_cms_banners_groups',

	sidebarMainEmails          : 'admin_notification-center_email',
	sidebarMainEmails_List     : 'admin_notification-center_email_email-list',
	sidebarMainEmails_Templates: 'admin_notification-center_email_email-templates',

	sidebarMainCasino                : 'admin_casino-management',
	sidebarMainCasino_List           : 'admin_casino-management_provider',
	sidebarMainCasino_GameCategories : 'admin_casino-management_game-category',
	sidebarMainCasino_Games          : 'admin_casino-management_game',
	sidebarMainCasino_CustomProviders: 'admin_casino-management_game-custom-provider',
	sidebarMainCasino_Integrators    : 'admin_casino-management_integrator',

	sidebarMainSport: 'admin_sport-management',

	sidebarCMS: 'admin_cms',

	sidebarCmsMenu: 'admin_cms_menu',
	sidebarPages  : 'admin_cms_pages',

	sidebarNotificationCenter: 'admin_notification-center',

	sidebarMainHomeManage              : 'admin_cms_home-page',
	sidebarMainHomeManage_Priorities   : 'admin_cms_landing-pages_sports-priorities',
	sidebarMainHomeManage_SpecialOffers: 'admin_cms_landing-pages_today-special-offers',
	sidebarMainHomeManage_Upcoming     : 'admin_cms_landing-pages_upcoming-events',
	sidebarMainHomeManage_MatchesOfDay : 'admin_cms_landing-pages_matches-of-the-day',

	sidebarMainBonuses          : 'admin_loyalty_bonus-engine_bonuses',
	sidebarBonuses              : 'admin_loyalty_bonus-engine_bonuses',
	sidebarBonusTemplates       : 'admin_loyalty_bonus-template',
	sidebarMainBonuses_MassBonus: 'admin_loyalty_bonus-engine_free-spins',
	sidebarMainBonuses_Welcome  : 'admin_loyalty_bonus-engine_welcome',
	sidebarMainBonuses_Cashback : 'admin_loyalty_bonus-engine_cashback',
	
	sidebarHistoryMain              : 'admin_loyalty_bonus-history',
	sidebarMainBonuses_FreeSpins    : 'admin_loyalty_bonus-history_free-spins',
	sidebarMainBonuses_CashbackUsers: 'admin_loyalty_bonus-history_cashback-users',
	sidebarMainBonuses_FreeBets     : 'admin_loyalty_bonus-history_free-bet',
	
	tournaments: 'admin_loyalty_tournaments',
	
	sidebarMainPushNotifications: 'admin_notification-center_push-messages',
	sidebarMainMessages         : 'admin_notification-center_internal-messages',
	sidebarSMS                  : 'admin_notification-center_ ',

	sidebarMainAffiliates               : 'admin_affiliates',
	sidebarMainSettingsSubScopes        : 'sidebar_main_settings_sub_scopes',
	sidebarMainHistory                  : 'sidebar_main_history',
	sidebarMainSettingsSubStatisticTypes: 'sidebar_main_settings_sub_statisticTypes',
	readLogsParticipantPermissionString : 'read_logs_participant_permission_string',
	sidebarMainHistorySubCategory       : 'sidebar_main_history_sub_category',


	sidebarMainReports: 'admin_reports',

	sidebarMainReports_ReportByPlayer: 'admin_reports_report-by-player-game',
	accessExportReportByPlayerGame   : 'admin_reports_export-report-by-player-game',

	sidebarMainReports_ReportByGame: 'admin_reports_report-by-game',
	accessExportReportByGame       : 'admin_reports_export-report-by-game',
	accessExportTransactions       : 'admin_reports_export-transactions',
	
	sidebarMainReports_reportByProvider: 'admin_reports_report-by-provider',
	accessExportReportProvider         : 'admin_reports_export-report-by-provider',

	sidebarMainReports_ReportByIntegrator: 'admin_reports_report-by-integrator',
	accessExportReportByIntegrator       : 'admin_reports_export-report-by-integrator',


	sidebarMainPartner    : 'admin_partners',
	sidebarPartnerPartners: 'admin_partners_partners',
	sidebarPartnerWebsites: 'admin_partners_websites_websites',

	agents           : 'agents',
	agentsSystem     : 'admin_agent-system_agent',
	agentTransactions: 'admin_agent-system_transactions',
	agentRole        : 'agent',
  
	agentPlayer            : 'agent_players',
	agentPlayerTransactions: 'admin_agent-player_transactions',
	agentPlayerRole        : 'agent_player',

	sidebarDashboard: 'admin_dashboard',
	sidebarAdmins   : 'sidebarAdmins',

	// Dashboard
	// ------------------------------------------------------------------------------------
	dashboardPlayersStats     : 'dashboard_players_stats',
	dashboardBetsStats        : 'dashboard_bets_stats',
	dashboardDepositsStats    : 'dashboard_deposits_stats',
	dashboardWithdrawalsStats : 'dashboard_withdrawals_stats',
	dashboardProviderBetsStats: 'dashboard_provider_bets_stats',

	// READ & WRITE
	// ---------------------------------------------------------------------------------

	// Betslip
	// --------------------------------------------------------------------------------------
	readBetslip       : 'read_betslip',
	readBetslipDetails: 'read_betslip_details',

	writeBetslip       : 'edit_betslip',
	writeBetslipDetails: 'edit_betslip_details',

	// Categories
	// -----------------------------------------------------------------------------------
	readCategory: 'read_category',
	readLeague  : 'read_league',

	writeCategory: 'edit_category',
	writeLeague  : 'edit_league',

	// RTM
	// ------------------------------------------------------------------------------------------
	readRtm: 'read_rtm',

	// Participants
	// ---------------------------------------------------------------------------------
	readParticipant      : 'read_participant',
	readParticipantMember: 'read_participant_member',
	readParticipantAge   : 'read_participant_age',

	writeParticipant      : 'edit_participant',
	writeParticipantMember: 'edit_participant_member',
	writeParticipantAge   : 'edit_participant_age',

	// Markets
	// --------------------------------------------------------------------------------------
	readMarket                : 'read_market',
	readMarketDisplayType     : 'read_market_displayType',
	readMarketSelections      : 'read_market_selections',
	readMarketCombinationGroup: 'read_combinationGroup',
	readMarketLadder          : 'read_ladder',

	writeMarket                : 'edit_market',
	writeMarketDisplayType     : 'edit_market_displayType',
	writeMarketSelections      : 'edit_market_selections',
	writeMarketCombinationGroup: 'edit_combinationGroup',
	writeMarketLadder          : 'edit_ladder',

	// Events
	// ---------------------------------------------------------------------------------------
	readEvent         : 'read_event',
	readEventMarket   : 'read_event_market',
	readEventSelection: 'read_event_selection',
	readEventResult   : 'read_event_result',

	writeEvent         : 'edit_event',
	writeEventMarket   : 'edit_event_market',
	writeEventSelection: 'edit_event_selection',
	writeEventResult   : 'edit_event_result',

	// Currency
	// -------------------------------------------------------------------------------------
	readCurrency : 'read_currency',
	writeCurrency: 'edit_currency',

	// CustomerManagement
	// ----------------------------------------------------------------------------------------
	readUsers         : 'read_users',
	readUsersRiskGroup: 'read_riskGroup',

	writeUsers         : 'edit_users',
	writeUsersRiskGroup: 'edit_riskGroup',

	// Menu
	// --------------------------------------------------------------------------------------
	readContentMenu    : 'read_content_menu',
	readContentPages   : 'read_content_pages',
	readContentNews    : 'read_content_news',
	readContentBanners : 'read_content_banners',
	readContentHomepage: 'read_content_homepage',

	writeContentMenu    : 'edit_content_menu',
	writeContentPages   : 'edit_content_pages',
	writeContentNews    : 'edit_content_news',
	writeContentBanners : 'edit_content_banners',
	writeContentHomepage: 'edit_content_homepage',

	// Settings
	// -------------------------------------------------------------------------------------
	readSettingsGeneral      : 'read_settings_general',
	readSettingsScopes       : 'read_settings_scopes',
	readSettingsStatisticType: 'read_settings_statisticType',
	readMarketResultRules    : 'read_settings_resultRules',

	writeSettingsGeneral      : 'edit_settings_general',
	writeSettingsScopes       : 'edit_settings_scopes',
	writeSettingsStatisticType: 'edit_settings_statisticType',
	writeMarketResultRules    : 'edit_settings_resultRules',

	// Logs
	// -----------------------------------------------------------------------------------------
	readLogsCategory                              : 'read_logs_category',
	readLogsMarket                                : 'read_logs_market',
	readLogsSelection                             : 'read_logs_selection',
	readLogsEvent                                 : 'read_logs_event',
	readLogsResult                                : 'read_logs_result',
	readLogsEventSelection                        : 'read_logs_event_selection',
	readLogsEventMarket                           : 'read_logs_event_market',
	readLogsBonusess                              : 'read_logs_reg_free_spin_permission_string',
	readLogsParticipantTranslationPermissionString: 'read_logs_participant_translation_permission_string',

	// Website
	// --------------------------------------------------------------------------------------
	readWebsite : 'read_website',
	writeWebsite: 'edit_website',

	// Payment
	// --------------------------------------------------------------------------------------
	readPayment : 'read_payment',
	writePayment: 'edit_payment',

	// Security
	// -------------------------------------------------------------------------------------
	readSecurityQuestions : 'read_questions',
	readSecurityWhitelist : 'read_whitelist',
	writeSecurityQuestions: 'edit_questions',
	writeSecurityWhitelist: 'edit_whitelist',

	// E-mail
	// ---------------------------------------------------------------------------------------
	readEmail         : 'read_email',
	readEmailTemplates: 'read_emailTemplates',

	writeEmail         : 'edit_email',
	writeEmailTemplates: 'edit_emailTemplates',

	// Casino
	// ---------------------------------------------------------------------------------------
	readCasino               : 'read_casino',
	readCasinoCategories     : 'read_casino_categories',
	readCasinoGames          : 'read_casino_games',
	readCasinoCustomProviders: 'read_casino_custom_providers',

	writeCasino               : 'edit_casino',
	writeCasinoCategories     : 'edit_casino_categories',
	writeCasinoGames          : 'edit_casino_games',
	writeCasinoCustomProviders: 'edit_casino_custom_providers',
	writeCasinoIntegrators    : 'edit_casino_integrators',

	// BonusingSystem
	// --------------------------------------------------------------------------------------
	readBirthdayBonus     : 'read_birthday_bonus',
	readFirstDepositBonus : 'read_first_deposit_bonus',
	readWelcomeBonus      : 'read_welcome_bonus',
	readFreeSpinBonus     : 'admin_read_bonus_spin',
	readFreeBetBonus      : 'admin_read_bonus_bet',
	readRegistrationBonus : 'admin_read_registration_bonus',
	readCashbackBonus     : 'read_cashback_bonus',
	readCashbackUsersBonus: 'read_cashbackUsers_bonus',

	writeBirthdayBonus     : 'edit_birthday_bonus',
	writeFirstDepositBonus : 'edit_first_deposit_bonus',
	writeWelcomeBonus      : 'edit_welcome_bonus',
	writeFreeSpinBonus     : 'admin_write_bonus_spin',
	writeFreeBetBonus      : 'admin_write_bonus_bet',
	writeRegistrationBonus : 'admin_write_registration_bonus',
	writeCashbackBonus     : 'edit_cashback_bonus',
	writeCashbackUsersBonus: 'edit_cashbackUsers_bonus',

	// Messages
	// -------------------------------------------------------------------------------------
	readMessages : 'read_messages',
	writeMessages: 'edit_messages',

	// Affiliates
	// -----------------------------------------------------------------------------------
	readAffiliates : 'read_affiliates',
	writeAffiliates: 'edit_affiliates',

	// Others
	// ---------------------------------------------------------------------------------------
	readBlockedIP             : 'read_blockedIp',
	readProviders             : 'read_providers',
	readLanguage              : 'read_language',
	readMapping               : 'read_mapping',
	readNotificationTemplates : 'read_notificationTemplates',
	readRestrictedIP          : 'read_restrictedIp',
	readSportRegulationType   : 'read_sportRegulationType',
	readTransactions          : 'read_transactions',
	readWarning               : 'read_warning',
	readCashBack              : 'read_cash_back',
	adminReadPushNotifications: 'admin_read_push_notifications',

	writeBlockedIP            : 'edit_blockedIp',
	writeProviders            : 'edit_providers',
	writeLanguage             : 'edit_language',
	writeMapping              : 'edit_mapping',
	writeNotificationTemplates: 'edit_notificationTemplates',
	writeRestrictedIP         : 'edit_restrictedIp',
	writeSportRegulationType  : 'edit_sportRegulationType',
	writeTransactions         : 'edit_transactions',
	writeWarning              : 'edit_warning',
	editCashBack              : 'edit_cash_back',

};

export const SIDEBAR_PERMISSIONS = {

	'categories'      : PERMISSIONS.sidebarMainCategory,
	'sport/categories': PERMISSIONS.sidebarMainCategory_Categories,

	'rtm'            : PERMISSIONS.sidebarMainRTM,
	'rtm/bets'       : PERMISSIONS.sidebarMainRTM_Bets,
	'rtm/deposits'   : PERMISSIONS.sidebarMainRTM_Deposit,
	'rtm/withdrawals': PERMISSIONS.sidebarMainRTM_Withdrawals,
	'rtm/casino'     : PERMISSIONS.sidebarMainRTM_Casino,

	'rtm/documents'  : PERMISSIONS.sidebarMainRTM_Documents,
	'rtm/risk-alerts': PERMISSIONS.sidebarMainRTM_RiskManagement,

	'riskManagement'     : PERMISSIONS.sidebarRiskManagement,
	'riskManagement/bets': PERMISSIONS.sidebarRiskManagement_bets,


	'participants'           : PERMISSIONS.sidebarMainParticipant,
	'sport/participants/list': PERMISSIONS.sidebarMainParticipant_List,
	'sport/participants/ages': PERMISSIONS.sidebarMainParticipant_Ages,

	'markets'                     : PERMISSIONS.sidebarMainMarket,
	'sport/markets/templates'     : PERMISSIONS.sidebarMainMarket_Templates,
	'sport/markets/groups'        : PERMISSIONS.sidebarMainMarket_Groups,
	'sport/markets/statisticTypes': PERMISSIONS.sidebarMainMarket_StatisticTypes,

	'sport/markets/displayTypes': PERMISSIONS.sidebarMainMarket_DisplayTypes,

	'sportSettings'                   : PERMISSIONS.sidebarMainSport_Settings,
	'sport/sportSettings/bettingRules': PERMISSIONS.sidebarMainSport_Settings_BettingRules,
	'sport/sportSettings/scopes'      : PERMISSIONS.sidebarMainSport_Settings_Scopes,
	'sport/sportSettings/ladders'     : PERMISSIONS.sidebarMainSport_Settings_Ladders,

	'sport/events'            : PERMISSIONS.sidebarMainEvents,
	'sport/results'           : PERMISSIONS.sidebarMainResults,
	'sport/liveStatisticTypes': PERMISSIONS.sidebarMainResults,

	'users'       : PERMISSIONS.sidebarMainUsers,
	'users/list'  : PERMISSIONS.sidebarMainUsers_Search,
	'users/info'  : PERMISSIONS.sidebarMainUsers_Search,
	'users/groups': PERMISSIONS.sidebarMainUsers_Groups,

	'staff'         : PERMISSIONS.sidebarMain,
	'staff/personal': PERMISSIONS.sidebarMainPersonal,
	'personal/info' : PERMISSIONS.sidebarMainPersonal,
	'staff/roles'   : PERMISSIONS.sidebarMainUsers_Roles,
	'transactions'  : PERMISSIONS.sidebarMainTransactions,

	'settings'                 : PERMISSIONS.sidebarMainSettings,
	'settings/sidebarEditor'   : PERMISSIONS.sidebarMainSettings_SidebarEditor,
	'settings/sms/smsGateways' : PERMISSIONS.sidebarSmsGateways,
	'settings/sms/smsTemplates': PERMISSIONS.sidebarSmsTemplates,
	'settings/currencies'      : PERMISSIONS.sidebarMainCurrencies,
	'settings/payments'        : PERMISSIONS.sidebarMainPayments,
	'payment/payways'          : PERMISSIONS.sidebarMainPayments,

	'cms'                               : PERMISSIONS.sidebarCMS,
	'cmsPages'                          : PERMISSIONS.sidebarPages,
	'cms/homeManage/priorities'         : PERMISSIONS.sidebarMainHomeManage_Priorities,
	'cms/homeManage/specialOffers'      : PERMISSIONS.sidebarMainHomeManage_SpecialOffers,
	'specialOffers/sports'              : PERMISSIONS.sidebarMainHomeManage_SpecialOffers, //inner Page
	'cms/homeManage/upcoming'           : PERMISSIONS.sidebarMainHomeManage_Upcoming,
	'cms/homeManage/matchesOfDay'       : PERMISSIONS.sidebarMainHomeManage_MatchesOfDay,
	'cms/homeManage/landingPageGameGrid': PERMISSIONS.sidebarMainContent_LandingPageGameGrid,

	'cms/contentMenu'     : PERMISSIONS.sidebarMainContent,
	'cms/content/articles': PERMISSIONS.sidebarMainContent_Articles,
	'cms/content/header'  : PERMISSIONS.sidebarMainContent_Header,
	'cms/content/footer'  : PERMISSIONS.sidebarMainContent_Footer,

	'cms/cmsPages/infoPages' : PERMISSIONS.sidebarMainContent_InfoPages,
	'content/page'           : PERMISSIONS.sidebarMainContent_InfoPages,
	'cms/cmsPages/promotions': PERMISSIONS.sidebarMainContent_Promotions,
	'content/newPromotion'   : PERMISSIONS.sidebarMainContent_Promotions, // inner Page
	'cms/cmsPages/news'      : PERMISSIONS.sidebarMainContent_News,
	'content/news/article'   : PERMISSIONS.sidebarMainContent_News, // inner Page
	'cms/cmsPages/helpCenter': PERMISSIONS.sidebarMainContent_HelpCenter,


	'security'                    : PERMISSIONS.sidebarMainSecurity,
	'settings/security/questions' : PERMISSIONS.sidebarMainSecurity_Questions,
	'settings/security/blockedIps': PERMISSIONS.sidebarMainSecurity_BlockedIps,
	'settings/security/whitelist' : PERMISSIONS.sidebarMainSecurity_Whitelist,

	'banners'           : PERMISSIONS.sidebarMainBanners,
	'cms/banners/list'  : PERMISSIONS.sidebarMainBanners_List,
	'cms/banners/groups': PERMISSIONS.sidebarMainBanners_Groups,

	// 'historyMenu'              : PERMISSIONS.sidebarMainHistory,
	// 'history/category'         : PERMISSIONS.sidebarMainHistory_Category,
	'partners/partners'    : PERMISSIONS.sidebarPartnerPartners,
	'partners/websites'    : PERMISSIONS.sidebarPartnerWebsites,
	'partners/websitesInfo': PERMISSIONS.sidebarPartnerWebsites,

	'loyalty/bonusingSystem/massbonus': PERMISSIONS.sidebarMainBonuses_MassBonus,

	'casino'                : PERMISSIONS.sidebarMainCasino,
	'casino/list'           : PERMISSIONS.sidebarMainCasino_List,
	'casino/gameCategories' : PERMISSIONS.sidebarMainCasino_GameCategories,
	'casino/games'          : PERMISSIONS.sidebarMainCasino_Games,
	'casino/customProviders': PERMISSIONS.sidebarMainCasino_CustomProviders,
	'casino/integrators'    : PERMISSIONS.sidebarMainCasino_Integrators,

	'sport': PERMISSIONS.sidebarMainSport,


	'content': PERMISSIONS.sidebarCmsMenu,


	'notificationCenter': PERMISSIONS.sidebarNotificationCenter,

	'notificationCenter/pushNotifications': PERMISSIONS.sidebarMainPushNotifications,
	'notificationCenter/messages'         : PERMISSIONS.sidebarMainMessages,
	'notificationCenter/sms'              : PERMISSIONS.sidebarSMS,

	'emails'                             : PERMISSIONS.sidebarMainEmails,
	'notificationCenter/emails/list'     : PERMISSIONS.sidebarMainEmails_List,
	'notificationCenter/emails/templates': PERMISSIONS.sidebarMainEmails_Templates,

	'homeManage': PERMISSIONS.sidebarMainHomeManage,


	'loyalty/bonusingSystem'               : PERMISSIONS.sidebarMainBonuses,
	'loyalty/bonusingSystem/bonuses'       : PERMISSIONS.sidebarMainBonuses,
	'loyalty/bonusingSystem/bonuses/create': PERMISSIONS.sidebarMainBonuses,


	'loyalty/bonusTemplates': PERMISSIONS.sidebarBonusTemplates,
	'loyalty/templates/view': PERMISSIONS.sidebarBonusTemplates,

	'loyalty/bonusingSystem/welcome'  : PERMISSIONS.sidebarMainBonuses_Welcome,
	'loyalty/bonusingSystem/cashback' : PERMISSIONS.sidebarMainBonuses_Cashback,
	'loyalty/bonusingSystem/massBonus': PERMISSIONS.sidebarMainBonuses_MassBonus,

	'loyalty/bonusingSystem/welcome/create': PERMISSIONS.sidebarMainBonuses_Welcome,
	'loyalty/bonusingSystem/bonuses/edit'  : PERMISSIONS.sidebarMainBonuses_Welcome,

	'bonusingHistory': PERMISSIONS.sidebarHistoryMain,
	
	'loyalty/bonusingHistory/freeSpins'        : PERMISSIONS.sidebarMainBonuses_FreeSpins,
	'loyalty/bonusingHistory/usersWithCashback': PERMISSIONS.sidebarMainBonuses_CashbackUsers,
	'loyalty/bonusingHistory/freeBets'         : PERMISSIONS.sidebarMainBonuses_FreeBets,

	'loyalty/tournaments': PERMISSIONS.tournaments,


	'affiliates': PERMISSIONS.sidebarMainAffiliates,

	'reports': PERMISSIONS.sidebarMainReports,

	'reports/reportByProvider'  : PERMISSIONS.sidebarMainReports_reportByProvider,
	'reports/reportByPlayer'    : PERMISSIONS.sidebarMainReports_ReportByPlayer,
	'reports/reportByGame'      : PERMISSIONS.sidebarMainReports_ReportByGame,
	'reports/reportByIntegrator': PERMISSIONS.sidebarMainReports_ReportByIntegrator,
	'reports/transactions'      : PERMISSIONS.sidebarMainTransactions,

	'partner'             : PERMISSIONS.sidebarMainPartner,
	'admins'              : PERMISSIONS.sidebarAdmins,
	'dashboard'           : PERMISSIONS.sidebarDashboard,
	// 'agents'             : PERMISSIONS.agents,
	'agents-system/agents': PERMISSIONS.agentsSystem,
	'agent/players'       : PERMISSIONS.agents,
};

export const PERMISSIONS_LANG = {

	// Common
	// ---------------------------------------------------------------------------------------
	sectionCommon: 'permissions.section.common',
	superAdmin   : 'permissions.superAdmin',
	admin        : 'permissions.admin',
	user         : 'permissions.user',
	guest        : 'permissions.guest',

	// Custom
	// ---------------------------------------------------------------------------------------
	sectionCustom          : 'permissions.section.custom',
	accessUserDocuments    : 'permissions.accessUserDocuments',
	accessBalanceAdjustment: 'permissions.accessBalanceAdjustment',
	sendPushNotifications  : 'permissions.sendPushNotifications',

	// Sidebar
	// --------------------------------------------------------------------------------------
	sectionSidebar: 'permissions.section.sidebar',

	sidebarMainCategory               : 'sidebar.categoriesMenu',
	sidebarMainCategory_Categories    : 'sidebar.categories',
	sidebarMainCategory_Scopes        : 'sidebar.scopes.list',
	sidebarMainCategory_StatisticTypes: 'sidebar.statisticTypes.list',

	sidebarMainRTM               : 'sidebar.rtmMenu',
	sidebarMainRTM_Bets          : 'sidebar.rtm.bets',
	sidebarMainRTM_Deposit       : 'sidebar.rtm.deposits',
	sidebarMainRTM_Withdrawals   : 'sidebar.rtm.withdrawals',
	sidebarMainRTM_Casino        : 'sidebar.rtm.casino',
	sidebarMainRTM_Documents     : 'sidebar.rtm.documents',
	sidebarMainRTM_RiskManagement: 'sidebar.rtm.riskManagement',

	sidebarMainParticipant     : 'sidebar.participantsMenu',
	sidebarMainParticipant_List: 'sidebar.participants.list',
	sidebarMainParticipant_Ages: 'sidebar.participants.ages',

	sidebarMainSport_Settings             : 'sidebar.sportSettings',
	sidebarMainSport_Settings_BettingRules: 'sidebar.bettingRules',


	sidebarMainMarket             : 'sidebar.marketsMenu',
	sidebarMainMarket_Templates   : 'sidebar.markets.templates',
	sidebarMainMarket_Groups      : 'sidebar.markets.groups',
	sidebarMainMarket_Ladders     : 'sidebar.markets.ladders',
	sidebarMainMarket_DisplayTypes: 'sidebar.markets.displayTypes',

	sidebarMainEvents    : 'sidebar.events',
	sidebarMainResults   : 'sidebar.results',
	sidebarMainCurrencies: 'sidebar.currencies',

	sidebarMainUsers       : 'sidebar.usersMenu',
	sidebarMainUsers_Search: 'sidebar.usersSearch',
	sidebarMainUsers_Groups: 'sidebar.userGroups',
	sidebarMainUsers_Roles : 'sidebar.userRoles',

	sidebarMain            : 'sidebar.staff',
	sidebarMainPersonal    : 'sidebar.staff',
	sidebarMainTransactions: 'sidebar.transactions',

	sidebarMainContent                    : 'sidebar.contentMenu',
	sidebarMainContent_InfoPages          : 'sidebar.menu.infoPages',
	sidebarMainContent_Promotions         : 'sidebar.menu.promotions',
	sidebarMainContent_News               : 'sidebar.menu.news',
	sidebarMainContent_Articles           : 'sidebar.menu.articles',
	sidebarMainContent_HelpCenter         : 'sidebar.menu.helpCenter',
	sidebarMainContent_Header             : 'sidebar.menu.header',
	sidebarMainContent_Footer             : 'sidebar.menu.footer',
	sidebarMainContent_LandingPageGameGrid: 'sidebar.menu.gameGrid',

	sidebarMainSettings              : 'sidebar.settingsMenu',
	sidebarMainSettings_SidebarEditor: 'sidebar.sidebarEditor',

	sidebarMainHistory         : 'sidebar.historyMenu',
	sidebarMainHistory_Category: 'sidebar.history.category',

	sidebarMainWebsites: 'sidebar.websites',
	sidebarMainPayments: 'sidebar.payments',

	sidebarMainSecurity           : 'sidebar.securityMenu',
	sidebarMainSecurity_Questions : 'sidebar.security.questions',
	sidebarMainSecurity_BlockedIps: 'sidebar.security.blockedIps',
	sidebarMainSecurity_Whitelist : 'sidebar.security.whitelist',

	sidebarMainBanners       : 'sidebar.bannersMenu',
	sidebarMainBanners_List  : 'sidebar.banners.list',
	sidebarMainBanners_Groups: 'sidebar.banners.groups',

	sidebarMainEmails          : 'sidebar.emailsMenu',
	sidebarMainEmails_List     : 'sidebar.emails.list',
	sidebarMainEmails_Templates: 'sidebar.emails.templates',

	sidebarMainCasino                : 'sidebar.casinoMenu',
	sidebarMainCasino_List           : 'sidebar.casino.list',
	sidebarMainCasino_GameCategories : 'sidebar.casino.gameCategories',
	sidebarMainCasino_Games          : 'sidebar.casino.games',
	sidebarMainCasino_CustomProviders: 'sidebar.casino.customProviders',

	sidebarMainSport: 'sidebar.sport',

	sidebarCMS: 'sidebar.CMS',

	sidebarCmsMenu: 'sidebar.cmsMenu',

	sidebarCmsPages          : 'sidebar.cmsPages',
	sidebarNotificationCenter: 'sidebar.notificationCenter',

	sidebarMainHomeManage              : 'sidebar.homeManage',
	sidebarMainHomeManage_Priorities   : 'sidebar.homeManage.priorities',
	sidebarMainHomeManage_SpecialOffers: 'sidebar.homeManage.specialOffers',
	sidebarMainHomeManage_Upcoming     : 'sidebar.homeManage.upcoming',
	sidebarMainHomeManage_MatchesOfDay : 'sidebar.homeManage.matchesOfDay',

	sidebarMainBonuses              : 'sidebar.bonusesMenu',
	sidebarMainBonuses_FreeSpins    : 'sidebar.bonuses.freeSpins',
	sidebarMainBonuses_Registration : 'sidebar.bonuses.registration',
	sidebarMainBonuses_Welcome      : 'sidebar.bonuses.welcome',
	sidebarMainBonuses_FirstDeposit : 'sidebar.bonuses.firstDeposit',
	sidebarMainBonuses_Cashback     : 'sidebar.bonuses.cashback',
	sidebarMainBonuses_CashbackUsers: 'sidebar.bonuses.cashbackUsers',
	sidebarMainBonuses_FreeBets     : 'sidebar.bonuses.freeBets',

	sidebarMainMessages         : 'sidebar.messages',
	sidebarMainAffiliates       : 'sidebar.affiliates',
	sidebarMainPushNotifications: 'sidebar.pushNotifications',

	sidebarReportByPlayer: 'sidebar.reportByPlayer',
	sidebarReportByGame  : 'sidebar.reportByGame',


	// Dashboard
	// ------------------------------------------------------------------------------------
	sectionDashboard          : 'permissions.section.dashboard',
	dashboardPlayersStats     : 'dashboard.players',
	dashboardBetsStats        : 'dashboard.placedBets',
	dashboardDepositsStats    : 'dashboard.deposits',
	dashboardWithdrawalsStats : 'dashboard.withdrawals',
	dashboardProviderBetsStats: 'dashboard.betsByProvider',

	// READ & WRITE
	// ---------------------------------------------------------------------------------

	// Betslip
	// --------------------------------------------------------------------------------------
	sectionBetslip    : 'permissions.section.betslip',
	readBetslip       : 'permissions.betslip.betslips.read',
	readBetslipDetails: 'permissions.betslip.betslipDetails.read',

	writeBetslip       : 'permissions.betslip.betslips.write',
	writeBetslipDetails: 'permissions.betslip.betslipDetails.write',

	// Categories
	// -----------------------------------------------------------------------------------
	sectionCategories        : 'permissions.section.categories',
	readCategory             : 'permissions.categories.category.read',
	readLeague               : 'permissions.categories.league.read',
	readSettingsScopes       : 'permissions.settings.scopes.read',
	readSettingsStatisticType: 'permissions.settings.statisticType.read',

	writeCategory             : 'permissions.categories.category.write',
	writeLeague               : 'permissions.categories.league.write',
	writeSettingsScopes       : 'permissions.settings.scopes.write',
	writeSettingsStatisticType: 'permissions.settings.statisticType.write',

	// RTM
	// ------------------------------------------------------------------------------------------
	sectionRTM: 'permissions.section.RTM',
	readRtm   : 'permissions.RTM.RTM.read',

	// Participants
	// ---------------------------------------------------------------------------------
	sectionParticipants  : 'permissions.section.participants',
	readParticipant      : 'permissions.participants.participant.read',
	readParticipantMember: 'permissions.participants.member.read',
	readParticipantAge   : 'permissions.participants.age.read',

	writeParticipant      : 'permissions.participants.participant.write',
	writeParticipantMember: 'permissions.participants.member.write',
	writeParticipantAge   : 'permissions.participants.age.write',

	// Markets
	// --------------------------------------------------------------------------------------
	sectionMarkets            : 'permissions.section.markets',
	readMarket                : 'permissions.markets.market.read',
	readMarketDisplayType     : 'permissions.markets.displayType.read',
	readMarketSelections      : 'permissions.markets.selection.read',
	readMarketCombinationGroup: 'permissions.markets.combinationGroup.read',
	readMarketLadder          : 'permissions.markets.ladder.read',
	readMarketResultRules     : 'permissions.markets.resultRules.read',

	writeMarket                : 'permissions.markets.market.write',
	writeMarketDisplayType     : 'permissions.markets.displayType.write',
	writeMarketSelections      : 'permissions.markets.selection.write',
	writeMarketCombinationGroup: 'permissions.markets.combinationGroup.write',
	writeMarketLadder          : 'permissions.markets.ladder.write',
	writeMarketResultRules     : 'permissions.markets.resultRules.write',

	// Events
	// ---------------------------------------------------------------------------------------
	sectionEvents     : 'permissions.section.events',
	readEvent         : 'permissions.events.event.read',
	readEventMarket   : 'permissions.events.market.read',
	readEventSelection: 'permissions.events.selection.read',
	readEventResult   : 'permissions.events.result.read',

	writeEvent         : 'permissions.events.event.write',
	writeEventMarket   : 'permissions.events.market.write',
	writeEventSelection: 'permissions.events.selection.write',
	writeEventResult   : 'permissions.events.result.write',

	// Currency
	// -------------------------------------------------------------------------------------
	sectionCurrency: 'permissions.section.currency',
	readCurrency   : 'permissions.currency.currency.read',
	writeCurrency  : 'permissions.currency.currency.write',

	// CustomerManagement
	// ----------------------------------------------------------------------------------------
	sectionUsers      : 'permissions.section.users',
	readUsers         : 'permissions.users.user.read',
	readUsersRiskGroup: 'permissions.users.riskGroup.read',

	writeUsers         : 'permissions.users.user.write',
	writeUsersRiskGroup: 'permissions.users.riskGroup.write',

	// Transactions
	// ---------------------------------------------------------------------------------
	sectionTransactions: 'permissions.section.transactions',
	readTransactions   : 'permissions.transactions.transactions.read',
	writeTransactions  : 'permissions.transactions.transactions.write',

	// Menu
	// --------------------------------------------------------------------------------------
	sectionContent     : 'permissions.section.menu',
	readContentMenu    : 'permissions.menu.menu.read',
	readContentPages   : 'permissions.menu.infoPages.read',
	readContentNews    : 'permissions.menu.news.read',
	readContentBanners : 'permissions.menu.banners.read',
	readContentHomepage: 'permissions.menu.homepage.read',

	writeContentMenu    : 'permissions.menu.menu.write',
	writeContentPages   : 'permissions.menu.infoPages.write',
	writeContentNews    : 'permissions.menu.news.write',
	writeContentBanners : 'permissions.menu.banners.write',
	writeContentHomepage: 'permissions.menu.homepage.write',

	// Settings
	// -------------------------------------------------------------------------------------
	sectionSettings     : 'permissions.section.settings',
	readSettingsGeneral : 'permissions.settings.general.read',
	writeSettingsGeneral: 'permissions.settings.general.write',

	// Logs
	// -----------------------------------------------------------------------------------------
	sectionLogs     : 'permissions.section.logs',
	readLogsBonusess: 'permissions.logs.regFreeSpins.read',
	/*
   readLogsCategory       : 'permissions.logs.category.read',
   readLogsMarket         : 'permissions.logs.market.read',
   readLogsSelection      : 'permissions.logs.selection.read',
   readLogsEvent          : 'permissions.logs.event.read',
   readLogsResult         : 'permissions.logs.result.read',
   readLogsEventSelection : 'permissions.logs.eventSelection.read',
   readLogsEventMarket    : 'permissions.logs.eventMarket.read',
   */

	// Website
	// --------------------------------------------------------------------------------------
	sectionWebsites: 'permissions.section.websites',
	readWebsite    : 'permissions.websites.websites.read',
	writeWebsite   : 'permissions.websites.websites.write',

	// Payment
	// --------------------------------------------------------------------------------------
	sectionPayments: 'permissions.section.payments',
	readPayment    : 'permissions.payments.payments.read',
	writePayment   : 'permissions.payments.payments.write',

	// Security
	// -------------------------------------------------------------------------------------
	sectionSecurity      : 'permissions.section.security',
	readSecurityQuestions: 'permissions.security.questions.read',
	readBlockedIP        : 'permissions.security.blockedIps.read',
	readWhiteList        : 'permissions.security.whitelist.read',

	writeSecurityQuestions: 'permissions.security.questions.write',
	writeBlockedIP        : 'permissions.security.blockedIps.write',
	writeWhiteList        : 'permissions.security.whitelist.write',

	// E-mail
	// ---------------------------------------------------------------------------------------
	sectionEmail      : 'permissions.section.email',
	readEmail         : 'permissions.email.emails.read',
	readEmailTemplates: 'permissions.email.emailTemplates.read',

	writeEmail         : 'permissions.email.emails.write',
	writeEmailTemplates: 'permissions.email.emailTemplates.write',

	// Casino
	// ---------------------------------------------------------------------------------------
	sectionCasino            : 'permissions.section.casino',
	readCasino               : 'permissions.casino.casino.read',
	readCasinoCategories     : 'permissions.casino.games.read',
	readCasinoGames          : 'permissions.casino.gameCategories.read',
	readCasinoCustomProviders: 'permissions.casino.customProviders.read',

	writeCasino               : 'permissions.casino.casino.write',
	writeCasinoCategories     : 'permissions.casino.games.write',
	writeCasinoGames          : 'permissions.casino.gameCategories.write',
	writeCasinoCustomProviders: 'permissions.casino.customProviders.write',

	// BonusingSystem
	// --------------------------------------------------------------------------------------
	sectionBonuses        : 'permissions.section.bonuses',
	readBirthdayBonus     : 'permissions.bonuses.birthday.read',
	readFirstDepositBonus : 'permissions.bonuses.firstDeposit.read',
	readWelcomeBonus      : 'permissions.bonuses.welcome.read',
	readFreeSpinBonus     : 'permissions.bonuses.freeSpins.read',
	readRegistrationBonus : 'permissions.bonuses.registration.read',
	readCashbackBonus     : 'permissions.bonuses.cashback.read',
	readCashbackUsersBonus: 'permissions.bonuses.cashbackUsers.read',
	readFreeBetBonus      : 'permissions.bonuses.freeBets.read',

	writeBirthdayBonus     : 'permissions.bonuses.birthday.write',
	writeFirstDepositBonus : 'permissions.bonuses.firstDeposit.write',
	writeWelcomeBonus      : 'permissions.bonuses.welcome.write',
	writeFreeSpinBonus     : 'permissions.bonuses.freeSpins.write',
	writeFreeBetBonus      : 'permissions.bonuses.freeBets.write',
	writeRegistrationBonus : 'permissions.bonuses.registration.write',
	writeCashbackBonus     : 'permissions.bonuses.cashback.write',
	writeCashbackUsersBonus: 'permissions.bonuses.cashbackUsers.write',

	// Messages
	// -------------------------------------------------------------------------------------
	sectionMessages: 'permissions.section.messages',
	readMessages   : 'permissions.messages.messages.read',
	writeMessages  : 'permissions.messages.messages.write',

	// Affiliates
	// -----------------------------------------------------------------------------------
	sectionAffiliates: 'permissions.section.affiliates',
	readAffiliates   : 'permissions.affiliates.affiliates.read',
	writeAffiliates  : 'permissions.affiliates.affiliates.write',

	// Others
	// ---------------------------------------------------------------------------------------
	sectionOthers            : 'permissions.section.others',
	readProviders            : 'permissions.others.providers.read',
	readLanguage             : 'permissions.others.language.read',
	readMapping              : 'permissions.others.mapping.read',
	readNotificationTemplates: 'permissions.others.notificationTemplates.read',
	readRestrictedIP         : 'permissions.others.restrictedIP.read',
	readSportRegulationType  : 'permissions.others.sportRegulationType.read',
	readWarning              : 'permissions.others.warning.read',

	writeProviders            : 'permissions.others.providers.write',
	writeLanguage             : 'permissions.others.language.write',
	writeMapping              : 'permissions.others.mapping.write',
	writeNotificationTemplates: 'permissions.others.notificationTemplates.write',
	writeRestrictedIP         : 'permissions.others.restrictedIP.write',
	writeSportRegulationType  : 'permissions.others.sportRegulationType.write',
	writeWarning              : 'permissions.others.warning.write',
};


export const API_PERMISSIONS = {
	count     : 'admin_notificationCenter_notificationTemplates_read',
	providers : 'admin_sportManagement_providers_read',
	admins    : 'admin_operatorManagement_personalData_read',
	riskGroups: 'admin_customer-management_risk-group_read',
};

export const API_KEY = {
	count     : 'count',
	admins    : 'admins',
	providers : 'providers',
	riskGroups: 'riskGroups',
};


export const nonPlatformModules = [
	'agents-system/agents',
	'agentPlayers',
	'agent/player',
	'agent/players',
];
