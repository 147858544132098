import { AgentStatus } from '@ceo-betmakers/common-globals';
import { NumberOrNull } from '../../helpers/api/types';
import { TStringOrNull } from '../../containers/Partner/Websites/Inner/tabs/TabProvider/types';

export interface IAgentsInitialState {
	list              : IAgentData[];
	filter            : IAgentFilter;
	transactionFilter : IAgentTransactionFilter;
	autoCompleteData  : IAutoCompleteData;
	baseData          : IAgentBaseData;
	transactions      : IAgentTransaction[];
	agentInfoBaseData : IAgentInfoBaseData;
	UI                : IAgentsUI;
	agentInfo         : IAgentInfo;
	changePassBaseData: IChangePassBaseData;
}

export enum EAgentStatus {
	Active = AgentStatus.ACTIVE,
	Inactive = AgentStatus.INACTIVE,
	Archived = AgentStatus.ARCHIVED,
}

export interface IAgentBaseData  {
	username       : string;
	firstName      : string;
	lastName       : string;
	email          : string;
	currency       : 'USD';
	countryID      : NumberOrNull;
	city           : string;
	address        : string;
	phone          : string;
	password       : string;
	confirmPassword: string;
	status         : EAgentStatus;
}

export interface IAgentInfoBaseData {
	amount : NumberOrNull;
	agentID: NumberOrNull;
}

export interface IChangePassBaseData {
	password       : TStringOrNull;
	confirmPassword: TStringOrNull;
}

export type IAutoCompleteData = {
	[key in EAgentAutoCompleteType]: string[];
};


export interface IAgentData {
	id             : number;
	email          : string;
	password       : string;
	username       : string;
	lastName       : string;
	firstName      : string;
	status         : EAgentStatus;
	playersCount   : number;
	balance        : number;
	countryID      : number;
	city           : string;
	currencyID     : number;
	address        : string;
	phone          : string;
	totalBalanceIn : number;
	totalBalanceOut: number;
}

export interface IAgentResponse {
	email        : string;
	username     : string;
	last_name    : string;
	first_name   : string;
	status_id    : EAgentStatus;
	players_count: number;
	balance      : number;
}

export interface IAgentDataResponse extends IAgentResponse {
	id: number;
}

export interface IAgentInfo {
	id             : number;
	email          : string;
	password       : string;
	username       : string;
	lastName       : string;
	firstName      : string;
	status         : EAgentStatus;
	playersCount   : number;
	balance        : number;
	countryID      : number;
	city           : string;
	address        : string;
	phone          : string;
	totalBalanceIn : number;
	totalBalanceOut: number;
}

export interface IAgentCreatePayload  {
	email     : string;
	username  : string;
	first_name: string;
	last_name : string;
	country   : number;
	city      : string;
	phone     : string;
	password  : string;
	status    : EAgentStatus;
}

export interface IAgentsUI  {
	visible           : boolean;
	listLoading       : boolean;
	modalLoading      : boolean;
	transactionLoading: boolean;
	adjustmentLoading : boolean;
	adjustmentVisible : boolean;
	baseChanged       : boolean;
	agentInfoLoading  : boolean;
	updateLoading     : boolean;
	changePassVisible : boolean;
	changePassLoading : boolean;
}

export interface IAgentFilter {
	id		     : number;
	status   : EAgentStatus;
	email    : string;
	username : string;
	lastName : string;
	firstName: string;
}

export interface IAgentTransactionFilter {
	transactionID: NumberOrNull;
	amount       : [NumberOrNull, NumberOrNull];
	date         : [string, string];
}

export interface IAgentTransactionResponse  {
	id            : number;
	amount        : number;
	balance_before: number;
	balance_after : number;
	date          : string;
	source_id     : number;
	currency      : 'USD';
}

export interface IAgentTransaction {
	transactionID: number;
	amount       : number;
	balanceBefore: number;
	balanceAfter : number;
	date         : string;
	sourceID     : number;
	currencyID   : 1; // USD only
}

export type TAgentFilterKey = keyof IAgentFilter;
export type TAgentDataKey = keyof IAgentData;
export enum EAgentAutoCompleteType {
	Email = 'email',
	Username = 'username',
	FirstName = 'firstName',
	LastName = 'lastName',
}


export type IAgentFilterValidation = {
	[key in TAgentFilterKey]: (value: string) => unknown;
};

export type IAgentFields = {
	[key in TAgentDataKey]: string;
};

export type TAgentTransactionKey = keyof IAgentTransaction;

export type TAgentTransactionFields = {
	[key in TAgentTransactionKey]: string;
};

export interface IAgentAutoCompletePayload {
	value: string;
	type : EAgentAutoCompleteType;
}

export interface IAgentGetStore {
	agentInfoBaseData    : IAgentInfoBaseData;
	filter               : IAgentFilter;
	transactionFilter    : IAgentTransactionFilter;
	transactionPagination: unknown;
	pagination           : unknown;
	sorting              : unknown;
	transactionSorting   : unknown;
	websiteID            : number;
	baseData             : IAgentBaseData;
	agentInfo            : IAgentInfo;
	changePassBaseData   : IChangePassBaseData;
}