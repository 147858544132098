
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import appTabsActions from '../../redux/appTabs/actions';
import { actions as partnerActions } from '../../redux/partner/partners/list/actions';
import { selectNotificationCount } from '../../selectors/notifications';
import { siteConfig } from '../../config';
import '../../../src/containers/App/global.css';
import Topbar from '../../containers/Topbar/Topbar';
import { IMainLayout, ISelectLayoutState } from './types';
import SidebarFC from '../../containers/Sidebar/SidebarFC';
import { MainContentWrapper } from './layout.styles.js';
import { Navigate } from 'react-router';
import { Routes } from '../../routes/routesData';
import UserSearch from '../../containers/CustomerManagement/UserSearch';
import AppTabsManagerFC from '../../containers/AppTabsManager/AppTabsManagerFC';
import { NotificationsRoot } from '../../components/NotificationPortal/NotificationsRoot';
import Helmet from 'react-helmet';
import { intlMessageValue } from '../../redux/utils';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { AppModals } from '../AppModals';
import authActions from '../../redux/auth/actions';
import { RootState } from '../../redux/store';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { hasAgentRole } from '../../helpers/permissions/utils';

const { Footer } = Layout;

const selectStore = (state: RootState): ISelectLayoutState => {
	return {
		activeTabTitle: state.AppTabs.get('activeTabTitle'),
		idToken       : state.Auth.get('idToken'),
		user          : state.Auth.get('user'),
		count         : selectNotificationCount(state),
	};
};
export const MainLayout: React.FC<IMainLayout> = (props) => {
	const dispatch = useAppDispatch();

	const { idToken, user, count, activeTabTitle } = useAppSelector(selectStore);
	const isAgent = hasAgentRole(user?.userRoles as string[]);
	useEffect(() => {
		if (idToken && user) {
			dispatch(partnerActions.partnersWithWebsitesReload());
			dispatch(appTabsActions.tabsReload());
		}
	},[idToken]);

	if (!idToken) {
		return <Navigate to={Routes.signin} />;
	}

	const documentTitleText = () => {
		let documentTitle = intlMessageValue(activeTabTitle);
		if (count > 0) {
			documentTitle = `(${count})\u00A0${intlMessageValue(activeTabTitle)}`;
		}
		return documentTitle;
	};

	if (!user) {
		dispatch(authActions.logout());
		return <div />;
	}
	return (
		<ErrorBoundary id="main-layout">
			<Layout hasSider>
				<Helmet>
					<title>
						{documentTitleText()}
					</title>
				</Helmet>
				<NotificationsRoot />
				<SidebarFC />
				<Layout className="site-layout" >
					<Topbar />
					<MainContentWrapper>
						<AppTabsManagerFC />
						{props.children}
					</MainContentWrapper>

					<Footer style={{ textAlign: 'center', display: 'flex', position: 'sticky', bottom: '0' }}>
						{siteConfig.footerText}
						{!isAgent && <UserSearch />}
					</Footer>

					<AppModals />
				</Layout>
			</Layout>
		</ErrorBoundary>

	);
};

export default MainLayout;
