import React from 'react';
import isArray from 'lodash/isArray';
import IntlMessages from '../../../../../../../components/utility/intlMessages';
import { SpanMandatory, SpanRequired } from '../FormItemWrapper.style';

export const labelWidth = 20;
export const staticService = 0;

export const lang = {
	tabMailingTitle   : <IntlMessages id="websites.modal.mailingService" />,
	tabCdnTitle       : <IntlMessages id="websites.modal.cdn" />,
	tabCRMSTitle      : <IntlMessages id="websites.modal.crms" />,
	tabAffillatesTitle: <IntlMessages id="websites.modal.affillates" />,
	
	host     				: <IntlMessages id="websites.modal.mailingService.host" />,
	port      			: <IntlMessages id="websites.modal.mailingService.port" />,
	protocol   		: <IntlMessages id="websites.modal.mailingService.protocol" />,
	username   		: <IntlMessages id="usersList.filter.userName" />,
	password   		: <IntlMessages id="personalList.modalPerson.password" />,
	email     			: <IntlMessages id="websites.modal.mailingService.email" />,
	fromName  			: <IntlMessages id="websites.modal.mailingService.fromName" />,
	ApiId     			: <IntlMessages id="websites.modal.mailingService.api_id" />,
	ApiKey    			: <IntlMessages id="websites.modal.mailingService.api_key" />,
	MerchantID 		: <IntlMessages id="websites.modal.cdn.merchant_id" />,
	active    			: <IntlMessages id="banners.modal.tabMain.active" />,
	sendTestEmail: <IntlMessages id="websites.modal.mailingService.sendTestEmail" />,
	static    			: <IntlMessages id="websites.modal.mailingService.static" />,
	amazon       : <IntlMessages id="websites.modal.mailingService.amazon" />,
	cloudFlare   : <IntlMessages id="websites.modal.mailingService.cloudFlare" />,
};
export const objectsKeys = {
	apiId        				: 'api_id',
	apiKey       				: 'api_key',
	merchantId    			: 'merchant_id',
	websiteMailing 		: 'website_mailing',
	websiteCrms   			: 'website_crms',
	websiteCdn    			: 'website_cdn',
	websiteAffiliates: 'website_affiliates',
	websiteAffiliate	: 'website_affiliate',
	serviceId     			: 'service_id',
	websiteId     			: 'website_id',
	CustomerIo    			: 'Customer.io',
	richBuddies   			: 'Rich-buddies',
	affise        			: 'Affise',
	url         					: 'url',
};

export const SERVICE_TABS = {
	first : 1,
	second: 2,
};


export const FIELD_NAMES = {
	protocol: 'protocol',
	host  		: 'host',
	from  		: 'from',
	fromName: 'from_name',
	username: 'username',
	password: 'password',
	port  		: 'port',
};


export const formItemLayout = {
	labelCol: {
		// margin: '0 10% 0 0',
		xs: { span: 24 },
		sm: { span: 6  },
		md: { span: 6  },
		lg: { span: 6 },
		xl: { span: 4 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 18 },
		md: { span: 18 },
		lg: { span: 18 },
		xl: { span: 20 },
	},
};
export const isValidHost	= host	=> {
	// eslint-disable-next-line no-useless-escape
	return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(host);
};

export const isValidPort	= port	=>	/^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(port);
export const isValidEmail	= email =>  /\S+@\S+\.\S+/.test(email);

export const mailingFieldsMessage = 'Integration Mailing service\'s fields can\'t be empty !';
export function formItemLabel(label, isRequired) {
	return (
		<SpanRequired mandatory={isRequired}>
			{label}
		</SpanRequired>
	);
}
export const IsEmptyObject = (object, key) => {
	return object ? object[key] : false;
};

export const isEmptyArray = (array, index, key) => {
	if (array.length) {
		return array[index]?.[key]; // TODO remove this '?'
	}
	return '';
};

export const getObject = (dataBody, objectName, index) => {
	return isArray(dataBody[objectName]) ? dataBody[objectName][index] : dataBody[objectName];
};

export const formLabel = (label, isRequired) => {
	return (
		<SpanMandatory mandatory={isRequired}>
			{label}
		</SpanMandatory>
	);
};