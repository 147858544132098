import { call, fork, takeEvery, all, put, select } from 'redux-saga/effects';
import { actions } from './actions';
import { smsGatewaysAPI } from '../../../../helpers/api/smsGateways';
import { adaptSmsGateways, messages, prepareSmsGateways, adaptDataSmsGateway, prepareSmsGatewaysTest } from '../../utils';
import { showError, showSuccess } from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';

const getStore = ({ Settings }) => ({ 
	baseData: Settings.SMS.SMSGateway.get('baseData'),
	entity  : Settings.SMS.SMSGateway.get('entity'),
});

function* createSMSGateway() {
	yield takeEvery(actions.DATA_SAVE, function* ({ data: editMode }) {
		yield put(actions.uiRefresh({ loadEntity: true }));

		const { baseData, entity } = yield select(getStore);
		const { id } = baseData;
		let res;
		try {
			const preparedData = prepareSmsGateways(baseData);
			if (!editMode) {
				res = yield call(smsGatewaysAPI.smsGatewaysCreate, preparedData);
				if (res && res.status === 200) {
					yield put(actions.dataReload());
				}
			} else {
				res = yield call(smsGatewaysAPI.smsGatewaysUpdate, preparedData, id);
				if (res && res.status === 200) {
					const adaptItem = adaptDataSmsGateway(res.data.data);
					entity[id] = adaptItem;
					yield put(actions.entityRefresh(entity));

				}
			}
			yield put(actions.uiRefresh({ loadEntity: false }));
			showSuccess(messages.successSmsGatewaySave);
		} catch (e) {
			showError(messages.errorSmsGatewaySave, e);
			yield put(actions.uiRefresh({ loadEntity: false }));
			logger.log(e);
		}
	});
}

function* listReload() {
	yield takeEvery(actions.RELOAD, function* ({ data: partnerID }) {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(smsGatewaysAPI.smsGatewaysList, partnerID);
			if (res && res.status === 200) {
				const entities = adaptSmsGateways(res.data.data);
				yield put(actions.entityRefresh(entities));
			}
		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		finally {
			yield put(actions.uiRefresh({ loading: false }));
		}
		yield call(makeRequestPartnersSmsGateways, partnerID);
	});
}

function* listReloadByAdmin() {
	yield takeEvery(actions.RELOAD_BY_ADMIN, function* () {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(smsGatewaysAPI.smsGatewaysListByAdmin);
			if ( res && res.status === 200) {
				const adaptData = adaptSmsGateways(res.data.data);
				yield put(actions.entityRefresh(adaptData));
			}
		} catch (e) {
			showError(messages.errorListReload, e);
			logger.log(e);
		}
		yield put(actions.uiRefresh({ loading: false }));
	});
}

function* getByID() {
	yield takeEvery(actions.GET_BY_ID, function* ({ id }) {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(smsGatewaysAPI.smsGatewaysGetByID, id);
			if ( res && res.status === 200) {
				const adaptData = adaptDataSmsGateway(res.data.data);
				yield put(actions.baseDataRefresh(adaptData));
			}
			yield put(actions.uiRefresh({ loading: false }));

		} catch (e) {
			showError(messages.errorListReload, e);
			yield put(actions.uiRefresh({ loading: false }));
			logger.log(e);
		}
	});
}

function* deleteById() {
	yield takeEvery(actions.DELETE_BY_ID, function* ({ id }) {
		yield put(actions.uiRefresh({ loading: true }));
		try {
			const res = yield call(smsGatewaysAPI.smsGatewaysDelete, id);
			if ( res && res.status === 200) {
				yield put(actions.dataReload());
			}
			yield put(actions.uiRefresh({ loading: false }));
			showSuccess(messages.successSmsGatewayDelete);
		} catch (e) {
			showError(messages.errorDelete, e);
			yield put(actions.uiRefresh({ loading: false }));
			logger.log(e);
		}
	});
}

function* sendMessage() {
	// eslint-disable-next-line require-yield
	yield takeEvery(actions.SEND_TEST_MASSAGE, function* ({ data }) {
		const preparedTestData = prepareSmsGatewaysTest(data);
		try {
			const res = yield call(smsGatewaysAPI.smsGatewayTestMessage, preparedTestData);
			if ( res && res.status === 200) {
				showSuccess(messages.successSmsGatewayTestSent);
			}
		} catch (e) {
			showError(messages.errorSmsGatewayTestSent, e);
			logger.log(e);
		}

	});
}

export function* makeRequestPartnersSmsGateways(partnerID) {
	yield put(actions.uiRefresh({ loading: true }));
	try {
		const res = yield call(smsGatewaysAPI.smsGatewaysList, partnerID);
		if (res && res.status === 200) {
			const entities = adaptSmsGateways(res.data.data);
			yield put(actions.entityRefresh(entities));
			return entities;
		}
	} catch (e) {
		showError(messages.errorListReload, e);
		logger.log(e);
	} finally {
		yield put(actions.uiRefresh({ loading: false }));
	}
}

export default function* createSmsGatewaysSaga() {
	yield all([
		fork(listReload),
		fork(listReloadByAdmin),
		fork(createSMSGateway),
		fork(getByID),
		fork(deleteById),
		fork(sendMessage),
	]);
}
