
import { cloneDeep } from 'lodash';
import isArray from 'lodash/isArray';
import { BONUS_TYPES } from '../../../../constants/bonuses';

import ParamsBuilder from '../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../helpers/entityAdapter';
import { isID } from '../../../../helpers/utils';
import { adaptHistoryList } from '../utils';
import QueryToFilterValidaion from '../../../../helpers/queryToFilterValidaion';
import IntlMessages from '../../../../components/utility/intlMessages';
import { createEntities } from '../../../utility';

const { isValidID, isValidString, isValidArray, isValidDateRange } = QueryToFilterValidaion;
export const initFilterTypes = {
	// primary
	playerID          : isValidID,
	casinoGameID      : isValidID,
	statusID          : isValidID,
	currencyID        : isValidID,
	bonusType         : isValidID,
	name              : isValidString,
	affiliateReference: isValidString,
	amount            : isValidArray,
	maxPayoutAmount   : isValidArray,
	maxPayoutPercent  : isValidArray,
	startDate         : isValidDateRange,
	endDate           : isValidDateRange,
	createdDate       : isValidDateRange,
};

export const fields = {
	templateID        : 'template_id',
	id                : 'id',
	name              : 'name',                // string;
	statusID          : 'status_id',           // BonusStatus;
	amount            : 'amount',              // number;
	currencyID        : 'currency_id',         // number;
	currencyIDs       : 'currency_ids',		// number[];
	startDate         : 'start_date',          // Date;
	endDate           : 'end_date',            // Date;
	createdDate       : 'create_date',			// Date;
	gameIDs           : 'game_ids',            // array;
	allow             : 'allow',               // bool;
	casinoGameID      : 'casino_game_id',      // number;
	casinoGameName    : 'casino_game_name',		// string;
	websiteID         : 'website_id',          // number
	categoryIDs       : 'category_ids',
	tagIDs            : 'tag_ids',
	spinsTotal        : 'spins_total',         // number;
	typeID            : 'type_id',             // number;
	affiliateReference: 'affiliate_reference', // string;
	daysToExpire      : 'days_to_expire',      // number;
	wageringTurnover  : 'wagering_turnover',   // number;
	weight            : 'weight',              // number
	logoURL           : 'logo_url',
	maxDepositAmount  : 'max_deposit_amount',
	minDepositAmount  : 'min_deposit_amount',
	depositNumber     : 'deposit_number',
	forced            : 'forced',
	overlap           : 'overlap',             // bool
	way               : 'way',                 // number
	bonusType         : 'bonus_type',
	bonusID           : 'bonus_id',
	nextDeposit       : 'next_deposit',        // bool
	integratorIDs     : 'integrator_ids',
	providerIDs       : 'provider_ids',
	count             : 'count',
	daysToExpireWallet: 'days_to_expire_wallet', 
	daysToExpireBonus : 'days_to_expire_bonus', 
	
	currencies: 'currencies',

	countriesIDs            : 'country_ids',
	countriesAllow          : 'country_allow',
	maxPayoutAmount         : 'max_payout_amount',
	maxPayoutPercent        : 'max_payout_percent', // 0-100 number
	freespinMaxPayoutAmount : 'freespin_max_payout_amount',
	freespinMaxPayoutPercent: 'freespin_max_payout_percent', // 0-100 number
	bonusMaxPayoutAmount    : 'max_payout_amount',
	bonusMaxPayoutPercent   : 'max_payout_percent', // 0-100 number

	//filter
	startDateFrom: 'start_date_from',    // Date;
	startDateTo  : 'start_date_to',      // Date;
	
	endDateFrom: 'end_date_from',      // Date;
	endDateTo  : 'end_date_to',        // Date;

	createdDateFrom: 'create_date_from',	// Date;
	createdDateTo  : 'create_date_to',		// Date;

	freeSpinWageringTurnover: 'free_spin_wagering_turnover',   // number;
	// limits
	betID                   : 'bet_id',
	betPerLine              : 'bet_per_line',
	lines                   : 'lines',
	denominationKey         : 'denomination',
	requiredBetPerLine      : 'required_bet_per_line',
	requiredLines           : 'required_lines',
	denominationValue       : 'denomination_value',
	amountFrom              : 'amount_from',
	amountTo                : 'amount_to',
	titles                  : 'titles',
	title                   : 'title',
	description             : 'description',
	langID                  : 'lang_id',
	gamesIDs                : 'games_ids',
	maxPayoutAmountFrom     : 'max_payout_amount_from',
	maxPayoutAmountTo       : 'max_payout_amount_to',
	maxPayoutPercentFrom    : 'max_payout_percent_from',
	maxPayoutPercentTo      : 'max_payout_percent_to',
};

const listAdapter				= createListAdapter();
const betsAdapter				= createBetsAdapter();
const lineAndDenomination		= createLineAndDenominationAdapter();
const freeSpinCurrencyAdapter	= freeSpinCurrencyPreparer();
const depositCurrencyAdapter	= depositCurrencyPreparer();
const packCurrencyAdapter		= packCurrencyPreparer();
const itemIdapter				= createBonusItemAdapter();

const listAdapterWithTemplate	= createListAdapterWithTemplate();

export function getListParams(filter, sorting, pagination) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	builder.addValue('page', pagination.currentPage);
	builder.addValue('limit', pagination.itemsPerPage);

	builder.addField(rules.isID, 'id', fields.id);
	builder.addField(rules.isID, 'playerID', fields.id);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);
	builder.addField(rules.isID, 'casinoGameID', fields.casinoGameID);
	builder.addField(rules.isID, 'bonusType', fields.bonusType);
	builder.addField(rules.string, 'casinoGameName', fields.casinoGameName);

	builder.addField(rules.isString, 'name', fields.name);
	builder.addField(rules.isBoolean, 'overlap', fields.overlap);
	builder.addField(rules.isNumber, 'way', fields.way);

	builder.addField(rules.isString, 'affiliateReference', fields.affiliateReference);
	builder.addRangeField(rules.isNumberRange, filter.amount, [fields.amountFrom, fields.amountTo]);
	
	builder.addRangeField(rules.isNumberRange, filter.maxPayoutAmount, [
		fields.maxPayoutAmountFrom, 
		fields.maxPayoutAmountTo,
	]);
	builder.addRangeField(rules.isNumberRange, filter.maxPayoutPercent, [
		fields.maxPayoutPercentFrom, 
		fields.maxPayoutPercentTo,
	]);

	builder.addRangeField(rules.isDateTimeRange, filter.startDate, [
		fields.startDateFrom,
		fields.startDateTo,
	]);
	builder.addRangeField(rules.isDateTimeRange, filter.createdDate, [
		fields.createdDateFrom,
		fields.createdDateTo,
	]);
	
	builder.addRangeField(rules.isDateTimeRange, filter.endDate, [
		fields.endDateFrom,
		fields.endDateTo,
	]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------
export function adaptBonusItem(rawData = {}) {
	const titlesAdapter = createTitleAdapter();
	itemIdapter.clearExcludes();
	titlesAdapter.clearExcludes();
	const bonus			= itemIdapter.adapt(rawData);
	bonus.titles		= titlesAdapter.adaptList(bonus.titles);
	bonus.currencyID	= [];
	const someFreeSpinType	= bonus.bonusType === BONUS_TYPES.nextDepositFreeSpin || bonus.bonusType === BONUS_TYPES.depositFreeSpin || bonus.bonusType === BONUS_TYPES.registrationFreeSpin;
	const someDepositType	= bonus.bonusType === BONUS_TYPES.deposit || bonus.bonusType === BONUS_TYPES.nextDepositBonus;
	const somePackType		= bonus.bonusType === BONUS_TYPES.pack || bonus.bonusType === BONUS_TYPES.packNext;
	const currencyAdapted	= 
		someFreeSpinType ? freeSpinCurrencyAdapter.adaptList(bonus.currencies) :
			someDepositType ? depositCurrencyAdapter.adaptList(bonus.currencies) : 
				somePackType ? packCurrencyAdapter.adaptList(bonus.currencies) : packCurrencyAdapter.adaptList(bonus.currencies); 

	bonus.currencies	= currencyAdapted.map(item => {
		bonus.currencyID.push(item.currencyID);
		return {
			...item,
			denom: `${item.denomination || 1}/${item.denominationValue}`,
		};
	});
	if (!Object.keys(bonus.count).length) {
		bonus.count = {
			providers : {},
			categories: {},
		};
	}
	return bonus;
}
export function adaptList(rawData = [], currencies = []) {
	if (!isArray(rawData)) {
		return {};
	}

	listAdapter.clearExcludes();
	const currencyEntities = createEntities(currencies);

	const adaptedData = listAdapter.adaptList(rawData).map((bonus) => {
		const titlesAdapter = createTitleAdapter(bonus);
		titlesAdapter.clearExcludes();
		freeSpinCurrencyAdapter.clearExcludes();
		const providers  = bonus.count.providers ? bonus.count.providers : {};
		const categories  = bonus.count.categories ? bonus.count.categories : {};
		bonus.count = {
			providers : cloneDeep(providers),
			categories: cloneDeep(categories),
		};
		bonus.titles = titlesAdapter.adaptList(bonus.titles);
		bonus.currencies = freeSpinCurrencyAdapter.adaptList(bonus.currencies).map(item => {
			return {
				...item,
				denom: `${item.denomination || 1}/${item.denominationValue}`,
			};
		});
		bonus.currencyCodes = bonus.currencyID.map(cur => currencyEntities[cur] ? currencyEntities[cur].code : cur.toString());
		return bonus;
	});

	const bonusEntities = adaptedData.reduce((next, bonus) => {
		const { id, bonusType } = bonus;
		const uniqueID = `${bonusType}_${id}`;
		bonus.uniqueID = uniqueID;
		next[uniqueID] = bonus;
		return next;
	}, {});

	return {
		bonusEntities,
		adaptedData,
	};
}

export function adaptBets(rawData = []) {

	betsAdapter.clearExcludes();
	const adaptedData = betsAdapter.adaptList(rawData);

	return adaptedData;
}

export function adaptLineAndDenomination(rawData = []) {

	lineAndDenomination.clearExcludes();
	const adaptedData = lineAndDenomination.adapt(rawData);

	return adaptedData;
}

export function adaptHistory(rawData) {
	const arrayFields = [
		fields.gamesIDs,
		fields.affiliateReference,
	];

	const diffOptions = {
		onlyChanged: true,
		excludeKeys: [fields.id, ...arrayFields],
	};


	const dataList = adaptHistoryList(rawData, diffOptions, arrayFields);

	return dataList;
}

// Prepare ----------------------------------------------------------------------------------------
export function prepareBonusData(rawData = {}, freeSpinType = null, bonusType, editMode, showLineAndDenominationInputs) {
	const titlesAdapter = createTitlePrepared(editMode);

	listAdapter.addExcludeField('id');
	listAdapter.addExcludeField('denominationKey');
	listAdapter.addExcludeField('denominationValue');
	listAdapter.addExcludeField('betID');
	if (!showLineAndDenominationInputs) {
		listAdapter.addExcludeField('betPerLine');
		listAdapter.addExcludeField('lines');
	}
	listAdapter.addExcludeField('count');
	const someFreeSpinType	= bonusType === BONUS_TYPES.nextDepositFreeSpin || bonusType === BONUS_TYPES.depositFreeSpin || bonusType === BONUS_TYPES.registrationFreeSpin;
	const someDepositType	= bonusType === BONUS_TYPES.deposit || bonusType === BONUS_TYPES.nextDepositBonus;
	const somePackType		= bonusType === BONUS_TYPES.pack || bonusType === BONUS_TYPES.packNext;

	if (!somePackType) {
		listAdapter.addExcludeField('freeSpinWageringTurnover');
	}
	if (bonusType === 'freeSpins') {
		if (freeSpinType !== BONUS_TYPES.depositFreeSpin ) {
			listAdapter.addExcludeField('depositNumber');
		}
	}
	if (bonusType === BONUS_TYPES.nextDepositFreeSpin || (bonusType === BONUS_TYPES.pack && rawData.nextDeposit)) {
		listAdapter.addExcludeField('depositNumber');
	}

	listAdapter.clearExcludes();

	const preparedData		= listAdapter.prepare(rawData);
	preparedData.titles		= titlesAdapter.prepareList(preparedData.titles);
	preparedData.currencies =
		someFreeSpinType	? freeSpinCurrencyAdapter.prepareList(preparedData.currencies) :
			someDepositType		? depositCurrencyAdapter.prepareList(preparedData.currencies) :
				somePackType		? packCurrencyAdapter.prepareList(preparedData.currencies) :
					packCurrencyAdapter.prepareList(preparedData.currencies);
	if (isID(rawData.id)) {
		preparedData.id = rawData.id;
	}

	preparedData.provider_ids = rawData.providerIDs;

	delete preparedData.create_date;
	delete preparedData.bet_id;

	return preparedData;
}

export function prepareBonusDataWithTemplate(bonusData, bonusType) {
	const titlesAdapter = createTitlePrepared();

	const someFreeSpinType	= bonusType === BONUS_TYPES.nextDepositFreeSpin || bonusType === BONUS_TYPES.depositFreeSpin || bonusType === BONUS_TYPES.registrationFreeSpin;
	const someDepositType	= bonusType === BONUS_TYPES.deposit || bonusType === BONUS_TYPES.nextDepositBonus;
	const somePackType		= bonusType === BONUS_TYPES.pack || bonusType === BONUS_TYPES.packNext;

	if (bonusType !== BONUS_TYPES.depositFreeSpin) {
		listAdapterWithTemplate.addExcludeField('depositNumber');
	}
	if (bonusType !== BONUS_TYPES.nextDepositFreeSpin) {
		listAdapterWithTemplate.addExcludeField('way');
	}
	listAdapterWithTemplate.clearExcludes();

	const preparedData		= listAdapterWithTemplate.prepare(bonusData);
	preparedData.titles		= titlesAdapter.prepareList(preparedData.titles);
	preparedData.currencies =
	someFreeSpinType	? freeSpinCurrencyAdapter.prepareList(preparedData.currencies) :
		someDepositType		? depositCurrencyAdapter.prepareList(preparedData.currencies) :
			somePackType		? packCurrencyAdapter.prepareList(preparedData.currencies) :
				packCurrencyAdapter.prepareList(preparedData.currencies);
	if (isID(bonusData.id)) {
		preparedData.id = bonusData.id;
	}
	return preparedData;
}

// Adapters ---------------------------------------------------------------------------------------
export function createBonusItemAdapter() {
	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id,              'betID', fields.betID);
	adapter.addField(rules.id,              'id', fields.id);
	adapter.addField(rules.intOrNull,       'statusID', fields.statusID);
	adapter.addField(rules.intOrNull,       'templateID', fields.templateID);

	adapter.addField(rules.intOrNull,       'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.intOrNull,       'websiteID', fields.websiteID);
	adapter.addField(rules.intOrNull,       'typeID', fields.typeID);
	adapter.addField(rules.arrayNumber,     'categoryIDs', fields.categoryIDs);
	adapter.addField(rules.arrayNumber,     'tagIDs', fields.tagIDs);
	adapter.addField(rules.string,          'name', fields.name);
	adapter.addField(rules.object,          'count', fields.count);
	adapter.addField(rules.string,			'casinoGameName', fields.casinoGameName);
	adapter.addField(rules.arrayID,			'countriesIDs', fields.countriesIDs);
	adapter.addField(rules.bool,			'countriesAllow', fields.countriesAllow);

	adapter.addField(rules.arrayString,     'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.positiveNumber,  'daysToExpire', fields.daysToExpire);
	adapter.addField(rules.positiveNumber,  'daysToExpireWallet', fields.daysToExpireWallet);
	adapter.addField(rules.positiveNumber,  'daysToExpireBonus', fields.daysToExpireBonus);

	adapter.addField(rules.positiveNumber,  'amount', fields.amount);
	adapter.addField(rules.positiveNumber,  'spinsTotal', fields.spinsTotal);
	adapter.addField(rules.positiveNumber,  'betPerLine', fields.betPerLine);
	adapter.addField(rules.positiveNumber,  'lines', fields.lines);
	adapter.addField(rules.positiveNumber,  'denominationKey', fields.denominationKey);
	adapter.addField(rules.positiveNumber,  'denominationValue', fields.denominationValue);
	adapter.addField(rules.positiveNumber,  'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.positiveNumber,  'freeSpinWageringTurnover', fields.freeSpinWageringTurnover);
	adapter.addField(rules.positiveNumber,  'weight', fields.weight);
	adapter.addField(rules.positiveNumber,  'maxDepositAmount', fields.maxDepositAmount);
	adapter.addField(rules.positiveNumber,  'minDepositAmount', fields.minDepositAmount);
	adapter.addField(rules.positiveNumber,  'depositNumber', fields.depositNumber);
	adapter.addField(rules.positiveNumber,  'bonusType', fields.bonusType);
	adapter.addField(rules.fullDate,        'startDate', fields.startDate);
	adapter.addField(rules.fullDate,        'endDate', fields.endDate);
	adapter.addField(rules.fullDate,        'createdDate', fields.createdDate);
	adapter.addField(rules.arrayNumber,     'gameIDs', fields.gameIDs);
	adapter.addField(rules.bool,            'allow', fields.allow);
	adapter.addField(rules.bool,            'forced', fields.forced);
	adapter.addField(rules.number,          'way', fields.way);
	adapter.addField(rules.isBoolean,       'overlap', fields.overlap);
	adapter.addField(rules.isBoolean,       'nextDeposit', fields.nextDeposit);
	adapter.addField(rules.arrayID,       'integratorIDs', fields.integratorIDs);
	adapter.addField(rules.arrayID,       'providerIDs', fields.providerIDs);

	adapter.addField(rules.string,          'logoURL', fields.logoURL);
	adapter.addField(rules.arrayObject,     'titles', fields.titles);
	adapter.addField(rules.arrayObject, 'currencies', fields.currencies);
	adapter.addField(rules.arrayID, 'currencyID', fields.currencyIDs);
	// Max Payout
	adapter.addField(rules.numberOrNull,  'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'maxPayoutPercent', fields.maxPayoutPercent);
	adapter.addField(rules.numberOrNull,  'bonusMaxPayoutAmount', fields.bonusMaxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'bonusMaxPayoutPercent', fields.bonusMaxPayoutPercent);
	adapter.addField(rules.numberOrNull,  'freespinMaxPayoutAmount', fields.freespinMaxPayoutAmount);
	adapter.addField(rules.numberOrNull,  'freespinMaxPayoutPercent', fields.freespinMaxPayoutPercent);
	adapter.addField(rules.numberOrNull,  'freespinMaxPayoutPercent', fields.freespinMaxPayoutPercent);

	return adapter;
}
export function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id,              'betID', fields.betID);
	adapter.addField(rules.id,              'id', fields.id);
	adapter.addField(rules.intOrNull,       'statusID', fields.statusID);
	adapter.addField(rules.intOrNull,       'templateID', fields.templateID);

	adapter.addField(rules.intOrNull,       'casinoGameID', fields.casinoGameID);
	adapter.addField(rules.intOrNull,       'websiteID', fields.websiteID);
	adapter.addField(rules.intOrNull,       'typeID', fields.typeID);
	adapter.addField(rules.arrayNumber,     'categoryIDs', fields.categoryIDs);
	adapter.addField(rules.arrayNumber,     'tagIDs', fields.tagIDs);
	adapter.addField(rules.string,          'name', fields.name);
	adapter.addField(rules.object,          'count', fields.count);
	adapter.addField(rules.string,			'casinoGameName', fields.casinoGameName);
	adapter.addField(rules.arrayID,			'countriesIDs', fields.countriesIDs);
	adapter.addField(rules.bool,			'countriesAllow', fields.countriesAllow);

	adapter.addField(rules.arrayString,     'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.positiveNumber,  'daysToExpireWallet', fields.daysToExpireWallet);
	adapter.addField(rules.positiveNumber,  'daysToExpireBonus', fields.daysToExpireBonus);
	adapter.addField(rules.positiveNumber,  'amount', fields.amount);
	adapter.addField(rules.positiveNumber,  'spinsTotal', fields.spinsTotal);

	adapter.addField(rules.positiveNumber,  'freeSpinWageringTurnover', fields.freeSpinWageringTurnover);
	adapter.addField(rules.positiveNumber,  'wageringTurnover', fields.wageringTurnover);
	adapter.addField(rules.positiveNumber,  'weight', fields.weight);

	adapter.addField(rules.positiveNumber,  'depositNumber', fields.depositNumber);
	adapter.addField(rules.positiveNumber,  'bonusType', fields.bonusType);
	adapter.addField(rules.ISOStringOrNull, 'startDate', fields.startDate);
	adapter.addField(rules.ISOStringOrNull, 'endDate', fields.endDate);
	adapter.addField(rules.ISOStringOrNull, 'createdDate', fields.createdDate);
	adapter.addField(rules.arrayNumber,     'gameIDs', fields.gameIDs);
	adapter.addField(rules.bool,            'allow', fields.allow);
	adapter.addField(rules.bool,            'forced', fields.forced);
	adapter.addField(rules.number,          'way', fields.way);
	adapter.addField(rules.isBoolean,       'overlap', fields.overlap);
	adapter.addField(rules.isBoolean,       'nextDeposit', fields.nextDeposit);
	adapter.addField(rules.arrayID,       'integratorIDs', fields.integratorIDs);
	adapter.addField(rules.arrayID,       'providerIDs', fields.providerIDs);

	adapter.addField(rules.string,          'logoURL', fields.logoURL);
	adapter.addField(rules.arrayObject,     'titles', fields.titles);
	adapter.addField(rules.arrayObject, 'currencies', fields.currencies);
	adapter.addField(rules.arrayID, 'currencyID', fields.currencyIDs);
	
	return adapter;
}

export function createListAdapterWithTemplate() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id,              'templateID', fields.templateID);
	adapter.addField(rules.intOrNull,       'statusID', fields.statusID);
	adapter.addField(rules.intOrNull,       'websiteID', fields.websiteID);
	adapter.addField(rules.string,          'name', fields.name);
	adapter.addField(rules.arrayID,			'countriesIDs', fields.countriesIDs);
	adapter.addField(rules.bool,			'countriesAllow', fields.countriesAllow);
	adapter.addField(rules.arrayString,     'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.positiveNumber,  'spinsTotal', fields.spinsTotal);
	adapter.addField(rules.positiveNumber,  'weight', fields.weight);
	adapter.addField(rules.number,          'way', fields.way);
	adapter.addField(rules.isBoolean,       'overlap', fields.overlap);
	adapter.addField(rules.ISOStringOrNull, 'startDate', fields.startDate);
	adapter.addField(rules.ISOStringOrNull, 'endDate', fields.endDate);
	adapter.addField(rules.string,          'logoURL', fields.logoURL);
	adapter.addField(rules.arrayObject,     'titles', fields.titles);
	adapter.addField(rules.arrayObject,		'currencies', fields.currencies);
	adapter.addField(rules.arrayID,			'currencyID', fields.currencyIDs);
	adapter.addField(rules.number,			'depositNumber', fields.depositNumber);


	return adapter;
}

export function createBetsAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'betID', fields.betID);

	adapter.addField(rules.positiveNumber, 'betPerLine', fields.betPerLine);
	adapter.addField(rules.positiveNumber, 'lines', fields.lines);

	return adapter;
}

export function createLineAndDenominationAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.bool, 'requiredBetPerLine', fields.requiredBetPerLine);
	adapter.addField(rules.bool, 'requiredLines', fields.requiredLines);

	return adapter;
}

export function createTitlePrepared() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.id,     'langID', fields.langID);

	return adapter;
}

export function createTitleAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.string, 'title', fields.title);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.id,     'langID', fields.langID);
	adapter.addField(rules.id,     'id', fields.id);
	adapter.addField(rules.id,     'bonusID', fields.bonusID);
	adapter.addField(rules.id,     'bonusType', fields.bonusType);

	return adapter;
}

function depositCurrencyPreparer() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'currencyID', fields.currencyID);
	adapter.addField(rules.numberOrNull, 'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull, 'maxPayoutPercent', fields.maxPayoutPercent);
	adapter.addField(rules.numberOrNull, 'minDepositAmount', fields.minDepositAmount);
	adapter.addField(rules.numberOrNull, 'maxDepositAmount', fields.maxDepositAmount);

	return adapter;
}
function freeSpinCurrencyPreparer() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'currencyID', fields.currencyID);
	adapter.addField(rules.numberOrNull, 'maxPayoutAmount', fields.maxPayoutAmount);
	adapter.addField(rules.numberOrNull, 'minDepositAmount', fields.minDepositAmount);
	adapter.addField(rules.number, 'betPerLine', fields.betPerLine);
	adapter.addField(rules.number, 'lines', fields.lines);
	adapter.addField(rules.number, 'denominationValue', fields.denominationValue);
	adapter.addField(rules.number, 'denominationKey', fields.denominationKey);

	return adapter;
}

function packCurrencyPreparer() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.number, 'currencyID', fields.currencyID);
	adapter.addField(rules.numberOrNull, 'maxPayoutAmount', fields.bonusMaxPayoutAmount); // maxPayoutAmount
	adapter.addField(rules.numberOrNull, 'maxPayoutPercent', fields.bonusMaxPayoutPercent); // maxPayoutPercent

	adapter.addField(rules.numberOrNull, 'minDepositAmount', fields.minDepositAmount);
	adapter.addField(rules.numberOrNull, 'maxDepositAmount', fields.maxDepositAmount);

	adapter.addField(rules.number, 'betPerLine', fields.betPerLine);
	adapter.addField(rules.number, 'lines', fields.lines);
	adapter.addField(rules.number, 'denominationValue', fields.denominationValue);
	adapter.addField(rules.number, 'denominationKey', fields.denominationKey);

	adapter.addField(rules.numberOrNull,  'freespinMaxPayoutAmount', fields.freespinMaxPayoutAmount);

	return adapter;
}
export const daysToExpireWalletInfo = <IntlMessages id = "bonuses.tooltip.daysToExpireWalletInfo" />;
export const daysToExpireBonusInfo = <IntlMessages id = "bonuses.tooltip.daysToExpireBonusInfo" />;

export const mergeBonusAndTemplatePerCurrValues = (bonusCurrencies, templateCurrencies) => {
	const templateCurrenciesEntities = createEntities(templateCurrencies, 'currencyID');
	const currencyID = [];
	const currencies = bonusCurrencies.map(item => {
		currencyID.push(item.currencyID);
		return {
			...templateCurrenciesEntities[item.currencyID],
			minDepositAmount: item.minDepositAmount,
		};
	});
	return {
		currencyID,
		currencies,
	};
};

export const createTemplateDependentFiels = (template, countriesList, bonusCreate) => {
	const mergedData = {
		templateValues      : true,
		templateID          : template.id,
		// Steps 1 Main Settings
		daysToExpireBonus   : template.daysToExpireBonus,
		daysToExpireWallet  : template.daysToExpireWallet,
		forced              : template.forced,
		countriesAllowFake  : template.countriesAllow,
		countriesIDs        : template.countriesAllow ? template.countriesIDs : countriesList.filter(c => !template.countriesIDs.includes(c.id)).map(i => i.id),
		countriesRequiring  : template.countriesIDs,
		affiliateReference  : template.affiliateReference,
		hasTemplateAffiliate: !!template.affiliateReference.length,
		hasTemplateCountries: !!template.countriesIDs.length,

		// Step 2 Currency and Wagering Settings
		wageringTurnover   : template.wageringTurnover,
		currencyID         : template.currencyID,
		currencyRequiring  : template.currencyID,
		currencies         : template.currencies,
		currenciesRequiring: template.currencies,

		// Step 3 Game and Bets Configuration
		showRemainingSpinsTotal: template.showRemainingSpinsTotal,
		remainingSpinsTotal    : template.remainingSpinsTotal,
		totalSpins             : template.spinsTotal,
		typeID                 : template.typeID,
		spinsFrom              : template.spinsFrom,
		spinsTo                : template.spinsTo,
		casinoGameID           : template.casinoGameID,
		casinoGameName         : template.casinoGameName,

		// Step 4 Eligibility
		integratorIDs: template.integratorIDs,
		providerIDs  : template.providerIDs,
		gameIDs      : template.gameIDs,
		categoryIDs  : template.categoryIDs,
		tagIDs       : template.tagIDs,
		count        : template.count,
		allow        : template.allow,
		games        : [],
		gamesIDs     : [],

		// Step 5 titles
		titles : template.titles,
		logoURL: template.logoURL,
	};
	if (bonusCreate) {
		mergedData.name				= null;
		mergedData.weight			= null;
		mergedData.deposit			= null;
		mergedData.overlap			= true;
		mergedData.spinsTotal		= null;
		mergedData.depositNumber	= null;
	}
	return mergedData;
};
