import {
	InputWrapper,
	InputGroupWrapper,
	InputSearchWrapper,
	TextAreaWrapper,
	ToggleInputSearchWrapper,
} from './styles/input.style';
import WithDirection from '../../config/withDirection';

import { Input } from 'antd';
import { InputComponent as NativeInput } from './InputComponent';
import TextAreaComponent from './TextAreaComponent';
const { Search, Group } = Input;

const WDStyledInput = InputWrapper(NativeInput);
const StyledInput = WithDirection(WDStyledInput);
const InputPassword = WithDirection(Input.Password);

const WDInputGroup = InputGroupWrapper(Group);
const InputGroup = WithDirection(WDInputGroup);

const WDInputSearch = InputSearchWrapper(Search);
const ToggleInputSearch = ToggleInputSearchWrapper(Search);
const InputSearch = WithDirection(WDInputSearch);
const InputSearchToggle = WithDirection(ToggleInputSearch);

const WDTextarea = TextAreaWrapper(TextAreaComponent);
const Textarea = WithDirection(WDTextarea);

export default StyledInput;
export { InputSearch, InputGroup, Textarea, InputSearchToggle, InputPassword };
